import {useMemo, useState, useCallback} from "react";
import dayjs from "dayjs";
import {useQuery} from "@apollo/client";

import {EmptyList} from "../../components/empty-list";
import {BaseModalArgs, ModalHook, useModal, useNewModal} from "../../modals";
import {Button, InputRow, Text} from "../../components/input";
import {Span, Span4} from "../../components/text";
import {getFieldValue, FeedPostVisitor as Visitor} from "../../data/visitor";
import {downloadCsv} from "../../utils/csv";
import {
	GET_SALESFORCE_INTEGRATION,
	SalesforceSyncResponse,
	SYNC_SALESFORCE_LEADS,
	useMyUser,
	GET_HUBSPOT_INTEGRATION,
	HubspotSyncResponse,
	SYNC_HUBSPOT_CONTACTS,
} from "../../data";
import {useMutationToast, useToast} from "../../toast";
import {Icon} from "../../components/images";
import {Modal} from "../../modals/new";
import {ErrorsLogModal as SalesforceErrorsLogModal} from "../settings/integrations/salesforce";
import {ErrorsLogModal as HubspotErrorsLogModal} from "../settings/integrations/hubspot";

import styles from "./find-out-who-modal.module.scss";

interface FindOutWhoArgs<T> extends BaseModalArgs<T> {
	visitors: Visitor[];
	title?: string;
	subtitle?: string;
}

const SyncSalesforceLeads = () => {
	const {data, loading} = useQuery(GET_SALESFORCE_INTEGRATION);
	const {open, close, modal} = useNewModal({zIndex: 1000});
	const [syncSalesforceLeads, {loading: syncing}] = useMutationToast(SYNC_SALESFORCE_LEADS);
	const {open: errorsLogOpen, modal: errorsLogModal} = useNewModal({zIndex: 1000});
	const toast = useToast();
	const onSync = useCallback(async () => {
		const response = await syncSalesforceLeads();
		const {message, success} = response?.data?.syncSalesforceLeads || ({} as SalesforceSyncResponse);

		toast({
			text: message || (success ? "Salesforce synced successfully!" : "Salesforce sync failed."),
			color: success ? "green" : "red",
		});
	}, [toast, syncSalesforceLeads]);
	const {lastSyncedAt, lastSyncStatus} = data?.salesforceIntegration ?? {};

	if (loading || !data?.salesforceIntegration?.connected) return null;

	return (
		<>
			<Button onClick={open} value="Sync with Salesforce" invert IconComponent={<Icon icon="salesforce" />} />
			<Modal modal={modal} title="Sync with Salesforce" size="small" className={styles.syncModal}>
				<div className={styles.syncModalContent}>
					<Span>Are you sure you want to manually sync your data with Salesforce?</Span>
					<div className={styles.syncStatus}>
						<InputRow position="between">
							<Span color="black">Last sync:</Span>
							<Span bold>{lastSyncedAt ? dayjs(lastSyncedAt).formatAs() : "-"}</Span>
						</InputRow>
						<InputRow position="between">
							<Span color="black">Sync status: </Span>
							{!lastSyncStatus ? (
								<Span>-</Span>
							) : lastSyncStatus === "success" ? (
								<Span color="blue">Successful</Span>
							) : (
								<Span className={styles.openErrors} color="pink" onClick={errorsLogOpen}>
									Sync errors
									<Icon color="pink" icon="goto-link" />
								</Span>
							)}
						</InputRow>
					</div>
				</div>
				<InputRow position="right">
					<Button onClick={close} value="Cancel" disabled={syncing} color="black" invert border={false} />
					<Button onClick={onSync} loading={syncing} value="Sync now" disabled={syncing} color="blue" />
				</InputRow>
			</Modal>
			<SalesforceErrorsLogModal modal={errorsLogModal} />
		</>
	);
};

const SyncHubspotContacts = () => {
	const {data, loading} = useQuery(GET_HUBSPOT_INTEGRATION);
	const {open, close, modal} = useNewModal({zIndex: 1000});
	const [syncHubspotContacts, {loading: syncing}] = useMutationToast(SYNC_HUBSPOT_CONTACTS);
	const {open: errorsLogOpen, modal: errorsLogModal} = useNewModal({zIndex: 1000});
	const toast = useToast();
	const onSync = useCallback(async () => {
		const response = await syncHubspotContacts();
		const {message, success} = response?.data?.syncHubspotContacts || ({} as HubspotSyncResponse);

		toast({
			text: message || (success ? "Hubspot synced successfully!" : "Hubspot sync failed."),
			color: success ? "green" : "red",
		});
	}, [toast, syncHubspotContacts]);
	const {lastSyncedAt, lastSyncStatus} = data?.hubspotIntegration ?? {};

	if (loading || !data?.hubspotIntegration?.connected) return null;

	return (
		<>
			<Button onClick={open} value="Sync with Hubspot" invert IconComponent={<Icon icon="hubspot" />} />
			<Modal modal={modal} title="Sync with HubSpot" size="small" className={styles.syncModal}>
				<div className={styles.syncModalContent}>
					<Span>Are you sure you want to manually sync your data with HubSpot?</Span>
					<div className={styles.syncStatus}>
						<InputRow position="between">
							<Span color="black">Last sync:</Span>
							<Span bold>{lastSyncedAt ? dayjs(lastSyncedAt).formatAs() : "-"}</Span>
						</InputRow>
						<InputRow position="between">
							<Span color="black">Sync status:</Span>
							{!lastSyncStatus ? (
								<Span>-</Span>
							) : lastSyncStatus === "success" ? (
								<Span color="blue" bold>
									{lastSyncStatus ? "Successful" : "-"}
								</Span>
							) : (
								<Span className={styles.openErrors} color="pink" onClick={errorsLogOpen}>
									Sync errors
									<Icon color="pink" icon="goto-link" />
								</Span>
							)}
						</InputRow>
					</div>
				</div>
				<InputRow className={styles.toolbar} position="right">
					<Button onClick={close} value="Cancel" disabled={syncing} color="black" invert border={false} />
					<Button onClick={onSync} loading={syncing} value="Sync now" disabled={syncing} color="blue" />
				</InputRow>
			</Modal>
			<HubspotErrorsLogModal modal={errorsLogModal} />
		</>
	);
};

export function useFindOutWhoModal<T>({visitors, title, subtitle}: FindOutWhoArgs<T>): ModalHook<T> {
	const me = useMyUser();
	const [search, setSearch] = useState("");
	const csvData = useMemo(() => {
		const headers = ["Company", "Website", "Location", "Employees", "Contact", "Date Visited", "Clicks"];
		const searchTrimmed = search.trim();
		const searchLC = search.toLowerCase();

		const items = visitors
			.filter(
				visitors =>
					!searchTrimmed ||
					visitors?.name?.toLowerCase()?.includes(searchLC) ||
					visitors?.as_name?.toLowerCase()?.includes(searchLC) ||
					visitors?.site_url?.toLowerCase()?.includes(searchLC) ||
					visitors?.state?.toLowerCase()?.includes(searchLC) ||
					visitors?.city?.toLowerCase()?.includes(searchLC)
			)
			.map(visitor => [
				getFieldValue(visitor, "name"),
				getFieldValue(visitor, "site_url"),
				getFieldValue(visitor, "location"),
				getFieldValue(visitor, "employees"),
				getFieldValue(visitor, "contact"),
				getFieldValue(visitor, "date_visited"),
				getFieldValue(visitor, "clicks"),
			]);

		return [headers, ...items];
	}, [visitors, search]);

	const isEmpty = csvData.length <= 1;

	const handleExport = useCallback(() => {
		downloadCsv(csvData.slice(1), csvData[0], "find-out-who.csv");
	}, [csvData]);

	return useModal(
		() => ({
			size: "large",
			header: (
				<div className={styles.header}>
					<h3>{title || "Find Out Who"}</h3> {subtitle && <Span4 color={"grey"}>{`${subtitle}`}</Span4>}
				</div>
			),
			body: (
				<>
					<InputRow>
						<Text icon="search" value={search} onChange={setSearch} placeholder="Search" />
						<Button onClick={handleExport} value="Export CSV" />
						{me.role === "admin" && <SyncSalesforceLeads />}
						{me.role === "admin" && <SyncHubspotContacts />}
					</InputRow>

					<div className={styles.items}>
						{csvData.map((row, rowIndex) => (
							<div className={styles.row} key={rowIndex}>
								{row.map((rowItem, rowItemIndex) => (
									<Span key={rowItemIndex} bold={rowIndex === 0} className={styles.rowItem}>
										{rowItem}
									</Span>
								))}
							</div>
						))}
						{isEmpty && <EmptyList text={search ? "No matches found" : "No items"} />}
					</div>
				</>
			),
		}),
		[csvData, search, isEmpty, handleExport, title, subtitle, me?.role]
	);
}
