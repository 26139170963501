import {FC, Fragment, useCallback, useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import classnames from "classnames";
import {useNavigate} from "react-router-dom";

import {count, isURL} from "../../../utils/text";
import {P, Span2} from "../../../components/text";
import {Validate, Validator, required} from "../../../components/input/validation";
import {Button, InputRow, MultiColumn, Text} from "../../../components/input";
import {useMutationToast} from "../../../toast";
import {GET_SLACK_CHANNELS, SET_SLACK_CHANNELS, SlackChannel} from "../../../data/slack";
import {deleteTypename} from "../../../data";
import {ConfirmModal, ModalData} from "../../../modals/new";
import {Card} from "../../../components/card";
import {Icon} from "../../../components/images";
import {Loading} from "../../../components/loading";
import {useNewModal as useModal} from "../../../modals";
import config from "../../../config";

import styles from "./integrations.module.scss";

export const isTeamWebhookUrl = (input: string): boolean =>
	/^(https?:\/\/hooks\.slack\.com\/services\/[A-Za-z0-9]+\/[A-Za-z0-9]+\/[A-Za-z0-9]+)$/.test(input.trim());

export const validTeamWebhook: Validator<string | undefined> = {
	required: true,
	check: value =>
		value && isURL(value) && isTeamWebhookUrl(value) ? false : "You must enter a valid slack webhook URL.",
};

export const SlackCard: FC = () => {
	const navigate = useNavigate();
	const {data, loading} = useQuery<{slackChannels: SlackChannel[]}>(GET_SLACK_CHANNELS);
	const onClick = useCallback(() => navigate("/settings/integrations/slack"), [navigate]);
	return (
		<Card space={false} className={classnames(styles.integrationsCard)}>
			<div className={classnames(styles.header, "space")}>
				<Icon icon="slack-multicolor" width={32} />
				<h5>Slack</h5>
			</div>
			<P color="grey">
				Seamlessly integrate with Slack to enhance team collaboration and bring Cleareview Social’s powerful
				features and functionalities right to your workspace.
			</P>
			<InputRow position="between" className={styles.bottom}>
				<Button invert onClick={onClick} icon="settings" value="Configure" />
				{loading ? (
					<Loading position="input" />
				) : (
					<Span2 color="grey" bold>
						{count(data?.slackChannels, "channel")} configured
					</Span2>
				)}
			</InputRow>
		</Card>
	);
};

export const OpenSlackModal: FC = () => {
	const navigate = useNavigate();
	const onClose = useCallback(() => navigate("/settings/integrations"), [navigate]);
	const {modal: slackModal} = useModal({defaultOpen: true, onClose, maxWidth: "800px"});
	return <SlackModal modal={slackModal} />;
};

const SlackModal: FC<{modal: ModalData}> = ({modal}) => {
	const {data, loading} = useQuery<{slackChannels: SlackChannel[]}>(GET_SLACK_CHANNELS);
	const [hooks, setHooks] = useState<Partial<SlackChannel>[]>();
	const [valid, setValid] = useState(false);
	const [setSlack, {loading: updating}] = useMutationToast(SET_SLACK_CHANNELS);

	const handleSave = (close: ModalData["close"]) =>
		hooks &&
		setSlack({
			variables: {channels: hooks.map(h => deleteTypename(h))},
			onCompleted: close,
		});

	useEffect(() => {
		setHooks(data?.slackChannels ?? []);
	}, [data?.slackChannels, modal.open]);

	const handleDelete = useCallback(
		(idx: number) =>
			setHooks(c => {
				const ret = [...(c ?? data?.slackChannels ?? [])];
				ret.splice(idx, 1);
				return ret;
			}),
		[data?.slackChannels]
	);
	const update = useCallback(
		<T extends keyof SlackChannel>(idx: number, key: T, value: SlackChannel[T]) =>
			setHooks(c => {
				const ret = [...(c ?? data?.slackChannels ?? [])];
				if (idx >= ret.length) return c;
				ret[idx] = {...ret[idx], [key]: value};
				return ret;
			}),
		[data?.slackChannels]
	);

	return (
		<ConfirmModal
			modal={modal}
			title="Slack"
			size="large"
			confirmText="Save"
			disabled={!valid}
			loading={loading || updating}
			onConfirm={handleSave}
		>
			<Validate setStatus={setValid}>
				<MultiColumn className={styles.slack}>
					<P color="grey">Channel</P>
					<P color="grey">Slack Webhook URL</P>
					<div />
					{(hooks ?? data?.slackChannels ?? []).map((c, i) => (
						<Fragment key={i}>
							<Text validate={[required]} value={c.channelName} onChange={v => update(i, "channelName", v)} />
							<Text
								disabled
								validate={[validTeamWebhook]}
								value={c.url}
								onChange={v => update(i, "url", v)}
								type="textarea"
								minRows={1}
							/>
							<Button icon="delete" onClick={() => handleDelete(i)} invert color="black" border={false} />
						</Fragment>
					))}
				</MultiColumn>
			</Validate>
			<Button
				icon="add"
				value="Add Slack Channel"
				onClick={() => {
					window.location.href = config.slackURL;
				}}
				invert
				border={false}
			/>
		</ConfirmModal>
	);
};
