import {ReactElement, useMemo, useState} from "react";
import dayjs from "dayjs";
import {useQuery} from "@apollo/client";

import {Card} from "../../components/card";
import {EmptyList} from "../../components/empty-list";
import {useFindOutWhoModal} from "./find-out-who-modal";
import {Loading} from "../../components/loading";
import {Span1, Span3, Span4} from "../../components/text";
import {DateRange, DateRangeType, useCustomRange} from "../../components/input/date-time/date-range";
import {GET_VISITORS} from "../../data";
import {inflateVisitor} from "../../data/visitor";

import styles from "./find-out-who-card.module.scss";

const minDate = dayjs().add(-10, "year").startOf("year");

export const FindOutWhoCard = (): ReactElement => {
	const [customDate, setCustomDate] = useState<DateRangeType>("1M");
	const {start, end} = useCustomRange(customDate, minDate);

	const variables = useMemo(
		() => ({
			start: start.isBefore(minDate) ? minDate : start,
			end: end.isBefore(minDate) ? minDate : end,
		}),
		[start, end]
	);
	const {data, loading} = useQuery(GET_VISITORS, {
		variables,
	});
	const visitors = useMemo(() => data?.visitors?.items?.map(visitor => inflateVisitor(visitor)) || [], [
		data?.visitors,
	]);

	const isEmpty = !loading && !visitors.length;

	const subtitle = start.isSame(end, "day")
		? start.formatAs("shortDate")
		: `${start.formatAs("shortDate")} - ${end.formatAs("shortDate")}`;

	const findOutWhoModal = useFindOutWhoModal({title: "Find Out Who", subtitle, visitors});

	return (
		<Card className={styles.card}>
			<div className={styles.header}>
				<div>
					<h4>Find Out Who</h4>
					<Span4 color="grey">Companies who have engaged with your content.</Span4>
				</div>
				<Span3 onClick={findOutWhoModal.open} color="blue" bold>
					Full View
				</Span3>
			</div>
			<DateRange
				min={minDate}
				max={dayjs().add(2, "day").startOf("day")}
				value={customDate}
				onChange={setCustomDate}
				className={styles.dateSelector}
				noToggle
			/>

			<div className={styles.items}>
				<div className={styles.header}>
					<Span1 bold className={styles.name}>
						Company
					</Span1>
					<Span1 bold>Visits</Span1>
				</div>
				{loading && <Loading className={styles.loading} />}
				{isEmpty && <EmptyList text="No items" />}
				{visitors.slice(0, 5).map(visitor => (
					<div className={styles.row} key={visitor?.name}>
						<Span1 className={styles.name}>{visitor?.name}</Span1>
						<Span1>{visitor?.clicks}</Span1>
					</div>
				))}
			</div>
		</Card>
	);
};
