import {ReactElement, useMemo, useState} from "react";
import classnames from "classnames";
import dayjs, {Dayjs} from "dayjs";
import {useDrop, useDrag} from "react-dnd";
import {FetchResult} from "@apollo/client";

import {Card} from "../../components/card";
import {CalendarItem, CalendarProps, sortDate} from ".";
import {Span3} from "../../components/text";
import {InputRow, SmallButton} from "../../components/input";
import {useEventModal} from "./event-modal";
import {EventList} from "./calendar-items";
import {Loading} from "../../components/loading";
import {Icon} from "../../components/images";

import styles from "./schedule.module.scss";

const CalendarDragItem = "calendar-item";

interface CalendarMonthItemProps {
	day: Dayjs;
	data: CalendarItem[];
	eventHandler: (data: CalendarItem) => void;
	setDayView: (date: Dayjs) => void;
	updateItem: (item: CalendarItem) => Promise<FetchResult>;
}

interface CalendarEventItemProps {
	item: CalendarItem;
	eventHandler: (data: CalendarItem) => void;
}

const CalendarEventItem = ({item, eventHandler}: CalendarEventItemProps): ReactElement => {
	const [, drag] = useDrag(
		() => ({
			type: CalendarDragItem,
			item,
			collect: monitor => monitor.isDragging(),
			canDrag: item.date?.isAfter(new Date()),
		}),
		[item]
	);
	return (
		<div
			ref={drag}
			className={classnames(styles.item, styles[item.type])}
			key={`${item.type}-${item.id}`}
			onClick={() => eventHandler(item)}
		>
			{item.date?.isAfter(new Date()) && (
				<Icon icon="drag" height={16} color="grey" className={[styles.draggable]} />
			)}
			<EventList data={item} />
		</div>
	);
};

const CalendarMonthItem = ({
	day,
	data,
	eventHandler,
	setDayView,
	updateItem,
}: CalendarMonthItemProps): ReactElement => {
	const start = day.startOf("day");
	const end = start.add(1, "day");
	const [loadingItem, setLoadingItem] = useState(false);
	const [over, drop] = useDrop(
		() => ({
			accept: CalendarDragItem,
			drop: (item: CalendarItem) => {
				if (day.isAfter(new Date())) {
					setLoadingItem(true);
					updateItem({
						...item,
						date: item.date?.set("date", day.date()).set("month", day.month()),
					}).finally(() => setLoadingItem(false));
				}
			},
			collect: monitor => monitor.canDrop() && monitor.isOver(),
			canDrop: (item: CalendarItem) => item.date?.isAfter(new Date()),
		}),
		[day, updateItem]
	);

	const final = data.filter(x => x.date?.isBetween(start, end, null, "[)")).sort(sortDate);
	return (
		<div ref={node => drop(node)} className={styles.events}>
			{loadingItem && <Loading position="absolute" />}
			{over && <div className={styles.over} />}
			{final.slice(0, 2).map(item => (
				<CalendarEventItem key={`${item.type}-${item.id}`} item={item} eventHandler={eventHandler} />
			))}
			{final.length > 2 && (
				<InputRow position="right">
					<SmallButton
						color="black"
						value={`+${final.length - 2} more`}
						onClick={() => setDayView(day)}
						border={false}
						invert
					/>
				</InputRow>
			)}
		</div>
	);
};

export const CalendarMonth = ({
	selectedDay,
	data,
	loading,
	setDayView,
	updateItem,
}: CalendarProps): ReactElement => {
	const weekdays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
	const currentDays = useMemo(() => selectedDay.getWeeksInRange().flatMap(week => week.days), [selectedDay]);
	const today = dayjs();
	const [itemData, setItemData] = useState<CalendarItem>();

	const {open: openEventModal} = useEventModal({calendarItem: itemData});

	const eventHandler = (data: CalendarItem) => {
		setItemData(data);
		openEventModal();
	};

	return (
		<Card className={styles.monthlyCalendar}>
			{loading && <Loading position="absolute" />}
			<div className={styles.days}>
				{currentDays.map((day, index) => (
					<div
						key={day.valueOf()}
						className={classnames(styles.day, !day.isSame(selectedDay, "month") && styles.notCurrent)}
					>
						<div className={styles.dateText}>
							<Span3 bold className={classnames(day.isSame(today, "day") && styles.todaysDay)}>
								{day.format("D")}
							</Span3>
							<Span3 color="grey">{weekdays[index]}</Span3>
						</div>
						<CalendarMonthItem
							day={day}
							data={data}
							eventHandler={eventHandler}
							updateItem={updateItem}
							setDayView={setDayView}
						/>
					</div>
				))}
			</div>
		</Card>
	);
};
