import {KeyboardEvent, ReactElement, UIEvent, forwardRef, useMemo} from "react";
import classnames from "classnames";

import {ColorWithGrey, EmptyComponent} from "../../types";

import styles from "./image.module.scss";

export interface SVGData {
	pathing: ReactElement;
	width: number;
	height: number;
	viewBox?: string;
}

export interface SVGProps extends EmptyComponent {
	onClick?: (() => void) | false;
	svg: SVGData;
	width?: number;
	height?: number;
	tabIndex?: number;
	color?: ColorWithGrey;
}

export const SVG = forwardRef<HTMLDivElement, SVGProps>(
	({color, onClick, className, width, height, svg, tabIndex}, ref) => {
		const [h, w] = useMemo(() => {
			const h = ((width || 0) * svg.height) / svg.width;
			const w = ((height || 0) * svg.width) / svg.height;
			if (width && !height) return [h, width];
			if (height && !width) return [height, w];
			if (width && height) {
				if (h > height) return [(w * svg.height) / svg.width, w];
				if (w > width) return [h, (h * svg.width) / svg.height];
				return [h, w];
			}
			return [svg.height, svg.width];
		}, [height, width, svg]);

		const handleClick = (e: UIEvent<HTMLDivElement>) => {
			if (!onClick) return;
			e.stopPropagation();
			e.preventDefault();
			onClick();
		};
		const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
			if (e.key !== "Enter" && e.key !== " ") return;
			handleClick(e);
		};

		return (
			<div
				className={classnames(className, styles.icon, onClick && styles.onClick, color && styles[color])}
				onClick={handleClick}
				tabIndex={tabIndex ?? (onClick ? 0 : undefined)}
				onKeyDown={handleKeyDown}
				ref={ref}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox={svg.viewBox || `0 0 ${svg.width} ${svg.height}`}
					height={h}
					width={w}
				>
					{svg.pathing}
				</svg>
			</div>
		);
	}
);
SVG.displayName = "SVG";

export const Dot = (props: Omit<SVGProps, "svg">): ReactElement => (
	<SVG
		svg={{
			width: 8,
			height: 8,
			pathing: <path d="M 4,1 A 3,3 0 1,1 4,7 A 3,3 0 1,1 4,1 Z" />,
		}}
		{...props}
	/>
);

export {Arrow} from "./arrow";
export {Icon} from "./icon";
export type {IconProps, IconType} from "./icon";
export type {NavIconProps} from "./nav-icon";
export {Logo} from "./logo";
export {MenuIcon} from "./menu-icon";
export {NavIcon} from "./nav-icon";
