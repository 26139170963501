import dayjs, {Dayjs} from "dayjs";
import {gql} from "@apollo/client";

import {OpenGraph} from "./opengraph";
import {DayOfWeekNumber} from "../types";
import {SUGGESTED_SHARES_FRAGMENT} from "./feed";

export interface RssFeed {
	id: number;
	feedId: number;
	title: string;
	url: string;
	showIndustryNews: boolean;
	autoFeed: boolean;
	dayOfWeek: DayOfWeekNumber[];
	created: string;
	updated: string;
	groups: number[];
	status?: "parsed" | "unparsed" | "error" | "stopped";
	type?: "JDSupra" | "Manzama";
}

export interface RssFeedCategory {
	feedId: number;
	category: string;
	title: string;
}

export interface RssFeedPost {
	id: number;
	feedId: number;
	url?: string;
	sharedAt: Dayjs;
	item: OpenGraph;
	suggestedBy?: number;
	seen: boolean;
}

export const RSS_FEED_POST_FRAGMENT = gql`
	fragment RssFeedPostFields on RssFeedPost {
		id
		feedId
		url
		sharedAt
		item {
			description
			image
			title
		}
		suggestedBy
		seen
	}
`;

export const RSS_FEED_FRAGMENT = gql`
	fragment RssFeedFields on RssFeed {
		id
		feedId
		title
		url
		showIndustryNews
		autoFeed
		dayOfWeek
		groups
		status
		type
	}
`;

export const USER_FEED_FRAGMENT = gql`
	fragment UserFeedFields on UserFeed {
		id
		feedId
		title
		autoFeed
		dayOfWeek
	}
`;

export const CUSTOM_RSS_FEED_FRAGMENT = gql`
	fragment CustomRssFeedFields on RssFeedCategory {
		category
		feedId
		title
	}
`;

export const GET_RSS_FEEDS = gql`
	query {
		rssFeeds {
			...RssFeedFields
		}
	}
	${RSS_FEED_FRAGMENT}
`;

export const GET_USER_RSS_FEEDS = gql`
	query {
		userFeeds {
			...RssFeedFields
		}
		rssFeeds {
			...RssFeedFields
		}
	}
	${RSS_FEED_FRAGMENT}
`;

export const GET_RSS_FEED_POSTS = gql`
	query GetRssFeedPosts(
		$ids: [PositiveInt!]!
		$cursor: String
		$search: String
		$includeSuggestedToAdmins: Boolean
	) {
		rssFeedPosts(
			ids: $ids
			cursor: $cursor
			search: $search
			includeSuggestedToAdmins: $includeSuggestedToAdmins
		) {
			items {
				...RssFeedPostFields
			}
			cursor
		}
	}
	${RSS_FEED_POST_FRAGMENT}
`;

export const SUGGEST_RSS_FEED_POST = gql`
	mutation SuggestRssFeedPost($id: PositiveInt!, $comment: String) {
		suggestRssFeedPost(id: $id, comment: $comment) {
			...RssFeedPostFields
		}
	}
	${RSS_FEED_POST_FRAGMENT}
`;

export const SUGGEST_URL = gql`
	mutation SuggestUrl($url: String!, $comment: String!) {
		suggestUrl(url: $url, comment: $comment) {
			...SuggestedSharesFields
		}
	}
	${SUGGESTED_SHARES_FRAGMENT}
`;

export const HIDE_SUGGESTED_RSS_FEED_POST = gql`
	mutation HideSuggestedRssFeedPost($id: PositiveInt!) {
		hideSuggestedRssFeedPost(id: $id) {
			...RssFeedPostFields
		}
	}
	${RSS_FEED_POST_FRAGMENT}
`;

export const UPDATE_RSS_FEED = gql`
	mutation UpdateRssFeed($id: PositiveInt!, $changes: UpdateRssFeed!) {
		updateRssFeed(id: $id, changes: $changes) {
			...RssFeedFields
		}
	}
	${RSS_FEED_FRAGMENT}
`;

export const DELETE_RSS_FEED = gql`
	mutation DeleteRssFeed($id: PositiveInt!) {
		deleteRssFeed(id: $id) {
			...RssFeedFields
		}
	}
	${RSS_FEED_FRAGMENT}
`;

export const ADD_RSS_FEED = gql`
	mutation AddRssFeed($add: UpdateRssFeed!, $userFeed: Boolean) {
		addRssFeed(add: $add, userFeed: $userFeed) {
			...RssFeedFields
		}
	}
	${RSS_FEED_FRAGMENT}
`;

export const GET_MANZAMA_CATEGORIES = gql`
	query {
		manzamaRssFeedCategories {
			...CustomRssFeedFields
		}
	}
	${CUSTOM_RSS_FEED_FRAGMENT}
`;

export const GET_JDSUPRA_CATEGORIES = gql`
	query {
		jdSupraRssFeedCategories {
			...CustomRssFeedFields
		}
	}
	${CUSTOM_RSS_FEED_FRAGMENT}
`;

export const ADD_CUSTOM_RSS_FEED = gql`
	mutation AddRssFeed($feedId: PositiveInt!, $feedType: RssFeedType!) {
		addCustomRssFeed(feedId: $feedId, feedType: $feedType) {
			...RssFeedFields
		}
	}
	${RSS_FEED_FRAGMENT}
`;

export const GET_RSS_FEED_POST = gql`
	query RssFeedPost($id: PositiveInt!) {
		rssFeedPost(id: $id) {
			...RssFeedPostFields
		}
	}
	${RSS_FEED_POST_FRAGMENT}
`;

export const UPDATE_USER_FEED = gql`
	mutation UpdateUserFeed($id: PositiveInt!, $changes: UpdateUserFeed!) {
		updateUserFeed(id: $id, changes: $changes) {
			...UserFeedFields
		}
	}
	${USER_FEED_FRAGMENT}
`;

export const inflateRssFeedPost = (raw): RssFeedPost => ({
	...raw,
	sharedAt: dayjs(raw.sharedAt),
});

export const feedImage = ({type}: RssFeed) =>
	type === "JDSupra" ? "/jdsupra-feed.png" : type === "Manzama" ? "/manzama-feed.png" : "/rss-feed.png";
