import {
	FocusEvent,
	ReactElement,
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import {validate} from "email-validator";

import {MaybeArray, Setter} from "../../types";
import {isURL} from "../../utils/text";

const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9!@#$%^&*)(+=._-\W]{8,})$/;
type ValidationResult = string | undefined | null | false;
export interface Validator<V> {
	check: (value: V) => ValidationResult;
	required?: boolean;
	immediate?: boolean;
}
export type ValidationCheck<V> = MaybeArray<Validator<V>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const required: Validator<string | number | undefined | Array<any>> = {
	check: value =>
		(Array.isArray(value) ? value.length : value || value === 0) ? false : "You must enter a value.",
	required: true,
};

export const validEmail: Validator<string | undefined> = {
	required: true,
	check: value => (value && validate(value) ? false : "You must enter a valid email address."),
};

export const validPassword: Validator<string | undefined> = {
	required: true,
	check: value =>
		value && passwordRegex.test(value)
			? false
			: "Your password must be at least 8 characters, and contain at least 1 uppercase letter, 1 lowercase letter, and 1 number.",
};

export const validURL: Validator<string | undefined> = {
	required: true,
	check: value => (value && isURL(value) ? false : "You must enter a valid URL."),
};

export const validMaxLength = (max: number): Validator<string | undefined> =>
	({
		required: true,
		check: value => (value && value.length > max ? `Must be ${max} characters or less.` : false),
	} as Validator<string | undefined>);

export const validConfirmPassword = (original?: string): Validator<string | undefined> => ({
	required: true,
	check: value => (value && value !== original ? "Password do not match." : validPassword.check(value)),
});

interface ValidationHook {
	inputProps: {
		error: string | undefined;
		required: boolean;
		onBlur: (e: FocusEvent<HTMLDivElement | HTMLInputElement | HTMLTextAreaElement>) => void;
	};
	error: string | undefined;
	update: () => void;
}

interface ValidationState {
	valid: boolean;
}
type ValidationStatus = Record<string, ValidationState>;
const ValidationContext = createContext<Setter<ValidationStatus> | undefined>(undefined);

export const Validate = ({
	children,
	setStatus,
}: {
	children: ReactNode;
	setStatus: Setter<boolean>;
}): ReactElement => {
	const valid = useRef<ValidationStatus>({});
	const value = useCallback<Setter<ValidationStatus>>(
		(c: ValidationStatus | ((current: ValidationStatus) => ValidationStatus)) => {
			valid.current = typeof c === "function" ? c(valid.current) : c;
			setStatus(Object.values(valid.current).every(c => c.valid));
		},
		[setStatus]
	);
	return <ValidationContext.Provider value={value}>{children}</ValidationContext.Provider>;
};

export function useValidate<V>(id: string, value: V, checks: ValidationCheck<V> | undefined): ValidationHook {
	const setState = useContext(ValidationContext);
	const [error, setError] = useState<string>();
	const shown = useRef(false);
	const lastError = useRef<string>();

	const cx = useMemo(() => (checks ? (Array.isArray(checks) ? checks : [checks]) : []), [checks]);

	useEffect(() => {
		let valid = true;
		let e: string | undefined;
		for (const c of cx) {
			e = c.check(value) || undefined;
			if (e === undefined) continue;

			valid = false;
			if (c.immediate || shown.current) {
				setError(e);
				break;
			}
		}
		lastError.current = e;
		if (e === undefined) setError(undefined);
		setState?.(c => {
			if (c?.[id]?.valid === valid) return c;
			return {...c, [id]: {valid}};
		});
	}, [cx, id, setState, value]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	useEffect(() => () => setState?.(({[id]: x, ...c}) => c), [id, setState]);

	return useMemo(
		() => ({
			inputProps: {
				error,
				onBlur: () => {
					shown.current = true;
					setError(lastError.current);
				},
				required: cx.some(c => c.required),
			},
			error,
			update: () => setError(lastError.current),
		}),
		[cx, error]
	);
}
