import dayjs, {Dayjs} from "dayjs";
import {gql} from "@apollo/client";

import {Metadata} from "./metadata";
import {Option} from "../components/input";
import {api, post} from "../api";
import {BASE_USER_FRAGMENT} from "./user";

export interface OrgVideo {
	id: number;
	url?: string;
	originalUrl: string;
	thumbnailUrl?: string;
	status: string;
	createdBy: number;
}

export interface Image {
	id: number;
	created: Dayjs;
	createdBy: number;
	title: string;
	isDefault: boolean;
	originalUrl: string;
	url: string;
}

export interface Video {
	id: number;
	updated: Dayjs;
	created: Dayjs;
	createdBy: number;
	title: string;
	originalUrl: string;
	url: string;
	status: string;
	thumbnailUrl: string;
	metaData?: string;
}

export interface VideoUploadStatus {
	s3Key: string;
	title: string;
}

export interface PresignedUrl {
	key: string;
	signedUrl: string;
	metadata: {
		[key: string]: string;
	};
}
export interface VideoMeta {
	duration: number;
	videoWidth: number;
	videoHeight: number;
}

export interface Media {
	id: number;
	url: string;
	alt?: string;
	title: string;
	type: "image" | "video";
	isDefault?: boolean;
	thumbnailUrl?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const inflateImage = (raw: Record<string, any>): Image => ({
	id: raw.image_id,
	created: dayjs(raw.created_at),
	createdBy: raw.created_by,
	title: raw.title,
	isDefault: !!raw.is_default,
	originalUrl: raw.original_url,
	url: raw.url,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const inflateUploadedImage = (raw: Record<string, any>): Image => ({
	id: raw.image_id,
	created: dayjs(),
	createdBy: 0,
	title: raw.filename,
	url: raw.file,
	isDefault: false,
	originalUrl: raw.file,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const inflateVideo = (raw: Record<string, any>): Video => ({
	id: Number(raw.org_video_id),
	thumbnailUrl: raw.thumbnail_url,
	created: dayjs(raw.created),
	createdBy: raw.created_by,
	updated: dayjs(raw.updated),
	title: raw.title,
	originalUrl: raw.original_url,
	url: raw.url || raw.original_url,
	status: raw.status,
	metaData: raw.meta_data,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const inflateImages = (raw: Record<string, any>): Image[] => raw.images.map(inflateImage);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const inflateVideos = (raw: Record<string, any>): Video[] => raw.orgVideos.map(inflateVideo);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const inflateMetadata = (raw: Record<string, any>): Metadata => ({
	page: raw.page,
	totalPages: raw.totalPages,
	count: raw.count,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPresignedVideoData = (raw: Record<string, any>): PresignedUrl => ({
	key: raw.key,
	signedUrl: raw.signedUrl,
	metadata: raw.metadata,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const inflatePostedVideoData = (raw: Record<string, any>): Media => ({
	id: raw.video_id,
	url: raw.file,
	title: raw.filename,
	type: "video",
});

export const VALID_FILE_TYPES = [
	"avi",
	"h264",
	"m4v",
	"mp4",
	"mpeg4",
	"mpg",
	"mov",
	"ogg",
	"quicktime",
	"webm",
];

export const uploadVideo = (file: File, preSignedUrl: PresignedUrl): Promise<VideoUploadStatus> =>
	api(
		preSignedUrl.signedUrl,
		"PUT",
		file,
		{
			"Content-Type": file.type,
		},
		false,
		false
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	).then(data => ({s3Key: preSignedUrl.key, title: data ? (data as Record<string, any>).title : file.name}));

export const getPreSignedUrls = (file: File, duration: number, size: number): Promise<PresignedUrl> =>
	post(
		`/videos/generatePreSignedPutUrl?objectName=${encodeURIComponent(file.name)}&contentType=${
			file.type
		}&path=raw`,
		{
			metadata: {
				duration,
				size,
			},
		}
	).then(data => {
		if (!data) {
			throw new Error("Video could not be uploaded");
		}

		return getPresignedVideoData(data);
	});

export const getVideoMetadata = (file: File): Promise<VideoMeta> =>
	new Promise((resolve, reject) => {
		try {
			const video = document.createElement("video");
			video.preload = "metadata";

			video.onloadedmetadata = function () {
				window.URL.revokeObjectURL(video.src);
				resolve({
					duration: video.duration,
					videoWidth: video.videoWidth,
					videoHeight: video.videoHeight,
				});
			};

			video.onerror = function () {
				reject("Invalid video. Please select a video file.");
			};

			video.src = window.URL.createObjectURL(file);
		} catch (e) {
			reject(e);
		}
	});

export const postVideo = (s3Key: string, title: string): Promise<Media> =>
	post(`/videos/video-list`, {
		s3_key: s3Key,
		title,
	}).then(data => inflatePostedVideoData(data ?? {}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const inflateUser = (raw: Record<string, any>): Option<number> => ({
	value: raw.value,
	label: raw.label,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const inflateMediaUsers = (raw: Record<string, any>): Option<number>[] => raw.map(inflateUser);

export const dataUrlToBlob = (dataUrl: string): Blob => {
	const arr = dataUrl.split(",");
	const mimeMatch = arr[0].match(/:(.*?);/) ?? [];
	const mime = mimeMatch[1];
	const bstr = atob(arr[1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new Blob([u8arr], {type: mime});
};

export const ORG_VIDEO_FRAGMENT = gql`
	fragment OrgVideoFields on OrgVideo {
		id
		url
		originalUrl
		thumbnailUrl
		createdBy
		status
	}
`;

export const ORG_IMAGE_FRAGMENT = gql`
	fragment OrgImageFields on OrgImage {
		id
		createdBy
		title
		isDefault
		originalUrl
		url
	}
`;

export const IMAGE_CREATORS = gql`
	query ImageCreators {
		imageCreators {
			...BaseUserFields
		}
	}
	${BASE_USER_FRAGMENT}
`;
export const VIDEO_CREATORS = gql`
	query VideoCreators {
		videoCreators {
			...BaseUserFields
		}
	}
	${BASE_USER_FRAGMENT}
`;

export const UPLOAD_ORG_VIDEO = gql`
	mutation UploadOrgVideo($file: Upload!) {
		uploadOrgVideo(file: $file) {
			...OrgVideoFields
		}
	}
	${ORG_VIDEO_FRAGMENT}
`;

export const GET_PRESIGNED_VIDEO_URL = gql`
	mutation GeneratePreSignedUrl(
		$fileName: String!
		$contentType: String!
		$duration: Float!
		$size: Int!
		$path: String
	) {
		generatePreSignedUrl(
			fileName: $fileName
			contentType: $contentType
			duration: $duration
			size: $size
			path: $path
		) {
			signedUrl
			key
		}
	}
`;

export const GET_VIDEO_LIST = gql`
	query VideoList($page: PositiveInt, $createdBy: PositiveInt, $search: String, $ids: [PositiveInt]) {
		videoList(page: $page, createdBy: $createdBy, search: $search, ids: $ids) {
			page
			totalPages
			count
			items {
				...OrgVideoFields
			}
		}
	}
	${ORG_VIDEO_FRAGMENT}
`;

export const GET_IMAGE_LIST = gql`
	query ImageList($page: PositiveInt!, $createdBy: PositiveInt, $search: String) {
		imageList(page: $page, createdBy: $createdBy, search: $search) {
			page
			totalPages
			count
			items {
				...OrgImageFields
			}
		}
	}
	${ORG_IMAGE_FRAGMENT}
`;

export const DELETE_IMAGE = gql`
	mutation DeleteImage($imageId: PositiveInt!) {
		deleteImage(imageId: $imageId) {
			...OrgImageFields
		}
	}
	${ORG_IMAGE_FRAGMENT}
`;

export const DELETE_VIDEO = gql`
	mutation DeleteVideo($id: PositiveInt!) {
		deleteVideo(id: $id) {
			...OrgVideoFields
		}
	}
	${ORG_VIDEO_FRAGMENT}
`;

export const UPLOAD_MEDIA_IMAGE = gql`
	mutation UploadMediaImage($file: Upload!, $title: String!) {
		uploadMediaImage(file: $file, title: $title) {
			...OrgImageFields
		}
	}
	${ORG_IMAGE_FRAGMENT}
`;

export const UPDATE_MEDIA_IMAGE = gql`
	mutation UpdateMediaImage($imageId: PositiveInt!, $file: Upload, $title: String, $isDefault: Boolean) {
		updateMediaImage(imageId: $imageId, file: $file, title: $title, isDefault: $isDefault) {
			...OrgImageFields
		}
	}
	${ORG_IMAGE_FRAGMENT}
`;

export const SET_URL_FOR_ORG_VIDEO = gql`
	mutation SetUrlForOrgVideo($key: String!, $title: String!) {
		setUrlForOrgVideo(key: $key, title: $title) {
			...OrgVideoFields
		}
	}
	${ORG_VIDEO_FRAGMENT}
`;

export const GET_ORG_VIDEO = gql`
	query GetOrgVideo($id: PositiveInt!) {
		orgVideo(id: $id) {
			...OrgVideoFields

			status
		}
	}
	${ORG_VIDEO_FRAGMENT}
`;

export const UPLOAD_IMAGE_URL = gql`
	mutation UploadImageUrl($url: String!) {
		uploadImageUrl(url: $url)
	}
`;
