import React, {FC, useCallback, useMemo, useState} from "react";
import {useNavigate} from "react-router";
import {useQuery} from "@apollo/client";
import dayjs, {Dayjs} from "dayjs";

import {Card} from "../../components/card";
import {GET_TEAMS_CHANNELS, TeamsChannel} from "../../data/teams";
import {
	ADD_CATEGORY_TO_COLLECTION,
	ADD_POST,
	Category,
	CREATE_CATEGORY,
	Collection,
	GET_CATEGORIES,
	GET_SLACK_CHANNELS,
	DELETE_COLLECTION,
	STOP_ALL_FUTURE_SHARES,
	MovePostVars,
	SlackChannel,
	User,
	getPostEmv,
	useMyUser,
	useCompanies,
	useSimpleUsers,
	UPDATE_ME,
} from "../../data";
import {
	Button,
	CalendarButton,
	DropdownButton,
	IconOption,
	InputHeader,
	InputRow,
	GroupedOptions,
	RichText,
	Separator,
	SmallButton,
	Switch,
	Text,
	UserSelect,
	SearchableSelect,
	Option,
	Checkbox,
	Validate,
} from "../../components/input";
import {Icon} from "../../components/images";
import {Badge} from "../../components/badge";
import {UserAvatar} from "../../components/user-avatar";
import {Span, P1} from "../../components/text";
import {Social} from "../../components/social";
import {PostCategoryList, PostList} from "./post-list";
import {DUPLICATE_COLLECTION, SAVE_COLLECTION} from "../../data/collection";
import {UpdateFunc} from "../../dirty-copy";
import {useConfirmDeleteModal, useConfirmModal, useModal, useNewModal} from "../../modals";
import {useMutationToast, useToast} from "../../toast";
import {usePostSharesModal} from "./post-shares-modal";
import {getCollectionStatusBadge} from ".";
import {useComplexGroup} from "../../data/group";
import {formatMoney} from "../../utils/number";
import {ManageCategories} from "../../modals/collections/manage-categories";
import {useCollectionModal} from "./collection-modal";
import {ModalType} from "./edit";
import {useToastApi} from "../../api";
import {useCollectionConfirmModal} from "./collection-confirm-modal";

import styles from "./post.module.scss";

export interface CollectionEditorProps {
	collection: Collection;
	disabled: boolean;
	flush: () => Promise<void>;
	movePost: (vars: MovePostVars) => void;
	onChange: UpdateFunc<Collection>;
	saved?: "saved" | "saving";
}

type RecipientType = "companyId" | "slackChannel" | "virtualAssistantId" | "group" | "teamsChannel";
type RecipientValue = `${RecipientType}-${string | number}`;

const cleanUpAutoLike = (autoLike: {twitter: boolean; linkedin: boolean}) => ({
	twitter: autoLike.twitter,
	linkedin: autoLike.linkedin,
});

export const CollectionEditor: FC<CollectionEditorProps> = ({
	collection,
	disabled,
	flush,
	movePost,
	onChange,
	saved,
}) => {
	const me = useMyUser();
	const [valid, setValid] = useState(true);
	const [updateMe] = useMutationToast(UPDATE_ME);
	const groups = useComplexGroup();
	const corporateAccounts = useCompanies();
	const hasRbac = useMemo(() => me?.org?.features?.rbac, [me?.org?.features?.rbac]);
	const {users: allUsers} = useSimpleUsers({limit: null, filter: {virtualAssistant: true}});
	const isEMVVisible = useMemo(() => collection.posts.some(p => p.url), [collection]);
	const emvValue = useMemo(
		() => formatMoney(collection.posts.map(getPostEmv).reduce((acc, obj) => acc + obj, 0)),
		[collection.posts]
	);
	const navigate = useNavigate();
	const [deleteCollection, {loading: isDeleting}] = useMutationToast(DELETE_COLLECTION, {
		variables: {id: collection.id},
		onCompleted: () => navigate("/collections"),
	});

	const postIds = useMemo(() => collection.posts.map(({id}) => id), [collection]);
	const [stopAllFutureShares, {loading: stoppingShares}] = useMutationToast(STOP_ALL_FUTURE_SHARES, {
		variables: {id: collection.id, postIds},
	});

	const {open: manageCategories, modal: categoriesModal} = useNewModal({});
	const deleteModal = useConfirmDeleteModal({
		what: collection.title,
		onDelete: () => deleteCollection(),
		deleting: isDeleting,
	});

	const sharesSuccessCount = useMemo(
		() => collection.posts.flatMap(post => post?.shares ?? []).reduce((ac, cur) => ac + cur.successCount, 0),
		[collection.posts]
	);

	const {data: slack} = useQuery<{slackChannels?: SlackChannel[]}>(GET_SLACK_CHANNELS);
	const {data: teams} = useQuery<{teamsChannels?: TeamsChannel[]}>(GET_TEAMS_CHANNELS);
	const {data, refetch} = useQuery<{categories: Category[]}>(GET_CATEGORIES);
	const [addPost, {loading: adding}] = useMutationToast(ADD_POST);
	const [createCategory, {loading: creatingCategory}] = useMutationToast(CREATE_CATEGORY);
	const [addCategory, {loading: addingCategory}] = useMutationToast(ADD_CATEGORY_TO_COLLECTION);
	const [saveCollection, {loading}] = useMutationToast(SAVE_COLLECTION);
	const [duplicateCollection] = useMutationToast(DUPLICATE_COLLECTION, {variables: {id: collection.id}});

	const statusBadge = getCollectionStatusBadge(collection, saved);

	const categories = useMemo(() => data?.categories ?? [], [data?.categories]);
	const addCategories = useMemo(
		() =>
			categories
				.filter(cat => !collection?.categories?.includes(cat.id))
				.map(cat => ({
					onClick: () => addCategory({variables: {id: collection?.id, categoryId: cat.id}}),
					label: cat.name,
				})),
		[addCategory, collection, categories]
	);

	const [saveAutoLikePreferences, setSaveAutoLikePreferences] = useState(false);
	const {open: openCollectionModal} = useCollectionConfirmModal({collection});

	const approveCollections = useCallback(
		(scheduledFor: Dayjs | undefined, type: ModalType, close: () => void) => {
			saveCollection({variables: {id: collection.id, changes: {scheduledFor, status: "APPROVED"}}}).then(
				() => {
					type === "send"
						? flush().then(() => {
								close();
								openCollectionModal();
						  })
						: navigate("/collections");
				}
			);
		},
		[collection.id, saveCollection, flush, navigate, openCollectionModal]
	);

	const {open: schedule} = useCollectionModal({
		collection,
		onApprove: approveCollections,
		type: "schedule",
	});

	const {open: sendNow} = useCollectionModal({
		collection,
		onApprove: approveCollections,
		type: "send",
	});
	const handleSave = useCallback(() => flush().then(() => navigate("/collections")), [navigate, flush]);
	const handleSchedule = useCallback(() => flush().then(schedule), [flush, schedule]);
	const handleSend = useCallback(() => flush().then(sendNow), [flush, sendNow]);

	const recipientCount = useMemo(
		() =>
			Object.values(collection.to)
				.filter(Array.isArray)
				.reduce((sum, array) => sum + (array?.length ?? 0), 0),
		[collection.to]
	);
	const hasPosts = useMemo(() => collection.posts.length > 0, [collection.posts]);
	const {put} = useToastApi();
	const toast = useToast();
	const onSendPreviewClick = useCallback(() => {
		toast({
			color: "blue",
			text:
				"We are currently sending your collection preview. We will let you know as soon as the collection has been sent.",
			timeout: 5,
		});
		put("send preview", `queues/${collection.id}/send-test`, null, resp => {
			if (resp.success) {
				toast({
					color: "blue",
					text:
						"Your test collection has been sent. Please allow a few minutes for the message to appear in your inbox as some spam filters delay delivery.",
					timeout: 5,
				});
			}
		});
	}, [toast, put, collection.id]);

	const updateAutoLikePreferences = useCallback(
		autoLike => {
			updateMe({
				variables: {
					changes: {
						options: {
							autoLikePreferences: {
								twitter: autoLike.twitter,
								linkedin: autoLike.linkedin,
							},
						},
					},
				},
			});
		},
		[updateMe]
	);

	const setAutoLikeHandler = useCallback(
		(autoLike: {twitter: boolean; linkedin: boolean}) => {
			onChange({autoLike});
			if (saveAutoLikePreferences) updateAutoLikePreferences(autoLike);
		},
		[onChange, saveAutoLikePreferences, updateAutoLikePreferences]
	);

	const setAutoLikeAsDefault = useCallback(
		checked => {
			const autoLike = checked ? cleanUpAutoLike(collection.autoLike) : {twitter: false, linkedin: false};
			setSaveAutoLikePreferences(checked);
			updateAutoLikePreferences(autoLike);
		},
		[setSaveAutoLikePreferences, updateAutoLikePreferences, collection.autoLike]
	);

	const {open} = useModal(() => {
		if (!collection) return {body: <></>};
		const autoLikeAll = collection.autoLike.twitter && collection.autoLike.linkedin;

		const selectAllAccountJSX = () => (
			<div className={styles.selectAllAccounts}>
				<Switch
					label={<Span bold>Select All</Span>}
					className={styles.switch}
					onChange={checked => {
						setAutoLikeHandler({twitter: checked, linkedin: checked});
					}}
					value={autoLikeAll}
					condensed
				/>
			</div>
		);

		const accountJSX = (account: "twitter" | "linkedin", {connections: {[account]: connection}}: User) =>
			connection?.connected ? (
				<div className={styles.account}>
					<Social name={account} active={collection.autoLike[account]} size="x-small" />
					<Switch
						label={connection.identifier}
						className={styles.switch}
						onChange={checked => {
							const autoLike = {...cleanUpAutoLike(collection.autoLike), [account]: checked};
							setAutoLikeHandler(autoLike);
						}}
						value={collection.autoLike[account] ?? false}
						condensed
					/>
				</div>
			) : (
				<></>
			);

		const saveAutoLikePreferencesJSX = () => (
			<div className="space">
				<Checkbox
					onChange={setAutoLikeAsDefault}
					value={saveAutoLikePreferences}
					label="Use these as the default Auto-like settings"
				></Checkbox>
			</div>
		);

		const body = me ? (
			<>
				<div className="space">
					<Span>
						By clicking the Auto Share button above, {me.fullName} will automatically like all shares that
						come from this collection on each specified network.
					</Span>
					<br />
					<Span color="grey">You can set this individually for each collection.</Span>
				</div>

				{!me.connections.twitter?.connected && !me.connections.linkedin?.connected ? (
					<p className="no-network-msg">
						<a className="learn-more-link" href="/settings">
							Connect a network
						</a>{" "}
						to get started.
					</p>
				) : (
					<div className="space">
						{selectAllAccountJSX()}
						{accountJSX("linkedin", me)}
						{accountJSX("twitter", me)}
						<Separator horizontal />
						{saveAutoLikePreferencesJSX()}
					</div>
				)}
				{(me.connections.twitter?.connected || me.connections.linkedin?.connected) && (
					<div className="space">
						<a
							href="https://help.clearviewsocial.com/article/amplification-v-2"
							target="_blank"
							rel="noreferrer"
						>
							Learn More
						</a>
					</div>
				)}
			</>
		) : (
			<></>
		);

		const header = <h3>Auto-Like All Shares</h3>;

		return {header, body};
	}, [me, collection, setAutoLikeHandler, setAutoLikeAsDefault, saveAutoLikePreferences]);

	const handleAddPost = () =>
		addPost({
			variables: {
				id: collection.id,
				openGraphData: {
					comment: "",
				},
			},
		});

	const deleteEmptyCollectionModal = useConfirmModal(
		() => ({
			onConfirm: () => deleteCollection(),
			confirming: isDeleting,
			confirmColor: "pink",
			confirmText: "Delete",
			title: "Confirm Delete",
			body: <Span>Collection {collection.title} has no posts. Do you want to delete it?</Span>,
		}),
		[isDeleting, collection, deleteCollection]
	);
	const handleDelete = useCallback(
		(idx: number) => {
			onChange((_val, changes) => {
				if (!changes?.posts) return changes;
				const posts = [...changes.posts].splice(idx, 1);
				return {posts};
			});

			if (
				collection.posts.length <= 1 &&
				idx === 0 &&
				((collection.scheduledFor && collection.status === "approved") || collection.sent)
			) {
				deleteEmptyCollectionModal.open();
			}
		},
		[onChange, deleteEmptyCollectionModal, collection]
	);

	const {open: openStopAllSharesModal} = useConfirmModal(
		() => ({
			title: "You’re about to stop future sharing of all post in this Collection",
			body: (
				<div>
					Are you sure you want to permanently disable sharing of all posts? Once disabled, posts will be
					expired and others will no longer be able to share them to social media.{" "}
					<div>Some posts may already have been shared. Disabling will not recall these shares.</div>
				</div>
			),
			confirming: stoppingShares,
			onConfirm: close =>
				stopAllFutureShares().then(() => {
					if (collection.sent) {
						deleteEmptyCollectionModal.open();
					} else {
						navigate("/collections");
					}

					close();
				}),
		}),
		[stopAllFutureShares, stoppingShares, deleteEmptyCollectionModal, collection.sent, navigate]
	);

	const handleAddCategory = useCallback(
		(categoryName: string) =>
			createCategory({
				variables: {
					name: categoryName,
				},
			}).then(({data: {createCategory: category}}) =>
				addCategory({variables: {id: collection?.id, categoryId: category.id}})
			),
		[createCategory, addCategory, collection]
	);

	const options: IconOption[] = [
		{
			label: "Add New Post",
			onClick: handleAddPost,
			disabled,
		},
		{
			label: "Duplicate Collection",
			onClick: () =>
				duplicateCollection().then(({data}) => navigate(`/collections/${data.duplicateCollection.id}`)),
		},
		...(collection.sent
			? [{label: "Revoke", onClick: openStopAllSharesModal}]
			: [{label: "Delete", onClick: deleteModal.open}]),
	];

	const handleTo = useCallback(
		(v: RecipientValue[]) => {
			const to = {
				slackChannels: [] as string[],
				groupIds: [] as number[],
				virtualAssistantIds: [] as number[],
				companyIds: [] as number[],
				teamsChannels: [] as string[],
			};
			v.forEach(r => {
				const [type, value] = r.split("-", 2);
				to[`${type}s`].push(value);
			});
			onChange({to});
		},
		[onChange]
	);
	const onSelectChange = useCallback(
		to => {
			handleTo(to.map(({value}) => value));
		},
		[handleTo]
	);
	const sendableGroups = useMemo(() => {
		const all = groups.reduce((acc: number[], group) => {
			if (group.name === "Everyone" || (me.groups.includes(group.id) && group?.queueGroupIds?.length)) {
				return [...acc, ...group.queueGroupIds];
			}
			return acc;
		}, []);
		return Array.from(new Set(all));
	}, [groups, me.groups]);
	const sendableCompanyAccounts = useMemo(() => {
		const all = groups.reduce((acc: number[], group) => {
			if (me.groups.includes(group.id) && group?.queueCompanyIds?.length) {
				return [...acc, ...group.queueCompanyIds];
			}
			return acc;
		}, []);
		return Array.from(new Set(all));
	}, [groups, me.groups]);
	const selectOptions = useMemo((): GroupedOptions<RecipientValue>[] => {
		const teamsChannelOptions =
			teams?.teamsChannels?.map(s => ({
				value: `teamsChannel-${s.channelId}` as RecipientValue,
				label: s.channelId,
				icon: "ms-teams" as const,
			})) ?? [];

		const slackChannelOptions =
			slack?.slackChannels?.map(s => ({
				value: `slackChannel-${s.channelId}` as RecipientValue,
				label: s.channelName,
				icon: "slack" as const,
			})) ?? [];
		let groupOptions =
			groups
				?.filter(g => g.queueGroupIds)
				.map(g => ({
					label: g.name,
					value: `groupId-${g.id}` as RecipientValue,
					icon: "team" as const,
					isDisabled: hasRbac ? !sendableGroups.includes(g.id) : false,
				})) ?? [];
		const everyoneGroup = groupOptions.find(option => option.label === "Everyone");
		if (everyoneGroup) {
			groupOptions = [everyoneGroup, ...groupOptions.filter(option => option.label !== "Everyone")];
		}

		const virtualAssistantUsers =
			allUsers
				.filter(u => u.virtualAssistant)
				.map(u => ({
					value: `virtualAssistantId-${u.id}` as RecipientValue,
					label: `${u.fullName}'s VA`,
					icon: "person" as const,
				})) ?? [];
		const corporateAccountUsers =
			corporateAccounts?.map(company => ({
				value: `companyId-${company.id}` as RecipientValue,
				label: company.name,
				icon: "account" as const,
				isDisabled: hasRbac ? !sendableCompanyAccounts.includes(company.id) : false,
			})) ?? [];

		return [
			{
				label: "Groups",
				options: groupOptions,
			},
			{
				label: "Users` Virtual Assistants",
				options: virtualAssistantUsers,
			},
			{
				label: "Company Accounts",
				options: corporateAccountUsers,
			},
			{
				label: "Slack Channels",
				options: slackChannelOptions,
			},
			{
				label: "Teams Channels",
				options: teamsChannelOptions,
			},
		];
	}, [
		groups,
		corporateAccounts,
		allUsers,
		slack?.slackChannels,
		teams?.teamsChannels,
		sendableCompanyAccounts,
		sendableGroups,
		hasRbac,
	]);
	const postSharesModal = usePostSharesModal({posts: collection.posts});

	const getToFromChannel = useCallback(
		(channel: string, valueString: string, selectInd: number): Option<RecipientValue>[] =>
			collection?.to?.[channel].map(item => {
				const value = selectOptions[selectInd].options.find(
					option => `${valueString}-${item}` === option.value
				);
				return value;
			}),
		[collection.to, selectOptions]
	);

	const selectValues = useMemo(
		(): Option<RecipientValue>[] => [
			...getToFromChannel("slackChannels", "slackChannel", 3),
			...getToFromChannel("groupIds", "groupId", 0),
			...getToFromChannel("virtualAssistantIds", "virtualAssistantId", 1),
			...getToFromChannel("companyIds", "companyId", 2),
			...getToFromChannel("teamsChannels", "teamsChannel", 4),
		],
		[getToFromChannel]
	);

	const shareToLabels = useMemo(() => selectValues.map(option => option?.label).join(", "), [selectValues]);

	return (
		<Validate setStatus={setValid}>
			<Card className={styles.collectionEditor}>
				<InputRow position="between">
					<Badge {...statusBadge} />
					{options.some(option => !option.disabled) && (
						<DropdownButton options={options} invert value="Actions" arrow loading={adding} />
					)}
				</InputRow>

				{disabled ? (
					<P1 bold>{collection.title}</P1>
				) : (
					<Text
						value={collection.title}
						onChange={v => onChange({title: v})}
						bare
						size={2}
						disabled={disabled}
					/>
				)}

				{disabled ? (
					<UserAvatar userId={collection.owner} size="extraSmall" name className="space" />
				) : (
					<UserSelect
						value={collection.owner}
						size="extraSmall"
						name
						roles={["admin"]}
						className="space"
						onChange={v => onChange({owner: v})}
						disabled={disabled}
					/>
				)}

				{disabled ? (
					<InputRow className={styles.scheduledForDisplay}>
						<Icon icon="schedule" />
						<Span>{collection.scheduledFor?.formatAs("longDateAndTime")}</Span>
					</InputRow>
				) : (
					<CalendarButton
						color="black"
						size="small"
						onChange={v => onChange({scheduledFor: v})}
						icon="schedule"
						value={collection.scheduledFor}
						emptyValue="Set Schedule"
						disabled={disabled}
						min={dayjs().startOf("day")}
						border={false}
						invert
					/>
				)}

				{disabled ? (
					<InputRow className={styles.shareToLabels}>
						<Span>Recipients: </Span>
						<Span color="grey">{shareToLabels}</Span>
					</InputRow>
				) : (
					<SearchableSelect
						className={styles.searchableSelect}
						onChange={onSelectChange}
						value={selectValues ?? []}
						isMulti
						options={selectOptions}
						bare
						disabled={disabled}
						placeholder="Enter group name, account name or Slack/Teams channel"
						label="Select Recipients:"
						disableDropdownIndicator
					/>
				)}

				<RichText
					value={collection.message}
					onChange={v => onChange({message: v})}
					placeholder="Write a message to your recipients. This text will appear in the email body or the Slack message."
					disabled={disabled}
				/>
				<Separator horizontal />
				<InputHeader>
					<div className={styles.information}>
						<Icon icon="information" color="grey" />
						{isEMVVisible && (
							<>
								<Span bold color="grey">
									Total EMV: {emvValue}
								</Span>
								<Separator />
							</>
						)}
						<Span bold color="grey">
							Total Posts: {collection.posts.length}
						</Span>
					</div>
					<>
						<SmallButton
							value="Auto-Like All Shared Posts"
							onClick={open}
							color={collection.autoLike.twitter && collection.autoLike.linkedin ? "blue" : "black"}
							icon="like"
							disabled={disabled}
							border={false}
							invert
						/>
						{collection.status !== "draft" && (
							<>
								<Separator />
								<SmallButton
									value={`See Shares (${sharesSuccessCount})`}
									onClick={postSharesModal.open}
									border={false}
									invert
								/>
							</>
						)}
					</>
				</InputHeader>

				{!disabled && (
					<DropdownButton
						options={addCategories}
						border={false}
						color="black"
						invert
						value="Add a Category"
						arrow
						disabled={adding || disabled}
						searchBar
						onAdd={handleAddCategory}
						onManage={manageCategories}
						loading={creatingCategory || addingCategory}
					/>
				)}

				{collection.categories ? (
					<PostCategoryList
						categories={categories}
						collection={collection}
						onDelete={handleDelete}
						movePost={movePost}
						disabled={disabled}
					/>
				) : (
					<PostList
						availableCategories={categories}
						posts={collection.posts}
						originalPosts={collection.posts.map(p => p.id).sort((a, b) => a - b)}
						onDelete={handleDelete}
						collectionId={collection.id}
						movePost={movePost}
						disabled={disabled}
					/>
				)}
				{!disabled && (
					<Button
						value="New Post"
						onClick={handleAddPost}
						invert
						border={false}
						icon="add"
						loading={adding}
						disabled={disabled}
					/>
				)}
				<ManageCategories modal={categoriesModal} categories={categories} refetch={refetch} />
				{!disabled && (
					<div className={styles.actionButtons}>
						<Separator horizontal />
						<InputRow className={styles.collectionButtons}>
							<Button
								onClick={handleSave}
								value="Save and Exit"
								color="black"
								invert
								border={false}
								loading={loading}
								disabled={!valid}
							/>
							<Button
								onClick={onSendPreviewClick}
								value="Send Preview"
								invert
								disabled={!hasPosts || !valid}
							/>
							<Button
								onClick={handleSchedule}
								value="Approve and Schedule"
								invert
								disabled={!recipientCount || !hasPosts || !valid}
							/>
							<Button
								onClick={handleSend}
								value="Approve and Send Now"
								disabled={!recipientCount || !hasPosts || !valid}
							/>
						</InputRow>
					</div>
				)}
			</Card>
		</Validate>
	);
};
