import {MutableRefObject, SetStateAction, useCallback, useRef, useState} from "react";

import {Setter} from "./types";

export function useStateRef<T>(val: T): [T, Setter<T>, MutableRefObject<T>] {
	const [value, setter] = useState<T>(val);
	const ref = useRef<T>(val);
	const setFn = useCallback((v: SetStateAction<T>) => {
		const newV = typeof v === "function" ? (v as (cur: T) => T)(ref.current) : v;
		ref.current = newV;
		return setter(newV);
	}, []);
	return [value, setFn, ref];
}
