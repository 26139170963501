import React, {FC, KeyboardEvent, ReactElement, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {useQuery} from "@apollo/client";
import classnames from "classnames";

import {Span, Span2} from "../../components/text";
import {Card} from "../../components/card";
import {Button, CheckboxGroup, InputRow, Separator, Text} from "../../components/input";
import {Loading} from "../../components/loading";
import {useMyUser} from "../../data";
import {GET_TRACKING_COOKIES, inflateTrackingCookie, TrackingCookie} from "../../data/org";

import styles from "./find-out-who.module.scss";

type TagService = "MailChimp" | "HubSpot" | "Vuture" | "Pardot";

const tagMapping = {
	MailChimp: {
		name: "*|FNAME|*%20*|LNAME|*",
		email: "*|EMAIL|*",
	},
	HubSpot: {
		name: "{{ contact.first_name }}%20{{ contact.last_name }}",
		email: "{{ contact.email }}",
	},
	Vuture: {
		name: "{First Name}%20{Last Name}",
		email: "{From Email}",
	},
	Pardot: {
		name: "%%first_name{url}%%%20%%last_name{url}%%",
		email: "%%email%%",
	},
};

const UserRow: FC<{trackingCookie: TrackingCookie}> = ({trackingCookie}) => (
	<div className={styles.gridRow}>
		<Span>{trackingCookie.created.formatAs("shortDate")}</Span>
		<Span>{trackingCookie?.name}</Span>
		<Span>{trackingCookie?.email}</Span>
		<Span>{trackingCookie?.company}</Span>
	</div>
);

const MarketingPlatform: FC<{
	service: TagService;
	currentService: TagService;
	setService: (service: TagService) => void;
}> = ({service, currentService, setService}) => {
	const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>, service: TagService) => {
		switch (e.key) {
			case "Enter":
			case " ":
				setService(service);
				break;
			default:
				return;
		}
		e.stopPropagation();
		e.preventDefault();
	};

	return (
		<div
			className={classnames(styles.serviceContainer, currentService === service && styles.active)}
			onClick={() => setService(service)}
			onKeyDown={e => handleKeyDown(e, service)}
			tabIndex={0}
		>
			<img className={styles.img} src={`/${service}.png`} alt={service} />
		</div>
	);
};

export const FindOutWho = (): ReactElement => {
	const {data, loading} = useQuery(GET_TRACKING_COOKIES);
	const [urlParams, setUrlParams] = useState<string[]>([]);
	const [redirectUrl, setRedirectUrl] = useState("");
	const [service, setService] = useState<TagService>("MailChimp");
	const me = useMyUser();
	const getUrl = useMemo(
		() =>
			`${me.org.options.urlShortener}intellidirect302?id=${me.org.id}&redir=${encodeURIComponent(
				redirectUrl
			)}${
				urlParams.length > 0 ? `&${urlParams.map(val => `${val}=${tagMapping[service][val]}`).join("&")}` : ""
			}`,
		[service, redirectUrl, me.org.id, me.org.options.urlShortener, urlParams]
	);
	const handleCopyClick = async () => {
		try {
			await navigator.clipboard.writeText(getUrl);
		} catch (err) {
			return;
		}
	};

	return (
		<div className={styles.container}>
			<div className={styles.headerText}>
				<h3>Find Out Who</h3>
				<Span>Learn which companies are engaging with your content by creating a trackable URL.</Span>
			</div>
			<Card className={styles.header}>
				<h4>Maximize “Find Out Who”</h4>
				<Span color="grey" className={styles.divider}>
					Trackable URLs allow you to monitor and gather data on user engagement and interactions with your
					email campaign. It&apos;s an easy way to track the effectiveness of your campaign and make informed
					decisions about your marketing strategy. By sending your customers through a trackable URL on
					Clearview Social, you can &quot;tag&quot; their browser which will give you advanced ‘Find Out Who’
					results on engaged content.
				</Span>
				<h4>Suggested ways to track your users</h4>
				<div className={styles.headerText}>
					<h5>Add a trackable URL to a mass email campaign</h5>
					<Span color="grey">
						If you&apos;re running a mass email campaign, it&apos;s a good idea to use a trackable URL.
						Instead of directing users to a specific URL like &quot;https://yourfirm.com/event&quot; in your
						marketing email, we recommend replacing it with a trackable URL.
					</Span>
				</div>
				<div className={styles.headerText}>
					<h5>Add a trackable URL to a sign up page</h5>
					<Span color="grey">
						You can send people through a trackable URL when they sign up for an event or registration on your
						website. This helps you track their engagement and interactions more effectively.
					</Span>
				</div>
			</Card>

			<Card className={styles.card}>
				<InputRow position="between">
					<h4>Build your trackable URL</h4>
					<h4>
						<Span2 color="pink">*</Span2> Required Fields
					</h4>
				</InputRow>

				<InputRow className={styles.selectMarketingContainer}>
					<h4>
						1. Select your marketing platform <Span2 color="pink">*</Span2>
					</h4>
					<MarketingPlatform service="MailChimp" setService={setService} currentService={service} />
					<MarketingPlatform service="HubSpot" setService={setService} currentService={service} />
					<MarketingPlatform service="Vuture" setService={setService} currentService={service} />
					<MarketingPlatform service="Pardot" setService={setService} currentService={service} />
				</InputRow>
				<InputRow className={styles.selectMarketingContainer}>
					<h4>
						2. Enter your content URL <Span2 color="pink">*</Span2>
					</h4>
					<Text placeholder="Enter company website here" value={redirectUrl} onChange={setRedirectUrl}></Text>
				</InputRow>
				<InputRow className={classnames([styles.selectMarketingContainer, styles.checkboxInputRow])}>
					<h4>3. Select data you would like to track</h4>
					<CheckboxGroup
						className={styles.checkboxGroup}
						options={[
							{value: "name", label: "Name"},
							{value: "email", label: "Email"},
						]}
						value={urlParams}
						onChange={setUrlParams}
					/>
				</InputRow>
				{redirectUrl && (
					<>
						<Separator horizontal />
						<InputRow className={styles.selectMarketingContainer}>
							<Text value={getUrl} disabled onChange={() => undefined} />
							<Button onClick={handleCopyClick} value="Copy to clipboard" invert />
						</InputRow>
					</>
				)}
			</Card>

			<Card className={styles.card}>
				<div className={styles.header}>
					<h4>Tracking your users</h4>
					<Span color="grey">
						Users that have been tagged (by using the URL you created above) and have interacted with content
						that has been shared will show up under ‘Find Out Who’ on your Recent Shares on each user&apos;s{" "}
						<Link to="/analytics/personal">personal analytics</Link> page.
					</Span>
				</div>

				<Card className={styles.smallCard}>
					{loading ? (
						<Loading position="center" />
					) : (
						<>
							<h4>Recently Tagged Customers</h4>
							<div className={styles.grid}>
								<div className={styles.gridHeader}>
									<Span>Date</Span>
									<Span>Name</Span>
									<Span>Email</Span>
									<Span>Company</Span>
								</div>
								<Separator horizontal />

								{data?.trackingCookies?.map((rawTracking, index) => (
									<UserRow key={index} trackingCookie={inflateTrackingCookie(rawTracking)} />
								))}
							</div>
						</>
					)}
				</Card>
			</Card>
		</div>
	);
};
