import {FC, ReactElement, useCallback, useEffect, useMemo, useState} from "react";
import {useQuery} from "@apollo/client";
import timeZones from "timezones-list";

import config from "../../config";
import {Connection, Social} from "../../components/social";
import {
	Button,
	Checkbox,
	InputRow,
	MultiColumn,
	Select,
	Separator,
	Text,
	required,
	validEmail,
	Switch,
} from "../../components/input";
import {P, Span, Span1, Span3, Span4} from "../../components/text";
import {Loading} from "../../components/loading";
import {Collapsible} from "../../components/card";
import {Badge} from "../../components/badge";
import {Validate, validMaxLength} from "../../components/input/validation";
import {HourPicker} from "../../components/input/date-time";
import {useDirtyCopy} from "../../dirty-copy";
import {useMutationToast, useToast} from "../../toast";
import {PeakTimeSelector} from "./account";
import {
	Company,
	loadCompany,
	ADD_COMPANY,
	CONNECT_COMPANY,
	DELETE_COMPANY,
	DISCONNECT_COMPANY,
	UPDATE_COMPANY,
	GET_COMPANY_PAGES,
} from "../../data/company";
import {
	Connection as ConnectionType,
	accountNames,
	ConnectionProfile,
	GET_USER_CONNECTION_PAGES,
	Service,
	services,
	useGroupList,
} from "../../data";
import {useConfirmDeleteModal, useConfirmModal, useNewModal as useModal, ConfirmModal} from "../../modals";
import {REGISTER_PAGE_EVENT} from "../../data/badges";

import styles from "./corporate.module.scss";

export const PageConnection: FC<{
	account: Service;
	connection: ConnectionType;
	onConnect: (account: Service) => void;
	onDisconnect: (account: Service) => void;
	company: Company;
	update: (company: Partial<Company>) => void;
	disconnecting: boolean;
	availablePages: ConnectionProfile[];
}> = ({account, onConnect, onDisconnect, connection, company, update, disconnecting, availablePages}) => {
	const {groups} = useGroupList();
	const [enableAutoLike, setEnableAutoLike] = useState(
		company[`${account}LikeAmplifiedGroupIds`]?.length > 0
	);
	const [enableAutoShare, setEnableAutoShare] = useState(
		company[`${account}ShareAmplifiedGroupIds`]?.length > 0
	);
	const personalizedIdentifier = useMemo(
		() =>
			`${account === "twitter" || account === "instagram" ? "@" : account === "facebook" ? "/" : ""}${
				connection?.displayName || connection?.identifier
			}`,
		[account, connection?.identifier, connection?.displayName]
	);
	const handleAutoShare = (checked: boolean) => {
		if (checked) {
			return setEnableAutoShare(true);
		}

		setEnableAutoShare(false);
		update({[`${account}shareAmplifiedGroupIds`]: []});
	};
	const handleAutoLike = (checked: boolean) => {
		if (checked) {
			return setEnableAutoLike(true);
		}

		setEnableAutoLike(false);
		update({[`${account}LikeAmplifiedGroupIds`]: []});
	};
	const hideAutoLikeFeature = account === "instagram" || account === "facebook";
	return (
		<div className={styles.social}>
			<div className={styles.header}>
				<InputRow position="left">
					<Social
						hasError={connection?.expired}
						size="small"
						name={account}
						active={connection?.connected}
						onClick={() => onConnect(account)}
					/>
					<Button
						onClick={() => onConnect(account)}
						value={accountNames[account]}
						color="black"
						invert
						border={false}
					/>
				</InputRow>
				<Span color="grey" className={styles.infoSocial}>
					{account === "twitter" && (
						<>
							To connect to the company&apos;s X, first log out of your personal account. Use private browsing
							or another browser to ensure the correct account connects.
						</>
					)}
					{account === "facebook" && (
						<>
							To link the company&apos;s Facebook, ensure you&apos;re logged out of any personal account
							first. Using private browsing or a different browser can help avoid confusion.
						</>
					)}
					{account === "linkedin" && (
						<>
							For secure LinkedIn Company Page connection, log out of personal LinkedIn accounts prior to
							clicking the LinkedIn button.
						</>
					)}
					{account === "instagram" && (
						<>
							Connect your Instagram Business Account to enhance your team&apos;s social sharing capabilities.{" "}
							<Span color="grey" className={styles.infoItalic}>
								Remember to follow the setup instructions carefully for a successful connection.
							</Span>
						</>
					)}
				</Span>
			</div>
			<div className={styles.actions}>
				{connection?.connected ? (
					<>
						<InputRow position="between">
							<InputRow position="left">
								<Button
									onClick={() => onConnect(account)}
									value={personalizedIdentifier}
									border={false}
									invert
								/>
								{connection.expired && <Badge text="Expired" color="pink" />}
							</InputRow>
							<Button
								loading={disconnecting}
								onClick={() => onDisconnect(account)}
								value="Remove account"
								color="black"
								invert
								border={false}
							/>
						</InputRow>
						{!hideAutoLikeFeature && (
							<div>
								<Switch value={enableAutoLike} onChange={handleAutoLike} label="Auto-Like Posts by others" />
								{enableAutoLike && (
									<Select
										multi
										pills
										disabled={!enableAutoLike}
										value={company[`${account}LikeAmplifiedGroupIds`]}
										label={
											<Span>
												Select the groups that you&apos;d like{" "}
												<Span color="black" bold>
													{personalizedIdentifier}
												</Span>{" "}
												to auto-like
											</Span>
										}
										options={groups}
										onChange={groups => update({[`${account}LikeAmplifiedGroupIds`]: groups})}
									/>
								)}
							</div>
						)}
						{!hideAutoLikeFeature && <Separator horizontal />}
						<div>
							<Switch
								disabled={account === "facebook" || account === "instagram"}
								value={enableAutoShare}
								onChange={handleAutoShare}
								label="Auto Re-Share posts by others"
								text={<Span color="grey">Re-share others’ most popular posts to social media* daily.</Span>}
							/>
							{enableAutoShare && (
								<>
									<InputRow>
										<HourPicker
											disabled={!enableAutoShare}
											label="Re-Share daily at:"
											labelClassName={styles.hourPickerLabel}
											value={company.queueDefaultTime}
											onChange={v => update({queueDefaultTime: v})}
										/>
										<Select
											options={timeZones.map(t => ({label: t.label, value: t.tzCode}))}
											value={company.timezone}
											onChange={v => update({timezone: v})}
										/>
									</InputRow>
									<Select
										multi
										pills
										value={company[`${account}ShareAmplifiedGroupIds`]}
										disabled={!enableAutoShare}
										label={
											<Span>
												Select the groups that you&apos;d like{" "}
												<Span color="black" bold>
													{personalizedIdentifier}
												</Span>{" "}
												to auto-share
											</Span>
										}
										options={groups}
										onChange={groups => update({[`${account}ShareAmplifiedGroupIds`]: groups})}
									/>
								</>
							)}
						</div>

						{connection.expired && account === "twitter" && (
							<Button value="Reconnect" onClick={() => onConnect(account)} />
						)}

						{account === "facebook" && (
							<Span4 color="grey">
								*Facebook Pages are not currently supported for re-sharing. To learn more about why we&apos;re
								not able to support this, please refer to{" "}
								<Span4
									href="https://developers.facebook.com/docs/graph-api/reference/v18.0/object/sharedposts#publish"
									color="blue"
								>
									Facebook&apos;s API
								</Span4>{" "}
								Limits doc.
							</Span4>
						)}
						{account === "instagram" && (
							<Span4 color="grey">
								*Instagram Pages are not currently supported for re-sharing or liking. To learn more about why
								we&apos;re not able to support this, please refer to{" "}
								<Span4
									href="https://developers.facebook.com/docs/instagram-api/reference/ig-media"
									color="blue"
								>
									Instagram&apos;s API Limits doc.
								</Span4>
							</Span4>
						)}
					</>
				) : availablePages?.length > 0 || account === "twitter" ? (
					<Button onClick={() => onConnect(account)} value={`Add ${accountNames[account]}`} />
				) : account === "instagram" ? (
					<div>
						<Span>
							To connect your Clearview Social Company Account to an Instagram Business Account, you must
							first do the following:
						</Span>
						<ul>
							<li>
								<Span3 href="https://help.instagram.com/502981923235522" color="blue">
									Set Up an Instagram Business Account.
								</Span3>
							</li>
							<li>
								<Span3 href="https://help.instagram.com/570895513091465" color="blue">
									Connect a Facebook Page to your Instagram Business Account on the Instagram Mobile App.
								</Span3>
							</li>
							<li>
								<Span3 href={`${config.appUrl}/settings/personal`} color="blue">
									If you have connected to your Facebook profile, you must go to the User Settings page,
									disconnect and reconnect your Facebook Profile connection (or connect for the first time if
									you have not previously connected), and approve of permissions for your Facebook Page and
									Instagram Page.
								</Span3>
							</li>
							<li>
								<Span3>
									In the &quot;Facebook&quot; section of this modal, connect to the same Facebook Page that
									you&apos;ve connected to your Instagram Business Account App.
								</Span3>
							</li>
						</ul>
						<P>
							Once you&apos;ve connected your Clearview Social Company Account to your Facebook Page, your
							Instagram Business Account should be available in this window.
						</P>
					</div>
				) : (
					<P>
						There are no available {accountNames[account]} pages to select. You must first connect to{" "}
						{accountNames[account]} before you can see pages you may use.
					</P>
				)}
			</div>
		</div>
	);
};

export const CorporateAccountHeader: FC<{account: Company}> = ({account}) => (
	<div className={styles.profileHeader}>
		<div className={styles.profile}>
			<Span1>{account.name}</Span1>
			<Span4>{account.email}</Span4>
		</div>
		<InputRow className={styles.corporateInfoContainer}>
			{account.virtualAssistant && <Badge color="blue" text="VA" />}
			{account.peakTime && <Badge color="blue" text="PeakTime" />}
			<div className={styles.networkIcons}>
				{services.map(name => (
					<Connection key={name} name={name} connections={account.connections} />
				))}
			</div>
		</InputRow>
	</div>
);

export const CorporateAccount: FC<{account: Company}> = ({account}) => {
	const [updateCompany] = useMutationToast(UPDATE_COMPANY);
	const [deleteCompany, {loading: deleting}] = useMutationToast(DELETE_COMPANY);
	const [disconnectCompany, {loading: disconnecting}] = useMutationToast(DISCONNECT_COMPANY);
	const [connectCompany, {loading: connecting}] = useMutationToast(CONNECT_COMPANY);
	const [selectedConnectPage, setSelectedConnectPage] = useState<Service>();
	const {data: connectedPages, loading: loadingPages} = useQuery(GET_USER_CONNECTION_PAGES);
	const [registerPageEvent] = useMutationToast(REGISTER_PAGE_EVENT);
	const [valid, setValid] = useState(true);
	const {update, val, inputFunc} = useDirtyCopy(account, {
		debounce: 2000,
		onUpdate: ({discard, changes}) =>
			updateCompany({
				variables: {
					id: val.id,
					...changes,
				},
				onCompleted: discard,
			}),
		invalid: valid,
	});

	const selectedConnectedPages = useMemo(
		() =>
			selectedConnectPage && connectedPages && connectedPages.userConnectionPages[selectedConnectPage]
				? connectedPages.userConnectionPages[selectedConnectPage]
				: [],
		[connectedPages, selectedConnectPage]
	);
	const toast = useToast();
	const [selectedPage, setSelectedPage] = useState<ConnectionProfile>();
	const {open, close} = useConfirmModal(() => {
		const readableAccountName = selectedConnectPage ? accountNames[selectedConnectPage] : "";
		const handleLink = () =>
			connectCompany({
				variables: {
					type: selectedConnectPage?.toUpperCase(),
					id: val.id,
					pageId: selectedPage?.id,
				},
				onCompleted: () => {
					close();
					toast({color: "green", text: "Social media linked.", icon: "circle-check"});
					if (selectedConnectPage !== "instagram") {
						registerPageEvent({
							variables: {
								type: `add-${selectedConnectPage}`,
								userId: val?.id,
							},
						});
					}
					setSelectedPage(undefined);
					setSelectedConnectPage(undefined);
				},
			});

		return {
			title: `Link a Corporate ${readableAccountName} Account`,
			size: "medium",
			body:
				loadingPages || !readableAccountName ? (
					<Loading position="center" />
				) : (
					<div className={styles.connectPageContainer}>
						<Span color="grey">
							To link the company&apos;s {readableAccountName}, ensure you&apos;re logged out of any personal
							account first. Using private browsing or a different browser can help avoid confusion.
						</Span>
						<Select
							options={selectedConnectedPages.map(page => ({
								value: page,
								label: page.fullName,
							}))}
							value={selectedPage}
							onChange={setSelectedPage}
						/>
					</div>
				),
			onConfirm: handleLink,
			confirmText: "Link",
			confirming: connecting,
		};
	}, [
		selectedConnectedPages,
		loadingPages,
		selectedConnectPage,
		selectedPage,
		toast,
		connectCompany,
		val?.id,
		connecting,
		registerPageEvent,
	]);

	const handleDelete = useCallback(
		(close: () => void) =>
			deleteCompany({
				variables: {id: val.id},
				update(cache, {data}) {
					if (data) {
						cache.evict({id: `Company:${data.deleteCompany.id}`, broadcast: false});
						cache.gc();
					}
				},
				onCompleted: close,
			}),
		[deleteCompany, val.id]
	);

	const deleteModal = useConfirmDeleteModal({what: "this company account", onDelete: handleDelete, deleting});

	const handleConnect = (account: Service) => {
		if (account !== "instagram") {
			registerPageEvent({
				variables: {
					type: `add-${account}`,
					userId: val?.id,
				},
			});
		}
		if (
			val.connections[account]?.expired ||
			(!val.connections[account]?.connected &&
				(!connectedPages?.userConnectionPages || connectedPages.userConnectionPages[account]?.length === 0))
		) {
			if (account === "twitter") {
				return window.open(`${config.twitterUrl}/company-pages/?company_user_id=${val.id}`, "_self");
			}
			return window.open(
				`${
					config[`${account === "instagram" ? "facebook" : account}Url`]
				}?redirect_override=settings/accounts`,
				"_self"
			);
		}

		if (val.connections[account]?.connected) {
			if (val.connections[account]?.url) {
				return window.open(val.connections[account]?.url, "_blank");
			}

			if (account === "twitter") {
				return window.open(`https://twitter.com/${val.connections.twitter?.identifier}`, "_blank");
			}
			if (account === "facebook") {
				return window.open(
					`https://facebook.com/profile.php?id=${val.connections.facebook?.identifier}`,
					"_blank"
				);
			}
			if (account === "linkedin") {
				return window.open(`https://linkedin.com/${val.connections.linkedin?.identifier}`, "_blank");
			}
			if (account === "instagram") {
				return window.open(`https://instagram.com/${val.connections.facebook?.identifier}`, "_blank");
			}
		}

		if (connectedPages?.userConnectionPages && connectedPages.userConnectionPages[account]?.length > 0) {
			setSelectedConnectPage(account);
			return open();
		}
	};

	const handleDisconnect = (account: Service) => {
		setSelectedConnectPage(account);
		disconnectCompany({
			variables: {
				id: val.id,
				type: account.toUpperCase(),
			},
			onCompleted: () => {
				if (account !== "instagram") {
					registerPageEvent({
						variables: {
							type: `remove-${account}`,
							userId: val?.id,
						},
					});
				}
				toast({color: "green", text: "Social media unlinked.", icon: "circle-check"});
			},
		});
	};

	return (
		<Collapsible header={<CorporateAccountHeader account={val} />}>
			<div className={styles.corporateContainer}>
				<Validate setStatus={setValid}>
					<MultiColumn>
						<Text
							type="text"
							label="Account"
							validate={[required, validMaxLength(200)]}
							placeholder="Company account"
							{...inputFunc("name")}
						/>
						<Text
							label="Email"
							validate={[required, validEmail]}
							placeholder="Company email"
							{...inputFunc("email")}
						/>
					</MultiColumn>
				</Validate>
				<Separator horizontal />
				<h6>Automated Actions</h6>
				<div className={styles.corporateActions}>
					<Checkbox
						{...inputFunc("virtualAssistant")}
						label="Virtual Assistant"
						text={
							<Span color="grey">
								Automatically schedule the best times for sharing and resharing content.
							</Span>
						}
					/>
					<PeakTimeSelector
						peakTime={val.peakTime}
						peakTimeSlots={val.peakTimeSlots}
						onChange={update}
						text={
							<Span color="grey">
								Automates content recommendations and tasks, and allows others to post on this account.
							</Span>
						}
					/>
				</div>
				<Separator horizontal />
				<div className={styles.socialMediaHeader}>
					<h6>Social Media Pages</h6>
					<Span>Add company social media pages and boost other’s shares by auto-liking.</Span>
				</div>
				<div className={styles.corporateNetworks}>
					{services.map(account => (
						<PageConnection
							disconnecting={disconnecting}
							key={account}
							account={account}
							connection={val.connections[account]}
							onConnect={handleConnect}
							onDisconnect={handleDisconnect}
							company={val}
							update={update}
							availablePages={connectedPages?.userConnectionPages[account]}
						/>
					))}
				</div>
				<Button
					value="Delete Account"
					invert
					color="pink"
					icon="delete"
					onClick={deleteModal.open}
					border={false}
				/>
			</div>
		</Collapsible>
	);
};

export const minCompany: Partial<Company> = {
	peakTime: true,
	peakTimeSlots: [],
	virtualAssistant: true,
};

export const CorporateAccounts = (): ReactElement => {
	const {data, loading} = useQuery(GET_COMPANY_PAGES);
	const handleOpenAdd = () => {
		setNewCompany(minCompany);
	};
	const {modal, open, close, setIsDirty} = useModal({onOpen: handleOpenAdd, size: "medium"});
	const [addCompany, {loading: addingCompany}] = useMutationToast(ADD_COMPANY);
	const accounts = useMemo<Company[]>(() => data?.companyPages?.map(loadCompany) ?? [], [data]);

	const [newCompany, setNewCompany] = useState(minCompany);
	useEffect(() => {
		setIsDirty(
			Boolean(
				newCompany?.name ||
					newCompany?.email ||
					newCompany?.peakTime !== minCompany.peakTime ||
					newCompany?.virtualAssistant !== minCompany.virtualAssistant
			)
		);
	}, [newCompany, setIsDirty]);
	const toast = useToast();
	const handleCreate = () =>
		addCompany({
			variables: newCompany,
			onCompleted: () => {
				close();
				toast({color: "green", text: "Account created successfully.", icon: "circle-check"});
			},
		});

	return (
		<div className={styles.container}>
			<ConfirmModal
				onConfirm={handleCreate}
				confirmText="Create Account"
				loading={addingCompany}
				disabled={!!validEmail.check(newCompany.email) || !!required.check(newCompany.name)}
				modal={modal}
				title="Add an Account"
			>
				<div className={styles.connectPageContainer}>
					<Span color="grey">
						Provide account details and enable features: Peaktime, Virtual Assistant, and Auto-like.
					</Span>
					<MultiColumn>
						<Text
							label="Account Name"
							value={newCompany?.name ?? ""}
							onChange={v => setNewCompany(c => ({...c, name: v}))}
							validate={[required]}
						/>
						<Text
							label="Account Email"
							value={newCompany?.email ?? ""}
							onChange={v => setNewCompany(c => ({...c, email: v}))}
							validate={[required, validEmail]}
						/>
						<PeakTimeSelector
							peakTime={newCompany?.peakTime ?? true}
							peakTimeSlots={newCompany?.peakTimeSlots ?? []}
							onChange={peakTimeSettings => setNewCompany(c => ({...c, ...peakTimeSettings}))}
						/>
						<Checkbox
							label="Virtual Assistant"
							text="Automates tasks, provides content suggestions, and enables collaborative posting on this account."
							value={newCompany?.virtualAssistant}
							onChange={v => setNewCompany(c => ({...c, virtualAssistant: v}))}
						/>
					</MultiColumn>
				</div>
			</ConfirmModal>
			<InputRow position="between">
				<div>
					<h3>Corporate Accounts</h3>
					<Span1>
						Manage your company&apos;s integrated accounts and adjust key settings swiftly and efficiently.
					</Span1>
				</div>
			</InputRow>
			<Separator horizontal />
			<div>
				<Button icon="add" value="Add New Account" onClick={open} />
			</div>
			<div className={styles.accountsContainer}>
				{loading ? (
					<Loading position="center" />
				) : (
					accounts.map((account: Company) => <CorporateAccount key={account.id} account={account} />)
				)}
			</div>
		</div>
	);
};
