import {gql, useQuery, WatchQueryFetchPolicy} from "@apollo/client";
import {useMemo} from "react";

export interface Category {
	id: number;
	name: string;
	default: boolean;
}

export const CATEGORY_FRAGMENT = gql`
	fragment CategoryFields on Category {
		id
		name
		default
	}
`;

export const GET_CATEGORIES = gql`
	query getCategories {
		categories {
			...CategoryFields
		}
	}
	${CATEGORY_FRAGMENT}
`;

export const CREATE_CATEGORY = gql`
	mutation CreateCategory($name: String!) {
		createCategory(name: $name) {
			...CategoryFields
		}
	}
	${CATEGORY_FRAGMENT}
`;

export const DELETE_CATEGORY = gql`
	mutation DeleteCategory($id: PositiveInt!) {
		deleteCategory(id: $id) {
			...CategoryFields
		}
	}
	${CATEGORY_FRAGMENT}
`;

export const useCategories = ({fetchPolicy}: {fetchPolicy?: WatchQueryFetchPolicy}): Category[] => {
	const {data, loading, error} = useQuery(GET_CATEGORIES, {fetchPolicy: fetchPolicy});

	return useMemo(() => (error || loading ? [] : data.categories), [data, error, loading]);
};
