import {FC} from "react";

import {SVG, SVGData, SVGProps} from ".";

import styles from "./image.module.scss";

interface ArrowProps extends Omit<SVGProps, "svg"> {
	direction: "up" | "down" | "left" | "right";
}

const data: SVGData = {
	pathing: (
		<path
			d="M0.274181 0.274649C-0.0913925 0.639597 -0.0913926 1.22798 0.274181 1.59293L6.47402 7.78215C6.76499 8.07262 7.23501 8.07262 7.52598 7.78215L13.7258 1.59293C14.0914 1.22798 14.0914 0.639597 13.7258 0.274649C13.3602 -0.0902987 12.7709 -0.0902988 12.4053 0.274649L6.99627 5.66694L1.58727 0.267201C1.22915 -0.0902994 0.632295 -0.0902993 0.274181 0.274649Z"
			fill="#818B9A"
		/>
	),
	width: 14,
	height: 14,
	viewBox: "0 -2 14 13",
};

export const Arrow: FC<ArrowProps> = ({className, direction, ...props}) => (
	<SVG className={[className, styles.arrow, styles[direction]]} {...props} svg={data} />
);
