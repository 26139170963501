import React, {FC} from "react";
import ReactSwitch from "react-switch";

import {Input, InputComponent, useId} from ".";

export const Switch: FC<InputComponent<boolean>> = ({id: maybeId, value, onChange, disabled, ...props}) => {
	const id = useId(maybeId);

	return (
		<Input baseClass="switch" id={id} {...props} disabled={disabled}>
			<ReactSwitch
				id={id}
				disabled={disabled}
				height={16}
				width={32}
				handleDiameter={11}
				onChange={onChange}
				checkedIcon={false}
				uncheckedIcon={false}
				onColor={disabled ? "#A1ADBF" : "#D32B79"}
				offColor="#A1ADBF"
				checked={value}
				activeBoxShadow="0px 0px 0px 4px #056490"
			/>
		</Input>
	);
};
