import {useCallback, useState} from "react";
import {useQuery} from "@apollo/client";
import dayjs, {Dayjs} from "dayjs";

import {ModalHook, useModal} from "../../modals";
import {Button, CalendarButton, InputRow} from "../../components/input";
import {Span2, Span3} from "../../components/text";
import {Arrow, Icon} from "../../components/images";
import {Badge} from "../../components/badge";
import {Collection, GET_SLACK_CHANNELS, SlackChannel, useAllGroups, useCompanies} from "../../data";
import {ModalType} from "../collections/edit";

import styles from "./collection-modal.module.scss";

interface CollectionModalProps {
	collection: Collection;
	onApprove: (modalScheduledFor: Dayjs | undefined, type: ModalType, close: () => void) => void;
	type: ModalType;
}

export function useCollectionModal<T>({collection, onApprove, type}: CollectionModalProps): ModalHook<T> {
	const {groups} = useAllGroups();
	const corporateAccounts = useCompanies();
	const {data: slack} = useQuery<{slackChannels?: SlackChannel[]}>(GET_SLACK_CHANNELS);
	const [modalScheduledFor, setModalScheduledFor] = useState<Dayjs>();
	const [groupOpen, setGroupOpen] = useState(false);
	const updateModalScheduledFor = useCallback(
		() => setModalScheduledFor(type === "schedule" ? collection.scheduledFor : dayjs()),
		[type, collection]
	);

	return useModal(() => {
		const collectionTo = [
			...collection.to.groupIds.map(id => groups.find(g => g.value === id) ?? {label: "Loading", value: id}),
			...collection.to.companyIds.map(
				id =>
					corporateAccounts
						.map(company => ({label: company.name, value: company.id}))
						.find(g => g.value === id) ?? {label: "Loading", value: id}
			),
			...(slack?.slackChannels
				?.filter(sc => collection.to.slackChannels.includes(sc.channelId))
				.map(sc => ({label: sc.channelName, value: sc.channelId})) ?? []),
		];

		return {
			header: (
				<InputRow>
					<h3>Your Collection is ready to fly!</h3>
					<Icon icon="send" className={styles.sendIcon} />
				</InputRow>
			),
			body: (
				<>
					<Span2 color="grey">Review the details below and confirm everything looks right.</Span2>
					<div className={styles.content}>
						<div>
							<Span3 color="pink">Scheduled for:</Span3>
							<br />
							{type === "schedule" ? (
								<CalendarButton
									value={modalScheduledFor}
									onChange={setModalScheduledFor}
									size="small"
									emptyValue="Select a time and date"
									min={dayjs()}
									border={false}
									invert
								/>
							) : (
								<Span3 color="grey">Immediately</Span3>
							)}
						</div>
						<div>
							<Span3 color="pink">Send to:</Span3>
							<br />
							<InputRow className={styles.badgeContainer}>
								{collectionTo.slice(0, groupOpen ? collectionTo.length : 3).map(item => (
									<Badge key={item.value} color="black" text={item.label} />
								))}
								{collectionTo.length > 3 && (
									<>
										{!groupOpen && <Badge outline color="black" text={`+${collectionTo.length - 3}`} />}
										<Arrow direction={groupOpen ? "up" : "down"} onClick={() => setGroupOpen(c => !c)} />
									</>
								)}
							</InputRow>
						</div>
					</div>
					<Span3 color="grey">{collection?.posts.length} Posts - Total EMV: $0 Saved</Span3>
				</>
			),
			footer: close => (
				<InputRow position="between">
					<Button value="Cancel" invert onClick={close} />
					<Button value="Approve" onClick={() => onApprove(modalScheduledFor, type, close)} />
				</InputRow>
			),
			onOpen: updateModalScheduledFor,
		};
	}, [
		collection?.posts.length,
		collection.to.companyIds,
		collection.to.groupIds,
		collection.to.slackChannels,
		corporateAccounts,
		groupOpen,
		groups,
		modalScheduledFor,
		onApprove,
		slack?.slackChannels,
		type,
		updateModalScheduledFor,
	]);
}
