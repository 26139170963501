interface BiggestValueProps {
	events: string[];
	biggestValueDate: {date: string; time: string; value: number};
}

export default function Results({events, biggestValueDate}: BiggestValueProps) {
	return (
		<div style={{marginTop: "30px"}}>
			<h3>Biggest value date</h3>
			<p>Date: {biggestValueDate.date}</p>
			<p>Hour: {biggestValueDate.time}</p>
			<p>Value: {biggestValueDate.value}</p>
			<h4>Events: </h4>
			{events.map((event, i) => (
				<p key={i}>{event}</p>
			))}
		</div>
	);
}
