import {debounce} from "lodash";
import {useMemo, useRef, useState} from "react";

function valueEquality<T>(left: T, right: T): boolean {
	return left === right;
}
// function to return debounced value
export function useDebounce<T>(
	value: T,
	delay: number,
	options?: {
		maxWait?: number;
		leading?: boolean;
		trailing?: boolean;
		equalityFn?: (left: T, right: T) => boolean;
	}
): T {
	const eq = options?.equalityFn ?? valueEquality;
	const [state, dispatch] = useState(value);
	const debounced = useMemo(() => debounce(dispatch, delay), [dispatch, delay]);
	const previousValue = useRef(value);
	if (!eq(previousValue.current, value)) {
		debounced(value);
		previousValue.current = value;
	}

	return state as T;
}
