import React, {FC, useCallback, useEffect, useMemo, useState} from "react";
import {useQuery} from "@apollo/client";
import classnames from "classnames/bind";
import dayjs from "dayjs";

import {P, P2, Span} from "../../../components/text";
import {Card} from "../../../components/card";
import {Button, DropdownButton, InputRow} from "../../../components/input";
import {useMutationToast, useToast} from "../../../toast";
import {
	GET_SALESFORCE_INTEGRATION,
	SET_SALESFORCE_INTEGRATION,
	DISCONNECT_SALESFORCE,
	SalesforceIntegration,
	SalesforceLeadField,
	SalesforceLeadFields,
	SYNC_SALESFORCE_LEADS,
	SalesforceSyncResponse,
} from "../../../data";
import {Icon} from "../../../components/images";
import {Modal, ModalData} from "../../../modals/new";
import {useConfirmModal, useNewModal as useModal} from "../../../modals";
import {HoverTooltip} from "../../../components/tooltip";
import {Loading} from "../../../components/loading";
import {useStateRef} from "../../../state-ref";

import styles from "./integrations.module.scss";
const bStyles = classnames.bind(styles);

type CvsFieldOption = {
	cvsField:
		| "name"
		| "city"
		| "state"
		| "site_url"
		| "phone_number"
		| "email"
		| "industry"
		| "employees"
		| "created"
		| "clicks"
		| "leadSource"
		| "";
	label: string;
	types: string[];
	salesforceField?: string;
};
const cvsFieldsOptions: CvsFieldOption[] = [
	{
		cvsField: "name",
		label: "Company",
		types: ["string"],
	},
	{
		cvsField: "site_url",
		label: "Site",
		types: ["string", "url"],
	},
	{
		cvsField: "city",
		label: "Location (city)",
		types: ["string", "picklist", "multipicklist", "text", "textarea", "address"],
	},
	{
		cvsField: "state",
		label: "Location (state)",
		types: ["string", "picklist", "multipicklist", "text", "textarea", "address"],
	},
	{
		cvsField: "employees",
		label: "Employees",
		types: ["string", "text", "textarea", "double", "int"],
	},
	{
		cvsField: "email",
		label: "Contact (email)",
		types: ["email"],
	},
	{
		cvsField: "phone_number",
		label: "Contact (phone)",
		types: ["phone"],
	},
	{
		cvsField: "industry",
		label: "Industry",
		types: ["string", "picklist", "multipicklist", "text", "textarea"],
	},
	{
		cvsField: "created",
		label: "Date Visited",
		types: ["date", "datetime"],
	},
	{
		cvsField: "clicks",
		label: "Clicks",
		types: ["int", "double"],
	},
	{
		cvsField: "leadSource",
		label: "Lead Source",
		types: ["string", "picklist", "multipicklist", "text", "textarea"],
	},
];

export const SalesforceCard: FC = () => {
	const {open: configureSalesforceOpen, modal: configureSalesforceModal} = useModal({size: "medium"});
	const {open: errorsLogOpen, modal: errorsLogModal} = useModal({});
	const toast = useToast();
	const {data: salesforce, loading} = useQuery<{salesforceIntegration: SalesforceIntegration}>(
		GET_SALESFORCE_INTEGRATION,
		{
			onError: error => {
				toast({color: "red", text: `Salesforce error: ${error.message}`});
			},
		}
	);
	const [disconnectSalesforce, {loading: salesforceDisconnecting}] = useMutationToast(DISCONNECT_SALESFORCE);
	const [syncSalesforceLeads, {loading: syncing}] = useMutationToast(SYNC_SALESFORCE_LEADS);

	const {open: disconnectConfirmModalOpen} = useConfirmModal(
		() => ({
			title: "Disconnect Salesforce",
			body: "Are you sure you want to disconnect the Salesforce integration?",
			confirmText: "Disconnect",
			confirming: salesforceDisconnecting,
			size: "small",
			onConfirm: async close => {
				await disconnectSalesforce();
				close();
			},
		}),
		[disconnectSalesforce, salesforceDisconnecting]
	);

	const onSync = useCallback(async () => {
		const response = await syncSalesforceLeads();
		const {message, success} = response?.data?.syncSalesforceLeads || ({} as SalesforceSyncResponse);

		toast({
			text: message || (success ? "Salesforce synced successfully!" : "Salesforce sync failed."),
			color: success ? "green" : "red",
		});
	}, [toast, syncSalesforceLeads]);

	useEffect(() => {
		if (salesforce?.salesforceIntegration?.needsConfiguration) {
			configureSalesforceOpen();
		}
	}, [salesforce?.salesforceIntegration?.needsConfiguration, configureSalesforceOpen]);

	const integration = salesforce?.salesforceIntegration;

	return (
		<Card space={false} className={bStyles("integrationsCard", "salesforce")}>
			<InputRow position="between">
				<div className={bStyles("header")}>
					<Icon icon="salesforce" width={32} />
					<h5 className="space">Salesforce</h5>
				</div>
			</InputRow>

			<P color="grey">
				Integrate with Salesforce to track and analyze lead generation.
				<P color="grey">
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://help.clearviewsocial.com/uncategorized/integrating-with-salesforce"
					>
						Learn more
					</a>{" "}
					about Salesforce integration.
				</P>
			</P>
			{integration?.connected &&
				integration.lastSyncedAt &&
				integration.lastSyncStatus &&
				integration.lastSyncStatus !== "syncing" && (
					<P>
						<Span color="grey">Last sync: {dayjs(integration.lastSyncedAt).formatAs()}</Span>
						<P color="grey">
							Sync Status:{" "}
							{integration.lastSyncStatus === "success" ? (
								<Span color="blue">Successful</Span>
							) : (
								<Span className={styles.openErrors} color="pink" onClick={errorsLogOpen}>
									Sync errors
									<Icon color="pink" icon="goto-link" />
								</Span>
							)}
						</P>
					</P>
				)}
			{integration?.connected ? (
				<InputRow className={styles.bottom}>
					<Button color="blue" invert onClick={configureSalesforceOpen} icon="settings" value="Configure" />
					<Button icon="refresh" loading={syncing} onClick={onSync} value="Sync" invert />
					<Button
						color="blue"
						invert
						onClick={disconnectConfirmModalOpen}
						loading={salesforceDisconnecting}
						value="Disconnect"
					/>
				</InputRow>
			) : (
				<InputRow className={styles.bottom}>
					<Button
						icon="open"
						onClick={() => window.open(integration?.authUrl, "_self")}
						value="Connect"
						loading={loading}
					/>
				</InputRow>
			)}
			<ConfigureSalesforceModal modal={configureSalesforceModal} />
			<ErrorsLogModal modal={errorsLogModal} />
		</Card>
	);
};

const ConfigureSalesforceModal: FC<{modal: ModalData}> = ({modal}) => {
	const {data, loading} = useQuery(GET_SALESFORCE_INTEGRATION);
	const [fields, setFields] = useState<SalesforceLeadFields | void>();
	const [, setRowIdx, rowIdxRef] = useStateRef<number | undefined>(undefined);
	const [setInfo, {loading: updating}] = useMutationToast(SET_SALESFORCE_INTEGRATION);
	const salesforceFields = useMemo(() => data?.salesforceIntegration?.leadFields ?? [], [
		data?.salesforceIntegration?.leadFields,
	]);
	const requiredFields = useMemo(() => salesforceFields.filter(field => field.required), [salesforceFields]);
	const fieldsMapping = fields ?? data?.salesforceIntegration?.fields ?? [];
	const toast = useToast();
	const handleSave = useCallback(
		async (close: ModalData["close"]) => {
			const fieldsMapping = (fields ?? data?.salesforceIntegration?.fields ?? []).filter(
				({cvsField, salesforceField}) =>
					!!cvsField && !!salesforceFields.find(f => f.name === salesforceField)
			);
			const missingRequiredFields = requiredFields.filter(
				field => !fieldsMapping.find(f => f.salesforceField === field.name)
			);

			if (missingRequiredFields.length) {
				toast({
					text: `Salesforce required fields aren't mapped: ${missingRequiredFields
						.map(f => f.label)
						.join(", ")}`,
					color: "red",
				});
				return;
			}
			await setInfo({
				variables: {
					fields: fieldsMapping.map(({cvsField, salesforceField}) => ({
						cvsField,
						salesforceField,
						type: salesforceFields.find(field => field.name === salesforceField)?.type,
						required: !!requiredFields.find(field => field.name === salesforceField),
					})),
				},
				onCompleted: close,
			});
		},
		[fields, salesforceFields, data?.salesforceIntegration?.fields, setInfo, requiredFields, toast]
	);
	const update = useCallback(
		(item: SalesforceLeadField | Pick<CvsFieldOption, "cvsField" | "salesforceField">, idx?: number) => {
			setFields(f => {
				const ret = [...(f ?? data?.salesforceIntegration?.fields ?? [])];

				if (idx === undefined) {
					return [...ret, item];
				}

				ret[idx] = {
					...ret[idx],
					...item,
				};

				return [...ret];
			});
		},
		[data?.salesforceIntegration?.fields]
	);
	const remove = useCallback(
		(idx: number) => {
			setFields(f => {
				const ret = [...(f ?? data?.salesforceIntegration?.fields ?? [])];
				ret.splice(idx, 1);
				return ret;
			});
		},
		[data?.salesforceIntegration?.fields]
	);

	const footer = useMemo(
		() => (
			<div className={styles.footer}>
				<Span color="grey" className={styles.info}>
					<Icon icon="information" color="grey" />
					Need help with Salesforce integration?{" "}
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://help.clearviewsocial.com/uncategorized/integrating-with-salesforce"
					>
						Learn More
					</a>
				</Span>
				<InputRow position="right">
					<Button
						onClick={modal.close}
						value="Cancel"
						invert
						color="black"
						border={false}
						disabled={loading || updating}
					/>
					<Button
						onClick={() => handleSave(modal.close)}
						value="Save"
						loading={loading || updating}
						color="blue"
					/>
				</InputRow>
			</div>
		),
		[updating, loading, modal.close, handleSave]
	);

	const removeFieldModal = useConfirmModal(
		() => {
			const fieldMap = fieldsMapping[rowIdxRef.current || 0];
			const salesforceField = salesforceFields.find(field => field.name === fieldMap?.salesforceField);
			const cvsField = cvsFieldsOptions.find(field => field.cvsField === fieldMap?.cvsField);

			return {
				title: "Remove Salesforce mapping",
				body: `Are you sure you want to remove the field mapping ${cvsField?.label || ""} - ${
					salesforceField?.label || ""
				}?`,
				confirmText: "Remove",
				size: "small",
				onConfirm: close => {
					if (rowIdxRef.current !== undefined) {
						remove(rowIdxRef.current);
					}

					close();
				},
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[remove, rowIdxRef, rowIdxRef?.current, fieldsMapping, salesforceFields]
	);

	const handleRemove = useCallback(
		(idx: number) => {
			setRowIdx(idx);
			removeFieldModal.open();
		},
		[removeFieldModal, setRowIdx]
	);

	const getRow = useCallback(
		(fieldMap, idx) => {
			const salesforceField = salesforceFields.find(field => field.name === fieldMap.salesforceField);
			const cvsField = cvsFieldsOptions.find(field => field.cvsField === fieldMap.cvsField);

			return (
				<div className={bStyles("salesforceRow", "space")} key={`${fieldMap.cvsField}-${idx}`}>
					<DropdownButton
						className={bStyles("fieldSelection", !cvsField?.label ? "empty" : "")}
						options={cvsFieldsOptions
							.filter(item => !salesforceField?.type || item.types.includes(salesforceField?.type))
							.map(item => ({
								label: item.label,
								onClick: () => update({cvsField: item.cvsField}, idx),
							}))}
						value={cvsField?.label || "Select a field"}
						color="black"
						invert
						arrow
					/>
					<Icon icon="arrow-right" />
					<div className={styles.field}>
						<Span>{salesforceField?.label}</Span>
						{fieldMap.cvsField === "leadSource" && (
							<HoverTooltip text="Clearview Social will define the data for this field as a Clearview Social Lead in Salesforce.">
								<Icon icon="information" color="grey" />
							</HoverTooltip>
						)}
					</div>
					{salesforceField?.required ? (
						<HoverTooltip text="Required Salesforce field" positions={["top"]}>
							<Span className={styles.required}>{""}</Span>
						</HoverTooltip>
					) : (
						<HoverTooltip text="Remove field" positions={["top"]}>
							<Icon icon="delete" onClick={() => handleRemove(idx)} />
						</HoverTooltip>
					)}
				</div>
			);
		},
		[salesforceFields, update, handleRemove]
	);

	useEffect(() => {
		if (modal.open) {
			setFields(undefined);
		}
	}, [modal.open]);

	return (
		<Modal
			modal={modal}
			footer={footer}
			title="Salesforce integration field mapping"
			size="fit-content"
			className={styles.salesforceModal}
		>
			<div className={styles.content}>
				<Span className={bStyles("salesforceRow", "header")}>
					{data?.salesforceIntegration?.userInfo && (
						<Span>
							<a target="_blank" href={data.salesforceIntegration.userInfo.domain} rel="noopener noreferrer">
								{data.salesforceIntegration.userInfo.domain || ""}
							</a>
							<Span color="grey">&nbsp;&#8226;&nbsp;</Span>
							{data.salesforceIntegration.userInfo.email || ""}
						</Span>
					)}
				</Span>
				<Span className={bStyles("salesforceRow", "space", "header")}>
					<Span color="grey">Clearview Social fields</Span>
					<Span color="grey">Salesforce fields</Span>
				</Span>
				{!data?.salesforceIntegration?.fields || loading ? (
					<Loading className={styles.loading} />
				) : (
					<>
						<div className={styles.fields}>{fieldsMapping.map(getRow)}</div>
						<DropdownButton
							className={styles.addFieldBtn}
							options={salesforceFields
								.filter(field => !fieldsMapping?.find(f => f.salesforceField === field.name))
								.map(field => ({
									label: field.label,
									onClick: () => update({cvsField: "", salesforceField: field.name}),
								}))}
							value={"Add field mapping"}
							color="blue"
							invert
							icon="add"
							searchBar
							searchPlaceholder={"Search Salesforce fields"}
							minHeight={200}
						/>
					</>
				)}
			</div>
		</Modal>
	);
};

export const ErrorsLogModal: FC<{modal: ModalData}> = ({modal}) => {
	const {data, loading} = useQuery(GET_SALESFORCE_INTEGRATION);
	const footer = useMemo(
		() => (
			<div className={styles.footer}>
				<Span>{""}</Span>
				<Button onClick={modal.close} value="Close" invert color="blue" />
			</div>
		),
		[modal.close]
	);

	return (
		<Modal
			className={styles.salesforceModal}
			modal={modal}
			title="Last sync errors"
			size="fit-content"
			footer={footer}
		>
			<div className={styles.errorsContent}>
				{loading ? (
					<Loading className={styles.loading} />
				) : (
					<div className={styles.errors}>
						{data?.salesforceIntegration?.logs?.map((log, idx) => (
							<div className={styles.log} key={`${log.date}-${idx}`}>
								<P2 bold>{dayjs(log.date).formatAs("longDateAndTime")}</P2>
								<P2>{`${log.totalErrors}/${log.totalRecords} failed records`}</P2>
								{log.errors
									?.reduce((acc, error) => {
										const msg = `${error.statusCode || ""} => ${error.message}`;

										if (!acc.includes(msg)) {
											acc.push(msg);
										}
										return acc;
									}, [])
									?.map((error, i) => (
										<P color="red" key={i}>
											{error}
										</P>
									))}
							</div>
						))}
					</div>
				)}
			</div>
		</Modal>
	);
};
