import React from "react";

import {Select} from "../../components/input";

interface VariableSettersProps {
	startDate: string;
	setStartDate: React.Dispatch<React.SetStateAction<string>>;
	totalDays: number;
	setTotalDays: React.Dispatch<React.SetStateAction<number>>;
	timeSlotsPerHour: number;
	setTimeSlotsPerHour: React.Dispatch<React.SetStateAction<number>>;
}

export default function VariableSetters({
	startDate,
	setStartDate,
	totalDays,
	setTotalDays,
	timeSlotsPerHour,
	setTimeSlotsPerHour,
}: VariableSettersProps) {
	return (
		<>
			<div style={{display: "flex", gap: "16px"}}>
				<div style={{display: "flex", flexDirection: "column"}}>
					<label>Total days</label>
					<Select
						placeholder="Total Days"
						value={totalDays}
						onChange={setTotalDays}
						options={Array.from({length: 30}, (_, i) => ({
							label: String(i + 1),
							value: i + 1,
						}))}
					/>
				</div>
				<div style={{display: "flex", flexDirection: "column"}}>
					<label>Time slots per hour</label>
					<Select
						placeholder="Total Days"
						value={timeSlotsPerHour}
						onChange={setTimeSlotsPerHour}
						options={Array.from({length: 12}, (_, i) => ({
							label: String(i + 1),
							value: i + 1,
						}))}
					/>
				</div>
				<div style={{display: "flex", flexDirection: "column"}}>
					<label>Start date</label>
					<input type="datetime-local" value={startDate} onChange={e => setStartDate(e.target.value)} />
				</div>
			</div>
		</>
	);
}
