import React, {useState} from "react";

import {Collapsible} from "../../components/card";
import {InputRow, Text, validEmail, required} from "../../components/input";
import {EmojiPickerInput} from "../../components/input/emoji-picker";
import {AITextPicker} from "../../components/input/ai-text-picker";

export const DemoTextFields = (): React.ReactElement => {
	const [text, setText] = useState("");
	const [text2, setText2] = useState("");
	const [text3, setText3] = useState("");
	const [text4, setText4] = useState("");
	const [text5, setText5] = useState(
		"Our easy-to-use employee advocacy software helps your team share your content on their social media platforms with easy prompts, email reminders, and one-click activation"
	);

	const [emojiText, setEmojiText] = useState("");

	return (
		<Collapsible header={<>Text Fields</>}>
			<Text
				label="Text, Max length 100"
				maxLength={100}
				value={text}
				onChange={setText}
				placeholder="Value?"
			/>
			<Text label="Text with icon" icon="search" value={text2} onChange={setText2} placeholder="Value?" />
			<Text label="Disabled Text" value="I am disabled" disabled onChange={() => undefined} />
			<Text label="Text requiring input" value={text3} onChange={setText3} validate={required} />
			<Text
				label="Text requiring email"
				value={text4}
				onChange={setText4}
				validate={[required, validEmail]}
			/>

			<InputRow>
				<Text onChange={setEmojiText} value={emojiText} label="Emojy Picker" />
				<EmojiPickerInput value={emojiText} onChange={setEmojiText} />
			</InputRow>

			<InputRow>
				<Text onChange={setText5} value={text5} label="AI Text Picker" />
				<AITextPicker onChange={setText5} value={text5} label="Demo Text" />
			</InputRow>
		</Collapsible>
	);
};
