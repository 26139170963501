import classnames from "classnames/bind";
import {ReactElement, ReactNode} from "react";
import {NonNegative} from "type-fest";

import {ColorWithGrey, EmptyComponent, MaybeArray} from "../../types";

import styles from "./text.module.scss";
const bStyles = classnames.bind(styles);

interface TextComponent extends EmptyComponent {
	type: "p" | "span";
	color?: ColorWithGrey;
	bold?: boolean;
	size?: 1 | 2 | 3 | 4 | 5;
	trim?: NonNegative<number>;
	children: MaybeArray<undefined | string | number | false | null | ReactNode>;
	href?: string;
	onClick?: (KeyboardEvent) => void;
	title?: string;
}

export const Text = ({
	type: Type = "span",
	color = "black",
	size = 3,
	bold,
	trim,
	children,
	className,
	href,
	onClick,
	title,
}: TextComponent): ReactElement => (
	<Type
		className={bStyles(className, {
			[color]: true,
			container: true,
			[`body${size}`]: true,
			trim1: trim === 1,
			trim: trim && trim > 1,
			onClick,
			bold,
			space: Type === "p",
			blue: href || color === "blue" || false,
		})}
		style={trim && trim > 1 ? {WebkitLineClamp: trim} : undefined}
		onClick={onClick}
		title={title}
	>
		{href ? (
			<a target="_blank" href={href} rel="noopener noreferrer">
				{children}
			</a>
		) : (
			children
		)}
	</Type>
);

export type SpanProps = Omit<TextComponent, "type">;

type S = Omit<SpanProps, "size">;

export const P = (props: Omit<TextComponent, "type">): ReactElement => <Text type="p" {...props} />;
export const P1 = (props: S): ReactElement => <Text type="p" size={1} {...props} />;
export const P2 = (props: S): ReactElement => <Text type="p" size={2} {...props} />;
export const P4 = (props: S): ReactElement => <Text type="p" size={4} {...props} />;
export const Span = (props: SpanProps): ReactElement => <Text type="span" {...props} />;
export const Span1 = (props: S): ReactElement => <Text type="span" size={1} {...props} />;
export const Span2 = (props: S): ReactElement => <Text type="span" size={2} {...props} />;
export const Span3 = (props: S): ReactElement => <Text type="span" size={3} {...props} />;
export const Span4 = (props: S): ReactElement => <Text type="span" size={4} {...props} />;
export const Span5 = (props: S): ReactElement => <Text type="span" size={5} {...props} />;
