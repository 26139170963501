import React, {useCallback, useMemo, useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import {useFormikContext} from "formik";
import {DefaultContext, FetchResult, MutationFunctionOptions, OperationVariables} from "@apollo/client";
import {useNavigate} from "react-router-dom";

import {FormValues, OnChange} from "./types";
import {accountNames, DELETE_SHARE_EVENT, PostServices, postServices, ShareEvent} from "../../../../data";
import {IconToggleGroup} from "../../../../components/toggle";
import {Button, EmojiPickerInput} from "../../../../components/input";
import {AITextPicker} from "../../../../components/input/ai-text-picker";
import {Span2} from "../../../../components/text";
import {MediaButton} from "../../../../components/media/media-button";
import {useMutationToast, useToast} from "../../../../toast";
import {Media} from "../../../../data/media";
import {useConfirmDeleteModal} from "../../../../modals";
import {HoverTooltip} from "../../../../components/tooltip";
import {PeakTimeCalendar} from "../../../../components/input/date-time/date-time-picker";

import styles from "./styles.module.scss";

const iconMapper = {general: "email"};

interface TopToolbarProps {
	activeNetwork: PostServices;
	availableNetworks: PostServices[];
	onChange: OnChange;
}
interface BottomToolbarProps {
	comment?: string;
	onChange: OnChange;
	scheduledFor: Dayjs | undefined;
	onScheduleChange: (value?: Dayjs | null) => void;
	peakTime?: boolean;
	shareEvent?: ShareEvent;
	redirectOnDelete?: boolean;
	uploadImage: (
		options?: MutationFunctionOptions<{uploadImage: string}, OperationVariables, DefaultContext>
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	) => Promise<FetchResult<any>>;
	createDesignJob: (designId: string) => void;
	disabled?: boolean;
}

export const TopToolbar = (props: TopToolbarProps) => {
	const {activeNetwork, availableNetworks, onChange} = props;
	const toggleOptions = postServices
		.filter(s => availableNetworks?.includes(s))
		?.map(s => ({value: s, icon: iconMapper[s] || s}));
	const onNetworkChange = useCallback((network: PostServices) => onChange("activeNetwork", network), [
		onChange,
	]);

	if (!toggleOptions?.length) return null;

	return (
		<IconToggleGroup
			className={styles.toggleNetwork}
			options={toggleOptions}
			value={activeNetwork}
			onChange={onNetworkChange}
		/>
	);
};

export const BottomToolbar = ({
	comment,
	onChange,
	redirectOnDelete,
	onScheduleChange,
	scheduledFor,
	peakTime,
	shareEvent,
	uploadImage,
	createDesignJob,
	disabled,
}: BottomToolbarProps) => {
	const {values, setFieldValue} = useFormikContext<FormValues>();
	const {url, recipient, schedule, expiresAt} = values;
	const [isDeleting, setIsDeleting] = useState(false);
	const [deleteShareEvent, {loading: deleting}] = useMutationToast(DELETE_SHARE_EVENT);
	const toast = useToast();
	const [calendarOpen, setCalendarOpen] = useState(false);
	const navigate = useNavigate();
	const tabs = useMemo<("image" | "video")[]>(() => (url ? ["image"] : ["image", "video"]), [url]);
	const handleMediaUpdate = useCallback(
		(selectedMedia: Media) => {
			if (selectedMedia.type === "image") {
				onChange("image", selectedMedia.url);
			} else {
				onChange("image", selectedMedia.thumbnailUrl);
				onChange("video", selectedMedia.url);
			}
		},
		[onChange]
	);

	const onCanvaItemSelect = useCallback(
		designId => {
			createDesignJob(designId);
		},
		[createDesignJob]
	);
	const onFileChange = useCallback(
		async file => {
			if (!file) return;

			const [type] = file.type.split("/");

			if (type === "image") {
				await uploadImage({variables: {file}})
					.then(({data}) => {
						const url = data?.uploadImage;
						onChange("image", url);
					})
					.catch(error => toast({color: "red", text: error, icon: "warning"}));
			}
		},
		[toast, uploadImage, onChange]
	);
	const handleDelete = useCallback(
		close => {
			if (values.recipient.networks.length !== 1) {
				const recipient = {...values.recipient};
				setIsDeleting(true);
				recipient.networks = recipient.networks.filter(n => n !== shareEvent?.network);
				setFieldValue("recipient", recipient);
			} else {
				deleteShareEvent({variables: {id: shareEvent?.id}}).then(() => {
					close();
					if (redirectOnDelete) navigate("/collections/posts");
				});
			}
		},
		[deleteShareEvent, shareEvent, navigate, redirectOnDelete, setFieldValue, values.recipient]
	);
	const deleteModal = useConfirmDeleteModal({
		what: `${accountNames[shareEvent?.network || "linkedin"]} share`,
		onDelete: handleDelete,
		deleting: isDeleting || deleting,
	});

	return (
		<div className={styles.editorBottomToolbar}>
			<div className={styles.buttonsContainer}>
				<MediaButton
					onFileChange={onFileChange}
					onCanvaSelect={onCanvaItemSelect}
					mediaLibrary={{
						onConfirm: handleMediaUpdate,
						tabs: tabs,
					}}
				/>
				<EmojiPickerInput value={""} onChange={v => onChange("emoji", v)} disabled={disabled} />
				<AITextPicker
					value={comment || ""}
					label="Comment"
					onChange={v => onChange("comment", v)}
					disabled={disabled}
				/>
				{!schedule?.immediately && (
					<>
						<div className={styles.buttonsSeparator} />
						<HoverTooltip text={"Schedule"} positions={["top"]}>
							<div className={styles.schedulerContainer} onClick={() => setCalendarOpen(true)}>
								<PeakTimeCalendar
									min={dayjs()}
									max={expiresAt ? dayjs(expiresAt) : undefined}
									peakTime={recipient?.peakTime}
									isOpen={calendarOpen}
									onOpen={setCalendarOpen}
									onChange={onScheduleChange}
									onUsePeakTime={() => onScheduleChange()}
									showClear={!shareEvent}
									value={scheduledFor ?? dayjs().add(1, "day")}
								/>
								<Button
									invert
									border={false}
									color="black"
									value=""
									icon="schedule"
									onClick={() => setCalendarOpen(value => !value)}
								/>
								{peakTime && !scheduledFor ? (
									<Span2 color="pink">Schedule using PeakTime™</Span2>
								) : !scheduledFor ? (
									<Span2 color="black">Not scheduled</Span2>
								) : (
									<Span2 color={dayjs(scheduledFor).isBefore() ? "pink" : "black"}>
										{dayjs(scheduledFor).format("MMM DD, YYYY, h:mm A")}
									</Span2>
								)}
							</div>
						</HoverTooltip>
					</>
				)}
			</div>
			{shareEvent && (
				<HoverTooltip text={"Remove Share"} positions={["top"]}>
					<Button
						onClick={deleteModal.open}
						icon="delete"
						value=""
						color="black"
						invert
						border={false}
						loading={isDeleting || deleting}
					/>
				</HoverTooltip>
			)}
		</div>
	);
};
