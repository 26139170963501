import React, {ReactElement, useCallback} from "react";
import {Dayjs} from "dayjs";

import {Arrow} from "../../images";
import {InputComponent} from "../index";
import {Span} from "../../text";

import styles from "./navigator.module.scss";

interface YearNavigatorProps extends InputComponent<Dayjs> {
	max?: Dayjs;
	min?: Dayjs;
	step?: number;
	intervalTxt?: string;
}
export const YearNavigator = ({
	value,
	min,
	max,
	onChange,
	intervalTxt,
	step = 1,
}: YearNavigatorProps): ReactElement => {
	const isDisabled = useCallback(
		y =>
			(max && value.add(y, "years").isAfter(max.endOf("year"))) ||
			(min && value.add(y, "years").isBefore(min.startOf("year"))),
		[value, min, max]
	);
	const onNavigate = y => !isDisabled(y) && onChange(value.add(y, "years"));

	return (
		<div className={styles.container}>
			<Span bold>{intervalTxt ?? value.format("YYYY")}</Span>
			<div className={styles.arrowsContainer}>
				<Arrow
					direction="left"
					color="blue"
					onClick={isDisabled(-step) ? undefined : () => onNavigate(-1)}
					className={[styles.arrow, isDisabled(-step) && styles.disabled]}
				/>
				<Arrow
					direction="right"
					color="blue"
					onClick={isDisabled(step) ? undefined : () => onNavigate(1)}
					className={[styles.arrow, isDisabled(step) && styles.disabled]}
				/>
			</div>
		</div>
	);
};
