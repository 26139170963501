import React, {useCallback} from "react";
import {useFormikContext} from "formik";

import {FormValues} from "./types";
import {useConfirmModal} from "../../../../modals";
import {accountNames, PostServices, services, ShareEvent} from "../../../../data";
import {Span1} from "../../../../components/text";
import {Icon} from "../../../../components/images";
import {getShareStatusBadge} from "../../../collections";
import {Badge} from "../../../../components/badge";
import {SmallButton} from "../../../../components/input";

import styles from "./styles.module.scss";

export const EditorHeader = ({
	shareEvent,
	activeNetwork,
}: {
	shareEvent?: ShareEvent;
	activeNetwork: PostServices;
}) => {
	const {values, setFieldValue} = useFormikContext<FormValues>();
	const {perNetwork, recipient, opengraphs} = values;
	const resetOpengraphs = useCallback(() => {
		setFieldValue(`opengraphs`, {
			general: opengraphs.general,
			...services.reduce((acc, service) => {
				acc[service] = null;
				return acc;
			}, {}),
		});
	}, [opengraphs, setFieldValue]);
	const {open: openEditBulkModal} = useConfirmModal(
		() => ({
			title: "Are you sure you want to make this change?",
			body: <div>Making this change will delete the content in the post. This action cannot be undone.</div>,
			onConfirm: close => {
				resetOpengraphs();
				setFieldValue("perNetwork", !perNetwork);
				setFieldValue("activeNetwork", "general");
				close();
			},
		}),
		[setFieldValue, perNetwork, resetOpengraphs]
	);
	const onEditModeChange = useCallback(() => {
		if (perNetwork && services.some(s => opengraphs[s])) {
			return openEditBulkModal();
		}

		setFieldValue("perNetwork", !perNetwork);

		if (perNetwork) {
			resetOpengraphs();
		}

		setFieldValue("activeNetwork", perNetwork ? "general" : recipient?.networks?.[0]);
	}, [openEditBulkModal, setFieldValue, perNetwork, recipient?.networks, resetOpengraphs, opengraphs]);

	return (
		<div className={styles.editorHeader}>
			{shareEvent ? (
				<>
					<div className={styles.network}>
						{activeNetwork !== "general" && (
							<Icon icon={`filled-${activeNetwork}`} width={16} height={16} viewBox={"0 0 16 16"} />
						)}
						<Span1 bold>{accountNames[activeNetwork]}</Span1>
					</div>
					<div>
						<Badge {...getShareStatusBadge(shareEvent)} />
					</div>
				</>
			) : (
				<>
					<Span1 bold className={styles.headerTitle}>
						{perNetwork ? "Individual Network Posts" : "Bulk Post"}
					</Span1>
					<SmallButton
						className={styles.editModeButton}
						IconComponent={
							<Icon
								color="blue"
								icon={perNetwork ? "bulkEdit" : "customizeNetwork"}
								width={16}
								viewBox="0 0 16 16"
							/>
						}
						value={perNetwork ? "Edit all posts in bulk" : "Customize per network"}
						onClick={onEditModeChange}
						color="blue"
						invert
					/>
				</>
			)}
		</div>
	);
};
