import {ReactElement, useMemo} from "react";
import dayjs from "dayjs";
import {useQuery} from "@apollo/client";

import {Card} from "../card";
import {Loading} from "../loading";
import {UserAvatar} from "../user-avatar";
import {Span, Span4} from "../text";
import {GET_USERS_LEADER_BOARD} from "../../data/user";
import {nFormatter} from "../../format";

import styles from "./leaderboard-widget.module.scss";

interface Badge {
	id: number;
	label: string;
	image: string;
}

const TOP_DOG = 20;

export const LeaderboardWidget = (): ReactElement => {
	const start = useMemo(() => dayjs().utc().add(-1, "month").endOf("month").startOf("day"), []);
	const end = useMemo(() => dayjs().utc().endOf("month").startOf("day"), []);
	const {data, loading: isLoading} = useQuery(GET_USERS_LEADER_BOARD, {
		variables: {start, end, global: false},
	});

	const lbData = useMemo(
		() =>
			[...(data?.usersLeaderBoard?.items ?? [])]?.splice(0, 3).map(u => ({
				userId: u.userId,
				smartScore: u.leaderBoard.smartScore,
				badges: u.badges.map(eb => ({id: eb.id, label: eb.title, image: eb.imageUrl} as Badge)),
			})),
		[data]
	);

	return (
		<Card className={styles.container}>
			<div className={styles.header}>
				<div>
					<h4>Leaderboard</h4>
					<Span4 color="grey">Top performers in social activity</Span4>
				</div>
				<a href="/analytics/leaderboard">Full View</a>
			</div>
			<div className={styles.items}>
				{isLoading ? (
					<Loading position="absolute" />
				) : (
					lbData.map((l, i) => {
						const topDog = l.badges.find(b => b.id === TOP_DOG);
						const badges = topDog
							? [topDog, ...l.badges.filter(b => b.id !== TOP_DOG).slice(0, 2)]
							: l.badges.slice(0, 3);
						return (
							<div className={styles.item} key={l.userId}>
								<Span className={styles.number} size={1} bold color="blue">
									{i + 1}
								</Span>
								<UserAvatar userId={l.userId} name size="small" />
								<div className={styles.score}>
									<Span4 color="grey">Social Score</Span4>
									<Span color="blue" bold>
										{nFormatter(l.smartScore, 1)}
									</Span>
								</div>
								<div className={styles.badges}>
									{badges.map(b => (
										<div className={styles.badge} key={b.id}>
											<img src={b.image} alt={b.label} title={b.label} />
										</div>
									))}
								</div>
							</div>
						);
					})
				)}
			</div>
		</Card>
	);
};
