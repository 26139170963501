import {FC, useState} from "react";

import {useDirtyCopy} from "../../../dirty-copy";
import {useMutationToast} from "../../../toast";
import {useMyUser, useUserList} from "../../../data";
import {UPDATE_ORG} from "../../../data/org";
import {Text, MultiColumn, Button, Select, required, Validate, Separator} from "../../../components/input";

export const CompanyInfo: FC = () => {
	const me = useMyUser();
	const {users} = useUserList("admin");
	const [updateOrg, {loading}] = useMutationToast(UPDATE_ORG);
	const [valid, setValid] = useState(true);
	const {dirty, discard, changes, inputFunc} = useDirtyCopy(me.org);
	const handleSave = () =>
		updateOrg({variables: {changes}}).then(({data}) => {
			if (data) discard();
		});
	return (
		<Validate setStatus={setValid}>
			<MultiColumn>
				<Text validate={required} type="text" label="Company Name" {...inputFunc("name")} />
				{users && <Select label="Primary Admin" options={users} {...inputFunc("primaryAdmin")} />}
			</MultiColumn>
			<Separator horizontal />
			<Button value="Save" disabled={!dirty || !valid} onClick={handleSave} loading={loading} />
		</Validate>
	);
};
