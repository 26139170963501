import {useMyUser} from "../../../data";
import {Post as PostEdit} from "./post";
import {EditShare} from "../../collections";

export const Post = () => {
	const me = useMyUser();

	if (me.org.features.upcomingFeatures) {
		return <PostEdit />;
	}

	return <EditShare />;
};
