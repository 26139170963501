import {ReactElement, useState} from "react";
import {useQuery} from "@apollo/client";

import {Button, Option, Select, Separator, Text} from "../../components/input";
import {Loading} from "../../components/loading";
import {useModal} from "../../modals";
import {useMutationToast} from "../../toast";
import {P, Span1} from "../../components/text";
import {
	GET_RSS_FEEDS,
	ADD_RSS_FEED,
	GET_MANZAMA_CATEGORIES,
	GET_JDSUPRA_CATEGORIES,
	ADD_CUSTOM_RSS_FEED,
} from "../../data";
import {RSSItem} from "./rss-feed";

type RssType = "rss" | "Manzama" | "JDSupra";

interface AddRssProps {
	close: () => void;
	selectedFeedIds: number[];
}

const sourceOptions: Option<RssType>[] = [
	{value: "rss", label: "RSS"},
	{value: "Manzama", label: "Manzama"},
	{value: "JDSupra", label: "JDSupra"},
];

const feedMap = f => ({value: f.feedId, label: `${f.category} - ${f.title}`});

const AddRss = ({close, selectedFeedIds}: AddRssProps): ReactElement => {
	const {data: manzama} = useQuery(GET_MANZAMA_CATEGORIES);
	const {data: jdSupra} = useQuery(GET_JDSUPRA_CATEGORIES);
	const [addCustomRssFeed, addCustomRssFeedData] = useMutationToast(ADD_CUSTOM_RSS_FEED);
	const [addRssFeed, addRssFeedData] = useMutationToast(ADD_RSS_FEED);
	const [type, setType] = useState<RssType>("rss");
	const [url, setUrl] = useState("");
	const [feedId, setFeedId] = useState<number>();
	const manzamaCategories = manzama?.manzamaRssFeedCategories ?? [];
	const jdSupraCategories = jdSupra?.jdSupraRssFeedCategories ?? [];
	const manzamaOptions: Option<number>[] = manzamaCategories
		.filter(f => !selectedFeedIds.includes(f.feedId))
		.map(feedMap);
	const jdSupraOptions: Option<number>[] = jdSupraCategories
		.filter(f => !selectedFeedIds.includes(f.feedId))
		.map(feedMap);
	const handleChangeType = (type: RssType) => {
		if (type === "Manzama") {
			setFeedId(manzamaOptions[0]?.value);
		}
		if (type === "JDSupra") {
			setFeedId(jdSupraOptions[0]?.value);
		}

		setType(type);
	};

	const manzamaCount = manzamaCategories.filter(c => selectedFeedIds.includes(c.feedId)).length;

	const handleAddFeed = () => {
		if (type === "rss") {
			return addRssFeed({variables: {add: {url}}}).then(close);
		}

		return addCustomRssFeed({variables: {feedId, feedType: type}}).then(close);
	};

	const buttonDisabled =
		(type === "rss" && !url.match(/^https?:\/\//)) ||
		(type === "Manzama" && (!feedId || manzamaCount >= 3)) ||
		(type === "JDSupra" && !feedId);

	return (
		<div>
			<Select label="Source" options={sourceOptions} value={type} onChange={handleChangeType} />
			{type === "rss" ? (
				<Text label="Feed URL" placeholder="https://" value={url} onChange={setUrl} />
			) : type === "JDSupra" ? (
				<Select label="Feed" options={jdSupraOptions} value={feedId} onChange={setFeedId} />
			) : manzamaCount < 3 ? (
				<Select label="Feed" options={manzamaOptions} value={feedId} onChange={setFeedId} />
			) : (
				<P>You have reached the limit of 3 Manzama Feeds. Please remove current feeds before adding more.</P>
			)}

			<Button
				large
				disabled={buttonDisabled}
				onClick={handleAddFeed}
				value="Save"
				color="blue"
				loading={addRssFeedData.loading || addCustomRssFeedData.loading}
			/>
		</div>
	);
};

export const ContentFeeds = (): ReactElement => {
	const {loading, data} = useQuery(GET_RSS_FEEDS);
	const {open} = useModal(
		({close}) => ({
			header: <h3>Add New Feed</h3>,
			body: <AddRss close={close} selectedFeedIds={loading ? [] : data?.rssFeeds.map(f => f.feedId)} />,
		}),
		[data?.rssFeeds, loading]
	);

	return loading || typeof data?.rssFeeds === undefined ? (
		<Loading position="center" />
	) : (
		<>
			<h3>Feeds</h3>
			<Span1>
				Simplify communication, manage notifications, and unify your platforms with these key integrations.
			</Span1>
			<Separator horizontal />
			<Button onClick={open} disabled={loading} value="Add Feed" />
			{data?.rssFeeds
				.flat()
				.sort((rssFeed1, rssFeed2) =>
					rssFeed1.title.trim().localeCompare(rssFeed2.title.trim(), "en", {sensitivity: "base"})
				)
				.map(feed => (
					<RSSItem feed={feed} key={feed.id} />
				))}
		</>
	);
};
