import {gql} from "@apollo/client";

const CANVA_INTEGRATION_FRAGMENT = gql`
	fragment CanvaIntegrationFields on CanvaIntegration {
		connected
		id
		url
	}
`;

export const CANVA_INTEGRATION = gql`
	query CanvaIntegration {
		canvaIntegration {
			...CanvaIntegrationFields
		}
	}
	${CANVA_INTEGRATION_FRAGMENT}
`;

export const DISCONNECT_CANVA = gql`
	mutation DisconnectCanva {
		canvaDisconnect {
			...CanvaIntegrationFields
		}
	}
	${CANVA_INTEGRATION_FRAGMENT}
`;

export const CANVA_DESIGNS = gql`
	query CanvaDesigns($cursor: String, $search: String, $order: CanvaDesignOrder) {
		canvaDesigns(cursor: $cursor, search: $search, order: $order) {
			items {
				id
				title
				thumbnail {
					height
					width
					url
				}
				createdAt
				updatedAt
				urls {
					editUrl
					viewUrl
				}
			}
			cursor
		}
	}
`;

export const CREATE_CANVA_EXPORT_DESIGN_JOB = gql`
	mutation CreateCanvaDesignExportJob($id: ID!) {
		createCanvaDesignExportJob(id: $id) {
			id
			status
			urls
		}
	}
`;

export const GET_CANVA_EXPORT_DESIGN_JOB = gql`
	query ExportDesignJob($id: ID!) {
		exportDesignJob(id: $id) {
			id
			status
			urls
		}
	}
`;
