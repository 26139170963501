import {useQuery} from "@tanstack/react-query";

import {useAITextInputSession} from "./use-ai-text-input-session";

export const useAITerms = () => {
	const {apiUrl, sessionId, loading} = useAITextInputSession();

	const {data, status} = useQuery({
		refetchOnWindowFocus: false,
		staleTime: Infinity,
		queryKey: ["ai-terms-and-conditions"],
		queryFn: async ({signal}) => {
			const resp = await fetch(`${apiUrl}/chatbot/chat/accepted-terms-and-conditions`, {
				signal,
				method: "GET",
				headers: {
					Authorization: `Bearer ${sessionId}`,
				},
			});
			return resp.json();
		},
		enabled: !!sessionId && !!apiUrl,
	});

	return {
		data,
		loading: loading || status === "pending",
	};
};
