import {useEffect} from "react";
import {useFormikContext} from "formik";

import {DragHandleProps} from "./drag-drop";
import {PostEditor} from "./post-editor";
import {PostEditorShuffle} from "./post-editor-shuffle";
import {PostCollectionFormValues} from "./post";

interface PostEditorProps {
	onRemove?: () => void;
	loadingAddShuffle: boolean;
	dragHandleProps: DragHandleProps;
	onValidityChange?: (valid: boolean) => void;
}

export const PostEditorSelector = ({
	onRemove,
	loadingAddShuffle,
	dragHandleProps,
	onValidityChange,
}: PostEditorProps) => {
	const {values, isValid} = useFormikContext<PostCollectionFormValues>();
	const {shuffledComments, shuffledImages} = values;

	useEffect(() => {
		onValidityChange?.(isValid);
	}, [onValidityChange, isValid]);

	if (shuffledComments && shuffledImages)
		return (
			<PostEditorShuffle
				loadingAddShuffle={loadingAddShuffle}
				onRemove={onRemove}
				dragHandleProps={dragHandleProps}
			/>
		);

	return <PostEditor onRemove={onRemove} dragHandleProps={dragHandleProps} />;
};
