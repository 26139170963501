import {ReactElement} from "react";

import {Icon, SVGProps} from ".";

import styles from "./image.module.scss";

interface MenuIconProps extends Omit<SVGProps, "svg"> {
	open?: boolean;
}

export const MenuIcon = ({open, className, ...props}: MenuIconProps): ReactElement => (
	<Icon icon={open ? "close" : "hamburger-menu"} className={[styles.menu, className]} {...props} />
);
