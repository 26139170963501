import {KeyboardEvent, MouseEvent, ReactElement, ReactNode, useCallback, useMemo, useState} from "react";
import classnames from "classnames";

import {
	Button,
	ButtonProps,
	Checkbox,
	MultiOptionProps,
	Switch,
	toggle,
	TabsProps,
	Text,
	SmallButton,
} from ".";
import {DropdownCallback, useDropdown} from "./dropdown";
import {Icon, IconType} from "../images";
import {Span, P2} from "../text";
import {useStateRef} from "../../state-ref";
import {BlankTabs} from "../../layout/tabs";
import {Loading} from "../loading";

import styles from "./input.module.scss";

export interface IconOption {
	label: string;
	disabled?: boolean;
	onClick: (close?: () => void) => void;
	icon?: IconType | "radio" | "checkbox" | "switch";
	selected?: boolean;
	hidden?: boolean;
	hint?: string;
}

export interface TabOption {
	label: string;
	loading?: boolean;
	options: IconOption[];
}

export interface DropdownButtonProps extends Omit<ButtonProps, "onClick" | "arrow" | "value"> {
	options: IconOption[];
	arrow?: boolean;
	searchBar?: boolean;
	searchPlaceholder?: string;
	onAdd?: (val: string) => void;
	label?: ReactNode;
	onManage?: () => void;
	minHeight?: number;
	value?: string;
	size?: "small" | "large";
}

export interface TabDropdownButtonProps extends Omit<ButtonProps, "onClick" | "arrow"> {
	tabs: TabOption[];
	arrow?: boolean;
}

const isNormalIcon = (o: IconOption): boolean =>
	o.icon !== "checkbox" && o.icon !== "radio" && o.icon !== "switch";

export function DropdownButton({
	options,
	arrow,
	searchBar,
	searchPlaceholder = "Search",
	onAdd,
	onManage,
	minHeight,
	size,
	...props
}: DropdownButtonProps): ReactElement {
	const [activeIndex, setActiveIndex, aiRef] = useStateRef<number | undefined>(undefined);
	const [search, setSearch] = useState("");
	const handleUnset = useCallback(() => setActiveIndex(undefined), [setActiveIndex]);
	const handleSelect = useCallback((close: () => void, o: IconOption, e?: MouseEvent) => {
		if (!o.disabled) o.onClick(close);
		if (isNormalIcon(o)) close();
		if (e) {
			e.stopPropagation();
			e.preventDefault();
		}
	}, []);
	const handleKeyDown = useCallback(
		(e: KeyboardEvent, {close}: DropdownCallback) => {
			switch (e.key) {
				case "Enter":
					if (aiRef.current !== undefined) {
						handleSelect(close, options[aiRef.current]);
						break;
					}
					return;
				case "ArrowDown":
					setActiveIndex(c => ((c ?? -1) + 1) % options.length);
					break;
				case "ArrowUp":
					setActiveIndex(c => ((c ?? options.length + 1) - 1 + options.length) % options.length);
					break;
				default:
					return;
			}
			e.stopPropagation();
			e.preventDefault();
		},
		[aiRef, handleSelect, options, setActiveIndex]
	);

	const popup = useCallback(
		({close}: DropdownCallback) => {
			let empty = true;
			const searchLC = search.toLowerCase();
			const handleAdd = () => {
				onAdd?.(search);
				setSearch("");
				close();
			};

			return (
				<>
					{searchBar && (
						<Text
							className={styles.search}
							icon="search"
							value={search}
							onChange={setSearch}
							placeholder={searchPlaceholder}
						/>
					)}
					{props.value?.includes("Category") && onManage && (
						<div
							className={styles.link}
							onClick={() => {
								onManage();
								close();
							}}
						>
							<Span color="blue">Manage Categories&nbsp;</Span>
							<Icon color="blue" icon="open" height={16} width={16} />
						</div>
					)}
					{options.map((o, i) => {
						if (o.hidden || !o.label.toLowerCase().includes(searchLC)) return <></>;
						empty = false;
						return (
							<div
								className={classnames(
									styles.option,
									i === activeIndex && styles.active,
									isNormalIcon(o) && o.selected && styles.selected,
									o.disabled && styles.disabled
								)}
								key={i}
								tabIndex={i === activeIndex ? 0 : -1}
								onMouseOver={() => setActiveIndex(i)}
								onClick={e => handleSelect(close, o, e)}
							>
								{o.icon === "radio" ? (
									<></>
								) : o.icon === "checkbox" ? (
									<Checkbox value={o.selected ?? false} onChange={() => undefined} label={o.label} />
								) : o.icon === "switch" ? (
									<Switch value={o.selected ?? false} onChange={() => undefined} label={o.label} />
								) : (
									<>
										{o.icon && <Icon icon={o.icon} />}
										<Span>{o.label}</Span>
										{o.hint && <Span color="grey">{o.hint}</Span>}
									</>
								)}
							</div>
						);
					})}
					{empty && search.trim().length > 0 && (
						<div>
							<P2 className={styles.message} color="grey">
								No matches found
							</P2>
							{onAdd && (
								<Button
									color="black"
									invert
									border={false}
									icon="add"
									value={`Add ${search}`}
									onClick={handleAdd}
								/>
							)}
						</div>
					)}
				</>
			);
		},
		[
			activeIndex,
			handleSelect,
			onAdd,
			options,
			search,
			searchBar,
			setActiveIndex,
			searchPlaceholder,
			onManage,
			props.value,
		]
	);

	const {isOpen, portal, reference, toggle} = useDropdown({
		popup,
		portalClassName: styles.buttonMenu,
		onClose: handleUnset,
		onKeyDown: handleKeyDown,
		onMouseOut: handleUnset,
		minHeight,
	});

	return (
		<>
			{size === "small" ? (
				<SmallButton
					{...props}
					onClick={toggle}
					ref={reference}
					arrow={arrow ? (isOpen ? "up" : "down") : undefined}
				/>
			) : (
				<Button
					{...props}
					onClick={toggle}
					ref={reference}
					arrow={arrow ? (isOpen ? "up" : "down") : undefined}
				/>
			)}
			{portal}
		</>
	);
}

export function TabsDropdownButton({tabs, arrow, ...props}: TabDropdownButtonProps): React.ReactElement {
	const [activeIndex, setActiveIndex, aiRef] = useStateRef<number | undefined>(undefined);
	const [activeTab, setActiveTab] = useState(0);
	const handleUnset = useCallback(() => setActiveIndex(undefined), [setActiveIndex]);

	const currentTab = useMemo(() => {
		if (activeTab > tabs.length - 1) {
			setActiveTab(0);
			return tabs[0];
		}
		return tabs[activeTab];
	}, [activeTab, tabs]);

	const options = currentTab.options;

	const handleSelect = useCallback((o: IconOption, e?: MouseEvent) => {
		o.onClick(close);
		if (isNormalIcon(o)) close();
		if (e) {
			e.stopPropagation();
			e.preventDefault();
		}
	}, []);

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent) => {
			switch (e.key) {
				case "Enter":
				case " ":
					if (aiRef.current !== undefined) {
						if (options && options.length > 0) {
							setActiveTab(aiRef.current);
						} else {
							handleSelect(options[aiRef.current]);
						}
					}
					break;
				case "ArrowUp":
					setActiveIndex(c => ((c ?? -1) + 1) % options.length);
					break;
				case "ArrowDown":
					setActiveIndex(c => ((c ?? options.length + 1) - 1 + options.length) % options.length);
					break;
				case "home":
					setActiveIndex(0);
					break;
				case "end":
					setActiveIndex(options.length - 1);
					break;
				case "ArrowRight":
					setActiveTab(c => ((c ?? -1) + 1) % tabs.length);
					setActiveIndex(undefined);
					break;
				case "ArrowLeft":
					setActiveTab(c => ((c ?? tabs.length + 1) - 1 + tabs.length) % tabs.length);
					setActiveIndex(undefined);
					break;
				default:
					return;
			}
			e.stopPropagation();
			e.preventDefault();
		},
		[aiRef, handleSelect, options, setActiveIndex, tabs]
	);

	const popup = useCallback(
		() => (
			<>
				<BlankTabs tabs={tabs} onSelect={setActiveTab} activeIndex={activeTab} />
				{tabs[activeTab].loading ? (
					<Loading position="center" />
				) : (
					tabs[activeTab].options
						.filter(o => !o.hidden)
						.map((o, optionIndex) => (
							<div
								className={classnames(
									styles.option,
									optionIndex === activeIndex && styles.active,
									isNormalIcon(o) && o.selected && styles.selected
								)}
								key={optionIndex}
								tabIndex={optionIndex === activeIndex ? 0 : -1}
								onMouseOver={() => setActiveIndex(optionIndex)}
								onClick={e => handleSelect(o, e)}
								role="tab"
							>
								{o.icon === "radio" ? (
									<></>
								) : o.icon === "checkbox" ? (
									<Checkbox value={o.selected ?? false} onChange={() => undefined} label={o.label} />
								) : o.icon === "switch" ? (
									<Switch value={o.selected ?? false} onChange={() => undefined} label={o.label} />
								) : (
									<div className={styles.menuItem}>
										{o.icon && <Icon icon={o.icon} />}
										<div className={styles.labelItem}>
											<Span>{o.label}</Span>
											{o.hint && <Span color="grey">{o.hint}</Span>}
										</div>
									</div>
								)}
							</div>
						))
				)}
			</>
		),
		[activeIndex, activeTab, handleSelect, setActiveIndex, tabs]
	);

	const {isOpen, portal, reference, toggle} = useDropdown({
		popup,
		portalClassName: styles.buttonMenu,
		onClose: handleUnset,
		onKeyDown: handleKeyDown,
		onMouseOut: handleUnset,
	});

	return (
		<>
			<Button
				{...props}
				onClick={toggle}
				ref={reference}
				arrow={arrow ? (isOpen ? "up" : "down") : undefined}
			/>
			{portal}
		</>
	);
}

export function SelectSomeMenu<T>({value, options, onChange, ...props}: MultiOptionProps<T>): ReactElement {
	return (
		<DropdownButton
			{...props}
			icon="sort"
			options={[
				{
					icon: "switch",
					label: "Select All",
					selected: value.length === options.length,
					onClick: () => onChange(value.length === options.length ? [] : options.map(o => o.value)),
				},
				...options.map(o => ({
					icon: "checkbox" as const,
					label: o.label,
					selected: value.findIndex(s => s === o.value) !== -1,
					onClick: () => onChange(toggle(o.value, value)),
				})),
			]}
		/>
	);
}

interface TabsSelectProps<K extends string, V> extends Omit<TabsProps<K, V>, "label"> {
	label: string;
}

export function TabsSelectSomeMenu<K extends string, V>({
	value,
	label,
	tabs,
	onChange,
}: TabsSelectProps<K, V>): ReactElement {
	return (
		<TabsDropdownButton
			value={label}
			invert
			arrow
			tabs={tabs.map(tab => ({
				label: tab.label,
				loading: tab.loading,
				options: [
					{
						icon: "checkbox",
						label: "Select All",
						selected:
							tab.options.length > 0 && tab.options.every(option => value[tab.value].includes(option.value)),
						onClick: () => {
							onChange({
								...value,
								[tab.value]:
									value[tab.value].length === tab.options.length ? [] : tab.options.map(o => o.value),
							});
						},
					},
					...tab.options.map(o => ({
						icon: "checkbox" as const,
						label: o.label,
						selected: value[tab.value].some(s => s === o.value),
						onClick: () => onChange({...value, [tab.value]: toggle(o.value, value[tab.value])}),
					})),
				],
			}))}
		/>
	);
}
