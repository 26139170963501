import {gql} from "@apollo/client";
import {Dayjs} from "dayjs";

export type HubspotContactField = {
	cvsField:
		| "name"
		| "city"
		| "state"
		| "site_url"
		| "phone_number"
		| "email"
		| "industry"
		| "employees"
		| "created"
		| "clicks"
		| "leadSource";
	hubspotContact: string;
	required?: boolean;
	type?: string;
};
export type HubspotContactFields = HubspotContactField[];

export type HubspotLog = {
	date: Dayjs;
	errors: JSON[];
	totalRecords: number;
	totalErrors: number;
};

type HubspotUserInfo = {
	email: string;
	contactsUrl: string;
};

export type HubspotSyncResponse = {
	success: boolean;
	message: string;
};

export interface HubspotIntegration {
	connected: boolean;
	authUrl: string;
	fields?: HubspotContactFields;
	contactFields?: HubspotContactFields;
	lastSyncStatus?: string;
	lastSyncedAt?: Dayjs;
	logs?: HubspotLog[];
	userInfo?: HubspotUserInfo;
	needsConfiguration: boolean;
}

const HUBSPOT_INTEGRATION_FRAGMENT = gql`
	fragment HubspotIntegrationFields on HubspotIntegration {
		connected
		authUrl
		userInfo {
			email
			contactsUrl
		}
		fields {
			cvsField
			hubspotField
			required
		}
		contactFields {
			label
			name
			required
			type
		}
		lastSyncedAt
		lastSyncStatus
		logs {
			date
			errors
			totalRecords
			totalErrors
		}
		needsConfiguration
	}
`;

export const GET_HUBSPOT_INTEGRATION = gql`
	query HubspotIntegration {
		hubspotIntegration {
			...HubspotIntegrationFields
		}
	}
	${HUBSPOT_INTEGRATION_FRAGMENT}
`;

export const SET_HUBSPOT_INTEGRATION = gql`
	mutation SetHubspotIntegration($fields: [HubspotContactFieldInput!]) {
		setHubspotIntegration(fields: $fields) {
			...HubspotIntegrationFields
		}
	}
	${HUBSPOT_INTEGRATION_FRAGMENT}
`;

export const DISCONNECT_HUBSPOT = gql`
	mutation DisconnectHubspot {
		disconnectHubspot {
			...HubspotIntegrationFields
		}
	}
	${HUBSPOT_INTEGRATION_FRAGMENT}
`;

export const SYNC_HUBSPOT_CONTACTS = gql`
	mutation SyncHubspotContacts($startDate: DateTime) {
		syncHubspotContacts(startDate: $startDate) {
			success
			message
		}
	}
`;
