import {ReactElement, useCallback} from "react";
import classnames from "classnames";

import {ButtonProps, InputRow, SmallButton} from "../../components/input";
import {useDropdown} from "../../components/input/dropdown";
import {CalendarItem} from ".";
import {Span2} from "../../components/text";
import {Icon} from "../../components/images";
import {EventList} from "./calendar-items";

import styles from "./schedule.module.scss";

interface MoreButtonProps extends Omit<ButtonProps, "onClick" | "iconRight"> {
	data: CalendarItem[];
}

export function MoreButton({data, ...props}: MoreButtonProps): ReactElement {
	const popup = useCallback(
		({close}) => (
			<>
				<InputRow position="between" className={styles.divider}>
					<Span2 bold>{data[0]?.date.formatAs("longDate")}</Span2>
					<Icon icon="close" onClick={close} />
				</InputRow>
				{data.slice(10).map(item => (
					<div className={classnames(styles.item, styles[item.type])} key={item.date.valueOf()}>
						<EventList data={item} />
					</div>
				))}
			</>
		),
		[data]
	);
	const {portal, reference, toggle} = useDropdown({
		popup,
		portalClassName: styles.buttonMenu,
	});
	return (
		<>
			<SmallButton {...props} onClick={toggle} ref={reference} border={false} invert />
			{portal}
		</>
	);
}
