import React, {useCallback, useMemo} from "react";
import dayjs from "dayjs";

interface GridProps {
	gridElements: Record<string, Record<string, number>>;
	days: dayjs.Dayjs[];
	timeSlotsPerHour: number;
	biggestValueDate: {date: string; time: string; value: number};
}

export default function Grid({gridElements, days, timeSlotsPerHour, biggestValueDate}: GridProps) {
	const getColor = useCallback(
		(value: number) =>
			value > 80
				? "rgba(0, 128, 0, 0.6)"
				: value > 60
				? "rgba(144, 238, 144, 0.6)"
				: value > 40
				? "rgba(255, 255, 0, 0.6)"
				: value > 20
				? "rgba(255, 165, 0, 0.6)"
				: value === 0
				? "#4CC9F0"
				: "rgba(255, 0, 0, 0.6)",
		[]
	);

	const timeGrid = useMemo(() => {
		let temp = Number(biggestValueDate.time.split(":")[1]);
		temp = temp - (temp % (60 / timeSlotsPerHour));
		return `${biggestValueDate.time.split(":")[0]}:${temp === 0 ? "00" : temp}`;
	}, [biggestValueDate.time, timeSlotsPerHour]);
	return (
		<>
			<div style={{marginTop: "1rem", display: "flex"}} className="space">
				<div style={{whiteSpace: "nowrap"}}>
					<div style={{height: "20px", paddingRight: "10px"}}>Hour: </div>
					<div style={{height: "20px", paddingRight: "10px"}}>Minute: </div>
					{days.map((day, ind) => (
						<div key={`date-${ind}`} style={{height: "20px", paddingRight: "10px"}}>
							{day.format("YYYY-MM-DD")} - {day.format("dddd")}
						</div>
					))}
				</div>
				<div style={{paddingRight: "20px"}}>
					<div style={{display: "flex"}}>
						{Object.keys(gridElements[Object.keys(gridElements)[0]]).map(timeslot => (
							<React.Fragment key={`hour-${timeslot.split(":")}`}>
								{Number(timeslot.split(":")[1]) === 0 && (
									<div
										style={{
											flex: 1,
											height: "20px",
											boxSizing: "border-box",
											border: "1px solid black",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											fontSize: "11px",
										}}
									>
										{Number(timeslot.split(":")[0])}
									</div>
								)}
							</React.Fragment>
						))}
					</div>
					<div style={{display: "flex"}}>
						{Object.keys(gridElements[Object.keys(gridElements)[0]]).map(timeslot => (
							<div
								key={`hour${timeslot}`}
								style={{
									width: "20px",
									height: "20px",
									boxSizing: "border-box",
									border: "1px solid black",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									fontSize: "11px",
								}}
							>
								{Number(timeslot.split(":")[1])}
							</div>
						))}
					</div>
					{Object.entries(gridElements).map(([date, slots], ind) => (
						<div key={ind}>
							<div style={{display: "flex"}}>
								{Object.entries(slots).map(([time, value], ind) => (
									<div
										key={`${ind}-${value}`}
										style={{
											width: "20px",
											height: "20px",
											boxSizing: "border-box",
											border: "1px solid black",
											borderColor:
												biggestValueDate.date === date &&
												timeGrid === time &&
												biggestValueDate.value === value
													? "red"
													: "black",

											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											fontSize: "11px",
											color:
												biggestValueDate.date === date &&
												timeGrid === time &&
												biggestValueDate.value === value
													? "white"
													: "black",
											background:
												biggestValueDate.date === date &&
												timeGrid === time &&
												biggestValueDate.value === value
													? "#0504AA"
													: getColor(value),
										}}
										title={String(value)}
									>
										{Math.floor(value)}
									</div>
								))}
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
}
