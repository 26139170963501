import {FC, useMemo, useState} from "react";
import dayjs from "dayjs";
import {useQuery} from "@apollo/client";

import {accountNames, services, Service, useGroupList} from "../../../data";
import {Button, InputHeader, InputRow, Select, Separator} from "../../../components/input";
import {IconToggleGroup} from "../../../components/toggle";
import {
	DateIntervalPicker,
	DateIntervalOptions,
	DateIntervalType,
	useCustomRange,
} from "../../../components/input/date-time/date-interval-picker";
import {GET_COMPANY_POSTS_STATS} from "../../../data/analytics";
import {Span, Span1, Span4} from "../../../components/text";
import {FindOutWhoCard} from "./find-out-who-card";
import {PopularPostsCard} from "./popular-posts-card";
import {UserStatusCard} from "./user-status-card";
import {UserAdoptionCards} from "./user-adoption-cards";
import {useModal} from "../../../modals/new";
import {ExportReportModal} from "./export-report-modal";

import styles from "./employee-advocacy.module.scss";

export interface EmployeeAdvocacyFilter {
	groups: number[];
	dateRange: DateIntervalType;
	network: Service | undefined;
}

export const minDate = dayjs().add(-10, "years").startOf("year");

const getEmv = emv =>
	new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	}).format(emv ?? 0);

const getStat = (data, prop, network) =>
	network
		? data?.companyPostsStats?.[prop]?.[network] || 0
		: services.reduce((acc, network) => acc + data?.companyPostsStats?.[prop]?.[network] || 0, 0);

const Stats: FC<{filter: EmployeeAdvocacyFilter}> = ({filter}) => {
	const dateRange = useCustomRange(filter.dateRange || "Max", minDate);
	const {start = dayjs(), end = dayjs()} = dateRange || {};

	const network = filter.network;
	const {data} = useQuery(GET_COMPANY_POSTS_STATS, {
		variables: {
			ids: filter.groups.length ? filter.groups : undefined,
			start,
			end,
		},
	});
	const intervalTxt = useMemo(() => `${start.formatAs("shortDate")} - ${end.formatAs("shortDate")}`, [
		start,
		end,
	]);

	return (
		<InputRow className={styles.statsRow}>
			<div className={styles.postStat}>
				<div className={styles.firstLine}>
					<Span className={styles.amount}>{getStat(data, "clicks", network)}</Span>
					<Span1>Clicks</Span1>
				</div>
				<Span4 color={"grey"}>{intervalTxt}</Span4>
			</div>
			<div className={styles.postStat}>
				<div className={styles.firstLine}>
					<Span className={styles.amount}>{getStat(data, "shares", network)}</Span>
					<Span1>Shares</Span1>
				</div>
				<Span4 color={"grey"}>{intervalTxt}</Span4>
			</div>
			<div className={styles.postStat}>
				<div className={styles.firstLine}>
					<Span className={styles.amount}>{getEmv(getStat(data, "emv", network))}</Span>
					<Span1>EMV</Span1>
				</div>
				<Span4 color={"grey"}>{intervalTxt}</Span4>
			</div>
		</InputRow>
	);
};

export const EmployeeAdvocacy: FC = () => {
	const {open, modal} = useModal({});
	const {groups} = useGroupList();

	const [filter, setFilter] = useState<EmployeeAdvocacyFilter>({
		groups: [],
		dateRange: DateIntervalOptions[2].value,
		network: undefined,
	});

	const onNetworkChange = v => setFilter(f => ({...f, network: v}));
	const onGroupsChange = v => setFilter(f => ({...f, groups: v}));

	const toggleOptions = useMemo(() => {
		const userServices = services.filter(s => s !== "instagram").map(s => ({value: s, icon: s}));

		return [
			{
				value: undefined,
				icon: "all-socials" as const,
			},
			...userServices,
		];
	}, []);

	return (
		<div className={styles.employeeAdvocacy}>
			<div>
				<InputHeader>
					<>
						<IconToggleGroup value={filter?.network} options={toggleOptions} onChange={onNetworkChange} />
						<h3 className="space">{filter?.network ? accountNames[filter.network] : "All Social Media"}</h3>
					</>
					<>
						<Select
							placeholder="All Groups"
							multi
							options={groups}
							value={filter.groups}
							onChange={onGroupsChange}
						/>
						<DateIntervalPicker
							className={styles.dateRange}
							min={minDate}
							max={dayjs()}
							showPresets={true}
							value={filter.dateRange}
							onChange={v => setFilter(f => ({...f, dateRange: v}))}
						/>
						<Button value="Export" icon={"export"} onClick={open} />
						<ExportReportModal filter={filter} modal={modal} />
					</>
				</InputHeader>
				<Stats filter={filter} />
			</div>
			<div className={styles.cardsContainer}>
				<PopularPostsCard filter={filter} />
				<FindOutWhoCard filter={filter} />
			</div>
			<div className={styles.sectionTitle}>
				<h1>Adoption Overview</h1>
				<Separator horizontal />
			</div>
			<UserAdoptionCards filter={filter} />
			<UserStatusCard filter={filter} />
		</div>
	);
};
