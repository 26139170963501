import {gql} from "@apollo/client";

const SSO_OPTIONS_FRAGMENT = gql`
	fragment SSOOptions on SSOConfig {
		domains
		ssoUrl
		sloUrl
		issuerUrl
		certificate
		service
		disableRequestedAuthnContext
		signinCert
		id
		ourSSOUrl
		ourSLOUrl
		ourEntityId
	}
`;

export const GET_SSO_SETTINGS = gql`
	query GetSSOSettings {
		sso {
			...SSOOptions
		}
	}
	${SSO_OPTIONS_FRAGMENT}
`;

export const UPDATE_SSO_SETTINGS = gql`
	mutation UpdateSSOSettings(
		$ssoUrl: String!
		$sloUrl: String!
		$issuerUrl: String!
		$service: String!
		$domains: [String!]!
		$certificate: String!
		$disableRequestedAuthnContext: Boolean!
	) {
		updateSSOConfig(
			ssoUrl: $ssoUrl
			sloUrl: $sloUrl
			issuerUrl: $issuerUrl
			service: $service
			domains: $domains
			certificate: $certificate
			disableRequestedAuthnContext: $disableRequestedAuthnContext
		) {
			...SSOOptions
		}
	}
	${SSO_OPTIONS_FRAGMENT}
`;

export const CREATE_SSO_SETTINGS = gql`
	mutation CreateSSOSettings(
		$ssoUrl: String!
		$sloUrl: String!
		$issuerUrl: String!
		$service: String!
		$domains: [String!]!
		$certificate: String!
		$disableRequestedAuthnContext: Boolean!
	) {
		createSSOConfig(
			ssoUrl: $ssoUrl
			sloUrl: $sloUrl
			issuerUrl: $issuerUrl
			service: $service
			domains: $domains
			certificate: $certificate
			disableRequestedAuthnContext: $disableRequestedAuthnContext
		) {
			...SSOOptions
		}
	}
	${SSO_OPTIONS_FRAGMENT}
`;

export const CREATE_SSO_CERTIFICATE = gql`
	mutation CreateSSOCertificate {
		createSSOCertificate
	}
`;
