import {ReactElement, useCallback, useState} from "react";
import classnames from "classnames/bind";

import {count} from "../../utils/text";
import {P2, Span2} from "../../components/text";
import {Button, Checkbox, InputRow} from "../../components/input";
import {useMediaLibraryModal} from "../media/media-library";
import {ModalData} from "../new";

import styles from "./shuffle-image-tab.module.scss";

const bStyles = classnames.bind(styles);

interface ShuffleImageProps {
	disabled: boolean;
	images: string[];
	setImages: (images: string[]) => void;
}

interface ItemProps {
	toggleSelection: (url: string, toggleValue: boolean) => void;
	url: string;
	selected: boolean;
	disabled: boolean;
}

interface SfuffleImageActionsProps {
	modal: ModalData;
	onConfirm: () => void;
	updating: boolean;
}

const ImageItem = ({url, selected, toggleSelection, disabled}: ItemProps) => {
	const handleSelection = useCallback(() => {
		if (disabled) {
			return;
		}

		toggleSelection(url, !selected);
	}, [url, selected, disabled, toggleSelection]);

	return (
		<div onClick={handleSelection} key={url} className={bStyles("item", {selected, disabled})}>
			{selected && <Checkbox className={styles.itemCheckbox} value={true} onChange={handleSelection} />}
			<img alt="" src={url} />
		</div>
	);
};

export const SfuffleImageActions = ({modal, onConfirm, updating}: SfuffleImageActionsProps): ReactElement => {
	const handleClose = useCallback(() => {
		modal.close();
	}, [modal]);

	return (
		<InputRow position="between">
			<Button onClick={handleClose} value="Cancel" loading={updating} invert />
			<Button onClick={onConfirm} value="Confirm" />
		</InputRow>
	);
};

export const ShuffleImageTab = ({disabled, images, setImages}: ShuffleImageProps) => {
	const [selected, setSelected] = useState<string[]>([]);

	const handleAddImage = useCallback(
		media => {
			if (media?.type !== "image") return;

			setImages([...images, media.url]);
		},
		[setImages, images]
	);

	const handleSelection = useCallback((url, isSelected) => {
		if (!isSelected) {
			setSelected(selected => selected.filter(item => item !== url));
			return;
		}

		setSelected(selected => [...selected, url]);
	}, []);

	const handleDeselectAll = useCallback(() => setSelected([]), []);

	const handleRemove = useCallback(() => {
		setImages(images.filter(image => !selected.includes(image)));
		setSelected([]);
	}, [setImages, selected, images]);

	const mediaLibraryModal = useMediaLibraryModal({
		onConfirm: handleAddImage,
	});

	const items = selected.length || images.length;

	return (
		<div className={bStyles("container")}>
			<P2 bold color="grey" className="space">
				Add alternative images that will be randomized for the recipients of this post.
			</P2>

			{images.length > 0 && (
				<div className={bStyles("toolbar", "space")}>
					<Span2 color="black" bold>
						{count(items, "Image")} {selected.length > 0 ? " selected" : ""}
					</Span2>

					{!disabled && selected.length > 0 && (
						<div className={bStyles("selection")}>
							<Button onClick={handleDeselectAll} color="blue" value="Deselect All" border={false} invert />

							<Button
								onClick={handleRemove}
								icon="close"
								color="blue"
								value="Remove from Post"
								border={false}
								invert
							/>
						</div>
					)}

					{!disabled && (
						<Button
							onClick={mediaLibraryModal.open}
							color="blue"
							value="Add More Images"
							icon="add"
							border={false}
							invert
						/>
					)}
				</div>
			)}

			<div className={bStyles("list", "space")}>
				{images.map(url => (
					<ImageItem
						key={url}
						url={url}
						disabled={disabled}
						selected={selected.includes(url)}
						toggleSelection={handleSelection}
					/>
				))}
			</div>

			{!disabled && !images.length && (
				<div className={bStyles("emptyList", "space")}>
					<Button onClick={mediaLibraryModal.open} invert color="blue" value="Add Images" icon="add" />
				</div>
			)}
		</div>
	);
};
