import dayjs, {Dayjs} from "dayjs";

interface HoursValueObject {
	[key: number]: number;
}

interface DaySlots {
	[key: string]: number;
}

interface DayObject {
	date: string;
	day: string;
	slots: DaySlots;
	value: number;
}

export function createDays(totalDays: number, startDate: Dayjs): Dayjs[] {
	const days: Dayjs[] = [startDate.clone()];
	for (let i = 1; i < totalDays; i++) {
		const day = startDate.clone().add(i, "days");
		days.push(day);
	}
	return days;
}

function slotCalculatedValueObject({
	hour,
	minute,
	day,
	timeSlotsPerHour,
	hoursValue,
}: {
	hour: string;
	minute: string;
	day: string;
	timeSlotsPerHour: number;
	hoursValue: {[key: string]: HoursValueObject};
}): number {
	const objectValues = hoursValue[day] ?? hoursValue.default;
	const currentHour = objectValues[Number(hour)];
	if (minute === "00") return currentHour;
	const nextHour = objectValues[(Number(hour) + 1) % 24];
	const diff = Math.abs(nextHour - currentHour);

	if (nextHour === currentHour) return currentHour;
	if (nextHour > currentHour)
		return currentHour + (diff / timeSlotsPerHour) * (Number(minute) / (60 / timeSlotsPerHour));
	return currentHour - (diff / timeSlotsPerHour) * (Number(minute) / (60 / timeSlotsPerHour));
}

export function timeSlots({
	day,
	timeSlotsPerHour,
	timeSlotInMinutes,
	hoursValue,
}: {
	day: Dayjs;
	timeSlotsPerHour: number;
	timeSlotInMinutes: number;
	hoursValue: {[key: string]: HoursValueObject};
}): DaySlots {
	const startOfDay = day.clone().startOf("day");
	const totalSlots = 24 * timeSlotsPerHour;

	return Array.from({length: totalSlots}, (_, i) =>
		startOfDay.clone().add(i * timeSlotInMinutes, "minutes")
	).reduce(
		(slots, slot) => ({
			...slots,
			[slot.format("HH:mm")]: slotCalculatedValueObject({
				hour: slot.format("HH"),
				minute: slot.format("mm"),
				day: day.format("dddd"),
				timeSlotsPerHour,
				hoursValue,
			}),
		}),
		{}
	);
}

function getDefaultSlots(daysObject: DayObject[], startDate): {[key: string]: DaySlots} {
	return daysObject.reduce((acc, day) => {
		const slots = Object.entries(day.slots).reduce((acc, [slot, value]) => {
			const slotDateTime = dayjs(`${day.date} ${slot}`);
			const withIn30Minutes = slotDateTime.diff(startDate, "minutes") <= 30;
			const adjustedValue = (value * day.value) / 100;
			return {
				...acc,
				[slot]: startDate > slotDateTime ? 0 : adjustedValue - adjustedValue * (withIn30Minutes ? 0.5 : 0),
			};
		}, {});
		return {
			...acc,
			[day.date]: slots,
		};
	}, {});
}

export function getSlotsParsedByEvents({
	daysObject,
	startDate,
	events,
	dayImpact,
	eventSpeadPenalty,
	longHourImpact,
	timeSlotsPerHour,
	shortHourImpact,
}: {
	daysObject: DayObject[];
	startDate: Dayjs;
	events: string[];
	dayImpact: number;
	eventSpeadPenalty: number;
	longHourImpact: number;
	shortHourImpact: number;
	timeSlotsPerHour: number;
}): {[key: string]: DaySlots} {
	const defaultSlots = getDefaultSlots(daysObject, startDate);
	const slotsCopy = {...defaultSlots};
	events.forEach(ev => {
		let event = dayjs(ev);
		event = event.set("minute", event.minute() - (event.minute() % (60 / timeSlotsPerHour)));
		if (Object.keys(slotsCopy).includes(event.format("YYYY-MM-DD"))) {
			const slot = slotsCopy[event.format("YYYY-MM-DD")];
			const newSlots = Object.keys(slot).reduce((acc, time) => {
				const slotTime = dayjs(`${event.format("YYYY-MM-DD")} ${time}`);
				acc[time] = slot[time] * (1 - dayImpact);
				const diff = Math.abs(slotTime.diff(event) / 1000 / 60 / 60);
				if (diff === 0) {
					acc[time] = 0;
				} else {
					// check if difference is under 6 hours
					if (diff < eventSpeadPenalty) {
						// only get first 4 numbers after decimal
						const num =
							slot[time] *
							(1 -
								longHourImpact +
								diff * timeSlotsPerHour * (longHourImpact / (eventSpeadPenalty * timeSlotsPerHour)));
						acc[time] = Number(((num * 100) / 100) * (1 - dayImpact));
						if (diff <= 0.25) {
							acc[time] = acc[time] * shortHourImpact;
						}
					}
				}
				return acc;
			}, {});
			slotsCopy[event.format("YYYY-MM-DD")] = newSlots;
		}
	});

	return slotsCopy;
}

export function getBiggestValue(
	final: {
		[key: string]: DaySlots;
	},
	timeSlotsPerHour
): {date: string; time: string; value: number} {
	const entries = Object.entries(final).flatMap(([date, slots]) =>
		Object.entries(slots).map(([time, value]) => ({date, time, value}))
	);

	// Find the maximum value
	const maxValue = Math.max(...entries.map(entry => entry.value));

	// Filter entries with the maximum value within 2% margin
	const maxEntries = entries.filter(
		entry => entry.value >= maxValue * 0.98 && entry.value <= maxValue * 1.02
	);

	// Pick a random entry from maxEntries
	const randomEntry = maxEntries[Math.floor(Math.random() * maxEntries.length)];
	const randomMinutes = Math.floor(Math.random() * (60 / timeSlotsPerHour));
	// const randomTime = moment(randomEntry.time, "HH:mm").add(randomMinutes, "minutes").format("HH:mm");
	const randomTime = dayjs(`${randomEntry.date} ${randomEntry.time}`);
	// .add(randomMinutes, "minutes").format("HH:mm");
	return {...randomEntry, time: randomTime.add(randomMinutes, "minutes").format("HH:mm")};
}
