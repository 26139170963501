import {FC, useMemo} from "react";
import classNames from "classnames";

import {Connections, Service} from "../../data";
import {Modal, ModalData} from "../../modals/new";
import {Social} from "../../components/social";
import {Span2, Span3} from "../../components/text";
import {HoverTooltip} from "../../components/tooltip";
import {InputRow} from "../../components/input";
import config from "../../config";

import styles from "./networks-modal.module.scss";

export interface NetworksModalProps {
	modal: ModalData;
	connections: Connections;
}

export const NetworksModal: FC<NetworksModalProps> = ({modal, connections}) => {
	const updatedModal = useMemo<ModalData>(
		() => ({
			...modal,
			close: () => {
				modal.close();
			},
		}),
		[modal]
	);

	return (
		<Modal modal={updatedModal}>
			<div className={styles.container}>
				<img src="/svgs/clearview-connections.svg" alt="ClearView Social Connections" />
				<h1 className="space">Uh oh!</h1>
				<Span2 className="space">
					Connect your social account below to properly use Clearview Social. Add at least one network to get
					started.
				</Span2>
				<InputRow className={styles.networksContainer}>
					{connections &&
						Object.entries(connections)
							.filter(([key]) => ["linkedin", "facebook", "twitter"].includes(key))
							.map(([key, value], index) => (
								<HoverTooltip
									text={`Click to ${value?.connected ? "refresh" : "connect"} ${key.uFirst()} account`}
									positions={["bottom"]}
									key={index}
								>
									<div
										className={classNames(
											styles.networks,
											value?.connected && styles.active,
											value?.expired && styles.expired
										)}
										onClick={() => {
											window.open(
												`${
													key === "linkedin"
														? config.linkedinUrl
														: key === "facebook"
														? config.facebookUrl
														: config.twitterUrl
												}?redirect_override=settings`,
												"_self"
											);
										}}
									>
										<Social name={key as Service} active={value?.connected} size="medium" />
										<Span3 color={value?.expired ? "pink" : value?.connected ? "black" : "grey"}>
											{value?.expired ? "Expired" : value?.connected ? "Connected" : "Disconnected"}
										</Span3>
									</div>
								</HoverTooltip>
							))}
				</InputRow>
			</div>
		</Modal>
	);
};
