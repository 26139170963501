import {useCallback, useEffect, useMemo, useState} from "react";
import {useFormikContext} from "formik";

import {Button, InputRow} from "../../../../components/input";
import {Recipient} from "./recipient";
import {FormValues, NetworkSchedule, Schedule} from "./types";
import {PostEditor} from "./post-editor";
import {CALCULATE_SOCIAL_SCORE, Service} from "../../../../data";
import {useModal} from "../../../../modals/new";
import {ScheduleSummaryModal, SocialScore} from "../../components";
import {SharingTime} from "./sharing-time";
import {useConfirmModal} from "../../../../modals";
import {Setter} from "../../../../types";
import {useMutationToast} from "../../../../toast";
import {useDebounceCallback} from "../../../../hooks/use-debounce-callback";

import styles from "./styles.module.scss";

type RecipientOption = {
	label: string;
	id: string;
	networks: Service[];
	schedule: Schedule;
};

interface CreatePostFormProps {
	recipientOptions: RecipientOption[];
	socialScore?: {
		value: number;
		potential?: number;
		suggestion?: string;
	};
	shareFromUrl?: string;
	setShareFromUrl: Setter<string | undefined>;
	disabled?: boolean;
	onClose?: () => void;
}

export const CreatePostForm = ({
	recipientOptions,
	socialScore: socialScoreProp,
	shareFromUrl,
	setShareFromUrl,
	disabled,
	onClose,
}: CreatePostFormProps) => {
	const [socialScore, setSocialScore] = useState(socialScoreProp);
	const [calculateSocialScore, {loading: isLoadingSocialScore}] = useMutationToast(CALCULATE_SOCIAL_SCORE);
	const {values, setFieldValue: onChange, submitForm, isValid, isSubmitting} = useFormikContext<FormValues>();
	const {recipient, schedule, opengraphs} = values;

	const {open: openScheduleSummaryModal, modal: scheduleSummaryModal} = useModal({});
	const {open: openShareNowModal} = useConfirmModal(
		() => ({
			title: "Share all posts now",
			body: "All posts will immediately be shared to social media. This action cannot be undone.",
			confirming: isSubmitting,
			onConfirm: close => {
				close();
				return submitForm();
			},
		}),
		[isSubmitting, submitForm]
	);
	const scheduleImmediately = useMemo(() => values?.schedule?.immediately ?? false, [values]);

	const onRecipientChange = useCallback(
		async (_, value) => {
			const newRecipient = {
				id: value.id,
				networks: value.networks,
			};
			const newSchedule = value?.networks?.reduce(
				(acc: Schedule, n: Service) => ({
					...acc,
					[n]: schedule?.[n] ?? {
						scheduledFor: undefined,
						peakTime: value?.peakTime ?? true,
						timeslot: !value?.peakTime,
					},
				}),
				{}
			);
			if (scheduleImmediately) newSchedule.immediately = true;

			await onChange("recipient", newRecipient);
			onChange("schedule", newSchedule);
		},
		[onChange, schedule, scheduleImmediately]
	);

	const onScheduleConfirm = useCallback(
		async (schedule: NetworkSchedule) => {
			await onChange("schedule", schedule);
			await submitForm();
			onClose?.();
		},
		[onChange, submitForm, onClose]
	);

	const getSocialScore = useCallback(
		async (opengraphs, services) => {
			const {
				data: {
					socialScoreAndSuggestions: {socialScore, suggestions},
				},
			} = await calculateSocialScore({variables: {opengraphs, services}});
			const potential = socialScore + suggestions?.general.reduce((acc, item) => acc + item.value, 0);
			setSocialScore({
				...socialScore,
				value: socialScore,
				suggestion: suggestions.general[0]?.message,
				potential: (socialScore / potential || 0) * 100,
			});
		},
		[calculateSocialScore]
	);

	const debounceSocialScore = useDebounceCallback(getSocialScore, 1500);

	useEffect(() => {
		debounceSocialScore(
			opengraphs,
			recipient?.networks.map(n => (n === "facebook" ? "facebook_page" : n))
		);
	}, [opengraphs, recipient, getSocialScore, debounceSocialScore]);

	return (
		<div className={styles.postForm}>
			<div className={styles.topContainer}>
				<Recipient className={styles.shareTo} options={recipientOptions} onChange={onRecipientChange} />
				<SharingTime onChange={onChange} />
			</div>
			<div className={styles.postBody}>
				<PostEditor disabled={disabled} shareFromUrl={shareFromUrl} setShareFromUrl={setShareFromUrl} />
				{!!socialScore && (
					<div className={styles.postStats}>
						<SocialScore
							loading={isLoadingSocialScore}
							socialScore={socialScore.value}
							potentialSocialScore={socialScore.potential}
							suggestion={socialScore.suggestion}
						/>
					</div>
				)}
			</div>
			<InputRow className={styles.formBottomToolbar} position={"right"}>
				<div className={styles.buttonsContainer}>
					<Button
						value={schedule.immediately ? "Share Now" : "Schedule"}
						onClick={schedule.immediately ? openShareNowModal : openScheduleSummaryModal}
						loading={isSubmitting}
						disabled={!isValid}
					/>
					{onClose && <Button value={"Discard"} color={"pink"} onClick={onClose} loading={isSubmitting} />}
				</div>
			</InputRow>
			{scheduleSummaryModal.open && (
				<ScheduleSummaryModal
					modal={scheduleSummaryModal}
					data={schedule ?? {}}
					userId={recipient?.id}
					onConfirm={onScheduleConfirm}
				/>
			)}
		</div>
	);
};
