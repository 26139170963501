import React, {FC, useCallback, useMemo, useState} from "react";
import dayjs from "dayjs";

import {Modal, ModalData} from "../../../modals/new";
import {User} from "../../../data";
import {Button, InputRow, Text} from "../../../components/input";
import {downloadCsv} from "../../../utils/csv";
import {Span} from "../../../components/text";
import {EmptyList} from "../../../components/empty-list";

import styles from "./pending-users-modal.module.scss";

interface PendingUsersModalProps {
	modal: ModalData;
	users: User[];
}

export const PendingUsersModal: FC<PendingUsersModalProps> = ({modal, users}) => {
	const [search, setSearch] = useState("");
	const csvData = useMemo(() => {
		const headers = ["Name", "E-mail", "Role", "Date Sent"];
		const searchTrimmed = search.trim();
		const searchLC = search.toLowerCase();
		const items = users
			.filter(user => !searchTrimmed || user?.fullName?.toLowerCase()?.includes(searchLC))
			.map(user => [
				user.fullName || "",
				user.email || "",
				user.role || "",
				user.lastInvited ? dayjs(user.lastInvited).formatAs("shortDate") : "",
			]);
		return [headers, ...items];
	}, [users, search]);
	const handleExport = useCallback(() => {
		downloadCsv(csvData.slice(1), csvData[0], "pending-users.csv");
	}, [csvData]);

	if (!modal.open) return null;

	return (
		<Modal modal={modal} title="Pending Users" size="fit-content" className={styles.pendingUsersModal}>
			<div className={styles.content}>
				<InputRow className={styles.toolbar}>
					<Text icon="search" value={search} onChange={setSearch} placeholder="Search" />
					<Button onClick={handleExport} value="Export CSV" />
				</InputRow>
				<div className={styles.items}>
					{csvData.map((row, rowIndex) => (
						<div className={styles.row} key={rowIndex}>
							{row.map((rowItem, rowItemIndex) => (
								<Span key={rowItemIndex} bold={rowIndex === 0} className={styles.rowItem}>
									{rowItem}
								</Span>
							))}
						</div>
					))}
					{!csvData.length && <EmptyList text={search ? "No matches found" : "No items"} />}
				</div>
			</div>
		</Modal>
	);
};
