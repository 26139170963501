import classNames from "classnames";
import {ArrowContainerProps, useArrowContainer} from "react-tiny-popover";

import styles from "./tooltip.module.scss";

export const ArrowContainer = ({
	childRect,
	popoverRect,
	position,
	arrowColor,
	arrowSize,
	children,
}: ArrowContainerProps) => {
	const {arrowContainerStyle, arrowStyle} = useArrowContainer({
		childRect,
		popoverRect,
		position,
		arrowColor,
		arrowSize,
	});

	return (
		<div className={styles.arrowContainer} style={arrowContainerStyle}>
			<div style={arrowStyle} className={classNames(styles.triangle, styles[position ?? "none"])} />
			{children}
		</div>
	);
};
