import {ReactElement, RefObject, useEffect, useRef, useState} from "react";
import classnames from "classnames";
import dayjs, {Dayjs} from "dayjs";
import {useDrop} from "react-dnd";
import {FetchResult} from "@apollo/client";

import {Card} from "../../components/card";
import {CalendarItems} from "./calendar-items";
import {CalendarItem, CalendarProps} from ".";
import {Loading} from "../../components/loading";

import styles from "./schedule.module.scss";

const CalendarDragItem = "calendar-item";

const hours: number[] = Array(24)
	.fill(0)
	.map((_, i) => i);

export const scrollTo9Am = (containerRef: RefObject<HTMLDivElement>): void => {
	if (containerRef.current && containerRef.current.children.length === 24) {
		const item = containerRef.current.children[7];
		if (item) {
			item.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		}
	}
};

interface CalendarWeekHourProps {
	hour: number;
	data: CalendarItem[];
	day: Dayjs;
	setDayView: (date: Dayjs) => void;
	updateItem: (item: CalendarItem) => Promise<FetchResult>;
}

const CalendarWeekHour = ({hour, data, day, setDayView, updateItem}: CalendarWeekHourProps): ReactElement => {
	const [loadingItem, setLoadingItem] = useState(false);
	const [over, drop] = useDrop(
		() => ({
			accept: CalendarDragItem,
			drop: (item: CalendarItem) => {
				if (item.date?.isAfter(new Date())) {
					setLoadingItem(true);
					updateItem({...item, date: item.date?.set("hour", hour).set("date", day.date())}).finally(() =>
						setLoadingItem(false)
					);
				}
			},
			collect: monitor => monitor.canDrop() && monitor.isOver(),
			canDrop: (item: CalendarItem) => item.date?.isAfter(new Date()),
		}),
		[day, hour, updateItem]
	);
	return (
		<div ref={node => drop(node)} key={hour} className={styles.hour}>
			{loadingItem && <Loading position="absolute" />}
			{over && <div className={styles.over} />}
			<CalendarItems data={data} day={day} hour={hour} mode="week" setDayView={setDayView} />
		</div>
	);
};

export const CalendarWeek = ({
	selectedDay,
	data,
	loading,
	setDayView,
	updateItem,
}: CalendarProps): ReactElement => {
	const containerRef = useRef<HTMLDivElement>(null);
	const start = selectedDay.day(0);
	const days: Dayjs[] = Array(7)
		.fill(0)
		.map((_, i) => start.add(i, "day"));

	const today = dayjs();

	useEffect(() => {
		scrollTo9Am(containerRef);
	}, [containerRef]);

	return (
		<Card className={styles.weeklyCalendar}>
			{loading && <Loading position="absolute" />}
			<div className={styles.weeklyView}>
				<div className={styles.hours}>
					{hours.map(hour => (
						<div key={hour}>{selectedDay.hour(hour).format("h A")}</div>
					))}
				</div>
				<div className={styles.days}>
					{days.map(day => (
						<div key={day.valueOf()}>
							<div className={classnames(styles.header, day.isSame(today, "day") && styles.today)}>
								<h1>{day.format("D")}</h1>
								<div>{day.format("ddd")}</div>
							</div>
							<div className={styles.hours} ref={containerRef}>
								{hours.map(hour => (
									<CalendarWeekHour
										hour={hour}
										data={data}
										day={day}
										setDayView={setDayView}
										updateItem={updateItem}
										key={hour}
									/>
								))}
							</div>
						</div>
					))}
				</div>
			</div>
		</Card>
	);
};
