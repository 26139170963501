import React from "react";
import classNames from "classnames";

import {OpenGraph} from "../../../../data";
import {Icon} from "../../../../components/images";
import {Loading} from "../../../../components/loading";

import styles from "./components.module.scss";

interface MediaProps {
	image?: OpenGraph["image"];
	video?: OpenGraph["video"];
	width?: number;
	loadingMedia?: boolean;
	height?: number;
	border?: boolean;
	onRemove?: () => void;
}
export const Media = ({
	image,
	video,
	width = 128,
	height = 96,
	border,
	onRemove,
	loadingMedia,
}: MediaProps) => (
	<div
		className={classNames(styles.media, (border || loadingMedia) && styles.border)}
		style={{width, height}}
	>
		{loadingMedia ? (
			<Loading position="absolute" size="small" />
		) : (
			<>
				{image && <img src={image} alt="Post Image" />}
				{video && !image && (
					<video src={video} controls={true} muted preload="metadata">
						<source src={video} />
						<track kind="captions" srcLang="en" label="english_captions" />
					</video>
				)}
				{onRemove && (
					<div className={styles.removeBtn} onClick={onRemove}>
						<Icon icon="close" width={24} color="white" />
					</div>
				)}
			</>
		)}
	</div>
);
