import classnames from "classnames";
import React, {ReactElement} from "react";

import {EmptyComponent, Size} from "../../types";
import {HoverTooltip} from "../tooltip";
import {UserBadge} from "../../data/user";

import styles from "./badge.module.scss";

interface AnalyticsBadgeBadgeComponent extends EmptyComponent {
	badge: UserBadge;
	size: Size;
}

export const AnalyticsBadge = ({className, badge, size}: AnalyticsBadgeBadgeComponent): ReactElement => (
	<HoverTooltip text={badge.description} positions={["top"]}>
		<div className={classnames(styles.container, className)}>
			<img className={classnames(styles.image, styles[size])} alt={badge.title} src={badge.imageUrl} />
		</div>
	</HoverTooltip>
);
