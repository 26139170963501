import React, {useCallback, useContext} from "react";
import {useFormikContext} from "formik";
import classnames from "classnames/bind";

import {Category} from "../../../../data";
import {CollectionFormValues, OnChange} from "./collection-form";
import {Icon} from "../../../../components/images";
import {Span} from "../../../../components/text";
import {ListCollapsedContext} from "./post-list";
import {DragHandleProps} from "./drag-drop";

import styles from "./styles.module.scss";
const bStyles = classnames.bind(styles);

interface PostCategoryProps {
	category: Category;
	postsCount: number;
	onChange: OnChange;
	disabled?: boolean;
	dragHandleProps?: DragHandleProps;
}
export const PostCategory = ({
	category,
	postsCount,
	onChange,
	disabled,
	dragHandleProps,
}: PostCategoryProps) => {
	const {values} = useFormikContext<CollectionFormValues>();
	const {categories} = values;
	const {collapsedCategories, setCollapsedCategories} = useContext(ListCollapsedContext);
	const handleRemove = useCallback(
		() =>
			!disabled &&
			onChange(
				"categories",
				(categories || []).filter(cId => cId !== category.id)
			),
		[category.id, categories, onChange, disabled]
	);

	return (
		<div
			className={bStyles(styles.categoryContainer, {[styles.collapsed]: collapsedCategories[category.id]})}
		>
			<div className={styles.categoryHeader}>
				<div className={styles.categoryTitleCt}>
					<div {...dragHandleProps}>
						<Icon
							icon="drag"
							color="black"
							height={16}
							className={bStyles(styles.draggable, {[styles.disabled]: disabled})}
						/>
					</div>
					<Span className={styles.title}>
						{category.name}
						{postsCount ? ` (${postsCount})` : ""}
					</Span>
					<Icon
						icon="close"
						color="black"
						width={24}
						className={bStyles(styles.closeIcon, {[styles.disabled]: disabled})}
						onClick={handleRemove}
					/>
				</div>
				<div
					className={styles.collapseBtn}
					onClick={() => setCollapsedCategories(category.id, !collapsedCategories[category.id])}
				>
					<Icon icon={collapsedCategories[category.id] ? "chevron-down" : "chevron-up"} color="black" />
				</div>
			</div>
		</div>
	);
};
