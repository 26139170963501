import {Fragment, useCallback, useMemo, useState} from "react";
import classNames from "classnames";

import {Dot, Icon} from "../../../../components/images";
import {P, P1, P2, Span, Span2, Span3, Span4} from "../../../../components/text";
import {Collection, getPostEmv, Post as PostType, useCategories} from "../../../../data";
import {formatMoney} from "../../../../utils/number";
import {Separator} from "../../../../components/input";

import styles from "./style.module.scss";

const Post = ({post}: {post: PostType}) => {
	const opengraph = post.opengraphs?.general || {};
	const {image, video, title, comment, description} = opengraph;
	const [isOpen, setIsOpen] = useState(false);
	const setOpen = useCallback(() => setIsOpen(true), [setIsOpen]);
	const setClosed = useCallback(() => setIsOpen(false), [setIsOpen]);
	const {url, keywords} = post;

	//TODO: implement showing keywords if they exist
	const emvValue = useMemo(
		() =>
			new Intl.NumberFormat("en-US", {
				style: "currency",
				currency: "USD",
			}).format(getPostEmv({url, keywords} as PostType)),
		[url, keywords]
	);

	if (isOpen) {
		return (
			<>
				<div className={styles.post}>
					<div className={styles.postTop}>
						<P1 bold>Bulk Post</P1>
						<div className={styles.collapseBtn} onClick={setClosed}>
							<Icon icon="chevron-up" color="black" />
						</div>
					</div>
					<div className={styles.postBottom}>
						{comment && <P2 className={styles.bulkComment}>{comment}</P2>}
						{post.url ? (
							<div className={styles.urlBox}>
								<div>
									{image && (
										<img src={image} alt="Post Image" style={{height: 96, width: 128, objectFit: "cover"}} />
									)}
									{!image && video && (
										<video
											src={video}
											controls={true}
											muted
											preload="metadata"
											style={{height: 96, width: 128, objectFit: "cover"}}
										>
											<source src={video} />
											<track kind="captions" srcLang="en" label="english_captions" />
										</video>
									)}
								</div>
								<div className={styles.text}>
									<a href={post.url} className={styles.postLink} target="_blank" rel="noreferrer">
										{new URL(post.url).hostname}
									</a>
									<Span bold className={styles.title}>
										{title}
									</Span>
									<Span4 className={styles.description}>{description}</Span4>
								</div>
							</div>
						) : image ? (
							<img src={image} alt="Post Image" style={{height: 96, width: 128, objectFit: "cover"}} />
						) : !image && video ? (
							<video
								src={video}
								controls={true}
								muted
								preload="metadata"
								style={{height: 96, width: 128, objectFit: "cover"}}
							>
								<source src={video} />
								<track kind="captions" srcLang="en" label="english_captions" />
							</video>
						) : null}
					</div>
				</div>
				{url && (
					<div className={styles.emv}>
						<Icon color="grey" icon="information" />
						<Span>EMV: {emvValue} Saved</Span>
					</div>
				)}
			</>
		);
	}
	return (
		<div className={classNames(styles.post, styles.closed)}>
			{(image || video) && (
				<div className={styles.media}>
					{image && <img src={image} alt="Post Image" style={{height: 60}} />}
					{!image && video && (
						<video src={video} controls={true} muted preload="metadata" style={{height: 60}}>
							<source src={video} />
							<track kind="captions" srcLang="en" label="english_captions" />
						</video>
					)}
				</div>
			)}
			<div className={styles.text}>
				<Span2 bold className={styles.title}>
					{title}
				</Span2>
				<Span3 className={styles.comment}>{comment || description}</Span3>
			</div>
			<div className={styles.collapseBtn} onClick={setOpen}>
				<Icon icon="chevron-down" color="black" />
			</div>
		</div>
	);
};

export const Posts = ({collection}: {collection: Collection}) => {
	const emvValue = useMemo(
		() => formatMoney(collection.posts.map(getPostEmv).reduce((acc, obj) => acc + obj, 0)),
		[collection.posts]
	);
	const allCategories = useCategories({});
	const categories = useMemo<string[]>(
		() =>
			collection?.categories?.map(id => {
				const cat = allCategories.find(c => c.id === id);
				if (cat) return cat.name;
				return "";
			}) ?? [],
		[collection, allCategories]
	);
	const categorisedPosts = useMemo(() => {
		if (collection.categories) {
			return collection.categories.map(cat => collection.posts.filter(post => post.categoryId === cat));
		}
		return [];
	}, [collection]);
	const nonCategorisedPosts = useMemo(() => collection.posts.filter(post => !post.categoryId), [collection]);

	return (
		<div className={styles.posts}>
			<div className={styles.postData}>
				<P color="grey">
					Total Posts: <span className={styles.black}>{collection.posts.length}</span>
				</P>
				<Dot height={4} color="grey-400" />
				<P color="grey">
					Total EMV: <span className={styles.black}>{emvValue}</span>
				</P>
			</div>
			{categories?.map((c, i) => (
				<Fragment key={`${c}-${i}`}>
					{i !== 0 && <Separator className={styles.noMargin} horizontal />}
					<h3>
						{c} ({categorisedPosts[i].length})
					</h3>
					<div className={styles.postGroup}>
						{categorisedPosts[i].map(post => (
							<Post key={post.id} post={post} />
						))}
					</div>
				</Fragment>
			))}
			{nonCategorisedPosts.length !== 0 && (
				<div className={styles.postGroup}>
					{nonCategorisedPosts.map(post => (
						<Post key={post.id} post={post} />
					))}
				</div>
			)}
		</div>
	);
};
