import {useCallback, useState} from "react";

import {Button, InputRow, Text} from "../../components/input";
import {CREATE_CATEGORY, Category} from "../../data";
import {NewModal as Modal, useConfirmDeleteModal} from "../../modals";
import {P, Span3} from "../../components/text";
import {ModalData} from "../../modals/new";
import {useMutationToast, useToast} from "../../toast";
import {DELETE_CATEGORY} from "../../data/category";

import styles from "./manage-categoreis.module.scss";

export const ManageCategories = ({
	modal,
	categories,
	refetch,
}: {
	modal: ModalData;
	categories: Category[];
	refetch: () => void;
}): React.ReactElement => {
	const [newCategory, setNewCategory] = useState<string>("");
	const [add, setAdd] = useState<boolean>(false);
	const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
	const toast = useToast();
	const [createCategory, {loading: creating}] = useMutationToast(CREATE_CATEGORY);
	const [deleteCategory, {loading: deleting}] = useMutationToast(DELETE_CATEGORY);

	const handleCreate = useCallback(
		() =>
			createCategory({
				variables: {
					name: newCategory,
				},
			}).then(() => {
				setNewCategory("");
				setAdd(false);
				refetch();
			}),
		[createCategory, newCategory, refetch]
	);

	const {open, close} = useConfirmDeleteModal({
		what: `the "${selectedCategory?.name}" category`,
		onDelete: () => {
			if (categories?.length === 1) {
				return toast({
					icon: "warning",
					text: "You can't delete this category. There must be at least one organization category.",
					color: "red",
				});
			}
			confirmDelete(close);
		},
		deleting,
	});

	const confirmDelete = useCallback(
		(close: () => void) =>
			deleteCategory({
				variables: {
					id: selectedCategory?.id,
				},
			}).then(() => {
				refetch();
				close();
			}),
		[deleteCategory, selectedCategory, refetch]
	);

	return (
		<Modal
			title="Manage Categories"
			modal={modal}
			footer={
				<InputRow position="right">
					<Button
						value="Done"
						onClick={() => {
							modal.close();
							setAdd(false);
							setNewCategory("");
						}}
						disabled={deleting || creating}
					/>
				</InputRow>
			}
		>
			<P>
				You can manage your organization&apos;s collection categories here, including adding or deleting them.
			</P>
			<div className={styles.container}>
				{categories.map((c, i) => (
					<InputRow position="between" key={i} className={styles.item}>
						<Span3>{c.name}</Span3>
						<Button
							color="black"
							invert
							border={false}
							icon="delete"
							iconSize="small"
							onClick={() => {
								setSelectedCategory(c);
								open();
							}}
						/>
					</InputRow>
				))}
			</div>
			{!add ? (
				<Button
					className={styles.addSection}
					color="blue"
					invert
					border={false}
					icon="add"
					value="Add Category"
					onClick={() => setAdd(!add)}
				/>
			) : (
				<InputRow position="between" className={styles.addSection}>
					<Text value={newCategory} onChange={setNewCategory} placeholder="Enter new category" />
					<Button
						color="blue"
						border={false}
						loading={creating}
						invert
						value="Create"
						onClick={handleCreate}
						disabled={!newCategory.trim()}
					/>
				</InputRow>
			)}
		</Modal>
	);
};
