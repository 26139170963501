import {ReactElement, useCallback, useRef, useState} from "react";
import {Accept, useDropzone} from "react-dropzone";
import classnames from "classnames";

import {Button, Input, InputComponent, InputRow, useId} from "./index";
import {Icon} from "../images";
import {Span3} from "../text";

import styles from "./input.module.scss";

export type OnFile = (files: File[], resetState: () => void) => void;

interface FileInputProps extends Omit<InputComponent<File[]>, "value" | "onChange"> {
	what: string;
	accept: Accept;
	onFile: OnFile;
	loading?: boolean;
}

type FileStatus = "idle" | "loading" | "success" | "error";

export const File = ({
	id: maybeId,
	loading,
	disabled,
	what,
	accept,
	onFile,
	...props
}: FileInputProps): ReactElement => {
	const id = useId(maybeId);
	const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
	const [status, setStatus] = useState<FileStatus>("idle");

	const inputRef = useRef<HTMLInputElement>(null);

	const onDrop = useCallback((acceptedFiles: File[]) => {
		setStatus("loading");
		try {
			setUploadedFiles(acceptedFiles);
			setStatus("success");
		} catch (error) {
			setStatus("error");
		}
	}, []);

	const onDropRejected = useCallback(() => {
		setStatus("error");
	}, []);

	const resetState = useCallback(() => {
		setStatus("idle");
	}, []);

	const confirmHandler = useCallback(() => {
		onFile(uploadedFiles, resetState);
	}, [onFile, uploadedFiles, resetState]);

	const {getRootProps, getInputProps, isDragActive} = useDropzone({
		accept,
		onDrop,
		onDropRejected,
		disabled: loading || disabled,
	});

	return (
		<Input id={id} disabled={disabled} {...props}>
			<div
				{...(status === "idle" ? getRootProps() : {})}
				className={classnames(styles.dropzone, isDragActive && styles.active, styles[status])}
			>
				<input {...getInputProps()} ref={inputRef} accept={Object.keys(accept).join(",")} />
				<div className={classnames(styles.defaultIcon)}>
					<Icon
						icon={
							status === "error"
								? "upload"
								: status === "loading"
								? "close"
								: status === "success"
								? "check"
								: "upload"
						}
						className={styles.icon}
						height={40}
						width={40}
					/>
				</div>
				<div className={styles.main}>
					<div className={styles.text}>
						{status === "error" && uploadedFiles.length === 0 ? (
							<h5 className={styles.errorMessage}>File Upload Failed</h5>
						) : status === "loading" ? (
							<h5>Uploading File...</h5>
						) : status === "success" && uploadedFiles.length > 0 ? (
							<>
								<h5>File Uploaded</h5>
								<h6>
									{uploadedFiles.map((file, index) => (
										<h6 key={index}>
											{file.name} {Math.round(file.size / 1024)}kB
										</h6>
									))}
								</h6>
							</>
						) : (
							<h5>Drag and drop {what}</h5>
						)}

						{(status === "error" ||
							status === "idle" ||
							(status === "success" && uploadedFiles.length === 0)) && (
							<h6>Supports {Object.values(accept).flat().join(", ")} files</h6>
						)}
					</div>

					{(status === "error" ||
						status === "idle" ||
						(status === "success" && uploadedFiles.length === 0)) && (
						<div className={styles.item}>
							<div className={styles.line}></div>
							<Span3 color="grey" bold>
								OR
							</Span3>
							<div className={styles.line}></div>
						</div>
					)}
				</div>
				{status === "success" && uploadedFiles.length > 0 ? (
					<InputRow>
						<Button onClick={resetState} value="Cancel" disabled={loading || disabled} invert />
						<Button onClick={confirmHandler} value="Confirm" loading={loading} disabled={disabled} />
					</InputRow>
				) : (
					<Button
						onClick={() => {
							if (inputRef.current) {
								inputRef.current.click();
							}
						}}
						value="Upload File"
						disabled={status === "loading" || disabled}
					/>
				)}
			</div>
		</Input>
	);
};
