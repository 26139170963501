import {FC, useContext, useMemo} from "react";
import dayjs from "dayjs";
import {useQuery} from "@apollo/client";

import {Loading} from "../../../components/loading";
import {CompanyAnalyticsContext} from ".";
import {AnalyticsCards} from "../card";
import {PostStats, Stat} from "./post-stats";
import {useCustomRange} from "../../../components/input/date-time/date-interval-picker";
import {
	GET_COMPANY_TWITTER_ANALYTICS,
	GET_COMPANY_TWITTER_ANALYTICS_CARDS,
	ShareEventAnalytics,
	loadAnalytics,
} from "../../../data/analytics";

import styles from "./company.module.scss";

const MIN_DATE = dayjs().subtract(14, "month");

const stats: Stat[] = [
	{label: "Comments", value: "comments"},
	{label: "Retweets", value: "retweets"},
	{label: "Likes", value: "likes"},
	{label: "Views", value: "views"},
	{label: "Shares", value: "shares"},
];

export const Twitter: FC = () => {
	const {accounts, dateRange} = useContext(CompanyAnalyticsContext);
	const interval = useCustomRange(dateRange || "Max", MIN_DATE);
	const {start = dayjs(), end = dayjs()} = interval || {};

	const {data: rawData, loading} = useQuery(GET_COMPANY_TWITTER_ANALYTICS, {
		variables: {ids: accounts.length ? accounts : undefined, start, end},
	});
	const {data: cardData, loading: cardLoading} = useQuery(GET_COMPANY_TWITTER_ANALYTICS_CARDS, {
		variables: {ids: accounts.length ? accounts : undefined},
	});

	const shareEvents = useMemo(() => {
		const raw = rawData?.companyTwitterAnalytics ?? {};
		const data: Record<number, ShareEventAnalytics> = {};
		Object.keys(raw).forEach(id => (data[id] = loadAnalytics(raw[id])));
		return Object.keys(data)
			.reduce((acc, item) => [...acc, ...data[item].shareEvents], [] as ShareEventAnalytics[])
			.sort((a, b) =>
				b.shareEvent.sharedAt && a.shareEvent.sharedAt ? b.shareEvent.sharedAt.diff(a.shareEvent.sharedAt) : 0
			);
	}, [rawData]);

	return (
		<div className={styles.container}>
			{cardLoading ? <Loading /> : <AnalyticsCards cards={cardData?.companyTwitterAnalyticsCards ?? []} />}

			<PostStats shareEvents={shareEvents} account="twitter" stats={stats} loading={loading} />
		</div>
	);
};
