import React, {FC, useCallback, useEffect, useMemo, useState} from "react";
import {useQuery} from "@apollo/client";
import classnames from "classnames/bind";
import dayjs from "dayjs";

import {P, P2, Span, Span5} from "../../../components/text";
import {Card} from "../../../components/card";
import {Button, DropdownButton, InputRow} from "../../../components/input";
import {useMutationToast, useToast} from "../../../toast";
import {
	GET_HUBSPOT_INTEGRATION,
	SET_HUBSPOT_INTEGRATION,
	DISCONNECT_HUBSPOT,
	HubspotIntegration,
	HubspotContactField,
	HubspotContactFields,
	SYNC_HUBSPOT_CONTACTS,
	HubspotSyncResponse,
} from "../../../data";
import {Icon} from "../../../components/images";
import {Modal, ModalData} from "../../../modals/new";
import {useConfirmModal, useNewModal as useModal} from "../../../modals";
import {HoverTooltip} from "../../../components/tooltip";
import {Loading} from "../../../components/loading";
import {useStateRef} from "../../../state-ref";

import styles from "./integrations.module.scss";
const bStyles = classnames.bind(styles);

type CvsFieldOption = {
	cvsField:
		| "name"
		| "city"
		| "state"
		| "site_url"
		| "phone_number"
		| "email"
		| "industry"
		| "employees"
		| "created"
		| "clicks"
		| "contactSource"
		| "";
	label: string;
	types: string[];
	hubspotField?: string;
};
const cvsFieldsOptions: CvsFieldOption[] = [
	{
		cvsField: "name",
		label: "Company",
		types: ["string", "text"],
	},
	{
		cvsField: "site_url",
		label: "Site",
		types: ["string", "text", "url"],
	},
	{
		cvsField: "city",
		label: "Location (city)",
		types: ["string", "text", "address", "select"],
	},
	{
		cvsField: "state",
		label: "Location (state)",
		types: ["string", "text", "select"],
	},
	{
		cvsField: "employees",
		label: "Employees",
		types: ["string", "text", "number", "select"],
	},
	{
		cvsField: "email",
		label: "Contact (email)",
		types: ["string", "text", "email"],
	},
	{
		cvsField: "phone_number",
		label: "Contact (phone)",
		types: ["phone", "phonenumber"],
	},
	{
		cvsField: "industry",
		label: "Industry",
		types: ["string", "text"],
	},
	{
		cvsField: "created",
		label: "Date Visited",
		types: ["date", "datetime"],
	},
	{
		cvsField: "clicks",
		label: "Clicks",
		types: ["int", "number"],
	},
	{
		cvsField: "contactSource",
		label: "Contact Source",
		types: ["string", "text"],
	},
];

export const HubspotCard: FC = () => {
	const {open: configureHubspotOpen, modal: configureHubspotModal} = useModal({size: "medium"});
	const {open: errorsLogOpen, modal: errorsLogModal} = useModal({});
	const toast = useToast();
	const {data: hubspot, loading} = useQuery<{hubspotIntegration: HubspotIntegration}>(
		GET_HUBSPOT_INTEGRATION,
		{
			onError: error => {
				toast({color: "red", text: `HubSpot error: ${error.message}`});
			},
		}
	);

	const [disconnectHubspot, {loading: hubspotDisconnecting}] = useMutationToast(DISCONNECT_HUBSPOT);
	const [syncHubspotContacts, {loading: syncing}] = useMutationToast(SYNC_HUBSPOT_CONTACTS);

	const {open: disconnectConfirmModalOpen} = useConfirmModal(
		() => ({
			title: "Disconnect HubSpot",
			body: "Are you sure you want to disconnect the HubSpot integration?",
			confirmText: "Disconnect",
			confirming: hubspotDisconnecting,
			size: "small",
			onConfirm: async close => {
				await disconnectHubspot();
				close();
			},
		}),
		[disconnectHubspot, hubspotDisconnecting]
	);

	const onSync = useCallback(async () => {
		const response = await syncHubspotContacts();
		const {message, success} = response?.data?.syncHubspotContacts || ({} as HubspotSyncResponse);

		toast({
			text: message || (success ? "HubSpot synced successfully!" : "HubSpot sync failed."),
			color: success ? "green" : "red",
		});
	}, [toast, syncHubspotContacts]);

	useEffect(() => {
		if (hubspot?.hubspotIntegration?.needsConfiguration) {
			configureHubspotOpen();
		}
	}, [hubspot?.hubspotIntegration?.needsConfiguration, configureHubspotOpen]);

	const integration = hubspot?.hubspotIntegration;
	return (
		<Card className={bStyles("integrationsCard", "hubspot")}>
			<InputRow position="between">
				<div className={bStyles("header")}>
					<Icon icon="hubspot" width={32} />
					<h5 className="space">HubSpot</h5>
				</div>
			</InputRow>

			<P color="grey">
				Integrate with HubSpot to track and analyze lead generation.
				<P color="grey">
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://help.clearviewsocial.com/uncategorized/integrating-with-hubspot"
					>
						Learn more
					</a>{" "}
					about HubSpot integration.
				</P>
			</P>
			{integration?.connected &&
				integration.lastSyncedAt &&
				integration.lastSyncStatus &&
				integration.lastSyncStatus !== "syncing" && (
					<P>
						<Span color="grey">Last sync: {dayjs(integration.lastSyncedAt).formatAs()}</Span>
						<P color="grey">
							Sync Status:{" "}
							{integration.lastSyncStatus === "success" ? (
								<Span color="blue">Successful</Span>
							) : (
								<Span className={styles.openErrors} color="pink" onClick={errorsLogOpen}>
									Sync errors
									<Icon color="pink" icon="goto-link" />
								</Span>
							)}
						</P>
					</P>
				)}
			{integration?.connected ? (
				<InputRow className={styles.bottom}>
					<Button color="blue" invert onClick={configureHubspotOpen} icon="settings" value="Configure" />
					<Button icon="refresh" loading={syncing} onClick={onSync} value="Sync" invert />
					<Button
						color="blue"
						invert
						onClick={disconnectConfirmModalOpen}
						loading={hubspotDisconnecting}
						value="Disconnect"
					/>
				</InputRow>
			) : (
				<InputRow className={styles.bottom}>
					<Button
						icon="open"
						onClick={() => window.open(integration?.authUrl, "_self")}
						value="Connect"
						loading={loading}
					/>
				</InputRow>
			)}
			<ConfigureHubspotModal modal={configureHubspotModal} />
			<ErrorsLogModal modal={errorsLogModal} />
		</Card>
	);
};

const ConfigureHubspotModal: FC<{modal: ModalData}> = ({modal}) => {
	const {data, loading} = useQuery(GET_HUBSPOT_INTEGRATION);
	const [fields, setFields] = useState<HubspotContactFields | void>();
	const [, setRowIdx, rowIdxRef] = useStateRef<number | undefined>(undefined);
	const [setInfo, {loading: updating}] = useMutationToast(SET_HUBSPOT_INTEGRATION);
	const hubspotFields = useMemo(() => data?.hubspotIntegration?.contactFields ?? [], [
		data?.hubspotIntegration?.contactFields,
	]);
	const requiredFields = useMemo(() => hubspotFields.filter(field => field.required), [hubspotFields]);
	const fieldsMapping = useMemo(() => fields ?? data?.hubspotIntegration?.fields ?? [], [
		data?.hubspotIntegration?.fields,
		fields,
	]);
	const [errors, setErrors] = useState<number[]>([]);
	const [showErrors, setShowErrors] = useState(false);
	const toast = useToast();
	const handleSave = useCallback(
		async (close: ModalData["close"]) => {
			if (errors.length) {
				setShowErrors(true);
				return;
			} else {
				const fieldsMapping = (fields ?? data?.hubspotIntegration?.fields ?? []).filter(
					({cvsField, hubspotField}) => !!cvsField && !!hubspotFields.find(f => f.name === hubspotField)
				);
				const missingRequiredFields = requiredFields.filter(
					field => !fieldsMapping.find(f => f.hubspotField === field.name)
				);

				if (missingRequiredFields.length) {
					toast({
						text: `HubSpot required fields aren't mapped: ${missingRequiredFields
							.map(f => f.label)
							.join(", ")}`,
						color: "red",
					});
					return;
				}
				await setInfo({
					variables: {
						fields: fieldsMapping.map(({cvsField, hubspotField}) => ({
							cvsField,
							hubspotField,
							type: hubspotFields.find(field => field.name === hubspotField)?.type,
							required: !!requiredFields.find(field => field.name === hubspotField),
						})),
					},
					onCompleted: close,
				});
			}
		},
		[fields, hubspotFields, data?.hubspotIntegration?.fields, setInfo, requiredFields, toast, errors]
	);
	const update = useCallback(
		(item: HubspotContactField | Pick<CvsFieldOption, "cvsField" | "hubspotField">, idx?: number) => {
			setFields(f => {
				const ret = [...(f ?? data?.hubspotIntegration?.fields ?? [])];

				if (idx === undefined) {
					return [...ret, item];
				}

				ret[idx] = {
					...ret[idx],
					...item,
				};

				return [...ret];
			});
		},
		[data?.hubspotIntegration?.fields]
	);
	const remove = useCallback(
		(idx: number) => {
			setFields(f => {
				const ret = [...(f ?? data?.hubspotIntegration?.fields ?? [])];
				ret.splice(idx, 1);
				return ret;
			});
		},
		[data?.hubspotIntegration?.fields]
	);

	const footer = useMemo(
		() => (
			<div className={styles.footer}>
				<Span color="grey" className={styles.info}>
					<Icon icon="information" color="grey" />
					Need help with HubSpot integration?{" "}
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://help.clearviewsocial.com/uncategorized/integrating-with-hubspot"
					>
						Learn More
					</a>
				</Span>
				<InputRow position="right">
					<Button
						onClick={modal.close}
						value="Cancel"
						invert
						color="black"
						border={false}
						disabled={loading || updating}
					/>
					<Button
						onClick={() => handleSave(modal.close)}
						value="Save"
						loading={loading || updating}
						color="blue"
					/>
				</InputRow>
			</div>
		),
		[updating, loading, modal.close, handleSave]
	);

	const removeFieldModal = useConfirmModal(
		() => {
			const fieldMap = fieldsMapping[rowIdxRef.current || 0];
			const hubspotField = hubspotFields.find(field => field.name === fieldMap?.hubspotField);
			const cvsField = cvsFieldsOptions.find(field => field.cvsField === fieldMap?.cvsField);

			return {
				title: "Remove HubSpot mapping",
				body: `Are you sure you want to remove the field mapping ${cvsField?.label || ""} - ${
					hubspotField?.label || ""
				}?`,
				confirmText: "Remove",
				size: "small",
				onConfirm: close => {
					if (rowIdxRef.current !== undefined) {
						remove(rowIdxRef.current);
					}

					close();
				},
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[remove, rowIdxRef, rowIdxRef?.current, fieldsMapping, hubspotFields]
	);

	const handleRemove = useCallback(
		(idx: number) => {
			setRowIdx(idx);
			removeFieldModal.open();
		},
		[removeFieldModal, setRowIdx]
	);

	useEffect(() => {
		const errors = fieldsMapping.reduce((acc, field, idx) => {
			if (!field.cvsField) {
				acc.push(idx);
			}
			return acc;
		}, [] as number[]);

		setErrors(errors);
		setShowErrors(show => show && !!errors.length);
	}, [fieldsMapping]);

	const getRow = useCallback(
		(fieldMap, idx) => {
			const hubspotField = hubspotFields.find(field => field.name === fieldMap.hubspotField);
			const cvsField = cvsFieldsOptions.find(field => field.cvsField === fieldMap.cvsField);

			return (
				<div className={bStyles("hubspotRow", "space")} key={`${fieldMap.cvsField}-${idx}`}>
					<DropdownButton
						className={bStyles(
							"fieldSelection",
							!cvsField?.label ? "empty" : "",
							errors.includes(idx) && showErrors ? styles.error : ""
						)}
						options={cvsFieldsOptions
							.filter(item => !hubspotField?.type || item.types.includes(hubspotField?.type))
							.map(item => ({
								label: item.label,
								onClick: () => update({cvsField: item.cvsField}, idx),
							}))}
						value={cvsField?.label || "Select a field"}
						color="black"
						invert
						arrow
					/>
					<Icon icon="arrow-right" />
					<div className={styles.field}>
						<Span>{hubspotField?.label}</Span>
						{fieldMap.cvsField === "contactSource" && (
							<HoverTooltip text="Clearview Social will define the data for this field as a Clearview Social Contact in HubSpot.">
								<Icon icon="information" color="grey" />
							</HoverTooltip>
						)}
					</div>
					{hubspotField?.required ? (
						<HoverTooltip text="Required HubSpot field" positions={["top"]}>
							<Span className={styles.required}>{""}</Span>
						</HoverTooltip>
					) : (
						<HoverTooltip text="Remove field" positions={["top"]}>
							<Icon icon="delete" onClick={() => handleRemove(idx)} />
						</HoverTooltip>
					)}
				</div>
			);
		},
		[hubspotFields, update, handleRemove, showErrors, errors]
	);

	useEffect(() => {
		if (modal.open) {
			setFields(undefined);
		}
	}, [modal.open]);
	return (
		<Modal
			modal={modal}
			footer={footer}
			title="HubSpot integration field mapping"
			size="fit-content"
			className={styles.hubspotModal}
		>
			<div className={styles.content}>
				<Span className={bStyles("hubspotRow", "header")}>
					{data?.hubspotIntegration?.userInfo && (
						<Span>
							<a
								target="_blank"
								href={data.hubspotIntegration.userInfo.contactsUrl}
								rel="noopener noreferrer"
							>
								app.hubspot.com
							</a>
							<Span color="grey">&nbsp;&#8226;&nbsp;</Span>
							{data.hubspotIntegration.userInfo.email || ""}
						</Span>
					)}
				</Span>
				<Span className={bStyles("hubspotRow", "space", "header")}>
					<Span color="grey">Clearview Social fields</Span>
					<Span color="grey">HubSpot fields</Span>
				</Span>
				{!data?.hubspotIntegration?.fields || loading ? (
					<Loading className={styles.loading} />
				) : (
					<>
						<div className={styles.fields}>{fieldsMapping.map(getRow)}</div>
						<DropdownButton
							className={bStyles(styles.addFieldBtn)}
							options={hubspotFields
								.filter(field => !fieldsMapping?.find(f => f.hubspotField === field.name))
								.map(field => ({
									label: field.label,
									onClick: () => update({cvsField: "", hubspotField: field.name}),
								}))}
							value={"Add field mapping"}
							color="blue"
							invert
							icon="add"
							searchBar
							searchPlaceholder={"Search HubSpot fields"}
							minHeight={200}
						/>
						{showErrors && <Span5 className={styles.errorWarning}>Some fields are not mapped</Span5>}
					</>
				)}
			</div>
		</Modal>
	);
};

export const ErrorsLogModal: FC<{modal: ModalData}> = ({modal}) => {
	const {data, loading} = useQuery(GET_HUBSPOT_INTEGRATION);
	const footer = useMemo(
		() => (
			<div className={styles.footer}>
				<Span>{""}</Span>
				<Button onClick={modal.close} value="Close" invert color="blue" />
			</div>
		),
		[modal.close]
	);

	return (
		<Modal
			className={styles.hubspotModal}
			modal={modal}
			title="Last sync errors"
			size="fit-content"
			footer={footer}
		>
			<div className={styles.errorsContent}>
				{loading ? (
					<Loading className={styles.loading} />
				) : (
					<div className={styles.errors}>
						{data?.hubspotIntegration?.logs?.map((log, idx) => (
							<div className={styles.log} key={`${log.date}-${idx}`}>
								<P2 bold>{dayjs(log.date).formatAs("longDateAndTime")}</P2>
								<P2>{`${log.totalErrors}/${log.totalRecords} failed records`}</P2>
								{log.errors
									?.reduce((acc, error) => {
										const msg = `${error.statusCode || ""} => ${error.message}`;

										if (!acc.includes(msg)) {
											acc.push(msg);
										}
										return acc;
									}, [])
									?.map((error, i) => (
										<P color="red" key={i}>
											{error}
										</P>
									))}
							</div>
						))}
					</div>
				)}
			</div>
		</Modal>
	);
};
