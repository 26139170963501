import {FC, useMemo, useState} from "react";
import classnames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";

import {InputComponent} from "..";
import {Span} from "../../text";

import styles from "./date-picker-multiple.module.scss";
const bStyles = classnames.bind(styles);

const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

export interface DateRangePickerProps extends InputComponent<[Dayjs, Dayjs | undefined]> {
	min?: Dayjs;
	max?: Dayjs;
	displayMonth: Dayjs;
}

export interface IntervalPickerProps extends InputComponent<[Dayjs, Dayjs | undefined] | undefined> {
	min?: Dayjs;
	max?: Dayjs;
	displayDate?: Dayjs;
	unit?: "day" | "week" | "month" | "year";
	intervalLength?: number;
}

interface BasePickerProps {
	min?: Dayjs;
	max?: Dayjs;
	startDate?: Dayjs;
	endDate?: Dayjs;
	handleChange: (day: Dayjs) => void;
}
interface PickerProps extends BasePickerProps {
	displayMonth?: Dayjs;
}
interface YearPickerProps extends BasePickerProps {
	displayYear?: Dayjs;
}

const Picker = ({displayMonth, min, max, startDate, endDate, handleChange}: PickerProps) => {
	const weeks = useMemo(() => displayMonth?.getWeeksInRange(), [displayMonth]);
	const [hoverValue, setHoverValue] = useState<Dayjs>();

	const week = week =>
		week.days.map(day => {
			const d = day.format("DD");

			const canClick =
				(min ? day.isSameOrAfter(min, "day") : true) && (max ? day.isSameOrBefore(max, "day") : true);
			const startDay = startDate?.isSame(day, "day");
			const endDay = endDate && endDate?.isSame(day, "day");

			const betweenDates =
				startDate &&
				endDate &&
				day > (startDate < endDate ? startDate : endDate) &&
				day < (startDate > endDate ? startDate : endDate);
			const hoverDay = hoverValue && hoverValue.isSame(day, "day");

			const betweenHover =
				startDate &&
				!endDate &&
				hoverValue &&
				day > (startDate < hoverValue ? startDate : hoverValue) &&
				day < (startDate > hoverValue ? startDate : hoverValue);

			return (
				<div
					tabIndex={canClick ? 0 : undefined}
					key={day.unix()}
					onMouseOver={() => setHoverValue(day)}
					onMouseOut={() => setHoverValue(undefined)}
					className={bStyles("weekContainer", {
						today: day.isSame(dayjs(), "day"),
						weekContainer: true,
						startDay,
						endDay,
						hoverDay,
						halfDottedLeft:
							startDate &&
							!endDate &&
							hoverValue &&
							(startDay && startDate
								? hoverValue < startDate
								: hoverDay
								? !startDate || hoverValue > startDate
								: false),
						halfDottedRight:
							startDate &&
							!endDate &&
							hoverValue &&
							(startDay && startDate
								? hoverValue > startDate
								: hoverDay
								? startDate && hoverValue < startDate
								: false),
						hideBefore:
							!startDate || !endDate ? startDay : endDate.startOf("day").isSame(startDate.startOf("day")),
						betweenHover,
						betweenDates,
						disabledDay: !canClick || !displayMonth?.isSame(day, "month"),
					})}
					onClick={() => handleChange(day)}
					onKeyDown={e => (e.key === "Enter" || e.key === " ") && handleChange(day)}
				>
					<Span>{d}</Span>
				</div>
			);
		});

	return (
		<div className={styles.datePicker}>
			{days.map(day => (
				<div className={styles.dayContainer} key={day}>
					<Span color="grey">{day}</Span>
				</div>
			))}
			{weeks?.map(week)}
		</div>
	);
};

const MonthPicker = ({displayYear, min, max, startDate, endDate, handleChange}: YearPickerProps) => {
	const months = useMemo(
		() =>
			Array(12)
				.fill(null)
				.map((_, index) => dayjs(displayYear).month(index).startOf("month")),
		[displayYear]
	);
	const [hoverValue, setHoverValue] = useState<Dayjs>();

	const renderMonth = month => {
		const m = month.format("MMM");

		const canClick =
			(min ? month.isSameOrAfter(min, "month") : true) && (max ? month.isSameOrBefore(max, "month") : true);
		const startDay = startDate?.isSame(month, "month");
		const endDay = endDate && endDate?.isSame(month, "month");

		const betweenDates =
			startDate &&
			endDate &&
			month > (startDate < endDate ? startDate : endDate) &&
			month < (startDate > endDate ? startDate : endDate);
		const hoverDay = hoverValue && hoverValue.isSame(month, "month");

		const betweenHover =
			startDate &&
			!endDate &&
			hoverValue &&
			month > (startDate < hoverValue ? startDate : hoverValue) &&
			month < (startDate > hoverValue ? startDate : hoverValue);

		return (
			<div
				tabIndex={canClick ? 0 : undefined}
				key={month.unix()}
				onMouseOver={() => setHoverValue(month)}
				onMouseOut={() => setHoverValue(undefined)}
				className={bStyles("monthContainer", {
					today: month.isSame(dayjs(), "month"),
					monthContainer: true,
					startDay,
					endDay,
					hoverDay,
					halfDottedLeft:
						startDate &&
						!endDate &&
						hoverValue &&
						(startDay && startDate
							? hoverValue < startDate
							: hoverDay
							? !startDate || hoverValue > startDate
							: false),
					halfDottedRight:
						startDate &&
						!endDate &&
						hoverValue &&
						(startDay && startDate
							? hoverValue > startDate
							: hoverDay
							? startDate && hoverValue < startDate
							: false),
					hideBefore:
						!startDate || !endDate ? startDay : endDate.startOf("day").isSame(startDate.startOf("day")),
					betweenHover,
					betweenDates,
					disabledDay: !canClick,
				})}
				onClick={() => handleChange(month)}
				onKeyDown={e => (e.key === "Enter" || e.key === " ") && handleChange(month)}
			>
				<Span>{m}</Span>
			</div>
		);
	};

	return <div className={bStyles("datePicker", "months")}>{months.map(renderMonth)}</div>;
};

const YearPicker = ({displayYear, min, max, startDate, endDate, handleChange}: YearPickerProps) => {
	const minYear = useMemo(() => Math.min(dayjs().year(), (displayYear?.year() || 0) + 10), [displayYear]);
	const years = useMemo(
		() =>
			Array(12)
				.fill(null)
				.map((_, index) =>
					dayjs()
						.year(minYear - 11 + index)
						.startOf("year")
				),
		[minYear]
	);
	const [hoverValue, setHoverValue] = useState<Dayjs>();

	const renderYear = year => {
		const y = year.format("YYYY");

		const canClick =
			(min ? year.isSameOrAfter(min, "year") : true) && (max ? year.isSameOrBefore(max, "year") : true);
		const startDay = startDate?.isSame(year, "year");
		const endDay = endDate && endDate?.isSame(year, "year");

		const betweenDates =
			startDate &&
			endDate &&
			year > (startDate < endDate ? startDate : endDate) &&
			year < (startDate > endDate ? startDate : endDate);
		const hoverDay = hoverValue && hoverValue.isSame(year, "year");

		const betweenHover =
			startDate &&
			!endDate &&
			hoverValue &&
			year > (startDate < hoverValue ? startDate : hoverValue) &&
			year < (startDate > hoverValue ? startDate : hoverValue);

		return (
			<div
				tabIndex={canClick ? 0 : undefined}
				key={year.unix()}
				onMouseOver={() => setHoverValue(year)}
				onMouseOut={() => setHoverValue(undefined)}
				className={bStyles("monthContainer", {
					today: year.isSame(dayjs(), "year"),
					monthContainer: true,
					startDay,
					endDay,
					hoverDay,
					halfDottedLeft:
						startDate &&
						!endDate &&
						hoverValue &&
						(startDay && startDate
							? hoverValue < startDate
							: hoverDay
							? !startDate || hoverValue > startDate
							: false),
					halfDottedRight:
						startDate &&
						!endDate &&
						hoverValue &&
						(startDay && startDate
							? hoverValue > startDate
							: hoverDay
							? startDate && hoverValue < startDate
							: false),
					hideBefore:
						!startDate || !endDate ? startDay : endDate.startOf("day").isSame(startDate.startOf("day")),
					betweenHover,
					betweenDates,
					disabledDay: !canClick,
				})}
				onClick={() => handleChange(year)}
				onKeyDown={e => (e.key === "Enter" || e.key === " ") && handleChange(year)}
			>
				<Span>{y}</Span>
			</div>
		);
	};

	return <div className={bStyles("datePicker", "years")}>{years.map(renderYear)}</div>;
};

export const DateRangePicker: FC<DateRangePickerProps> = ({value, onChange, min, max, displayMonth}) => {
	const startDate = value[0];
	const endDate = value[1];
	const handleChange = (day: Dayjs) => {
		if (endDate) onChange([day.startOf("day"), undefined]);
		else {
			if (startDate < day.startOf("day")) {
				onChange([startDate, day.endOf("day")]);
			} else {
				onChange([day.startOf("day"), startDate.endOf("day")]);
			}
		}
	};

	return (
		<Picker
			displayMonth={displayMonth}
			startDate={startDate}
			endDate={endDate}
			handleChange={handleChange}
			min={min}
			max={max}
		/>
	);
};

export const IntervalPicker: FC<IntervalPickerProps> = ({
	value,
	onChange,
	min,
	max,
	displayDate,
	unit = "day",
	intervalLength,
}) => {
	const startDate = value?.[0];
	const endDate = value?.[1];
	const handleChange = (day: Dayjs) => {
		if (!day) return undefined;
		if (!startDate || endDate) {
			const st = day.startOf(unit);
			onChange([st, intervalLength ? st.add(intervalLength, unit).subtract(1, "second") : undefined]);
		} else {
			if (startDate && startDate < day.startOf(unit)) {
				onChange([startDate, day.endOf(unit)]);
			} else {
				onChange([day.startOf(unit), (startDate || day).endOf(unit)]);
			}
		}
	};

	if (unit === "year") {
		return (
			<YearPicker
				displayYear={displayDate}
				startDate={startDate}
				endDate={endDate}
				handleChange={handleChange}
				min={min}
				max={max}
			/>
		);
	}

	if (unit === "month") {
		return (
			<MonthPicker
				displayYear={displayDate}
				startDate={startDate}
				endDate={endDate}
				handleChange={handleChange}
				min={min}
				max={max}
			/>
		);
	}

	return (
		<Picker
			displayMonth={displayDate}
			startDate={startDate}
			endDate={endDate}
			handleChange={handleChange}
			min={min}
			max={max}
		/>
	);
};
