import classnames from "classnames";
import {Link} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {useCallback} from "react";

import {Card} from "../../../components/card";
import {Icon} from "../../../components/images";
import {P} from "../../../components/text";
import {Button, InputRow} from "../../../components/input";
import {DISCONNECT_TREMENDOUS, TREMENDOUS_INTEGRATION, TREMENDOUS_URL} from "../../../data/contest";
import {useMutationToast, useToast} from "../../../toast";
import {useConfirmModal} from "../../../modals";

import styles from "./integrations.module.scss";

export const TremendousCard = () => {
	const toast = useToast();
	const {data, loading} = useQuery(TREMENDOUS_INTEGRATION, {
		onError: ({message}) => {
			toast({color: "red", text: message});
		},
	});
	const {data: tremendousUrlData, loading: loadingTremendousUrl} = useQuery(TREMENDOUS_URL);
	const [disconnect, {loading: loadingDisconnect}] = useMutationToast(DISCONNECT_TREMENDOUS);

	const tremendousUrl = tremendousUrlData?.tremendousUrl;
	const tremendousAppUrl = data?.tremendousIntegration?.url;
	const connected = data?.tremendousIntegration?.connected;
	const onConnect = useCallback(() => {
		window.location.href = tremendousUrl;
	}, [tremendousUrl]);

	const confirmDeleteModal = useConfirmModal(
		() => ({
			title: `Disconnect Tremendous`,
			body: `Are you sure you want to disconnect the Tremendous integration?`,
			onConfirm: close => {
				disconnect();
				close();
			},
			confirming: loadingDisconnect,
		}),
		[loadingDisconnect, disconnect]
	);

	return (
		<Card space={false} className={classnames(styles.integrationsCard)}>
			<div className={classnames(styles.header, "space")}>
				<Link to={tremendousAppUrl ?? "https://app.tremendous.com"} target="_blank">
					<Icon icon="tremendous" width={32} />
				</Link>
				<h5>Tremendous</h5>
			</div>
			<P color="grey">
				Distribute incentives directly from Clearview Social. Reward recipients with gift cards, cash and
				more. <br />
				<a
					target="_blank"
					rel="noopener noreferrer"
					href="https://help.clearviewsocial.com/contests-featuring-tremendous-integration"
				>
					Learn more
				</a>{" "}
				about the Tremendous integration.
			</P>
			<InputRow position="between" className={styles.bottom}>
				{connected ? (
					<Button value="Disconnect" loading={loadingDisconnect} invert onClick={confirmDeleteModal.open} />
				) : (
					<Button icon="open" loading={loading && loadingTremendousUrl} value="Connect" onClick={onConnect} />
				)}
			</InputRow>
		</Card>
	);
};
