import {Key, KeyboardEvent, ReactElement, useCallback, useState} from "react";
import classNames from "classnames";

import {Button, ButtonProps, Checkbox, Option, toggle, triState} from "./index";
import {useDropdown} from "./dropdown";
import {Span4} from "../text";

import styles from "./input.module.scss";

export interface Group<K extends string, T> {
	label: string;
	hint?: string;
	value: K;
	options: readonly Option<T>[];
	selectAllLabel?: string;
}

interface GroupedDropdownProps<K extends string, T> extends Omit<ButtonProps, "onClick" | "value"> {
	groups: readonly Group<K, T>[];
	onChange: (newValue: Record<K, T[]>) => void;
	value: Record<K, T[]>;
}

export function GroupedDropdown<K extends string, T>({
	value,
	groups,
	onChange,
	...props
}: GroupedDropdownProps<K, T>): ReactElement {
	const [active, setActive] = useState<[number, number]>();
	const handleUnset = useCallback(() => setActive([0, 0]), []);

	const handleSelect = useCallback(
		([g, o]: [number, number]) => {
			const group = groups[g];
			if (o === -1) {
				const selected = value[group.value].length === group.options.length;
				const selectedOptions = group.options.map(option => option.value);
				onChange({...value, [group.value]: selected ? [] : selectedOptions});
			} else {
				onChange({...value, [group.value]: toggle(group.options[o].value, value[group.value])});
			}
		},
		[groups, value, onChange]
	);

	const handleKeyDown = useCallback(
		(e: KeyboardEvent) => {
			switch (e.key) {
				case "Enter":
				case " ":
					if (active) handleSelect(active);
					break;
				case "ArrowDown":
					setActive(c => {
						if (c === undefined) return [0, 0];
						let [g, o] = c;
						if (groups[g].options.length - 1 <= o) {
							g++;
							if (g >= groups.length) return undefined;
							o = groups[g].selectAllLabel ? -1 : 0;
						} else {
							o = (o + 1) % groups[g].options.length;
						}
						return [g, o];
					});
					break;
				case "ArrowUp":
					setActive(([g, o] = [0, 0]) => {
						o--;
						if (o < (groups[g].selectAllLabel ? -1 : 0)) {
							g = (g - 1 + groups.length) % groups.length;
							o = groups[g].options.length - 1;
						}
						return [g, o];
					});
					break;
				default:
					return;
			}
			e.stopPropagation();
			e.preventDefault();
		},
		[active, groups, handleSelect]
	);

	const popup = useCallback(() => {
		const isActive = (g, o) => active && active[0] === g && active[1] === o;
		return groups.map((group, gIndex) => (
			<div key={group.value} className={styles.groupedDropdownItem}>
				<Span4 className={styles.label} color="grey">
					{group.label.toUpperCase()}
				</Span4>

				{group.selectAllLabel && (
					<div
						className={classNames(styles.options, isActive(gIndex, -1) && styles.active)}
						onMouseOver={() => setActive([gIndex, -1])}
					>
						<Checkbox
							value={triState(group.options, value[group.value])}
							onChange={() => handleSelect([gIndex, -1])}
							label={group.selectAllLabel}
						/>
					</div>
				)}

				{group.options.map((option, index) => (
					<div
						className={classNames(styles.options, isActive(gIndex, index) && styles.active)}
						key={option.value as Key}
						onMouseOver={() => setActive([gIndex, index])}
					>
						<Checkbox
							value={value[group.value].includes(option.value)}
							onChange={() => handleSelect([gIndex, index])}
							label={option.label}
						/>
					</div>
				))}
			</div>
		));
	}, [active, groups, handleSelect, value]);

	const {portal, reference, toggle: togglePopup} = useDropdown({
		popup,
		portalClassName: styles.buttonMenu,
		onClose: handleUnset,
		onKeyDown: handleKeyDown,
		onMouseOut: handleUnset,
	});

	return (
		<>
			<Button {...props} onClick={togglePopup} ref={reference} icon="filter" />
			{portal}
		</>
	);
}
