import {useState, useEffect, useRef} from "react";

export type ImageState = "loading" | "loaded" | "error";
export function useImageLoaded(src: string | null): ImageState {
	const [state, setState] = useState<ImageState>("loading");
	const active = useRef<boolean>();

	useEffect(() => {
		if (!src) {
			return undefined;
		}

		active.current = true;
		setState("loading");

		const image = new Image();
		image.onload = () => {
			if (!active.current) {
				return;
			}
			setState("loaded");
		};

		image.referrerPolicy = "no-referrer";

		image.onerror = () => {
			if (!active.current) {
				return;
			}
			setState("error");
		};
		image.src = src;

		return () => {
			active.current = false;
		};
	}, [src, active]);

	return state;
}
