import {ReactElement, useState} from "react";

import {CloseFunc, ConfirmArgs, ModalHook, useModal} from ".";
import {Button, InputRow, SmallButton, Text} from "../components/input";
import {Card} from "../components/card";
import {Span, Span2} from "../components/text";
import {Icon} from "../components/images";

import styles from "./selection-confirm.module.scss";

export interface Selection {
	description: string;
	id: number;
	title: string;
}

interface SelectionConfirmModalProps<T> extends Omit<ConfirmArgs<T>, "body" | "onConfirm"> {
	selections: Selection[];
	renderBody: (selections: Selection[]) => ReactElement;
	onConfirm: (selections: Selection[], close: CloseFunc<T>) => void;
	limit?: number;
}

export function useSelectionConfirmModal<T>({
	selections,
	title,
	renderBody,
	confirmText = "Confirm",
	onConfirm,
	confirming,
	limit,
}: SelectionConfirmModalProps<T>): ModalHook<T> {
	const [search, setSearch] = useState("");
	const [show, setShow] = useState(true);
	const [selected, setSelected] = useState<Selection[]>([]);

	return useModal(() => {
		const searchLC = search.toLowerCase();
		const available = selected.filter(user => user.title.toLowerCase().includes(searchLC));

		const reset = () => {
			setSelected(limit ? selections.slice(0, limit) : selections);
			setSearch("");
		};

		return {
			onOpen: () => setSelected(limit ? selections.slice(0, limit) : selections),
			header: <h3>{title}</h3>,
			body: (
				<div>
					{renderBody(selected)}
					<SmallButton
						arrow={show ? "up" : "down"}
						value={`${show ? "Hide" : "Show"} Selection (${selected.length})`}
						onClick={() => setShow(c => !c)}
						border={false}
						invert
					/>
					{show && (
						<div className="space">
							<Text icon="search" value={search} onChange={setSearch} placeholder="Search" />
							{available.length > 0 ? (
								<div className={styles.bodyContainer}>
									{available.map(item => (
										<Card key={item.id} className={styles.cardContainer}>
											<InputRow position="between">
												<div>{item.title}</div>
												<Icon
													icon="close"
													onClick={!confirming && (() => setSelected(c => c.filter(i => i.id !== item.id)))}
												/>
											</InputRow>
											<Span color="grey">{item.description}</Span>
										</Card>
									))}
								</div>
							) : (
								<Span2 color="grey">No matches found</Span2>
							)}
						</div>
					)}
				</div>
			),
			footer: close => (
				<InputRow position="between">
					<Button
						value="Reset"
						icon="refresh"
						invert
						color="black"
						border={false}
						disabled={selected.length === selections.length || confirming}
						onClick={reset}
					/>
					<Button value={confirmText} onClick={() => onConfirm(selected, close)} loading={confirming} />
				</InputRow>
			),
		};
	}, [search, selected, title, renderBody, show, limit, selections, confirming, confirmText, onConfirm]);
}
