import {FC, useMemo, useState} from "react";
import {To, useLocation, useNavigate} from "react-router-dom";
import classnames from "classnames";

import {Icon, IconProps, IconType} from "./icon";
import {Arrow} from "./arrow";
import {HoverTooltip} from "../tooltip";
import {useUser} from "../../data";
import {PathPerms, itemAllowed} from "../../layout/perms";
import {Span3} from "../text";

import styles from "./image.module.scss";

export interface NavIconProps extends Omit<IconProps, "icon">, PathPerms {
	name?: string;
	children?: Omit<NavIconProps, "children">[];
	icon?: IconType;
	modal?: () => void;
	expanded?: boolean;
	bottom?: boolean;
}

export const NavIcon: FC<NavIconProps> = ({
	children,
	className,
	icon,
	name = "",
	onClick,
	path,
	modal,
	expanded,
	bottom,
	...perms
}) => {
	const user = useUser();
	const allowedSubitems = useMemo(() => children?.filter(item => itemAllowed(item, user)), [children, user]);

	const {pathname} = useLocation();
	const active =
		pathname === path ||
		(allowedSubitems && pathname.startsWith(path) && pathname.charAt(path.length) === "/");
	const [open, setOpen] = useState(allowedSubitems && active);
	const [submenuOpen, setSubmenuOpen] = useState(false);
	const navigate = useNavigate();

	const handleDrawer = () => setOpen(c => !c);
	const handleNavigate = (newPath: To) => {
		if (modal) {
			modal();
			return;
		}
		if (typeof newPath === "string" && /^https?:\/\//.test(newPath)) {
			window.open(newPath, "_blank");
		} else {
			setSubmenuOpen(false);
			navigate(newPath);
		}
		if (onClick) onClick();
	};
	const subitems = useMemo(() => {
		const newItems = allowedSubitems?.filter(el => el.path !== path) ?? [];
		if (newItems.length <= 1 || bottom) return null;
		return newItems;
	}, [allowedSubitems, path, bottom]);

	const navContainer = (className: string, onClick: () => void) => (
		<div className={classnames(className, expanded && styles.alignLeft)} onClick={onClick}>
			<div className={classnames(styles.roundContainer, expanded && styles.noPadding)}>
				{!expanded && bottom ? (
					<HoverTooltip text={name} positions={["right", "top", "bottom", "left"]}>
						<Icon icon={(icon ?? "bookmark") as IconType} color="grey" width={32} height={32} />
					</HoverTooltip>
				) : (
					<Icon icon={(icon ?? "bookmark") as IconType} color="grey" width={32} height={32} />
				)}
			</div>
			<Span3 className={classnames(styles.nameText, expanded && styles.expandedDesktop)}>{name}</Span3>

			{subitems && <Arrow direction={open ? "up" : "down"} className={classnames(styles.navArrow)} />}
		</div>
	);

	if (!itemAllowed(perms, user)) return null;

	return (
		<div
			className={classnames(styles.navIcon, className)}
			onMouseEnter={() => setSubmenuOpen(true)}
			onMouseLeave={() => setSubmenuOpen(false)}
		>
			<div className={classnames(styles.iconContainer, active && styles.active)}>
				{navContainer(styles.desktopIconContainer, () => handleNavigate(path))}

				{navContainer(styles.phoneIconContainer, () => {
					if (allowedSubitems) return handleDrawer();

					handleNavigate(path);
				})}
			</div>
			{subitems && submenuOpen && (
				<div className={styles.submenu} tabIndex={0}>
					{subitems.map((item, index) => (
						<div key={index} className={styles.submenuItem} onClick={() => handleNavigate(item.path)}>
							{item.icon && <Icon icon={item.icon} color="grey" />}
							<Span3>{item.name}</Span3>
						</div>
					))}
				</div>
			)}
			{subitems && open && (
				<div className={styles.childNav}>
					{subitems.map(child => (
						<div
							key={child.path}
							className={classnames(styles.iconContainer, child.path === pathname && styles.active)}
							onClick={() => handleNavigate(child.path)}
						>
							<span className={classnames(styles.childNavText)}>{child.name}</span>
						</div>
					))}
				</div>
			)}
		</div>
	);
};
