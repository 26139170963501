import React, {FC, useCallback, useMemo} from "react";
import dayjs, {Dayjs} from "dayjs";
import {useQuery} from "@apollo/client";

import {Modal, ModalData} from "../../../../modals/new";
import {Span2, Span3, Span4} from "../../../../components/text";
import {Button} from "../../../../components/input";
import {accountNames, services} from "../../../../data";
import {Icon} from "../../../../components/images";
import {NetworkSchedule, Schedule} from "../../posts/form/types";
import {GET_NETWORK_TIME_SLOTS, GET_NETWORK_PEAK_TIME} from "../../../../data/share";

import styles from "./styles.module.scss";

interface ScheduleSummaryModalProps {
	modal: ModalData;
	data: Schedule;
	userId?: string;
	expiresAt?: Dayjs;
	onConfirm: (schedule: NetworkSchedule) => void;
}

export const ScheduleSummaryModal: FC<ScheduleSummaryModalProps> = ({
	modal,
	userId,
	expiresAt,
	data,
	onConfirm,
}) => {
	const peakTimeNetworks = Object.keys(data).filter(network => data[network].peakTime);
	const {data: peakTimeData, loading: loadingPeakTime} = useQuery(GET_NETWORK_PEAK_TIME, {
		variables: {
			networks: peakTimeNetworks,
			userId,
			expiresAt,
		},
		fetchPolicy: "network-only",
		skip: !peakTimeNetworks.length || !modal.open,
	});
	const peakTime = useMemo(
		() =>
			(peakTimeData?.peakTimeForNetwork || []).reduce((acc, slot) => {
				acc[slot.network] = dayjs(slot.scheduledFor);
				return acc;
			}, {}),
		[peakTimeData?.peakTimeForNetwork]
	);
	const timeslotNetworks = Object.keys(data).filter(network => data[network].timeslot);
	const {data: timeslotsData, loading: loadingTimeSlot} = useQuery(GET_NETWORK_TIME_SLOTS, {
		variables: {
			networks: timeslotNetworks,
			userId,
			expiresAt,
		},
		fetchPolicy: "network-only",
		skip: !timeslotNetworks.length || !modal.open,
	});
	const networkSlots = useMemo(
		() =>
			(timeslotsData?.timeSlotsForNetwork || []).reduce((acc, slot) => {
				acc[slot.network] = dayjs(slot.scheduledFor);
				return acc;
			}, {}),
		[timeslotsData?.timeSlotsForNetwork]
	);
	const schedule = useMemo(
		() =>
			Object.keys(data).reduce(
				(acc, network) => ({
					...acc,
					[network]:
						network !== "immediately"
							? {
									...data[network],
									scheduledFor: dayjs(
										peakTime[network] ?? networkSlots[network] ?? data[network].scheduledFor
									),
							  }
							: data[network],
				}),
				{} as NetworkSchedule
			),
		[data, peakTime, networkSlots]
	);
	const handleConfirm = useCallback(() => {
		onConfirm(schedule);
	}, [onConfirm, schedule]);

	const footer = useMemo(
		() => (
			<div className={styles.footer}>
				<Button onClick={modal.close} value="Cancel" invert color="black" border={false} />
				<Button
					onClick={handleConfirm}
					value="Schedule Post"
					color="blue"
					loading={loadingTimeSlot || loadingPeakTime}
				/>
			</div>
		),
		[modal.close, handleConfirm, loadingTimeSlot, loadingPeakTime]
	);

	return (
		<Modal
			className={styles.scheduleSummaryModal}
			modal={modal}
			title="Post schedule summary"
			size="fit-content"
			footer={footer}
		>
			<div className={styles.content}>
				<Span2>
					Some of the posts are scheduled for a later date and time, while others are set to post instantly.
				</Span2>
				<div className={styles.networksSchedule}>
					{services
						.filter(s => schedule[s])
						.map(network => (
							<div key={network} className={styles.networkSchedule}>
								<div className={styles.network}>
									<Icon icon={`filled-${network}`} height={16} viewBox={"0 0 16 16"} />
									<Span3>{accountNames[network]}</Span3>
								</div>
								<div className={styles.schedule}>
									{schedule[network]?.peakTime && <Span4 color="pink">Scheduled using PeakTime™</Span4>}
									{schedule[network]?.timeslot && <Span4 color="grey">Scheduled using Time Slots</Span4>}
									{!schedule[network]?.peakTime && !schedule[network]?.timeslot && (
										<Span4 color="grey">Scheduled Manually</Span4>
									)}
									<Span3>
										{loadingPeakTime || loadingTimeSlot
											? "-"
											: (schedule[network]?.scheduledFor ?? dayjs()).format("MMM DD, YYYY, h:mm A")}
									</Span3>
								</div>
							</div>
						))}
				</div>
			</div>
		</Modal>
	);
};
