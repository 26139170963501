import {useReducer, useCallback, useMemo, useRef, MutableRefObject} from "react";
import {PartialDeep} from "type-fest";
import mergeWith from "lodash/mergeWith";

import {Timeout} from "./types";
import {InputComponent} from "./components/input";

const arrayOverwrite = (_, srcValue) => (Array.isArray(srcValue) ? srcValue : undefined);

type UpdateType<T> =
	| PartialDeep<T>
	| ((base: T, changes: PartialDeep<T> | undefined) => PartialDeep<T> | undefined);
export type ChangeType<T> = PartialDeep<T> | undefined;

export type UpdateFunc<T> = (updated: UpdateType<T>) => void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface DirtyCopyReturn<T extends Record<string, any>> {
	val: T;
	discard: () => void;
	update: UpdateFunc<T>;
	dirty: boolean;
	changes: ChangeType<T>;
	flush: () => Promise<void>;
	inputFunc: <X extends keyof T>(key: X) => Pick<InputComponent<T[X]>, "onChange" | "value">;
}

interface UpdateFnArgs<T> {
	val: T;
	discard: () => void;
	changes: ChangeType<T>;
	ref: MutableRefObject<ChangeType<T>>;
}

interface DirtyCopyOptions<T> {
	debounce?: number;
	onUpdate?: (ret: UpdateFnArgs<T>) => Promise<unknown>;
	invalid?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDirtyCopy<T extends Record<string, any>>(
	val: T,
	{debounce, onUpdate, invalid}: DirtyCopyOptions<T> = {}
): DirtyCopyReturn<T> {
	const timer = useRef<Timeout>();
	const cur = useRef<ChangeType<T>>();

	const clear = () => {
		if (timer.current) {
			clearTimeout(timer.current);
			timer.current = undefined;
		}
	};

	const discard = useCallback(() => {
		update(undefined);
		clear();
	}, []);

	const [changes, update] = useReducer((state: ChangeType<T>, updated: UpdateType<T> | undefined) => {
		if (updated === undefined) return undefined;
		const ret = typeof updated === "function" ? updated(val, state) : {...state, ...updated};
		cur.current = ret;
		if (onUpdate && ret !== state) {
			if (!debounce) {
				onUpdate({val, changes: cur.current, discard, ref: cur});
				return ret;
			}
			clear();
			timer.current = setTimeout(
				() => (invalid ?? true) && onUpdate({val, changes: cur.current, discard, ref: cur}),
				debounce
			);
		}

		return ret;
	}, undefined);

	const flush = useCallback(() => {
		clear();
		if (!onUpdate) return Promise.resolve(undefined);
		return onUpdate({val, changes: cur.current, discard, ref: cur}).then(() => undefined);
	}, [discard, onUpdate, val]);

	return useMemo(() => {
		const newVal = mergeWith({}, val, changes, arrayOverwrite) as T;
		const inputFunc = <X extends keyof T>(key: X) => ({
			value: newVal[key],
			onChange: (value: keyof T) => update({[key]: value} as PartialDeep<T>),
		});

		return {val: newVal, discard, update, dirty: changes !== undefined, changes, flush, inputFunc};
	}, [changes, discard, flush, val]);
}
