import {ReactElement, ReactNode, forwardRef, useState} from "react";
import classnames, {Argument} from "classnames";

import {Component} from "../../types";
import {Arrow, Icon, IconProps} from "../images";
import {Card} from ".";
import {Separator} from "../input";

import styles from "./card.module.scss";

export type RenderItem = ReactNode | ((show: boolean) => ReactNode);
export interface CollapsibleProps extends Component {
	header?: RenderItem;
	icons?: IconProps[];
	headerClassName?: Argument;
	iconWithoutBorder?: boolean;
	withoutHr?: boolean;
	isDefaultOpen?: boolean;
}

export const Collapsible = forwardRef<HTMLDivElement, CollapsibleProps>(
	(
		{header, icons, className, children, headerClassName, iconWithoutBorder, withoutHr, isDefaultOpen},
		ref
	) => {
		const [show, setShow] = useState(isDefaultOpen ?? false);

		return (
			<Card ref={ref} className={[className]}>
				<div className={styles.header}>
					<div className={classnames(styles.headerContent, headerClassName)}>
						{typeof header === "function" ? header(show) : header}
					</div>
					<div className={classnames(styles.icons, {[styles.borderLeft]: !iconWithoutBorder})}>
						{icons?.map((props, i) => (
							<Icon color="grey" {...props} key={i} />
						))}
						<Arrow direction={show ? "up" : "down"} onClick={() => setShow(c => !c)} />
					</div>
				</div>
				{show && (
					<>
						{!withoutHr ? <Separator horizontal className={styles.divider} /> : null}
						<div>{children}</div>
					</>
				)}
			</Card>
		);
	}
);

export interface CollapsibleCardProps extends Component {
	header?: ReactNode;
	showInitial?: boolean;
	disabled?: boolean;
}

export const CollapsibleCard = ({
	header,
	className,
	children,
	disabled,
	showInitial = true,
}: CollapsibleCardProps): ReactElement => {
	const [show, setShow] = useState(showInitial);

	return (
		<div className={classnames(styles.collapsible, styles.card, !show && styles.hidden, "space")}>
			<div className={styles.header}>
				{header}
				<Arrow
					direction={show ? "up" : "down"}
					onClick={() => !disabled && setShow(c => !c)}
					className={classnames(styles.arrow, disabled && styles.disabled)}
				/>
			</div>
			{show && <div className={classnames(className, styles.body)}>{children}</div>}
		</div>
	);
};

Collapsible.displayName = "Collapsible";
