import {gql} from "@apollo/client";

import {Service} from ".";

export interface Connection {
	displayName: string;
	connected: boolean;
	expired: boolean;
	identifier: string;
	url?: string;
}

export interface ConnectionProfile {
	id: number;
	identifier: string;
	url: string;
	urn: string;
	imageUrl: string;
	fullName: string;
	pendingApproval: boolean;
	accessToken?: string;
}

export type Connections = Record<Service | "facebookPage", Connection | undefined>;

export const USER_CONNECTION_PROFILE_FRAGMENT = gql`
	fragment ConnectionProfileFields on ConnectionProfile {
		id
		identifier
		url
		urn
		imageUrl
		fullName
		pendingApproval
		accessToken
	}
`;

export const CONNECTIONS_FRAGMENT = gql`
	fragment ConnectionsFields on Connections {
		facebook {
			connected
			expired
			identifier
			url
			displayName
		}
		facebookPage {
			connected
			expired
			identifier
			url
			displayName
		}
		linkedin {
			connected
			expired
			identifier
			url
			urn
			displayName
		}
		twitter {
			connected
			expired
			identifier
			url
			displayName
		}
		instagram {
			connected
			expired
			identifier
			url
			displayName
		}
	}
`;

export const GET_USER_CONNECTION_PROFILES = gql`
	query UserConnectionProfiles {
		userConnectionProfiles {
			facebook {
				...ConnectionProfileFields
			}

			linkedin {
				...ConnectionProfileFields
			}

			twitter {
				...ConnectionProfileFields
			}
		}
	}
	${USER_CONNECTION_PROFILE_FRAGMENT}
`;

export const GET_USER_CONNECTION_PAGES = gql`
	query UserConnectionPages {
		userConnectionPages {
			facebook {
				...ConnectionProfileFields
			}

			linkedin {
				...ConnectionProfileFields
			}

			twitter {
				...ConnectionProfileFields
			}

			instagram {
				...ConnectionProfileFields
			}
		}
	}
	${USER_CONNECTION_PROFILE_FRAGMENT}
`;
