import {ChangeEvent, forwardRef, KeyboardEvent, useCallback, useImperativeHandle, useRef} from "react";

import {Input, InputComponent, useId} from "./index";
import {Icon} from "../images";
import {Span} from "../text";
import {MaybeArray} from "../../types";
import {Loading} from "../loading";
import {HoverTooltip} from "../tooltip";

import styles from "./input.module.scss";

export interface FileUploadInputProps extends InputComponent<File | undefined> {
	type: MaybeArray<"image" | "video">;
	onlyIcon?: boolean;
	iconSize: number;
	loading?: boolean;
	disableTooltip?: boolean;
}

export const FileUpload = forwardRef<HTMLInputElement, FileUploadInputProps>(
	({disabled, iconSize, id: maybeId, loading, onChange, onlyIcon, type, disableTooltip, ...props}, ref) => {
		const id = useId(maybeId);
		const inputRef = useRef<HTMLInputElement>(null);
		const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
			if (!e.target.files) {
				return;
			}

			const currentFile = e.target.files[0];

			onChange(currentFile);

			if (inputRef?.current) {
				inputRef.current.value = "";
			}
		};
		if (loading) disabled = true;

		const handleOnClick = useCallback(() => !disabled && inputRef.current?.click(), [disabled]);
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		useImperativeHandle(ref, () => inputRef.current!);

		const handleKeyDown = useCallback(
			(e: KeyboardEvent<HTMLDivElement>) => {
				switch (e.key) {
					case "Enter":
					case " ":
						handleOnClick();
						break;
					default:
						return;
				}
				e.stopPropagation();
				e.preventDefault();
			},
			[handleOnClick]
		);

		const uploadIcon = disableTooltip ? (
			<Icon icon="upload" width={iconSize} height={iconSize} />
		) : (
			<HoverTooltip text="Upload" positions={["top"]}>
				<Icon icon="upload" width={iconSize} height={iconSize} />
			</HoverTooltip>
		);

		return (
			<Input id={id} disabled={disabled} baseClass="fileUpload" {...props}>
				<div
					tabIndex={disabled ? -1 : 0}
					onKeyDown={handleKeyDown}
					onClick={handleOnClick}
					className={styles.mainContainer}
				>
					{uploadIcon}

					{!onlyIcon && (
						<Span trim={1} color="grey" className={styles.uploadText}>
							Upload file
						</Span>
					)}
					<input
						accept={
							Array.isArray(type) ? type.map(acceptedType => `${acceptedType}/*`).join(",") : `${type}/*`
						}
						type="file"
						ref={inputRef}
						onChange={handleFileChange}
						className={styles.inputFile}
						disabled={disabled}
					/>
					{loading && <Loading position="absolute" />}
				</div>
			</Input>
		);
	}
);

FileUpload.displayName = "FileUpload";
