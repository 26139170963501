import React, {ReactElement} from "react";
import {Doughnut} from "react-chartjs-2";
import classnames from "classnames";
import {Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions, ChartData} from "chart.js";

import {ChartComponent} from ".";
import {Span, Span3} from "../text";

import styles from "./doughnut-chart.module.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

const Options = (): ChartOptions<"doughnut"> => ({
	responsive: true,
	maintainAspectRatio: true,
	plugins: {
		legend: {display: false},
		title: {display: false},
		tooltip: {
			displayColors: false,
			callbacks: {
				label: () => "",
				title: context => `${context[0].parsed}` ?? context[0].label,
			},
		},
	},
});

export const DoughnutChart = ({data, labels, title, subtitle, className}: ChartComponent): ReactElement => {
	const formatData: ChartData<"doughnut"> = {
		labels,
		datasets: data.map(({data, backgroundColor, label}) => ({
			label,
			data: data as number[],
			backgroundColor,
			hoverBackgroundColor: backgroundColor,
			borderWidth: 1,
		})),
	};

	return (
		<div className={classnames(styles.container, className)}>
			<Doughnut data={formatData} options={Options()} />
			{title && (
				<div className={styles.centerText}>
					<Span className={styles.title} bold>
						{title}
					</Span>
					{subtitle && <Span3>{subtitle}</Span3>}
				</div>
			)}
		</div>
	);
};
