export const downloadCsv = (
	data: (string | number)[][],
	columns: (string | number)[],
	exportFileName: string,
	withBom?: boolean
) => {
	const escapeField = (value: string | number) => {
		const stringValue = String(value);
		if (stringValue.includes(",") || stringValue.includes("\n")) {
			return `"${stringValue.replace(/"/g, '""').replace(/\n/g, " ")}"`;
		}
		return stringValue;
	};

	const escapedData = data.map(row => row.map(field => escapeField(field)).join(","));
	const csvContent = [columns.join(","), ...escapedData].join("\n");

	const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
	const blobData: BlobPart[] = withBom ? [bom, csvContent] : [csvContent];

	const blob = new Blob(blobData, {
		type: "text/csv;charset=utf-8;",
	});
	const csvDataUri = URL.createObjectURL(blob);

	const link = document.createElement("a");
	link.href = csvDataUri;
	link.target = "_blank";
	link.download = exportFileName;
	link.style.visibility = "hidden";
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};
