import {Breathing} from "react-shimmer";
import classnames from "classnames";
import React, {useState, useEffect} from "react";

import {EmptyComponent} from "../../types";
import {Media} from "../../data/media";

interface ImageLoaderProps extends EmptyComponent {
	media: Media;
}
export const ImageLoader = ({media, className}: ImageLoaderProps) => {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		const image = new Image();
		image.src = media.url;
		image.onload = () => {
			setLoaded(true);
		};
	}, [media]);

	return loaded ? (
		<img alt={media.alt} className={classnames(className)} src={media.url} />
	) : (
		<Breathing className={classnames(className)} />
	);
};
