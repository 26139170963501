import {ReactElement, useMemo, useState} from "react";
import classnames from "classnames";

import {Loading} from "../../components/loading";
import {InputHeader, Select, Option, InputRow} from "../../components/input";
import {Card} from "../../components/card";
import {GradientChart, OptionsType} from "../../components/chart/gradient-chart";
import {AnyColor, EmptyComponent} from "../../types";
import {Span4} from "../../components/text";

import styles from "./chart-card.module.scss";

export interface ChartLine<TValue extends string> {
	value: TValue;
	label: string;
	tooltipLabel?: string;
	color: AnyColor;
	data: readonly number[];
}

interface ChartCardProps<TValue extends string> extends EmptyComponent {
	title: string;
	allLabel: string;
	data: readonly ChartLine<TValue>[];
	select?: boolean;
	labels: string[];
	loading?: boolean;
	fillArea?: boolean;
	formatLabel?: (value: string) => string;
	interval?: string;
}

export function ChartCard<TValue extends string>({
	allLabel,
	data,
	labels,
	select = true,
	title,
	loading,
	fillArea,
	useCustomTooltip,
	interactionMode,
	xAxisLabel,
	formatLabel,
	interval,
}: ChartCardProps<TValue> &
	Pick<OptionsType, "interactionMode" | "useCustomTooltip" | "xAxisLabel">): ReactElement {
	const [line, setLine] = useState<TValue | "all">("all");
	const chartData = useMemo(() => data.filter(d => d.value === line || line === "all"), [data, line]);
	const options = useMemo(() => {
		const ret: Option<TValue | "all">[] = data
			.filter(d => d.label)
			.map(d => ({label: d.label, value: d.value}));
		ret.unshift({label: `All ${allLabel}`, value: "all" as const});
		return ret;
	}, [allLabel, data]);

	return (
		<Card column className={styles.chartCard}>
			{select ? (
				<InputHeader className={styles.chartHeader}>
					<InputRow className={styles.headerTitle}>
						<h3>{title}</h3>
						{interval && <Span4 color="grey">{interval}</Span4>}
					</InputRow>
					<Select value={line} options={options} onChange={setLine} />
				</InputHeader>
			) : (
				<InputRow className={classnames(styles.chartHeader, styles.headerTitle)}>
					<h3>{title}</h3>
					{interval && <Span4 color="grey">{interval}</Span4>}
				</InputRow>
			)}
			<div className={styles.chartContainer}>
				{loading && <Loading position="absolute" />}
				<GradientChart
					showLegend
					subtitle={line === "all" ? `All ${allLabel}` : data.find(d => d.value === line)?.label}
					data={chartData}
					labels={labels}
					chartName={`${title} ${line.uFirst()}`}
					fillArea={fillArea}
					useCustomTooltip={useCustomTooltip}
					interactionMode={interactionMode}
					xAxisLabel={xAxisLabel}
					formatLabel={formatLabel}
				/>
			</div>
		</Card>
	);
}
