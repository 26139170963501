import React from "react";

const pointZeroToPercent = nr => Math.round(nr * 100);

export default function Rules({
	eventSpeadPenalty,
	maxTimeLostOverTime,
	dayImpact,
	shortHourImpact,
	longHourImpact,
}: {
	eventSpeadPenalty: number;
	maxTimeLostOverTime: number;
	dayImpact: number;
	longHourImpact: number;
	shortHourImpact: number;
}) {
	return (
		<>
			<div>
				<h3>Peaktime rules</h3>
				<ul>
					<li>Preset Values for Days</li>
					<li>Preset Values for Hours</li>
					<li>
						Start Time Impact: The first 30 minutes from the start date are reduced by{" "}
						{pointZeroToPercent(0.5)}%.
					</li>
					<li>
						Interval Value Decay: gradual decrease in value from the start day to the end of the interval from
						0% to {100 - maxTimeLostOverTime}%
					</li>
					<li>
						Event Impact: If there&apos;s an event on a day
						<ul>
							<li>Day impact: All slots are reduced by {pointZeroToPercent(dayImpact)}%.</li>
							<li>
								Long hour impact: Additionally, {eventSpeadPenalty} hours before and after the selected event
								are reduced from {pointZeroToPercent(longHourImpact)}% to 0%.
							</li>
							<li>
								Short hour impact: Additionally, 15 minutes before and after the selected event are reduced by{" "}
								{pointZeroToPercent(shortHourImpact)}%.
							</li>
						</ul>
					</li>
					<li>
						Randomness:
						<ul>
							<li>Pick a random slot from the top values (within a 2% margin).</li>
							<li>Pick a random minute from the selected slot.</li>
						</ul>
					</li>
				</ul>
			</div>
		</>
	);
}
