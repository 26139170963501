import {FC, KeyboardEvent, useCallback, useEffect, useState, useRef} from "react";

import {Switch} from "../../../components/input";
import {useDirtyCopy} from "../../../dirty-copy";
import {useMutationToast} from "../../../toast";
import {P, Span} from "../../../components/text";
import {useMyUser} from "../../../data";
import {Arrow} from "../../../components/images";
import {UPDATE_ORG} from "../../../data/org";

import styles from "./preferences.module.scss";

export const Preferences: FC = () => {
	const me = useMyUser();
	const [openLegal, setOpenLegal] = useState(false);
	const [updateOrg] = useMutationToast(UPDATE_ORG);
	const onUpdate = useCallback(
		async ({val, discard, changes}) => {
			if (!changes) return;
			Object.keys(changes).forEach(key => {
				if (changes[key] != null && changes[key] === val[key]) {
					delete changes[key];
				}
			});
			if (!Object.keys(changes).length) return;
			return updateOrg({variables: {changes}, onCompleted: discard});
		},
		[updateOrg]
	);
	const {inputFunc, update, val} = useDirtyCopy(me.org.options, {debounce: 2000, onUpdate});
	const legalTermsRef = useRef<null | HTMLDivElement>(null);
	useEffect(() => {
		if (openLegal && legalTermsRef) {
			legalTermsRef.current?.scrollIntoView({behavior: "smooth"});
		}
	}, [openLegal]);
	const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
		const events = {
			Enter: () => setOpenLegal(c => !c),
		};

		if (events[e.key]) {
			events[e.key]();
			e.stopPropagation();
			e.preventDefault();
		}
	};

	return (
		<div className={styles.container}>
			<Switch
				labelClassName={styles.label}
				label="Employee Advocacy Stats"
				info="Allow your users to view the company's Employee Advocacy Stats in the Feeds page, Company Tab."
				{...inputFunc("companyStats")}
			/>
			<Switch
				labelClassName={styles.label}
				label="PeakTime™"
				info="PeakTime™ is a feature that leverages an intelligent algorithm to send content at optimal times. By analyzing user engagement patterns, PeakTime™ ensures your content is shared when it's most likely to garner attention and interaction. ™"
				{...inputFunc("peakTime")}
			/>

			<Switch
				labelClassName={styles.label}
				label="Virtual Assistant"
				info="Enable or disable the Virtual Assistant. When enabled, the assistant provides automated help for sharing content and managing your schedule. Your team members can override this setting individually."
				{...inputFunc("virtualAssistant")}
			/>

			<Switch
				labelClassName={styles.label}
				label="Include Admins on Leaderboard Display"
				info="When this setting is activated, the rankings of all administrators will be prominently displayed on the leaderboard, providing transparency and insight into their performance."
				value={!val.adminsInvisible}
				onChange={v => update({adminsInvisible: !v})}
			/>

			<Switch
				labelClassName={styles.label}
				label="Auto-Notify Unclaimed Accounts"
				info="Send Collections to users even if they haven't claimed their account. This is useful for prompting new users to engage with the platform."
				{...inputFunc("queueToPendingUsersForever")}
			/>
			<Switch
				labelClassName={styles.label}
				label="AI Assist"
				info="Enable the use of artificial intelligence (AI) technologies like deep learning and machine learning within our services. By enabling AI, you consent to the use of these technologies as outline in our terms and conditions."
				{...inputFunc("ai")}
			/>
			<div className={styles.legal}>
				<div
					className={styles.legalTitle}
					onClick={() => setOpenLegal(c => !c)}
					tabIndex={0}
					onKeyDown={handleKeyDown}
				>
					<Span>See Legal Terms</Span>
					<Arrow direction={openLegal ? "up" : "down"} className={styles.legalArrow} />
				</div>
				<div ref={legalTermsRef}>
					{openLegal && (
						<>
							<P>AI SERVICES</P>
							<P>
								The Licensed Software or portions thereof may incorporate or otherwise leverage artificial
								intelligence or similar technologies (the “AI Services”). The AI Services may, but do not
								necessarily, include deep learning, machine learning, generative artificial intelligence,
								large language models, and other artificial intelligence technologies, including any and all
								(a) proprietary algorithms, software or systems that make use of or employ neural networks,
								statistical learning algorithms (like linear and logistic regression, support vector machines,
								random forests, k-means clustering), or reinforcement learning, and (b) proprietary embodied
								artificial intelligence and related hardware or equipment. The terms and conditions herein
								apply to any portion of the Licensed Software constituting or leveraging AI Services. The AI
								Services may enable Users to create, modify, or enhance content based on various inputs and
								parameters.
							</P>
							<P>
								1. Licensee represents and warrants that Licensee has obtained all necessary consents and
								permissions from any third parties whose data, information, content, or intellectual property
								rights may be involved in or affected by the use of the AI Services, or any data, information,
								or content submitted to or generated by the Licensed Software (collectively, “Content”).
								Licensee agrees to comply with all applicable laws, rules, and regulations regarding such use,
								and to respect and protect such rights.
							</P>
							<P>
								2. Licensee grants Licensor a non-exclusive, royalty-free, worldwide, perpetual, irrevocable,
								sublicensable, transferable license to copy, modify, and otherwise use any Content submitted
								to or generated by the AI Services for the purpose of enhancing the performance and
								functionality of the AI Services and developing new products and services. Licensee also agree
								that Licensor may collect and analyze data and feedback from Licensee’s use of the AI Services
								for such purposes.
							</P>
							<P>
								3. All or any portion of the AI Services may be provided by a third party, and third-party
								terms may apply to Licensee’s use of the AI Services. Licensee agrees to comply with any such
								third-party terms, and Licensee authorizes Licensor to transfer or otherwise provide such
								third parties with the Content submitted by Licensee. Licensee further acknowledge that such
								third parties are outside of Licensor’s control and may have access and rights to the Content
								Licensee submit to or through the AI Services. Licensor shall have no responsibility or
								liability for, and Licensee hereby holds Licensor harmless from, all acts and omissions of any
								such third-party. Further, Licensor cannot guarantee the availability of any third-party
								services, and therefore any AI Services dependent on such third-party services may be
								terminated or suspended at any time without notice, and in such event Licensor shall have no
								liability for such termination or suspension.
							</P>
							<P>
								4. Licensee acknowledges that use of the AI Services may involve risks and uncertainties, such
								as unpredictable or unintended outcomes, bias, errors, failures, or liabilities. Licensor does
								not represent, warrant or guarantee, and Licensee hereby holds Licensor harmless from, the
								accuracy, quality, suitability, or legality of any Content generated by the AI Services, and
								Licensee is solely responsible for verifying, reviewing, and approving such Content before
								using it for any purpose. Licensee agrees to assume all such risks and to indemnify and hold
								harmless Licensor from any claims, liabilities, losses, or damages arising from or relating to
								the use of the AI Services or any Content submitted to or generated by the AI Services, and no
								such claims, liabilities, losses, or damages shall be subject to, or limited by, any
								limitation of liability, limitation of remedy or disclaimer otherwise set forth in the
								Agreement.
							</P>
							<P>
								5. LICENSOR DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO THE AI SERVICES AND
								ANY CONTENT, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
								PARTICULAR PURPOSE, NON-INFRINGEMENT, AND QUALITY. LICENSOR DOES NOT WARRANT THAT THE AI
								SERVICES WILL MEET LICENSEE’S REQUIREMENTS OR EXPECTATIONS, OR THAT IT WILL OPERATE WITHOUT
								INTERRUPTION OR ERROR.
							</P>
							<P>
								6. TO THE MAXIMUM EXTENT PERMITTED BY LAW, LICENSOR&apos;S TOTAL AGGREGATE LIABILITY FOR ANY
								CLAIMS ARISING FROM OR RELATING TO THE AI SERVICES OR ANY CONTENT GENERATED BY THE AI SERVICES
								SHALL BE LIMITED TO ONE HUNDRED UNITED STATES DOLLARS ($100). TO THE MAXIMUM EXTENT PERMITTED
								BY LAW, IN NO EVENT SHALL LICENSOR BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL,
								PUNITIVE, EXEMPLARY, OR SPECIAL DAMAGES, OF ANY KIND WHATSOEVER, INCLUDING THOSE WITH RESPECT
								TO ANY LOSS OF PROFITS, REVENUE, DATA, OR GOODWILL, IN RELATION TO THE AI SERVICES.
							</P>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
