import classnames from "classnames";
import {useRef, useEffect, ReactElement} from "react";

import {Component} from "../../types";

export interface OutsideComponent extends Component {
	onOutsideClick: () => void;
}

export const Alerter = ({children, className, onOutsideClick, ...props}: OutsideComponent): ReactElement => {
	const wrapperRef = useRef(null);
	const isChildOf = (ref, event) => {
		try {
			return ref.current.contains(event.target);
		} catch (e) {
			return false;
		}
	};

	useEffect(() => {
		const handleOutsideClick = event => {
			if (!isChildOf(wrapperRef, event)) {
				onOutsideClick();
			}
		};

		document.addEventListener("mousedown", handleOutsideClick);
		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, [wrapperRef, onOutsideClick]);

	return (
		<div ref={wrapperRef} className={classnames(className)} {...props}>
			{children}
		</div>
	);
};
