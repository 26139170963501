import {debounce} from "lodash";
import {useEffect, useMemo} from "react";

export const useDebounceCallback = (func, wait = 200) => {
	const debouncedChangeHandler = useMemo(() => debounce(func, wait), [func, wait]);

	useEffect(() => () => debouncedChangeHandler.cancel(), [debouncedChangeHandler]);

	return debouncedChangeHandler;
};
