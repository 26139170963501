import {FC, useCallback, useMemo, useState} from "react";

import {Button, Text} from "../../components/input";
import {useMutationToast, useToast} from "../../toast";
import {ADD_RSS_FEED} from "../../data";
import {Modal, ModalData} from "../../modals/new";

export interface AddFeedModalProps {
	modal: ModalData;
	onSuccess?: () => void;
}

export const AddFeedModal: FC<AddFeedModalProps> = ({modal, onSuccess}) => {
	const [url, setUrl] = useState("");
	const [addRssFeed, {loading}] = useMutationToast(ADD_RSS_FEED);
	const toast = useToast();

	const buttonDisabled = !url.match(/^https?:\/\//);

	const modalPlusPlus = useMemo<ModalData>(
		() => ({
			...modal,
			close: () => {
				modal.close();
				setUrl("");
			},
		}),
		[modal]
	);
	const onClick = useCallback(() => {
		addRssFeed({variables: {add: {url}, userFeed: true}}).then(() => {
			toast({
				color: "green",
				text: "It'll be a few moments while we parse this feed. Check back soon.",
				timeout: 6,
			});
			modalPlusPlus.close();
			onSuccess?.();
		});
	}, [addRssFeed, modalPlusPlus, toast, onSuccess, url]);

	return (
		<Modal modal={modalPlusPlus} title="Add New Feed">
			<Text label="Feed URL" placeholder="https://" value={url} onChange={setUrl} />
			<Button value="Save" color="blue" onClick={onClick} loading={loading} disabled={buttonDisabled} />
		</Modal>
	);
};
