import {useState} from "react";

import {useMutationToast, useToast} from "../toast";
import {
	GET_PRESIGNED_VIDEO_URL,
	SET_URL_FOR_ORG_VIDEO,
	VALID_FILE_TYPES,
	getVideoMetadata,
} from "../data/media";

const maxVideoTitleLength = 100;

const getVideoInvalidReason = (size: number, duration: number, videoTitle: string): string | undefined => {
	const maxDuration = 140;
	const minDuration = 0.5;
	const maxFileSize = 512;
	const bytesToMb = (bytes: number) => Number(bytes / (1024 * 1024)).toFixed(2);
	const fileSize = Number(bytesToMb(size));

	if (duration > maxDuration || duration < minDuration) {
		return "The video you are uploading is not between 0.5s and 140s.";
	}

	if (fileSize > maxFileSize) {
		return "The video you are uploading is larger than 512MB.";
	}

	if (videoTitle.length > maxVideoTitleLength) {
		return "The title of the video you are uploading is larger than 100 characters.";
	}

	return "";
};

export const useVideoUpload = () => {
	const [loading, setLoading] = useState(false);
	const toast = useToast();
	const [getPresignedUrl] = useMutationToast(GET_PRESIGNED_VIDEO_URL);
	const [setUrlForOrgVideo] = useMutationToast(SET_URL_FOR_ORG_VIDEO);
	const [error, setError] = useState(null);

	const uploadFile = async file => {
		const videoType = file.type.substring(6);

		if (!VALID_FILE_TYPES.includes(videoType.toLowerCase())) {
			throw new Error(
				`The video you are uploading is not a valid file type. Supported file types are: ${VALID_FILE_TYPES.join(
					", "
				)}. Your file type: ${videoType || "not found"}.`
			);
		}

		setLoading(true);

		getVideoMetadata(file)
			.then(async videoMeta => {
				const failReason = getVideoInvalidReason(file.size, videoMeta.duration, file.name);

				if (failReason) {
					return Promise.reject(failReason);
				}
				await getPresignedUrl({
					variables: {
						fileName: file.name,
						path: "raw",
						contentType: file.type,
						size: file.size,
						duration: videoMeta.duration,
					},
					onCompleted: async data => {
						const {signedUrl: url, key} = data.generatePreSignedUrl;
						return new Promise((resolve, reject) => {
							const xhr = new XMLHttpRequest();

							xhr.open("PUT", url, true);
							xhr.onload = function () {
								if (xhr.status >= 200 && xhr.status < 300) {
									resolve(xhr.response);
								} else {
									reject(new Error(xhr.statusText));
								}
							};
							xhr.onerror = function () {
								reject(new Error("Network Error"));
							};
							xhr.send(file);
						}).then(() => {
							setUrlForOrgVideo({
								variables: {key, title: file.name},
								onCompleted: () => {
									setLoading(false);
								},
							});
						});
					},
				});
			})
			.catch(err => {
				setLoading(false);
				toast({color: "red", text: err?.toString(), icon: "warning"});
				setError(err?.toString() || `There was a problem uploading your video. Please try again later.`);
			});
	};
	return {uploadFile, loading, error};
};
