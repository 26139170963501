import {gql} from "@apollo/client";
import {Dayjs} from "dayjs";

import {BASE_USER_FRAGMENT, USER_LEADERBOARD_FRAGMENT, User} from "./user";

export interface Contest {
	id: number;
	name: string;
	startDate: Dayjs;
	endDate: Dayjs;
	kpiDriver: string;
	milestone: number;
	winners: number;
	prize: number;
	kpiAchieved: number;
	contestantsPreview: User[];
	group: string;
}
export type RewardError = {
	description: string;
	title: string;
	code: number;
};

export const CONTEST_FRAGMENT = gql`
	fragment ContestFields on Contest {
		id
		name
		startDate
		endDate
		kpiDriver
		complete
		totalContestants
		group
		milestone
		winners
		prize
		kpiAchieved
		timezone
		owner {
			...BaseUserFields
		}
		contestantsPreview {
			...BaseUserFields
		}
	}
	${BASE_USER_FRAGMENT}
`;

const TREMENDOUS_INTEGRATION_FRAGMENT = gql`
	fragment TremendousIntegrationFields on TremendousIntegration {
		connected
		id
		url
	}
`;

export const GET_CONTESTS = gql`
	query GetContests($cursor: String, $filter: ContestFilter) {
		contests(cursor: $cursor, filter: $filter) {
			items {
				...ContestFields
			}
			cursor
		}
	}
	${CONTEST_FRAGMENT}
`;

export const CREATE_CONTEST = gql`
	mutation CreateContest(
		$name: String!
		$startDate: DateTime!
		$endDate: DateTime!
		$kpiDriver: String!
		$milestone: Float!
		$winners: Int!
		$prize: Float!
		$timezone: String!
		$group: String!
	) {
		createContest(
			name: $name
			startDate: $startDate
			endDate: $endDate
			kpiDriver: $kpiDriver
			milestone: $milestone
			winners: $winners
			prize: $prize
			timezone: $timezone
			group: $group
		) {
			...ContestFields
		}
	}
	${CONTEST_FRAGMENT}
`;

export const CONTEST_LEADERBOARD = gql`
	query ContestLeaderboard($contestId: String!, $cursor: String) {
		contestLeaderboard(contestId: $contestId, cursor: $cursor) {
			items {
				id
				userTotalExperience
				leaderboard {
					...LeaderBoardFields
				}
				user {
					...BaseUserFields
				}
			}
			cursor
		}
	}
	${USER_LEADERBOARD_FRAGMENT}
	${BASE_USER_FRAGMENT}
`;

export const CONTEST = gql`
	query Contest($id: String!) {
		contest(id: $id) {
			...ContestFields
		}
	}
	${CONTEST_FRAGMENT}
`;

export const UPDATE_CONTEST = gql`
	mutation UpdateContest(
		$id: String!
		$name: String
		$startDate: DateTime
		$endDate: DateTime
		$kpiDriver: String
		$milestone: Float
		$winners: Int
		$prize: Float
		$group: String
	) {
		updateContest(
			id: $id
			name: $name
			startDate: $startDate
			endDate: $endDate
			kpiDriver: $kpiDriver
			milestone: $milestone
			winners: $winners
			prize: $prize
			group: $group
		) {
			...ContestFields
		}
	}
	${CONTEST_FRAGMENT}
`;

export const MARK_CONTEST_COMPLETE = gql`
	mutation MarkContestComplete($id: String!) {
		markContestComplete(id: $id) {
			...ContestFields
		}
	}
	${CONTEST_FRAGMENT}
`;

export const DELETE_CONTEST = gql`
	mutation DeleteContest($id: String!) {
		deleteContest(id: $id) {
			...ContestFields
		}
	}
	${CONTEST_FRAGMENT}
`;

export const ACTIVE_CONTEST_COUNT = gql`
	query ActiveContestCount {
		activeContestCount
	}
`;

export const TREMENDOUS_INTEGRATION = gql`
	query TremendousIntegration {
		tremendousIntegration {
			...TremendousIntegrationFields
		}
	}
	${TREMENDOUS_INTEGRATION_FRAGMENT}
`;

export const TREMENDOUS_URL = gql`
	query TremendousUrl {
		tremendousUrl
	}
`;

export const EXPORT_CONTEST_DATA = gql`
	query ExportContestData($contestId: String!) {
		exportContestData(id: $contestId) {
			columns
			data
		}
	}
`;

export const DISCONNECT_TREMENDOUS = gql`
	mutation DisconnectTremendous {
		disconnectTremendous {
			...TremendousIntegrationFields
		}
	}
	${TREMENDOUS_INTEGRATION_FRAGMENT}
`;

export const TREMENDOUS_REWARD_OPTIONS = gql`
	query TremendousRewardOptions {
		tremendousRewardOptions {
			fundingSources {
				name
				id
			}
			campaigns {
				name
				id
			}
		}
	}
`;

export const SEND_TREMENDOUS_REWARD = gql`
	mutation SendTremendousReward($id: String!, $fundingSource: String!, $campaign: String!) {
		sendTremendousReward(id: $id, fundingSource: $fundingSource, campaign: $campaign) {
			errors {
				description
				title
				code
			}
			id
			type
		}
	}
`;

export const GET_USER_EXPERIENCES = gql`
	query GetUserExperiences($userIds: [String!]!) {
		userExperiences(userIds: $userIds) {
			id
			experience
		}
	}
	${CONTEST_FRAGMENT}
`;
