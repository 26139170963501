import React, {ReactElement, useCallback, useMemo, useState} from "react";
import {useQuery} from "@apollo/client";
import {useNavigate, useParams} from "react-router";
import {Formik, useFormikContext} from "formik";
import classnames from "classnames";

import {Loading} from "../../../components/loading";
import {CollectionForm} from "./form/collection-form";
import {
	Collection as CollectionType,
	GET_FULL_COLLECTION,
	Post,
	SAVE_COLLECTION,
	loadCollection,
	ADD_POST_FROM_SUGGESTED_FEED,
	ADD_POST_FROM_RSS_FEED,
	ADD_CATEGORY_TO_COLLECTION,
	REMOVE_CATEGORY_FROM_COLLECTION,
} from "../../../data";
import {useMutationToast, useToast} from "../../../toast";
import {useToastApi} from "../../../api";
import {ContentLibrary} from "../components/library";
import {Span} from "../../../components/text";
import {Button} from "../../../components/input";
import {Icon} from "../../../components/images";

import styles from "./collection.module.scss";

const bStyles = classnames.bind(styles);

const updateCollectionFields = [
	"title",
	"message",
	"status",
	"autoLike",
	"scheduledFor",
	"groupIds",
	"virtualAssistantIds",
	"companyIds",
	"slackChannels",
	"teamsChannels",
	"owner",
	"to",
];
export const Collection = (): ReactElement => {
	const toast = useToast();
	const {put} = useToastApi();
	const navigate = useNavigate();
	const {id} = useParams();
	const [advanced, setAdvanced] = useState(false);
	const {data, loading: collectionLoading} = useQuery(GET_FULL_COLLECTION, {variables: {id: Number(id)}});
	const [addPostFromSuggestedFeed, {loading: addingPostFromFeed}] = useMutationToast(
		ADD_POST_FROM_SUGGESTED_FEED
	);
	const [addPostFromRssFeed, {loading: addingPostFromRss}] = useMutationToast(ADD_POST_FROM_RSS_FEED);
	const [saveCollection] = useMutationToast(SAVE_COLLECTION);
	const [addCategory] = useMutationToast(ADD_CATEGORY_TO_COLLECTION);
	const [removeCategory] = useMutationToast(REMOVE_CATEGORY_FROM_COLLECTION);
	const collection = useMemo(
		() =>
			data?.collection
				? loadCollection(data.collection)
				: ({
						autoLike: {},
						to: {
							groupIds: [] as number[],
							virtualAssistantIds: [] as number[],
							companyIds: [] as number[],
							slackChannels: [] as string[],
						},
						posts: [] as Post[],
				  } as CollectionType),
		[data?.collection]
	);

	const onSendPreview = useCallback(() => {
		toast({
			color: "blue",
			text:
				"We are currently sending your collection preview. We will let you know as soon as the collection has been sent.",
			timeout: 5,
		});
		put("send preview", `queues/${collection.id}/send-test`, null, resp => {
			if (resp.success) {
				toast({
					color: "blue",
					text:
						"Your test collection has been sent. Please allow a few minutes for the message to appear in your inbox as some spam filters delay delivery.",
					timeout: 5,
				});
			}
		});
	}, [toast, put, collection.id]);

	const onAddFromLibrary = useCallback(
		async ({shareId, feedId}: {shareId?: number; feedId?: number | undefined}) => {
			if (feedId) {
				return addPostFromRssFeed({
					variables: {id: collection.id, postId: shareId},
				});
			}

			return addPostFromSuggestedFeed({
				variables: {id: collection.id, postId: shareId, postType: "recommended"},
			});
		},
		[collection.id, addPostFromSuggestedFeed, addPostFromRssFeed]
	);

	const initialValues = useMemo(
		() => ({
			id: collection.id,
			owner: collection.owner,
			status: collection.status,
			autoLike: collection.autoLike,
			title: collection.title,
			scheduledFor: collection.scheduledFor,
			sent: collection.sent,
			message: collection.message,
			deliveryMessage: collection.deliveryMessage,
			categories: collection.categories,
			to: collection.to,
			posts: collection.posts,
		}),
		[collection]
	);

	const maybeAddCategories = useCallback(
		values =>
			Promise.all(
				values.categories
					?.filter(categoryId => !collection.categories?.includes(categoryId))
					?.map(categoryId => addCategory({variables: {id: collection.id, categoryId}})) || []
			),
		[collection.categories, collection.id, addCategory]
	);

	const maybeRemoveCategories = useCallback(
		values =>
			Promise.all(
				collection.categories
					?.filter(categoryId => !values?.categories?.includes(categoryId))
					?.map(categoryId => removeCategory({variables: {id: collection.id, categoryId}})) || []
			),
		[collection.categories, collection.id, removeCategory]
	);

	const maybeUpdateCollection = useCallback(
		values => {
			const changes = {};

			Object.entries(collection).forEach(([key, value]) => {
				if (!updateCollectionFields.includes(key) || JSON.stringify(values[key]) === JSON.stringify(value))
					return;

				if (key === "to") {
					Object.assign(changes, values[key]);
				} else {
					if (key === "scheduledFor") {
						changes[key] = values[key] ?? null;
					} else {
						changes[key] = values[key];
					}
				}
			});

			return saveCollection({
				variables: {
					id: collection.id,
					changes,
				},
			});
		},
		[saveCollection, collection]
	);

	const onSubmit = useCallback(
		values =>
			Promise.all([maybeUpdateCollection(values), maybeRemoveCategories(values), maybeAddCategories(values)]),
		[maybeUpdateCollection, maybeRemoveCategories, maybeAddCategories]
	);

	const onDiscard = useCallback(() => {
		navigate("/collections");
	}, [navigate]);

	const excludedUrls = useMemo(() => (collection?.posts?.map(p => p.url) ?? []).filter(Boolean) as string[], [
		collection.posts,
	]);

	if (collectionLoading) {
		return <Loading position="center" />;
	}

	return (
		<div className={styles.collectionContainer}>
			<div className={styles.collection}>
				<Formik initialValues={initialValues} validateOnChange onSubmit={onSubmit}>
					<>
						<div className={styles.control}>
							<div className={styles.buttonsCt}>
								<Span className={styles.collectionHeader}>{`${id ? "Edit" : "Create"} a Collection`}</Span>
								<Loader />
							</div>
							<Button
								invert
								border={false}
								onClick={() => setAdvanced(v => !v)}
								value={
									<div className={bStyles(styles.advancedBtn, {[styles.advanced]: advanced})}>
										<Icon
											color={"blue"}
											className={styles.advancedBtnIcon}
											icon={advanced ? "chevron-up" : "chevron-down"}
										/>
										<Span className={styles.advancedBtnText}>
											{advanced ? "Simple Builder" : "Advanced Builder"}
										</Span>
									</div>
								}
							/>
						</div>
						<CollectionForm
							collection={collection}
							onSendPreview={onSendPreview}
							onDiscard={onDiscard}
							addingPost={addingPostFromFeed || addingPostFromRss}
						/>
					</>
				</Formik>
			</div>
			{advanced && (
				<div>
					<ContentLibrary type={"Collection"} onAdd={onAddFromLibrary} excludedUrls={excludedUrls} />
				</div>
			)}
		</div>
	);
};

const Loader = () => {
	const {isSubmitting} = useFormikContext();

	return (
		<div className={bStyles(styles.loadingCt, {[styles.saving]: isSubmitting})}>
			<Loading className={styles.loading} size="small" position="center" />
			Saving....
		</div>
	);
};
