import {ReactElement, useEffect, useState} from "react";
import classnames from "classnames/bind";

import {Page} from "../../../layout";
import {Span1} from "../../../components/text";

import styles from "./bugs-priority.module.scss";

const bStyles = classnames.bind(styles);

const priorityLevels = {
	5: "Highest",
	4: "High",
	3: "Medium",
	2: "Low",
	1: "Lowest",
};

const severityFactor = 3;
const severityLevels = [
	{
		label: "Highest",
		value: "highest",
		description:
			"Complete system failure, data loss, severe security violation, or must-have feature not working",
		level: 5 * severityFactor,
	},
	{
		label: "High",
		value: "high",
		description: "Should-have feature not working, or security violation",
		level: 4 * severityFactor,
	},
	{
		label: "Medium",
		value: "medium",
		description: "Partial functionality loss, workaround required",
		level: 3 * severityFactor,
	},
	{
		label: "Low",
		value: "low",
		description: "Minor functionality issues, visual/cosmetic issues, or slight performance degradation",
		level: 2 * severityFactor,
	},
	{
		label: "Lowest",
		value: "lowest",
		description: "Trivial issues or low priority enhancements",
		level: 1 * severityFactor,
	},
];

const replicationLevels = [
	{
		label: "Replicated",
		value: "replicated",
		description: "Successfully reproduced by the team; issue is clear and testable",
		level: 5,
	},
	{
		label: "Partially Replicated",
		value: "partially_replicated",
		description: "Reproduced in part; some aspects are observable but not fully consistent",
		level: 4,
	},
	{
		label: "Unable to Test",
		value: "unable_to_test",
		description: "Cannot test due to environmental limitations or insufficient information",
		level: 3,
	},
	{
		label: "Not Replicated",
		value: "not_replicated",
		description: "Issue not reproduced by the team; indicates difficulties in replication or understanding",
		level: 1,
	},
];

const impactLevels = [
	{
		label: "Widespread",
		value: "widespread",
		description: "Affects a large segment of users or a significant portion of the user base; major issue",
		level: 5,
	},
	{
		label: "Significant",
		value: "significant",
		description: "Affects a moderate number of users or a key user group; notable impact but not universal",
		level: 4,
	},
	{
		label: "Limited",
		value: "limited",
		description: "Affects a small number of users or a specific subset; localized impact",
		level: 3,
	},
	{
		label: "Minimal",
		value: "minimal",
		description: "Affects very few users or has minimal impact; minor disruption",
		level: 1,
	},
];

export const BugsPriority = (): ReactElement => {
	const [severity, setSeverity] = useState("lowest");
	const [replication, setReplication] = useState("not_replicated");
	const [impact, setImpact] = useState("minimal");

	const [priority, setPriority] = useState(1);

	useEffect(() => {
		const severityLevel = severityLevels.find(({value}) => value === severity)?.level || 1;
		const replicationLevel = replicationLevels.find(({value}) => value === replication)?.level || 1;
		const impactLevel = impactLevels.find(({value}) => value === impact)?.level || 1;

		const priorityLevel = Math.round((severityLevel + replicationLevel + impactLevel) / 5);

		setPriority(priorityLevel);
	}, [severity, replication, impact]);

	const priorityLevel = priorityLevels[priority];

	return (
		<Page title="Bugs Priority">
			<div>
				{/* Severity Level */}
				<div className="space">
					<h2 className="space">Severity Level:</h2>
					{severityLevels.map(({label, value, description}) => (
						<div key={value} className={bStyles("row", value)} onClick={() => setSeverity(value)}>
							<div className={styles.radioGroup}>
								<input
									type="radio"
									id={`severity_${value}`}
									name="severity"
									value={value}
									checked={severity === value}
									onChange={() => setSeverity(value)}
								/>
								<label htmlFor={`severity_${value}`}>{label}</label>
							</div>

							<p>{description}</p>
						</div>
					))}
				</div>
				{/* Replication Level */}
				<div className="space">
					<h2 className="space">Replication Level:</h2>
					{replicationLevels.map(({label, value, description}) => (
						<div key={value} className={bStyles("row", value)} onClick={() => setReplication(value)}>
							<div className={styles.radioGroup}>
								<input
									type="radio"
									id={`replication_${value}`}
									name="replication"
									value={value}
									checked={replication === value}
									onChange={() => setReplication(value)}
								/>
								<label htmlFor={`replication_${value}`}>{label}</label>
							</div>

							<p>{description}</p>
						</div>
					))}
				</div>

				{/* Impact Level */}
				<div className="space">
					<h2 className="space">User Impact Level:</h2>
					{impactLevels.map(({label, value, description}) => (
						<div key={value} className={bStyles("row", value)} onClick={() => setImpact(value)}>
							<div className={styles.radioGroup}>
								<input
									type="radio"
									id={`impact_${value}`}
									name="impact"
									value={value}
									checked={impact === value}
									onChange={() => setImpact(value)}
								/>
								<label htmlFor={`impact_${value}`}>{label}</label>
							</div>

							<p>{description}</p>
						</div>
					))}
				</div>

				<div className={bStyles("result", priorityLevel.toLowerCase(), "space")}>
					<Span1>Bug Recommended Priority: {priorityLevel}</Span1>
				</div>
			</div>
		</Page>
	);
};
