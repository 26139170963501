import {ReactElement, ReactNode} from "react";
import {Portal} from "react-portal";
import classnames from "classnames";
import {Placement, autoUpdate, shift, flip, offset, useFloating} from "@floating-ui/react-dom";

import {Alerter} from "./alerter";
import {useId} from "../input";
import {Component} from "../../types";

import styles from "./alerter-widget.module.scss";

export interface PopoverComponent extends Component {
	id?: string;
	onOpen: (newValue: boolean) => void;
	isOpen: boolean;
	labelComponent?: ReactNode;
	placement?: Placement | undefined;
}

export const AlerterWidget = ({
	children,
	className,
	id: maybeId,
	labelComponent,
	isOpen,
	onOpen,
	placement = "bottom-start",
}: PopoverComponent): ReactElement => {
	const id = useId(maybeId);

	const {refs, floatingStyles} = useFloating({
		placement,
		whileElementsMounted: autoUpdate,
		middleware: [flip({padding: 20, fallbackAxisSideDirection: "end"}), shift(), offset(10)],
	});

	return (
		<div className={styles.container}>
			<div
				id={id}
				className={classnames(styles.labelContainer)}
				onClick={() => onOpen(true)}
				ref={refs.setReference}
			>
				{labelComponent}
			</div>
			{isOpen && (
				<Portal>
					<div
						className={classnames(styles.content, className)}
						ref={refs.setFloating}
						style={floatingStyles}
					>
						<Alerter onOutsideClick={() => onOpen(false)}>{children}</Alerter>
					</div>
				</Portal>
			)}
		</div>
	);
};
