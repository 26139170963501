import {useQuery, useQueryClient} from "@tanstack/react-query";
import {useCallback} from "react";

import {AIAssistantFormResponse} from "./use-ai-execute-prompt";

export const useAIMessage = ({formId}: {formId: string}) => {
	const queryClient = useQueryClient();

	const {data} = useQuery<AIAssistantFormResponse>({
		queryKey: ["ai-message", formId],
	});

	const setAiMessage = useCallback(
		(message: string) => {
			queryClient.setQueryData(["ai-message", formId], {
				...data,
				message,
			});
		},
		[queryClient, data, formId]
	);

	return {
		aiMessage: data?.message,
		setAiMessage,
	};
};
