import React, {ReactElement, useCallback, useEffect, useMemo, useState} from "react";
import dayjs from "dayjs";

import {Page} from "../../../layout";
import {Button, DropdownButton, Separator, Text} from "../../../components/input";
import {useToastApi} from "../../../api";
import {useToast} from "../../../toast";
import {useFindOutWhoModal} from "../../feed/find-out-who-modal";
import {useCustomRange} from "../../../components/input/date-time/date-range";
import {FeedPostVisitor as FindOutWhoVisitor} from "../../../data/visitor";
import {DatePicker} from "../../../components/input/date-time";
import {P} from "../../../components/text";

type Visitor = {
	name?: string;
	visitor_id?: number;
	date_visited?: string;
	clicks?: number;
	email?: string;
	city?: string;
	employees?: string;
	industry?: string;
	phone_number?: string;
	site_url?: string;
	state?: string;
	user_shared_url_ids?: number[];
};

const Visitors = (): ReactElement => {
	const {get, post, put} = useToastApi();
	const [findOutWhoVisitors, setFindOutWhoVisitors] = useState<FindOutWhoVisitor[]>([]);
	const [visitors, setVisitors] = useState<Visitor[]>([]);
	const [newData, setNewData] = useState<Visitor>();
	const [visitor, setVisitor] = useState<Visitor>();
	const [loading, setLoading] = useState<boolean>(false);
	const toast = useToast();

	useEffect(() => {
		get("Get Visitors", "/internal/find-out-who-visitors", data => {
			setVisitors(data);
		});
	}, [get]);

	const onPopulateMissingInfo = useCallback(() => {
		setLoading(true);
		post("Populate Missing Visitor Info", "/internal/find-out-who-visitors", {}, data => {
			setLoading(false);
			toast({color: "green", text: data?.message || `Successfully populated missing visitor info.`});
		});
	}, [post, toast]);

	const updateVisitor = useCallback(() => {
		if (!newData) return;
		setLoading(true);
		put(
			"Update Visitors",
			`/internal/find-out-who-visitors/${visitor?.visitor_id}`,
			{
				...newData,
				userSharedUrlIds: visitor?.user_shared_url_ids,
			},
			() => {
				setLoading(false);
				toast({color: "green", text: `Successfully updated visitor info`});
			}
		);
	}, [put, visitor, newData, toast]);

	const findOutWhoModal = useFindOutWhoModal({visitors: findOutWhoVisitors, title: "Find Out Who"});
	const {start, end} = useCustomRange("YTD", dayjs().add(-10, "year").startOf("year"));
	const f = useMemo(() => `start=${start.unix()}&end=${end.unix()}&groups=[]`, [start, end]);

	const findOutWhoClick = useCallback(async () => {
		setLoading(true);
		await get("FindOutWho data", `/analytics/find-out-who?${f}`, data => {
			setFindOutWhoVisitors(data);
			setLoading(false);
		});
		findOutWhoModal.open();
	}, [findOutWhoModal, f, get]);

	const addClicks = useCallback(() => {
		put(
			"Add clicks to Visitor",
			`/internal/find-out-who-visitors/${visitor?.visitor_id}`,
			{
				clicks: true,
				userSharedUrlIds: visitor?.user_shared_url_ids,
			},
			() => {
				toast({color: "green", text: `Successfully added clicks to visitor`});
			}
		);
	}, [put, visitor, toast]);

	return (
		<Page title="Visitors">
			<Button value="Find Out Who" onClick={findOutWhoClick} loading={loading} />
			<Button
				onClick={onPopulateMissingInfo}
				value="Populate Missing Visitor Info"
				invert
				color="blue"
				loading={loading}
			/>
			<DropdownButton
				options={visitors.map(v => ({
					label: v.name || "",
					onClick: () => setVisitor(v),
					selected: visitor?.visitor_id === v.visitor_id,
				}))}
				value={visitor?.name || "Select Visitor"}
				color="black"
				invert
				arrow
				searchBar={true}
			/>
			{!!visitor && (
				<div style={{padding: "20px", margin: "20px 0", background: "#EEF1F5", width: "450px"}}>
					<Text
						label="Name"
						value={newData?.name ?? visitor?.name}
						onChange={value => setNewData(v => ({...(v || {}), name: value}))}
					/>
					<Text
						label="City"
						value={newData?.city ?? visitor?.city}
						onChange={value => setNewData(v => ({...(v || {}), city: value}))}
					/>
					<Text
						label="State"
						value={newData?.state ?? visitor?.state}
						onChange={value => setNewData(v => ({...(v || {}), state: value}))}
					/>
					<Text
						label="Website"
						value={newData?.site_url ?? visitor?.site_url}
						onChange={value => setNewData(v => ({...(v || {}), site_url: value}))}
					/>
					<Text
						label="Email"
						value={newData?.email ?? visitor?.email}
						onChange={value => setNewData(v => ({...(v || {}), email: value}))}
					/>
					<Text
						label="Phone"
						value={newData?.phone_number ?? visitor?.phone_number}
						onChange={value => setNewData(v => ({...(v || {}), phone_number: value}))}
					/>
					<Text
						label="Industry"
						value={newData?.industry ?? visitor?.industry}
						onChange={value => setNewData(v => ({...(v || {}), industry: value}))}
					/>
					<Text
						label="Employees"
						value={newData?.employees ?? visitor?.employees}
						onChange={value => setNewData(v => ({...(v || {}), employees: value}))}
					/>
					<P>Date Visited</P>
					<DatePicker
						min={dayjs().add(-30, "day").startOf("day")}
						value={dayjs(newData?.date_visited ?? visitor?.date_visited ?? new Date())}
						onChange={value => setNewData(v => ({...(v || {}), date_visited: value.toISOString()}))}
						displayMonth={dayjs(newData?.date_visited ?? visitor?.date_visited ?? new Date()).startOf(
							"month"
						)}
					/>
					<P>{""}</P>
					<Separator horizontal />
					<Button onClick={updateVisitor} value="Save" color="blue" loading={loading} />
					<Separator horizontal />
					<Button onClick={addClicks} value="Acumulate clicks" invert color="blue" loading={loading} />
				</div>
			)}
		</Page>
	);
};

export default Visitors;
