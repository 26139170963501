import React, {FC, useCallback, useEffect, useMemo, useState} from "react";
import dayjs from "dayjs";
import classnames from "classnames";
import {useNavigate} from "react-router-dom";
import {useLazyQuery, useQuery} from "@apollo/client";

import {Button, InputRow, Select} from "../../../components/input";
import {
	AdoptionStatus,
	GET_USER_ADOPTION_STATS,
	GET_USERS_ADOPTION,
	getUserRank,
	UserAdoption,
} from "../../../data/user";
import {defaultLoadingProps as loadingProps, usePaginatedQuery} from "../../../paginated-query";
import {Span, Span4} from "../../../components/text";
import {Card} from "../../../components/card";
import {Icon} from "../../../components/images";
import {useGroupList} from "../../../data";
import {EmployeeAdvocacyFilter} from "./index";
import {useCustomRange} from "../../../components/input/date-time/date-interval-picker";
import {downloadCsv} from "../../../utils/csv";
import {Loading} from "../../../components/loading";

import styles from "./employee-advocacy.module.scss";

type SortDirection = "ASC" | "DESC";

interface AdoptionFilter {
	status: AdoptionStatus | "ALL";
	sort: SortDirection;
}

const adoptionStatuses = [
	{label: "All statuses", value: "ALL"},
	{label: "Shared Once", value: "SHARED_ONCE"},
	{label: "Shared Multiple Times", value: "SHARED_MULTIPLE"},
	{label: "No Activity", value: "NO_ACTIVITY"},
] as const;
const minDate = dayjs().add(-10, "year").startOf("year");
const defaultFilter: AdoptionFilter = {
	status: "ALL",
	sort: "ASC",
};
const readableStatus = (status: AdoptionStatus): string =>
	status === "INVITED"
		? "Pending"
		: status === "SHARED_MULTIPLE"
		? "Shared Multiple Times"
		: status === "SHARED_ONCE"
		? "Shared Once"
		: "No Activity";
const UserRow: FC<{userAdoption: UserAdoption}> = ({userAdoption}) => {
	const {groups} = useGroupList();

	return (
		<div className={classnames(styles.list, styles.listItem)}>
			<Span>{userAdoption.fullName}</Span>
			<Span className={styles.largeColumn}>{userAdoption.email}</Span>
			<Span>
				{groups
					.filter(group => userAdoption.groups.includes(group.value))
					.map(group => group.label)
					.join(", ")}
			</Span>
			<Span className={styles.mediumColumn}>
				{userAdoption.invitationSentOn ? dayjs(userAdoption.invitationSentOn).formatAs("shortDate") : ""}
			</Span>
			<Span className={styles.mediumColumn}>
				{userAdoption.invitationAcceptedOn
					? dayjs(userAdoption.invitationAcceptedOn).formatAs("shortDate")
					: ""}
			</Span>
			<Span>{readableStatus(userAdoption.status)}</Span>
			<Span className={styles.smallColumn}>{userAdoption.rank || "-"}</Span>
			<Span className={styles.smallColumn}>{getUserRank(userAdoption.experience) || "-"}</Span>
		</div>
	);
};
const renderItem = row => <UserRow key={row.userId} userAdoption={row} />;

const ListPageControl: FC<{
	pageSize: number;
	pageNumber: number;
	total: number;
	usersCount: number;
	hasMore: boolean;
	onChange: (n: number) => void;
}> = ({pageSize, pageNumber, total, hasMore, usersCount, onChange}) => (
	<InputRow className={styles.pageControl}>
		<Button onClick={() => onChange(-1)} icon="caret-left" disabled={pageNumber === 1} />
		<Span color="grey">
			Displaying{" "}
			{total > 1 ? `${(pageNumber - 1) * pageSize + 1}-${Math.min(pageNumber * pageSize, total)}` : total} of{" "}
			{usersCount} users
		</Span>
		<Button onClick={() => onChange(1)} icon="caret-right" disabled={!hasMore} />
	</InputRow>
);

export const UserStatusCard: FC<{filter: EmployeeAdvocacyFilter}> = ({filter}) => {
	const navigate = useNavigate();
	const dateRange = useCustomRange(filter.dateRange || "Max", minDate);
	const {start = dayjs(), end = dayjs()} = dateRange || {};
	const [usersFilter, setUsersFilter] = useState(defaultFilter);
	const [pageNumber, setPageNumber] = useState(1);
	const {data: adoptionStatsData} = useQuery(GET_USER_ADOPTION_STATS, {
		variables: {start, end},
		fetchPolicy: "cache-only",
	});
	const {groups} = useGroupList();
	const variables = useMemo(
		() => ({
			groups: filter.groups?.length ? filter.groups : undefined,
			status: usersFilter.status === "ALL" ? undefined : usersFilter.status,
			start,
			end,
			sort: {type: "ALPHABETICAL", direction: usersFilter.sort},
		}),
		[filter.groups, start, end, usersFilter.status, usersFilter.sort]
	);

	const {data, fetchMore, cursor, loading} = usePaginatedQuery<UserAdoption>(GET_USERS_ADOPTION, {
		loadingProps,
		variables,
		renderItem,
	});
	const [getUsersToExport, {loading: exportLoading}] = useLazyQuery(GET_USERS_ADOPTION, {
		fetchPolicy: "no-cache",
	});
	const handleExport = useCallback(async () => {
		const {data} = await getUsersToExport({variables: {...variables, limit: 500}});

		const columns = ["Name", "Email", "Groups", "Date Sent", "Date Accepted", "Status", "Rank", "Experience"];
		const csvData = data?.usersAdoption?.items?.map(row => [
			row.fullName,
			row.email,
			row.groups
				.map(groupId => groups?.find(g => g.value === groupId)?.label)
				.filter(Boolean)
				.join(", "),
			row.invitationSentOn ? dayjs(row.invitationSentOn).formatAs("shortDate") : "",
			row.invitationAcceptedOn ? dayjs(row.invitationAcceptedOn).formatAs("shortDate") : "",
			readableStatus(row.status),
			row.rank || "-",
			row.experience || "-",
		]);

		const exportFileName = `${
			usersFilter.status === "NO_ACTIVITY"
				? "inactive"
				: usersFilter.status === "SHARED_ONCE"
				? "shared_once"
				: usersFilter.status === "SHARED_MULTIPLE"
				? "shared_multiple"
				: "all_users"
		}.csv`;

		downloadCsv(csvData, columns, exportFileName);
	}, [getUsersToExport, groups, usersFilter.status, variables]);
	const totalRows = data?.length || 0;
	const totalActiveUsers =
		adoptionStatsData?.adoptionStats?.filter(
			({status}) => usersFilter.status === "ALL" || status === usersFilter.status
		)?.length ?? 0;
	const totalUsers = totalRows < 20 ? totalRows : totalActiveUsers;
	const onPageChange = useCallback(
		inc => {
			setPageNumber(pageNumber => pageNumber + inc);

			if (inc > 0 && cursor) fetchMore({variables: {cursor}});
		},
		[fetchMore, cursor]
	);
	const onStatusChange = s => {
		setUsersFilter(f => ({...f, status: s}));
		setPageNumber(1);
	};

	useEffect(() => {
		setPageNumber(1);
	}, [filter.groups, filter.dateRange, usersFilter.sort, usersFilter.status]);

	return (
		<Card className={styles.card}>
			<div>
				<div className={styles.cardHeader}>
					<div className={styles.sameLine}>
						<h3>User Status</h3>
						<Span4 color={"grey"}>{`${start.formatAs("shortDate")} - ${end.formatAs("shortDate")}`}</Span4>
					</div>
				</div>
				<Span>
					View and manage full list of users in your organization in{" "}
					<Span bold color="blue" onClick={() => navigate("/users/list")}>
						User management in Settings
					</Span>
				</Span>
			</div>
			<div className={styles.buttonsContainer}>
				<ListPageControl
					pageSize={20}
					pageNumber={pageNumber}
					total={totalRows}
					hasMore={pageNumber * 20 < totalRows || !!cursor}
					usersCount={totalUsers}
					onChange={onPageChange}
				/>
				<div className={styles.buttonsGroup}>
					<Select options={adoptionStatuses} value={usersFilter.status} onChange={onStatusChange} />
					<Button icon="export" onClick={handleExport} value="Export" loading={exportLoading} />
				</div>
			</div>
			<div className={styles.userStatusTable}>
				<div className={classnames(styles.list, styles.listHeader)}>
					<div className={styles.nameContainer}>
						<Span bold>Name</Span>
						<Icon
							icon="sort"
							color="black"
							className={styles[usersFilter.sort.toLowerCase()]}
							onClick={() =>
								setUsersFilter({...usersFilter, sort: usersFilter.sort === "ASC" ? "DESC" : "ASC"})
							}
						/>
					</div>
					<Span className={styles.largeColumn} bold>
						E-mail
					</Span>
					<Span bold>Group</Span>
					<Span className={styles.mediumColumn} bold>
						Date Sent
					</Span>
					<Span className={styles.mediumColumn} bold>
						Date Accepted
					</Span>
					<Span bold>Status</Span>
					<Span bold className={styles.smallColumn}>
						Rank
					</Span>
					<Span bold className={styles.smallColumn}>
						Level
					</Span>
				</div>
				<div className={styles.listItems}>
					{data.length ? (
						data.slice((pageNumber - 1) * 20, Math.min(totalRows, pageNumber * 20)).map(renderItem)
					) : (
						<InputRow position="center" className={styles.emptyMsg}>
							<Span>No users found.</Span>
						</InputRow>
					)}
					{loading && <Loading position="center" className={styles.loader} />}
				</div>
			</div>
			<ListPageControl
				pageSize={20}
				pageNumber={pageNumber}
				total={totalRows}
				hasMore={pageNumber * 20 < totalRows || !!cursor}
				usersCount={totalUsers}
				onChange={onPageChange}
			/>
		</Card>
	);
};
