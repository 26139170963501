import {FC} from "react";
import classnames from "classnames";

import {Span} from "../../components/text";
import {EmptyComponent} from "../../types";

import styles from "./social-score.module.scss";

const colors = ["pink", "yellow", "blue", "blue", "green"];
const arr = Array(5).fill(0);

export const SocialScore: FC<
	{socialScore: number; potentialSocialScore?: number; suggestion?: string} & EmptyComponent
> = ({className, socialScore, potentialSocialScore, suggestion}) => {
	const block = Math.ceil((potentialSocialScore || socialScore) / 20);
	return (
		<div className={classnames(styles.module, className)}>
			<div className={classnames(styles.container, className)}>
				<Span>SocialScore™</Span>
				<div className={styles.blocks}>
					{arr.map((_, i) => (
						<div key={i} className={classnames(i < block && styles[colors[block - 1]])} />
					))}
				</div>
				<Span>{socialScore !== 100 ? socialScore.toFixed(2) : socialScore}</Span>
			</div>
			{suggestion && (
				<div className={classnames(styles.suggestion, styles[colors[block - 1]])}>
					<Span size={5}>{suggestion}</Span>
				</div>
			)}
		</div>
	);
};
