import {ReactElement, useCallback, useRef, useState} from "react";
import classnames from "classnames/bind";

import {Text, Button, InputRow, EmojiPickerInput, Separator, useValidate} from "../../components/input";
import {AITextPicker} from "../../components/input/ai-text-picker";
import {useMyUser} from "../../data";
import {HoverTooltip} from "../../components/tooltip";

import styles from "./caption-editor.module.scss";

const bStyles = classnames.bind(styles);

interface CaptionEditorProps {
	disabled: boolean;
	value: string;
	aiValue?: string;
	id?: string;
	onChange: (value: string) => void;
	updating: boolean;
	saveButtonLabel?: string;
	onSave: () => void;
	onCancel: () => void;
	onFocus?: () => void;
	onDelete?: () => void;
	editMode: boolean;
	required?: boolean;
	isDirty: boolean;
}

export const CaptionEditor = ({
	value,
	aiValue,
	onChange,
	onSave,
	saveButtonLabel = "Update",
	updating,
	onCancel,
	onFocus,
	onDelete,
	editMode,
	disabled,
	required,
	isDirty,
	id,
}: CaptionEditorProps): ReactElement => {
	const [optimiseForTwitter, setOptimiseForTwitter] = useState(false);
	const me = useMyUser();

	const ref = useRef<HTMLButtonElement>(null);

	const handleOptimizeForTwitter = useCallback(() => {
		setOptimiseForTwitter(prev => !prev);
	}, []);

	const characterLimit = optimiseForTwitter ? 255 : 3000;
	const {error} = useValidate(id ?? "", value, {
		immediate: true,
		check: v => ((v?.length ?? 0) <= characterLimit ? false : "Caption is too long"),
	});

	const isDisabled = (required && !value) || value.length > characterLimit || !isDirty;
	return (
		<div className={bStyles("editor", error ? styles.error : "")}>
			<Text
				id={id}
				type="textarea"
				minRows={3}
				onChange={onChange}
				value={value}
				onFocus={onFocus}
				disabled={disabled}
				autoFocus={editMode}
				hideInfo={!editMode}
				error={error ?? ""}
				bare
			/>

			{editMode && !disabled && (
				<div className={bStyles("actionsContainer")}>
					<InputRow>
						{me.org.options.ai && !disabled && (
							<AITextPicker
								value={value || aiValue || ""}
								label={"Caption Shuffle"}
								onChange={onChange}
								disabled={disabled}
								maxLength={characterLimit}
							/>
						)}
						<EmojiPickerInput value={value} onChange={onChange} />

						<div className={bStyles("actionsSeparator")}>
							<Separator />
						</div>

						<HoverTooltip text={"Optimize for X (255-character limit)"} positions={["top"]}>
							<Button
								icon="twitter"
								onClick={handleOptimizeForTwitter}
								color={optimiseForTwitter ? "blue" : "black"}
								border={false}
								invert
							/>
						</HoverTooltip>

						<div className={bStyles("actionsRight")}>
							{onDelete && (
								<HoverTooltip text={"Delete Caption"} positions={["top"]}>
									<Button icon="delete" onClick={onDelete} color="black" border={false} invert />
								</HoverTooltip>
							)}
						</div>
					</InputRow>
					<InputRow position="right" className={bStyles("actions")}>
						<Button color="blue" invert border={false} onClick={onCancel} value="Cancel" />
						<Button
							ref={ref}
							color="blue"
							onClick={onSave}
							value={saveButtonLabel}
							loading={updating}
							disabled={isDisabled}
						/>
					</InputRow>
				</div>
			)}
		</div>
	);
};
