import {useQuery} from "@apollo/client";

import {GET_AI_TEXT_INPUT_SESSION} from "../../../data/ai";

export const useAITextInputSession = () => {
	const {data: sessionData, loading} = useQuery(GET_AI_TEXT_INPUT_SESSION);

	return {
		sessionId: sessionData?.aiTextInputSession?.sessionId,
		apiUrl: sessionData?.aiTextInputSession?.apiUrl,
		loading,
	};
};
