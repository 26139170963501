import {Fragment, ReactElement, UIEvent, useMemo, useRef} from "react";
import {
	useQuery,
	OperationVariables,
	DocumentNode,
	TypedDocumentNode,
	QueryHookOptions,
	NetworkStatus,
} from "@apollo/client";
import classnames, {Argument} from "classnames";

import {Loading, LoadingProps} from "./components/loading";

function shallowEqual<T>(a: Record<string, T> | undefined, b: Record<string, T> | undefined): boolean {
	if (a === undefined || b === undefined) return a === b;
	for (const key in a) if (a[key] !== b[key]) return false;
	return true;
}

interface Page<T> {
	cursor?: string;
	items: T[];
}

export const defaultLoadingProps = {position: "center"} as const;

export interface PaginatedQueryResult<TData> {
	handleScroll: (e: UIEvent<HTMLElement>) => void;
	render: ReactElement;
	data: TData[];
	networkStatus: NetworkStatus;
	fetchMore: (options: OperationVariables) => void;
	cursor?: string;
	loading: boolean;
}

export interface PaginatedQueryHookOptions<TData, TVariables extends OperationVariables = OperationVariables>
	extends Omit<QueryHookOptions<Page<TData>, TVariables>, "onCompleted" | "defaultOptions"> {
	loadingProps?: LoadingProps;
	loading?: ReactElement;
	inflateItem?: (item) => TData;
	renderItem: (item: TData, idx: number, items: TData[]) => ReactElement;
	empty?: ReactElement;
	itemsClassName?: Argument;
	sortItems?: (a: TData, b: TData) => number;
}

export function usePaginatedQuery<TData, TVariables extends OperationVariables = OperationVariables>(
	query: DocumentNode | TypedDocumentNode<TData, TVariables>,
	{
		empty,
		inflateItem,
		itemsClassName,
		loadingProps,
		loading,
		renderItem,
		sortItems,
		variables,
		...options
	}: PaginatedQueryHookOptions<TData, TVariables>
): PaginatedQueryResult<TData> {
	const ret = useQuery<Page<TData>, TVariables>(query, {
		...options,
		variables,
		notifyOnNetworkStatusChange: true,
	});
	const ref = useRef(variables);

	return useMemo(() => {
		const {cursor, items} = ret.data?.[Object.keys(ret.data ?? {})?.[0]] ?? {};
		const changed = !shallowEqual(variables, ref.current);
		if (changed && !options.skip) {
			ref.current = variables;
			ret.refetch(({...variables, cursor: undefined} as unknown) as TVariables);
		}

		const handleScroll = (e: React.UIEvent<HTMLElement>) => {
			if (
				Math.ceil(e.currentTarget.scrollTop + e.currentTarget.clientHeight) + 10 <
				e.currentTarget.scrollHeight
			)
				return;
			if (ret.networkStatus === NetworkStatus.fetchMore) return;
			if (!cursor) return;
			ret.fetchMore({variables: {cursor}});
		};

		let data = items ?? [];
		if (inflateItem) data = data.map(inflateItem);
		if (sortItems) data = [...data].sort(sortItems);
		const render = (
			<>
				{[NetworkStatus.loading, NetworkStatus.setVariables, NetworkStatus.refetch].includes(
					ret.networkStatus
				) ? (
					loading ?? <Loading {...loadingProps} />
				) : data.length ? (
					itemsClassName ? (
						<div className={classnames(itemsClassName)}>{data.map(renderItem)}</div>
					) : (
						data.map((item, index, arr) => <Fragment key={index}>{renderItem(item, index, arr)}</Fragment>)
					)
				) : (
					empty
				)}
				{ret.networkStatus === NetworkStatus.fetchMore && (loading ?? <Loading {...loadingProps} />)}
			</>
		);

		return {...ret, data, handleScroll, render, fetchMore: ret.fetchMore, cursor};
	}, [
		empty,
		inflateItem,
		itemsClassName,
		loadingProps,
		renderItem,
		ret,
		variables,
		sortItems,
		options?.skip,
		loading,
	]);
}
