import React, {FC, useCallback, useEffect, useMemo, useState} from "react";
import classnames from "classnames";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {useSearchParam} from "react-use";
import {isMobile} from "react-device-detect";

import {AnalyticsBadge} from "../../components/analytics-badge";
import {Badge} from "../../components/badge";
import {Button, InputRow, SmallButton} from "../../components/input";
import {Icon} from "../../components/images";
import {UserAvatar, UserSpan} from "../../components/user-avatar";
import {P4, P, P2, Span5, Span4, Span2, Span} from "../../components/text";
import {useNewModal as useModal} from "../../modals";
import {NetworksModal} from "./networks-modal";
import {
	GET_USER_SHARE_EVENTS,
	GET_USER_EVENTS,
	SCHEDULE_SHARE_EVENT,
	SCHEDULE_QUEUE,
	useMyUser,
	ShareEvent,
	DELETE_SHARE_EVENT,
	UPDATE_SHARE_EVENT,
	Service,
	userAccountNames,
} from "../../data";
import {REGISTER_PAGE_EVENT} from "../../data/badges";
import {GET_LEADER_BOARD, GET_USER_BADGES, LEVEL_XP, getUserRank} from "../../data/user";
import {useMutationToast, useToast} from "../../toast";
import {nFormatter} from "../../format";
import {defaultLoadingProps as loadingProps, usePaginatedQuery} from "../../paginated-query";
import {Modal, ModalData} from "../../modals/new";
import {Card} from "../../components/card";
import {getShareStatusBadge} from "../collections";
import {Social} from "../../components/social";
import {renderMentions} from "../../utils/text";

import styles from "./feed.module.scss";

const getErrorCategory = (message = "") => {
	return "Error Sharing this Article";
	if (message.includes("duplicate")) {
		return "Duplicate Post";
	}

	if (message.includes("limit")) {
		return "Limit Reached";
	}

	if (message.includes("expired")) {
		return "Expired Token";
	}

	if (message.includes("unauthorized")) {
		return "Unauthorized";
	}

	if (message.includes("blocked")) {
		return "Posting blocked";
	}

	if (message.includes("locked")) {
		return "Account locked";
	}

	if (message.includes("policy")) {
		return "Policy violation";
	}

	if (message.includes("not found")) {
		return "Resource Not Found";
	}

	if (message.includes("invalid image")) {
		return "Invalid Image";
	}

	return "Error Sharing this Article";
};

const renderScheduledPost = (
	{id, share, scheduledFor, sharedAt, network, result, resultData}: ShareEvent,
	onItemClick: (id: number) => void,
	onRemove: (id: number) => void,
	loading?: boolean
) => {
	const opengraphData = share.opengraphs[network] || share.opengraphs.general;
	const failedPost = sharedAt && !result;
	const {errorMessage} = resultData || {};

	return (
		<div className={classnames("space", styles.scheduledPost, failedPost ? styles.failedPost : "")} key={id}>
			<InputRow>
				{opengraphData?.image ? (
					<img className="space" src={opengraphData.image} />
				) : (
					<img height={24} width={24} src="/svgs/textPost.svg" alt="textPost" className="space" />
				)}
				<P trim={3} bold className={styles.postComment}>
					{opengraphData?.title ?? renderMentions(opengraphData?.comment) ?? ""}
				</P>
			</InputRow>
			<InputRow position="between" className={styles.postDetails}>
				<Span5
					className={classnames(styles.shareTitle, "space")}
					title={opengraphData?.title}
					trim={1}
					onClick={e => e.stopPropagation()}
					href={share.url ?? undefined}
				>
					{share.url ? opengraphData?.title : ""}
				</Span5>
			</InputRow>
			<InputRow className={styles.postDetails}>
				<div className={styles.detailsGroup}>
					<Icon viewBox={"0 0 16 16"} width={16} height={16} icon={`filled-${network}`} />
					<P4 color="pink">{failedPost ? getErrorCategory(errorMessage) : dayjs(scheduledFor).formatAs()}</P4>
				</div>
				<div className={classnames(styles.detailsGroup, styles.buttons)}>
					<SmallButton
						color="black"
						value={failedPost ? "Resolve" : "Edit"}
						invert
						onClick={() => onItemClick(share.id)}
					/>
					<SmallButton color="black" value="Cancel" invert disabled={loading} onClick={() => onRemove(id)} />
				</div>
			</InputRow>
		</div>
	);
};

const FailedPostModal: FC<{modal: ModalData; shareEvent: ShareEvent}> = ({modal, shareEvent}) => {
	const [updateShareEvent, {loading: updating}] = useMutationToast(UPDATE_SHARE_EVENT);
	const [deleteShareEvent, {loading: deletingEvent}] = useMutationToast(DELETE_SHARE_EVENT);
	const toast = useToast();
	const onCancelEvent = useCallback(
		() => deleteShareEvent({variables: {id: shareEvent.id}}).then(() => modal.close()),
		[deleteShareEvent, shareEvent.id, modal]
	);
	const onRescheduleEvent = useCallback(() => {
		updateShareEvent({
			variables: {id: shareEvent.id, scheduledFor: dayjs().add(1, "minute")},
			onCompleted: () => {
				toast({text: "Share event scheduled", color: "green"});
				modal.close();
			},
		});
	}, [modal, shareEvent.id, updateShareEvent, toast]);

	const footer = useMemo(
		() => (
			<div className={styles.footer}>
				<Button
					onClick={onCancelEvent}
					value="Cancel"
					invert
					border={false}
					loading={deletingEvent}
					color={"black"}
				/>
				<Button onClick={onRescheduleEvent} value="Reschedule Share" color="blue" loading={updating} />
			</div>
		),
		[onCancelEvent, onRescheduleEvent, updating, deletingEvent]
	);
	const opengraph = shareEvent.share.opengraphs[shareEvent.network] ?? shareEvent.share.opengraphs.general;

	return (
		<Modal
			className={styles.failedPostModal}
			modal={modal}
			title="Uh, oh!"
			size="fit-content"
			footer={footer}
		>
			<div className={styles.content}>
				<Span>{"We couldn't post the following share:"}</Span>
				<div className={styles.container}>
					<Card className={[styles.card]}>
						<InputRow>
							<Badge {...getShareStatusBadge(shareEvent)} />
						</InputRow>
						<Span size={1} color="blue" trim={1}>
							{opengraph.title}
						</Span>
						<Span trim={2}>{opengraph?.description}</Span>
						<div className={styles.articleContainer}>
							{opengraph?.video ? (
								<video className={styles.video} controls muted>
									<source src={opengraph?.video} />
									<track kind="captions" srcLang="en" label="english_captions" />
								</video>
							) : opengraph?.image ? (
								<img src={opengraph?.image} alt={opengraph?.description} />
							) : (
								<img src="/default-image.png" alt="" />
							)}
						</div>
						<div className={styles.networkInfo}>
							<Social size="x-small" name={shareEvent.network.replace("_page", "") as Service} active />
							<Span4 color={"pink"}>
								{`Scheduled ${dayjs(shareEvent.sharedAt).formatAs("numDateAndTime")}`}
							</Span4>
						</div>
					</Card>
				</div>
				<div className={styles.error}>
					<Span color={"pink"}>{`There was an error sharing this content to ${
						userAccountNames[shareEvent.network]
					}. Please try again later.`}</Span>
				</div>
				<div className={styles.separator} />
			</div>
		</Modal>
	);
};

const empty = <P4 color="grey">You don’t have any upcoming scheduled posts.</P4>;
const variables = {sort: "SCHEDULED"};

const MyHubPanel = () => {
	const me = useMyUser();
	const toast = useToast();
	const [selectedFailedPost, setSelectedFailedPost] = useState<ShareEvent | null>(null);
	const {open: failedPostModalOpen, modal: failedPostModal} = useModal({});
	const {data: userBadges} = useQuery(GET_USER_BADGES);
	const {data} = useQuery(GET_LEADER_BOARD);
	const {modal, open} = useModal({});
	const navigate = useNavigate();
	const [deleteShareEvent, {loading: deletingEvent}] = useMutationToast(DELETE_SHARE_EVENT);
	const [registerPageEvent] = useMutationToast(REGISTER_PAGE_EVENT);
	const renderItem = useCallback(
		(post: ShareEvent) =>
			renderScheduledPost(
				post,
				shareId => navigate(`/collections/posts/${shareId}`, {state: {expanded: true}}),
				id => deleteShareEvent({variables: {id}}),
				deletingEvent
			),
		[navigate, deleteShareEvent, deletingEvent]
	);
	const {handleScroll, render, data: scheduledPosts} = usePaginatedQuery<ShareEvent>(GET_USER_SHARE_EVENTS, {
		loadingProps,
		variables,
		renderItem,
		empty,
	});

	const {data: failedPosts} = useQuery(GET_USER_SHARE_EVENTS, {
		variables: {
			status: ["FAILED"],
			sort: "SCHEDULED",
			limit: 100,
		},
	});
	const renderFailedPost = useCallback(
		(post: ShareEvent) =>
			renderScheduledPost(
				post,
				() => {
					setSelectedFailedPost(post);
					failedPostModalOpen();
				},
				id => deleteShareEvent({variables: {id}}),
				deletingEvent
			),
		[deleteShareEvent, deletingEvent, failedPostModalOpen]
	);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 768 || isMobile) {
				registerPageEvent({
					variables: {
						type: "viewmobile",
						userId: me?.id,
					},
				});
			}
		};

		window.addEventListener("resize", handleResize);
		handleResize();

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [registerPageEvent, me?.id]);

	const disaplayNetworksModal = useCallback(() => {
		const connections = Object.values({...me?.connections, __typename: null});
		return (
			connections.some(network => network?.connected && network.expired) ||
			connections.every(val => val === null)
		);
	}, [me?.connections]);

	const toBeSentPosts = useMemo(
		() =>
			scheduledPosts
				?.filter(share => !share.sharedAt && dayjs(share?.scheduledFor).isBefore())
				?.map(share => share.id) ?? [],
		[scheduledPosts]
	);
	const {stopPolling, startPolling} = useQuery(GET_USER_EVENTS, {
		variables: {ids: toBeSentPosts},
		skip: !toBeSentPosts?.length,
	});
	const [scheduleSharesByQueueUrlId] = useMutationToast(SCHEDULE_SHARE_EVENT);
	const [scheduleSharesByQueueId] = useMutationToast(SCHEDULE_QUEUE);
	const userLevel = useMemo(() => getUserRank(data?.leaderBoard?.experience), [
		data?.leaderBoard?.experience,
	]);
	const shareId = useSearchParam("shareId");
	const queueId = useSearchParam("queueId");
	const source = useSearchParam("source");

	const userLevelProgress = useMemo(() => {
		if (!data?.leaderBoard?.experience) return 0;
		if (userLevel === LEVEL_XP.length - 1) return 100;
		const progress =
			((data.leaderBoard.experience - LEVEL_XP[userLevel - 1]) /
				(LEVEL_XP[userLevel] - LEVEL_XP[userLevel - 1])) *
			100;
		if (progress <= 5) return 5;
		if (progress > 99) return 100;

		return progress;
	}, [userLevel, data?.leaderBoard?.experience]);
	const neededExp = useMemo(() => LEVEL_XP[userLevel] - data?.leaderBoard?.experience, [
		data?.leaderBoard?.experience,
		userLevel,
	]);

	const onCreatePost = useCallback(() => navigate("/collections/posts/new", {state: {type: "personal"}}), [
		navigate,
	]);

	useEffect(() => {
		if (source) {
			registerPageEvent({
				variables: {
					type: "bookmarklet",
					userId: me?.id,
				},
			});
		}
		if (shareId) {
			scheduleSharesByQueueUrlId({
				variables: {id: shareId, timezone: dayjs.tz.guess()},
				onCompleted: () => {
					toast({color: "green", text: "Your post has been scheduled!"});
				},
			}).then(() => {
				navigate({search: ""});
			});
		}
		if (queueId) {
			scheduleSharesByQueueId({
				variables: {id: queueId, timezone: dayjs.tz.guess()},
				onCompleted: () => {
					toast({color: "green", text: "Your posts have been scheduled!"});
				},
			}).then(() => {
				registerPageEvent({
					variables: {
						type: "shareall",
						userId: me?.id,
					},
				});
				navigate({search: ""});
			});
		}
	}, [
		scheduleSharesByQueueUrlId,
		scheduleSharesByQueueId,
		navigate,
		toast,
		shareId,
		queueId,
		source,
		registerPageEvent,
		me?.id,
	]);

	useEffect(() => {
		if (toBeSentPosts.length === 0) {
			return stopPolling();
		}

		startPolling(5000);
	}, [startPolling, stopPolling, toBeSentPosts]);

	useEffect(() => {
		if (disaplayNetworksModal()) {
			open();
		}
	}, [disaplayNetworksModal, open]);

	const failedPostsGroups = Object.values(
		failedPosts?.userShareEvents?.items
			?.filter(post => post.sharedAt && !post.result)
			?.slice(0, 3)
			?.reduce((acc, post) => {
				const date = dayjs(post.sharedAt).format("YYYY-mm-dd");
				if (!acc[date]) {
					acc[date] = [];
				}
				acc[date].push(post);

				return acc;
			}, {}) ?? {}
	) as ShareEvent[][];
	const failedPostsCount =
		failedPosts?.userShareEvents?.items?.filter(post => post.sharedAt && !post.result)?.length ?? 0;

	return (
		<div className={styles.panelContainer} onScroll={handleScroll}>
			<div className={classnames("space", styles.panel, styles.userPanel)}>
				<InputRow>
					<UserAvatar className="space" size="extraLarge" />
					<div className={classnames("space", styles.achievements)}>
						<InputRow position="between" className={styles.gap}>
							<UserSpan userId={me.id} size={2} />
							<div className={styles.badges}>
								{userBadges?.earnedBadges?.map((badge, index) => (
									<div key={badge.id} style={{zIndex: userBadges?.earnedBadges.length - index}}>
										<AnalyticsBadge size="nano" badge={badge} />
									</div>
								))}
							</div>
						</InputRow>
						<div className={styles.roleAndLevel}>
							{me?.role === "admin" && <Badge color="pink" text="Admin" />}
							<h6>Level {userLevel}</h6>
						</div>
					</div>
				</InputRow>
				<InputRow className={classnames(styles.stats, !me.org.options.leaderboard && styles.twoColumn)}>
					{me.org.options.leaderboard ? (
						<>
							<Span4 color="grey">Rank</Span4>
							<Span4 color="grey">Shares</Span4>
							<Span4 color="grey">Clicks</Span4>
							<Span4 color="grey">SocialScore</Span4>
							<Span2 bold>{data?.leaderBoard?.rank}</Span2>
							<Span2 bold>{data?.leaderBoard?.shares}</Span2>
							<Span2 bold>{data?.leaderBoard?.clicks}</Span2>
							<Span2 bold>{nFormatter(data?.leaderBoard?.smartScore, 1)}</Span2>
						</>
					) : (
						<>
							<Span4 color="grey">Shares</Span4>
							<Span4 color="grey">Clicks</Span4>
							<Span2 bold>{data?.leaderBoard?.shares}</Span2>
							<Span2 bold>{data?.leaderBoard?.clicks}</Span2>
						</>
					)}
				</InputRow>
				<div className="space">
					<P4>
						{userLevel === LEVEL_XP.length ? (
							<strong>Max Level reached</strong>
						) : (
							<>
								<strong>{neededExp} Points</strong> away from <strong>Level {userLevel + 1}</strong>
							</>
						)}
					</P4>
					<div className={styles.progressFill}>
						<div className={styles.progress} style={{width: `${userLevelProgress}%`}} />
					</div>
					<InputRow position="right">
						<P4>
							See how to{" "}
							<a
								href="https://cvssupport.wpenginepowered.com/article/badges-and-achievements-in-clearview-social"
								target="_blank"
								rel="noreferrer"
							>
								Earn more points
							</a>
						</P4>
					</InputRow>
				</div>
			</div>
			<div onClick={onCreatePost} className={classnames("space", styles.panel, styles.createPost)}>
				<P2>Create a Post</P2>
				<Icon icon="create-post" />
			</div>

			{!!failedPostsGroups?.length && (
				<div className={classnames("space", styles.panel, styles.scheduledPostsContainer)}>
					<InputRow position="between">
						<P2 color={"pink"} bold>{`Failed Posts (${
							failedPostsCount < 100 ? failedPostsCount : "100+"
						})`}</P2>
						<a
							className={styles.clickable}
							onClick={() => navigate(`/collections/posts`, {state: {filter: {status: ["failed"]}}})}
						>
							See all
						</a>
					</InputRow>
					{failedPostsGroups.map(group => (
						<>
							<Span className={styles.groupDate} bold>
								{dayjs(group[0].sharedAt).format("dddd, MM/D/YYYY")}
							</Span>
							{group.map(renderFailedPost)}
						</>
					))}
				</div>
			)}

			<div className={classnames("space", styles.panel, styles.scheduledPostsContainer)}>
				<InputRow position="between">
					<P2>Scheduled Posts</P2>
					<a href="/schedule">See Calendar</a>
				</InputRow>
				{render}
			</div>
			<NetworksModal modal={modal} connections={me?.connections} />
			{selectedFailedPost && <FailedPostModal modal={failedPostModal} shareEvent={selectedFailedPost} />}
		</div>
	);
};

export default MyHubPanel;
