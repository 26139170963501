import {ReactElement, forwardRef} from "react";
import classnames from "classnames";

import {Component} from "../../types";

import styles from "./card.module.scss";

export interface CardProps extends Component {
	title?: string;
	column?: boolean;
	onClick?: () => void;
	space?: boolean;
}

export const Card = forwardRef<HTMLDivElement, CardProps>(
	({children, column, className, onClick, title, space = true}: CardProps, ref): ReactElement => (
		<div
			ref={ref}
			className={classnames(
				styles.card,
				className,
				column && styles.column,
				onClick && styles.onClick,
				space && "space"
			)}
			onClick={onClick}
		>
			{title && <h5>{title}</h5>}
			{children}
		</div>
	)
);

Card.displayName = "Card";

export {Collapsible, CollapsibleCard} from "./collapsible";
export type {CollapsibleCardProps} from "./collapsible";
