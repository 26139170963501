import {FC, useMemo} from "react";
import classnames from "classnames";

import {Button} from "../button";
import {Icon} from "../../images";
import {InputRow} from "..";
import {useAITerms} from "./use-ai-terms";
import {Loading} from "../../loading";
import {AITerms} from "./ai-terms";
import {AIAssistantForm, AIAssistantFormProps} from "./ai-assistant-form";

import styles from "./ai-text-picker.module.scss";

export const AIAssistant: FC<AIAssistantFormProps> = props => {
	const {onClose} = props;
	const {data: termsAndConditionsAccepted, loading: termsAndConditionsLoading} = useAITerms();

	const content = useMemo(() => {
		if (termsAndConditionsLoading) {
			return (
				<div className={styles.loadingContainer}>
					<Loading />
				</div>
			);
		}

		if (!termsAndConditionsAccepted) {
			return <AITerms onClose={onClose} />;
		}

		return <AIAssistantForm {...props} />;
	}, [termsAndConditionsLoading, termsAndConditionsAccepted, props, onClose]);

	return (
		<div className={classnames(styles.aiAssistant)}>
			<div className={classnames(styles.header, "space")}>
				<InputRow className={styles.title}>
					<Icon height={24} width={24} icon="robot" />
					<h4>AI Assistant</h4>
				</InputRow>

				<Button
					className={styles.closeButton}
					invert={true}
					icon="close"
					iconSize="medium"
					color="black"
					onClick={onClose}
					border={false}
				/>
			</div>

			{content}
		</div>
	);
};
