import React, {useCallback, useEffect, useState} from "react";

import {BaseModalArgs} from "../index";
import {Text} from "../../components/input";
import {Collapsible} from "../../components/card";
import {P2, Span2, Span} from "../../components/text";
import {useToast} from "../../toast";
import {isURL} from "../../utils/text";
import {ConfirmModal, ModalData} from "../new";

import styles from "./referral-campaign.module.scss";
export interface ReferralCampaignModalArgs<T> extends BaseModalArgs<T> {
	url: string;
	onConfirm: (url: string) => void;
	modal: ModalData;
}

export const ReferralCampaignModal = <T,>({url, onConfirm, modal}: ReferralCampaignModalArgs<T>) => {
	const [currentUrl, setCurrentUrl] = useState(url);
	const reloadUrl = useCallback(() => setCurrentUrl(url), [url]);
	const toast = useToast();
	const handleSave = close => {
		if (!isURL(currentUrl)) {
			return toast({color: "red", text: "Please provide correct URL without any white-spaces."});
		}

		onConfirm(currentUrl.trim());
		return close();
	};

	useEffect(() => {
		if (modal.open) {
			reloadUrl();
		}
	}, [modal.open, reloadUrl]);

	return (
		<ConfirmModal
			confirmText="Continue"
			onConfirm={handleSave}
			title="Referral & Campaign Tracking"
			modal={modal}
		>
			<div className={styles.container}>
				<Text label="Add a URL" value={currentUrl} onChange={setCurrentUrl} />
				<Collapsible
					className={styles.referralCollapsibleButton}
					iconWithoutBorder={true}
					withoutHr={true}
					header={<Span2 color="pink">Referral Tracking Guide</Span2>}
				>
					<P2>
						Track which teammates referred traffic to your site from this post. Simply add ‘referrer=’ and a
						merge tag to the end of your URL (see example).{" "}
						<a
							target="_blank"
							rel="noreferrer"
							href="https://cvssupport.wpenginepowered.com/article/using-url-referral-tracking-to-ascribe-credit-for-conversions?utm_source=queue-builder&utm_medium=modal&utm_campaign=learn"
						>
							Learn More
						</a>
					</P2>
					<P2>Merge tags</P2>
					<ul className={styles.ul}>
						<li>
							<P2>CLEARVIEW_FNAME</P2>
						</li>
						<li>
							<P2>CLEARVIEW_LNAME</P2>
						</li>
						<li>
							<P2>CLEARVIEW_USER_EMAIL</P2>
						</li>
					</ul>

					<P2 color="grey">
						Example:
						<br />
						<Span>
							www.url.com/home<strong>?referrer=CLEARVIEW_USER_EMAIL</strong>
						</Span>
					</P2>
				</Collapsible>

				<Collapsible
					className={styles.referralCollapsibleButton}
					iconWithoutBorder
					withoutHr
					header={<Span2 color="pink">Google UTM Guide</Span2>}
				>
					<P2>
						Add &quot;utm_campaign=ClearView&quot; to your URL if you wish to attribute Clearview Social as a
						traffic source in Google Analytics.
					</P2>
					<P2>
						Add &quot;utm_medium=CLEARVIEW_SHARE_SERVICE&quot; to your URL if you’d like to track the
						referring social network (see example).
						<br />
						<a
							target="_blank"
							rel="noreferrer"
							href="https://cvssupport.wpenginepowered.com/article/using-url-referral-tracking-to-ascribe-credit-for-conversions"
						>
							Learn More
						</a>
					</P2>
					<P2 color="grey">
						Example:
						<br />
						<Span>
							www.url.com/home?<strong>utm_campaign=ClearView&utm_medium=CLEARVIEW_ SHARE_SERVICE</strong>
						</Span>
					</P2>
				</Collapsible>
			</div>
		</ConfirmModal>
	);
};
