import React, {ReactElement, useCallback} from "react";
import {Dayjs} from "dayjs";

import {Arrow} from "../../images";
import {InputComponent} from "../index";
import {Span} from "../../text";

import styles from "./navigator.module.scss";

interface MonthNavigatorProps extends InputComponent<Dayjs> {
	max?: Dayjs;
	min?: Dayjs;
}
export const MonthNavigator = ({value, min, max, onChange}: MonthNavigatorProps): ReactElement => {
	const isDisabled = useCallback(
		m =>
			(max && value.add(m, "months").isAfter(max.endOf("month"))) ||
			(min && value.add(m, "months").isBefore(min.startOf("month"))),
		[value, min, max]
	);
	const onNavigate = m => !isDisabled(m) && onChange(value.add(m, "months"));

	return (
		<div className={styles.container}>
			<Span bold>{value.format("MMMM YYYY")}</Span>
			<div className={styles.arrowsContainer}>
				<Arrow
					direction="left"
					color="blue"
					onClick={isDisabled(-1) ? undefined : () => onNavigate(-1)}
					className={[styles.arrow, isDisabled(-1) && styles.disabled]}
				/>
				<Arrow
					direction="right"
					color="blue"
					onClick={isDisabled(1) ? undefined : () => onNavigate(1)}
					className={[styles.arrow, isDisabled(1) && styles.disabled]}
				/>
			</div>
		</div>
	);
};
