import {ReactElement} from "react";
import classNames from "classnames";

import {EmptyComponent} from "../../types";
import {Card} from "../../components/card";
import {Icon} from "../../components/images";
import {Span1, Span3, Span4} from "../../components/text";
import {nFormatter} from "../../format";
import {Loading} from "../../components/loading";

import styles from "./card.module.scss";

interface BaseAnalyticsCardProps extends EmptyComponent {
	value: string | number;
	label: string;
	lastValue?: number;
	lastLabel?: string;
}

interface LastValueProps extends BaseAnalyticsCardProps {
	lastValue: number;
	lastLabel: string;
}

interface NoLastValueProps extends BaseAnalyticsCardProps {
	lastValue?: never;
	lastLabel?: never;
}

export type AnalyticsCardProps = LastValueProps | NoLastValueProps;

const calculatePercentage = (value: number, lastValue: number) => {
	if (value === 0 && lastValue === 0) return 0;
	const percentage = ((value - lastValue) / lastValue) * 100;
	return percentage % 1 === 0 ? Math.abs(percentage).toFixed(0) : Math.abs(percentage).toFixed(1);
};
const getGainDeclineValue = (value: number, lastValue: number) => {
	const diff = value - lastValue;
	return `${diff > 0 ? "+" : "-"}${nFormatter(Math.abs(diff), 2)}`;
};

export const AnalyticsCard = ({
	className,
	value,
	label,
	lastValue,
	lastLabel,
	showComparison = true,
	loading,
}: AnalyticsCardProps & {showComparison?: boolean; loading?: boolean}): ReactElement => {
	const formattedValue = nFormatter(value, 1);
	const isPositive = typeof value === "number" && typeof lastValue === "number" && value >= lastValue;

	return (
		<Card className={classNames(styles.analyticsCard, className)}>
			{!loading && (
				<div className={styles.titleContainer}>
					<h3>{typeof value === "string" ? value : formattedValue}</h3>
					<Span1>{label}</Span1>
				</div>
			)}

			{!loading && showComparison && typeof value === "number" && lastValue !== undefined && lastLabel && (
				<div className={styles.titleContainer}>
					<div className={styles.subTitleContainer}>
						<Icon
							className={isPositive ? styles.caretUp : ""}
							width={24}
							icon="caret-down"
							color={isPositive ? "blue" : "pink"}
						/>
						{
							<Span3 color={isPositive ? "blue" : "pink"} bold>{`${calculatePercentage(
								value,
								lastValue
							)}%`}</Span3>
						}
					</div>
					{Math.abs(lastValue - value) > 0 && <Span3>({getGainDeclineValue(value, lastValue)})</Span3>}
					<Span4>{lastLabel}</Span4>
				</div>
			)}
			{loading && <Loading position="center" className={styles.loader} />}
		</Card>
	);
};

export interface AnalyticsCardsProps extends EmptyComponent {
	cards: AnalyticsCardProps[];
	showComparison?: boolean;
	loading?: boolean;
}

export const AnalyticsCards = ({
	className,
	cards,
	showComparison,
	loading,
}: AnalyticsCardsProps): ReactElement => (
	<div className={classNames(styles.analyticsCards, className, "space")}>
		{cards.map((card, index) => (
			<AnalyticsCard key={index} {...card} showComparison={showComparison} loading={loading} />
		))}
	</div>
);
