import {gql} from "@apollo/client";

export interface TeamsChannel {
	channelId: string;
	url: string;
}

const TEAMS_CHANNEL_FRAGMENT = gql`
	fragment TeamsChannelFields on TeamsChannel {
		channelId
		url
	}
`;

export const GET_TEAMS_CHANNELS = gql`
	query TeamsChannels {
		teamsChannels {
			...TeamsChannelFields
		}
	}
	${TEAMS_CHANNEL_FRAGMENT}
`;

export const SET_TEAMS_CHANNELS = gql`
	mutation SetTeamsChannels($channels: [TeamsChannelInput!]!) {
		setTeamsChannels(channels: $channels) {
			...TeamsChannelFields
		}
	}
	${TEAMS_CHANNEL_FRAGMENT}
`;
