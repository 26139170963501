import {useNavigate} from "react-router-dom";

import {count} from "../../utils/text";
import {ModalHook, useModal} from "../../modals";
import {Button, InputRow} from "../../components/input";
import {Span2} from "../../components/text";
import {Icon} from "../../components/images";
import {Collection} from "../../data";

import styles from "./collection-modal.module.scss";

interface CollectionConfirmModalProps {
	collection: Collection;
}

export function useCollectionConfirmModal<T>({collection}: CollectionConfirmModalProps): ModalHook<T> {
	const navigate = useNavigate();

	return useModal(
		() => ({
			header: (
				<InputRow>
					<h3>You&apos;ve done it! The collection is now delivered!</h3>
					<Icon icon="send" className={styles.sendIcon} />
				</InputRow>
			),
			body: (
				<Span2 color="grey">
					{collection.title} has been sent to{" "}
					{count(collection?.to.groupIds.length + (collection?.to.slackChannels?.length ?? 0), "group")} and
					scheduled for {collection?.scheduledFor?.formatAs("longDateAndTime")}.
				</Span2>
			),
			footer: (
				<InputRow position="between">
					<Button
						value="Analytics"
						invert
						border={false}
						onClick={() => navigate("/analytics/collections")}
					/>
					<Button value="Back to Feed" onClick={() => navigate("/feed")} />
				</InputRow>
			),
		}),
		[collection, navigate]
	);
}
