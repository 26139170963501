import {FC, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import classnames from "classnames";

import {Card} from "../card";
import {Social} from "../social";
import {Icon} from "../images";
import {toggle} from "../input";
import {useToastApi} from "../../api";
import {Service, accountNames, useMyUser} from "../../data";
import {DateRange} from "../input/date-time";
import {DateRangeType, useCustomRange} from "../input/date-time/date-range";
import {formatMoney} from "../../utils/number";

import styles from "./company-card.module.scss";

interface Total {
	linkedin?: {total: number};
	facebook?: {total: number};
	twitter?: {total: number};
}

const minDate = dayjs().add(-10, "year").startOf("year");

export const CompanyCard: FC<{disabled?: boolean}> = ({disabled}) => {
	const {get} = useToastApi();
	const me = useMyUser();
	const navigate = useNavigate();
	const [customDate, setCustomDate] = useState<DateRangeType>("7D");
	const {start, end} = useCustomRange(customDate, minDate);
	const [enabled, setEnabled] = useState<Service[]>(["linkedin", "facebook", "twitter"]);
	const f = useMemo(() => `start=${start.unix()}&end=${end.unix()}&groups=[]`, [start, end]);

	const [clicks, setClicks] = useState<Total>({});
	const [shares, setShares] = useState<Total>({});
	const [emv, setEMV] = useState<Total>({});

	const clicksCount = enabled.reduce((total, n) => total + Number(clicks[n]?.total ?? 0), 0);
	const sharesCount = enabled.reduce((total, n) => total + Number(shares[n]?.total ?? 0), 0);
	const emvAmount = enabled.reduce((total, n) => total + Number(emv[n]?.total ?? 0), 0);

	useEffect(() => {
		get("clicks", `/analytics/clicks?${f}`, setClicks);
		get("shares", `/analytics/shares?${f}`, setShares);
		get("EMV data", `/analytics/emv?${f}`, setEMV);
	}, [get, f]);

	return (
		<Card className={styles.card}>
			<div className={styles.logo}>
				<img src={me.org.settings?.uiLogo ?? "/no_clogo.svg"} alt="Company Logo" />
				{me.org.features?.uiBranding && (
					<div
						className={classnames(styles.edit, disabled && styles.disabled)}
						onClick={() => !disabled && navigate("/settings/company/logo")}
					>
						{!disabled && <Icon icon="edit" height={12} />}
					</div>
				)}
			</div>
			<div className={styles.companyStats}>
				<div>
					<h4>Company Stats</h4>
					<small>
						{enabled
							.sort()
							.map(e => accountNames[e])
							.join(", ")}
					</small>
				</div>
				<div className={styles.socialNetworkIcons}>
					{(["linkedin", "facebook", "twitter"] as Service[]).map(n => (
						<Social
							name={n}
							key={n}
							active={enabled.includes(n)}
							size="small"
							onClick={() => setEnabled(c => toggle(n, c))}
						/>
					))}
				</div>
			</div>
			<DateRange
				min={minDate}
				max={dayjs().add(2, "day").startOf("day")}
				value={customDate}
				onChange={setCustomDate}
				className={styles.dateSelector}
				noToggle
			/>
			<div className={styles.analytics}>
				<div>
					<h4>Clicks</h4>
					<h4>{clicksCount}</h4>
				</div>
				<div>
					<h4>Shares</h4>
					<h4>{sharesCount}</h4>
				</div>
				<div>
					<h4>EMV</h4>
					<h4>{formatMoney(emvAmount)}</h4>
				</div>
			</div>
		</Card>
	);
};
