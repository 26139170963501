import React, {FC, useContext, useEffect, useState} from "react";
import classNames from "classnames";

import {Icon} from "../../../../components/images";
import {Span2, Span3} from "../../../../components/text";
import {ListCollapsedContext} from "./post-list";
import {PostProps} from "./post";
import {postValidationSchema} from "./validation-schema";

import styles from "./styles.module.scss";

export const CollapsedPost: FC<PostProps> = ({post, dragHandleProps}) => {
	const opengraph = post.opengraphs?.general || {};
	const {image, video, title, comment, description} = opengraph;
	const {collapsedPosts, setCollapsedPosts} = useContext(ListCollapsedContext);
	const [postErrors, setPostErrors] = useState(false);
	useEffect(() => {
		postValidationSchema.isValid(post).then(isValid => {
			setPostErrors(!isValid);
		});
	}, [post]);
	return (
		<div className={classNames(styles.collapsedPost, postErrors && styles.hasError)}>
			<div className={styles.dragZone} {...dragHandleProps}>
				<Icon icon="drag" color="black" height={16} className={styles.draggable} />
			</div>
			{(image || video) && (
				<div className={styles.media}>
					{image && <img src={image} alt="Post Image" style={{height: 60}} />}
					{!image && video && (
						<video src={video} controls={true} muted preload="metadata" style={{height: 60}}>
							<source src={video} />
							<track kind="captions" srcLang="en" label="english_captions" />
						</video>
					)}
				</div>
			)}
			<div className={styles.text}>
				<Span2 className={styles.title}>{title}</Span2>
				<Span3 className={styles.comment}>{comment || description}</Span3>
			</div>
			<div
				className={styles.collapseBtn}
				onClick={() => setCollapsedPosts(post.id, !collapsedPosts[post.id])}
			>
				<Icon icon="chevron-down" color="black" />
			</div>
		</div>
	);
};
