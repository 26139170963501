import {FC, useContext, useMemo} from "react";
import dayjs from "dayjs";
import {useQuery} from "@apollo/client";

import {InputRow, MultiColumn} from "../../../components/input";
import {AnalyticsCards} from "../card";
import {ChartCard, ChartLine} from "../chart-card";
import {
	GET_COMPANY_FACEBOOK_ANALYTICS,
	GET_COMPANY_FACEBOOK_ANALYTICS_CARDS,
	ShareEventAnalytics,
	loadAnalytics,
} from "../../../data/analytics";
import {CompanyAnalyticsContext} from ".";
import {useCustomRange} from "../../../components/input/date-time/date-interval-picker";
import {Loading} from "../../../components/loading";
import {PostStats, Stat} from "./post-stats";
import {Card} from "../../../components/card";
import {Icon} from "../../../components/images";
import {P2} from "../../../components/text";

import styles from "./company.module.scss";

const keys = ["followers", "likes"] as const;
type Keys = typeof keys[number];

const MIN_DATE = dayjs().subtract(13, "month");

const stats: Stat[] = [
	{label: "Comments", value: "comments"},
	{label: "Clicks", value: "clicks"},
	{label: "Engagement", value: "engagement"},
	{label: "Impressions", value: "impressions"},
	{label: "Reach", value: "reach"},
	{label: "Reactions", value: "reactions"},
];

export const Facebook: FC = () => {
	const {accounts, dateRange} = useContext(CompanyAnalyticsContext);
	const interval = useCustomRange(dateRange || "Max", MIN_DATE);
	const {start = dayjs(), end = dayjs()} = interval || {};

	const {data: rawData} = useQuery(GET_COMPANY_FACEBOOK_ANALYTICS, {
		variables: {ids: accounts.length ? accounts : undefined, start, end},
	});

	const {data: cardData, loading} = useQuery(GET_COMPANY_FACEBOOK_ANALYTICS_CARDS, {
		variables: {ids: accounts.length ? accounts : undefined, start, end},
	});

	const [labels, shareEvents, pageFollowers, pageLikes] = useMemo<
		[string[], ShareEventAnalytics[], ChartLine<string>[], ChartLine<string>[]]
	>(() => {
		const labels: string[] = [];
		const raw = rawData?.companyFacebookAnalytics ?? {};
		const data: Record<number, ShareEventAnalytics> = {};
		Object.keys(raw).forEach(id => (data[id] = loadAnalytics(raw[id])));
		const shareEvents = Object.keys(data).reduce(
			(acc, item) => [...acc, ...data[item].shareEvents],
			[] as ShareEventAnalytics[]
		);

		const aggregate = {} as Record<Keys, number[]>;
		const timezoneOffset = new Date().getTimezoneOffset();
		const systemStart = dayjs(start).utcOffset(timezoneOffset);
		const systemEnd = dayjs(end).utcOffset(timezoneOffset);

		let day = systemStart;
		let i = 0;
		keys.forEach(k => (aggregate[k] = []));

		while (day.isSameOrBefore(systemEnd, "day")) {
			labels.push(day.format("MM/DD"));
			keys.forEach(k => {
				const value = Object.keys(data).reduce((acc, c) => acc + (data[c][k][i] ?? 0), 0);
				aggregate[k].push(value);
			});
			day = day.add(1, "day");
			i++;
		}
		return [
			labels,
			shareEvents,
			[{label: "Page Followers", value: "followers", color: "blue-primary", data: aggregate.followers}],
			[{label: "Page Likes", value: "likes", color: "blue-primary", data: aggregate.likes}],
		];
	}, [rawData, start, end]);

	return (
		<div className={styles.container}>
			<Card>
				<InputRow>
					<Icon color="blue" icon="information" className="space" />
					<P2 bold={true}>
						Please note that certain Facebook metrics may not be available for accounts with fewer than 100
						followers. Additionally, data is stored for the previous 24 months.{" "}
						<a
							href="https://developers.facebook.com/docs/graph-api/reference/v19.0/insights"
							target="_blank"
							rel="noreferrer"
						>
							Learn More about Facebook analytics
						</a>
					</P2>
				</InputRow>
			</Card>
			{loading ? (
				<Loading />
			) : (
				<AnalyticsCards cards={cardData?.companyFacebookAnalyticsCards ?? []} showComparison={false} />
			)}
			<MultiColumn>
				<ChartCard
					labels={labels}
					title="Facebook Page Likes"
					data={pageLikes}
					allLabel="Likes"
					select={false}
				/>
				<ChartCard
					labels={labels}
					title="Facebook Page Followers"
					data={pageFollowers}
					allLabel="Followers"
					select={false}
				/>
			</MultiColumn>
			<PostStats shareEvents={shareEvents} account="facebook" stats={stats} loading={loading} />
		</div>
	);
};
