import {gql} from "@apollo/client";

export interface PopularPost {
	clicks: number;
	url: string;
	openGraph: OpenGraph;
	emv: number;
	shares: number;
	network: string;
}

export interface ShareStats {
	shareEvent: ShareEvent;
	views: number;
	reactions: number;
	comments: number;
	engagement: number;
	reposts: number;
	clicks: number;
}

import {OPENGRAPH_FRAGMENT, ShareEvent, loadShareEvent, OpenGraph} from ".";

export const SHARE_STATS_FRAGMENT = gql`
	fragment ShareStatsFields on CompanyLinkedInShareStats {
		shareEvent {
			id
			sharedAt
			share {
				id
				companyId
				url
				opengraphs {
					general {
						...OpenGraphFields
					}
				}
			}
		}
		views
		reactions
		comments
		engagement
		reposts
		clicks
	}
	${OPENGRAPH_FRAGMENT}
`;

export const GET_COMPANY_LINKEDIN_ANALYTICS = gql`
	query CompanyLinkedInAnalytics($ids: [PositiveInt!], $start: DateTime, $end: DateTime) {
		companyLinkedInAnalytics(ids: $ids, start: $start, end: $end) {
			companyId
			pageViews
			uniqueVisitors
			followers
			reactions
			clicks
			comments
		}
	}
`;

export const GET_COMPANY_LINKEDIN_SHARE_STATS = gql`
	query CompanyLinkedInShareStats(
		$accounts: [PositiveInt!]
		$start: DateTime
		$end: DateTime
		$limit: PositiveInt
		$cursor: String
	) {
		companyLinkedInShareStats(accounts: $accounts, start: $start, end: $end, limit: $limit, cursor: $cursor) {
			cursor
			items {
				...ShareStatsFields
			}
		}
	}
	${SHARE_STATS_FRAGMENT}
`;

export const GET_COMPANY_TWITTER_ANALYTICS = gql`
	query CompanyTwitterAnalytics($ids: [PositiveInt!], $start: DateTime, $end: DateTime) {
		companyTwitterAnalytics(ids: $ids, start: $start, end: $end) {
			companyId
			shareEvents {
				shareEvent {
					id
					sharedAt
					share {
						id
						companyId
						url
						opengraphs {
							general {
								...OpenGraphFields
							}
						}
					}
				}
				views
				likes
				comments
				shares
				retweets
			}
		}
	}
	${OPENGRAPH_FRAGMENT}
`;

export const ANALYTICS_CARDS_FRAGMENT = gql`
	fragment AnalyticsCardsFields on AnalyticsCard {
		label
		value
		lastValue
		lastLabel
	}
`;

export const LINKEDIN_ANALYTICS_CARDS_FRAGMENT = gql`
	fragment LinkedInAnalyticsCardsFields on LinkedInAnalyticsCards {
		totalEngagement
		totalClicks
		postViews
		pageViews
		newFollowers
	}
`;

export const COMPANY_POSTS_STATS_FRAGMENT = gql`
	fragment CompanyPostsStatsFields on CompanyPostsStats {
		clicks {
			facebook
			instagram
			linkedin
			twitter
		}
		shares {
			facebook
			instagram
			linkedin
			twitter
		}
		emv {
			facebook
			instagram
			linkedin
			twitter
		}
	}
`;

export const GET_COMPANY_LINKEDIN_ANALYTICS_CARDS = gql`
	query CompanyLinkedInAnalyticsCards($ids: [PositiveInt!], $start: DateTime, $end: DateTime) {
		companyLinkedInAnalyticsCards(ids: $ids, start: $start, end: $end) {
			...LinkedInAnalyticsCardsFields
		}
	}
	${LINKEDIN_ANALYTICS_CARDS_FRAGMENT}
`;

export const GET_COMPANY_FACEBOOK_ANALYTICS_CARDS = gql`
	query CompanyFacebookAnalyticsCards($ids: [PositiveInt!], $start: DateTime, $end: DateTime) {
		companyFacebookAnalyticsCards(ids: $ids, start: $start, end: $end) {
			...AnalyticsCardsFields
		}
	}
	${ANALYTICS_CARDS_FRAGMENT}
`;

export const GET_COMPANY_FACEBOOK_ANALYTICS = gql`
	query CompanyFacebookAnalytics($ids: [PositiveInt!], $start: DateTime, $end: DateTime) {
		companyFacebookAnalytics(ids: $ids, start: $start, end: $end) {
			companyId
			followers
			likes
			shareEvents {
				shareEvent {
					id
					sharedAt
					share {
						id
						companyId
						url
						opengraphs {
							general {
								...OpenGraphFields
							}
						}
					}
				}
				comments
				clicks
				engagement
				impressions
				reach
				reactions
			}
		}
	}
	${OPENGRAPH_FRAGMENT}
`;

export const GET_COMPANY_TWITTER_ANALYTICS_CARDS = gql`
	query CompanyTwitterAnalyticsCards($ids: [PositiveInt!]) {
		companyTwitterAnalyticsCards(ids: $ids) {
			...AnalyticsCardsFields
		}
	}
	${ANALYTICS_CARDS_FRAGMENT}
`;

export const GET_COMPANY_INSTAGRAM_ANALYTICS = gql`
	query CompanyInstagramAnalytics($ids: [PositiveInt!], $start: DateTime, $end: DateTime) {
		companyInstagramAnalytics(ids: $ids, start: $start, end: $end) {
			companyId
			followers
			views
			reach
			shareEvents {
				shareEvent {
					id
					sharedAt
					share {
						id
						companyId
						url
						opengraphs {
							general {
								...OpenGraphFields
							}
						}
					}
				}
				comments
				likes
				saved
				shares
			}
		}
	}
	${OPENGRAPH_FRAGMENT}
`;

export const GET_COMPANY_INSTAGRAM_ANALYTICS_CARDS = gql`
	query CompanyInstagramAnalyticsCards($ids: [PositiveInt!], $start: DateTime, $end: DateTime) {
		companyInstagramAnalyticsCards(ids: $ids, start: $start, end: $end) {
			...AnalyticsCardsFields
		}
	}
	${ANALYTICS_CARDS_FRAGMENT}
`;

export const GET_COMPANY_POSTS_STATS = gql`
	query CompanyPostsStats($ids: [PositiveInt!], $start: DateTime, $end: DateTime) {
		companyPostsStats(ids: $ids, start: $start, end: $end) {
			...CompanyPostsStatsFields
		}
	}
	${COMPANY_POSTS_STATS_FRAGMENT}
`;

const POPULAR_POST_FRAGMENT = gql`
	fragment PopularPostFields on PopularPost {
		clicks
		shares
		emv
		url
		network
		openGraph {
			...OpenGraphFields
		}
	}
	${OPENGRAPH_FRAGMENT}
`;

export const GET_POPULAR_POSTS = gql`
	query PopularPost(
		$start: DateTime!
		$end: DateTime!
		$network: String
		$accounts: [PositiveInt!]
		$allAccounts: Boolean
		$groups: [PositiveInt!]
		$limit: PositiveInt
		$cursor: String
	) {
		popularPosts(
			start: $start
			end: $end
			network: $network
			accounts: $accounts
			allAccounts: $allAccounts
			groups: $groups
			limit: $limit
			cursor: $cursor
		) {
			cursor
			items {
				...PopularPostFields
			}
		}
	}
	${POPULAR_POST_FRAGMENT}
`;

export type ShareEventAnalytics = Record<string, number> & {
	shareEvent: ShareEvent;
};

export const loadAnalytics = (analytics): ShareEventAnalytics => ({
	...analytics,
	shareEvents: analytics.shareEvents.map(se => ({...se, shareEvent: loadShareEvent(se.shareEvent)})),
});
