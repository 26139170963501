import {useQuery} from "@apollo/client";
import {useCallback, useMemo} from "react";
import {useFormikContext} from "formik";

import SearchableSelect from "../../../../components/input/searchable-select";
import {GroupedOptions, Option} from "../../../../components/input";
import {useComplexGroup} from "../../../../data/group";
import {GET_SLACK_CHANNELS, SlackChannel, useCompanies, useMyUser, useSimpleUsers} from "../../../../data";
import {GET_TEAMS_CHANNELS, TeamsChannel} from "../../../../data/teams";
import {CollectionFormValues} from "./collection-form";

import styles from "./styles.module.scss";

type RecipientType = "companyId" | "slackChannel" | "virtualAssistantId" | "group" | "teamsChannel";
type RecipientValue = `${RecipientType}-${string | number}`;

interface RecipientsProps {
	onChange: (field: string, value: CollectionFormValues["to"]) => void;
	disabled?: boolean;
}

export const Recipients = ({onChange, disabled}: RecipientsProps) => {
	const me = useMyUser();
	const groups = useComplexGroup();
	const corporateAccounts = useCompanies();
	const hasRbac = useMemo(() => me?.org?.features?.rbac, [me?.org?.features?.rbac]);
	const {users: allUsers} = useSimpleUsers({limit: null, filter: {virtualAssistant: true}});
	const {data: slack} = useQuery<{slackChannels?: SlackChannel[]}>(GET_SLACK_CHANNELS);
	const {data: teams} = useQuery<{teamsChannels?: TeamsChannel[]}>(GET_TEAMS_CHANNELS);
	const {values} = useFormikContext<CollectionFormValues>();
	const {to} = values;

	const sendableGroups = useMemo(() => {
		const all = groups.reduce((acc: number[], group) => {
			if (group.name === "Everyone" || (me.groups.includes(group.id) && group?.queueGroupIds?.length)) {
				return [...acc, ...group.queueGroupIds];
			}
			return acc;
		}, []);
		return Array.from(new Set(all));
	}, [groups, me.groups]);
	const sendableCompanyAccounts = useMemo(() => {
		const all = groups.reduce((acc: number[], group) => {
			if (me.groups.includes(group.id) && group?.queueCompanyIds?.length) {
				return [...acc, ...group.queueCompanyIds];
			}
			return acc;
		}, []);
		return Array.from(new Set(all));
	}, [groups, me.groups]);
	const selectOptions = useMemo((): GroupedOptions<RecipientValue>[] => {
		const teamsChannelOptions =
			teams?.teamsChannels?.map(s => ({
				value: `teamsChannel-${s.channelId}` as RecipientValue,
				label: s.channelId,
				icon: "ms-teams" as const,
			})) ?? [];

		const slackChannelOptions =
			slack?.slackChannels?.map(s => ({
				value: `slackChannel-${s.channelId}` as RecipientValue,
				label: s.channelName,
				icon: "slack" as const,
			})) ?? [];
		let groupOptions =
			groups
				?.filter(g => g.queueGroupIds)
				.map(g => ({
					label: g.name,
					value: `groupId-${g.id}` as RecipientValue,
					icon: "team" as const,
					isDisabled: hasRbac ? !sendableGroups.includes(g.id) : false,
				})) ?? [];
		const everyoneGroup = groupOptions.find(option => option.label === "Everyone");
		if (everyoneGroup) {
			groupOptions = [everyoneGroup, ...groupOptions.filter(option => option.label !== "Everyone")];
		}

		const virtualAssistantUsers =
			allUsers
				.filter(u => u.virtualAssistant)
				.map(u => ({
					value: `virtualAssistantId-${u.id}` as RecipientValue,
					label: `${u.fullName}'s VA`,
					icon: "person" as const,
				})) ?? [];
		const corporateAccountUsers =
			corporateAccounts?.map(company => ({
				value: `companyId-${company.id}` as RecipientValue,
				label: company.name,
				icon: "account" as const,
				isDisabled: hasRbac ? !sendableCompanyAccounts.includes(company.id) : false,
			})) ?? [];

		return [
			{
				label: "Groups",
				options: groupOptions,
			},
			{
				label: "Users` Virtual Assistants",
				options: virtualAssistantUsers,
			},
			{
				label: "Company Accounts",
				options: corporateAccountUsers,
			},
			{
				label: "Slack Channels",
				options: slackChannelOptions,
			},
			{
				label: "Teams Channels",
				options: teamsChannelOptions,
			},
		];
	}, [
		groups,
		corporateAccounts,
		allUsers,
		slack?.slackChannels,
		teams?.teamsChannels,
		sendableCompanyAccounts,
		sendableGroups,
		hasRbac,
	]);
	const getToFromChannel = useCallback(
		(channel: string, valueString: string, selectInd: number): Option<RecipientValue>[] =>
			selectOptions[selectInd].options.length
				? to?.[channel].map(item => {
						const value = selectOptions[selectInd].options.find(
							option => `${valueString}-${item}` === option.value
						);
						return value;
				  })
				: [],
		[to, selectOptions]
	);

	const selectValues = useMemo(
		(): Option<RecipientValue>[] => [
			...getToFromChannel("slackChannels", "slackChannel", 3),
			...getToFromChannel("groupIds", "groupId", 0),
			...getToFromChannel("virtualAssistantIds", "virtualAssistantId", 1),
			...getToFromChannel("companyIds", "companyId", 2),
			...getToFromChannel("teamsChannels", "teamsChannel", 4),
		],
		[getToFromChannel]
	);

	const handleChange = useCallback(
		values => {
			const to = {
				slackChannels: [] as string[],
				groupIds: [] as number[],
				virtualAssistantIds: [] as number[],
				companyIds: [] as number[],
				teamsChannels: [] as string[],
			};
			values
				.map(({value}) => value)
				.forEach(r => {
					const [type, value] = r.split("-", 2);
					to[`${type}s`].push(value);
				});
			onChange("to", to);
		},
		[onChange]
	);

	return (
		<SearchableSelect
			className={styles.recipients}
			onChange={handleChange}
			value={selectValues ?? []}
			isMulti
			options={selectOptions}
			bare
			disabled={disabled}
			placeholder="Enter group name, account name or Slack/Teams channel"
			label="To:"
		/>
	);
};
