import {ReactElement} from "react";
import classnames from "classnames";

import {EmptyComponent} from "../../types";
import {Span} from "../text";

import styles from "./empty-list.module.scss";

export interface EmptyListProps extends EmptyComponent {
	text: string;
}

export const EmptyList = ({className, text}: EmptyListProps): ReactElement => (
	<div className={classnames(styles.container, className)}>
		<Span color="grey">{text}</Span>
	</div>
);
