import {useCallback, useEffect, useMemo} from "react";
import {useFormikContext} from "formik";
import classnames from "classnames";
import {useNavigate} from "react-router-dom";

import {Button, InputRow} from "../../../../components/input";
import {useDebounceCallback} from "../../../../hooks/use-debounce-callback";
import {FormValues} from "./types";
import {PostEditor} from "./post-editor";
import {postServices, Service, Share} from "../../../../data";
import {Span, Span2} from "../../../../components/text";
import {SharePreview, SocialScore} from "../../components";
import {HoverTooltip} from "../../../../components/tooltip";

import styles from "./styles.module.scss";

const bStyles = classnames.bind(styles);

interface EditPostFormProps {
	share: Share;
	socialScore?: {
		value: number;
		potential?: number;
		suggestion?: string;
	};
}

export const EditPostForm = ({share, socialScore}: EditPostFormProps) => {
	const {submitForm, isValid, values, isSubmitting} = useFormikContext<FormValues>();
	const navigate = useNavigate();
	const debouncedSubmit = useDebounceCallback(submitForm, 2000);
	const onShareAgain = useCallback(
		() => navigate("/collections/posts/new", {state: {id: share?.id, type: "personal"}}),
		[navigate, share?.id]
	);

	const futureShares = useMemo(
		() =>
			share.shareEvents.filter(se => se.scheduledFor.isAfter() || (se.scheduledFor.isBefore() && !se.result)),
		[share.shareEvents]
	);

	const pastShares = useMemo(() => share.shareEvents.filter(se => se.scheduledFor.isBefore() && se.result), [
		share.shareEvents,
	]);

	useEffect(() => {
		debouncedSubmit();
	}, [values, debouncedSubmit]);

	const scSuggestion = useMemo(() => {
		const messages = Object.entries(share.smartScoreSuggestions)
			.filter(([key, suggestions]) => postServices.includes(key as Service) && !!suggestions)
			.flatMap(([, suggestions]) => suggestions.map(s => s.message));

		return (
			<>
				{Array.from(new Set(messages)).map((message, index) => (
					<p key={index}>{message}</p>
				))}
			</>
		);
	}, [share]);

	return (
		<div className={bStyles(styles.postForm, styles.editMode)}>
			<div className={styles.postBody}>
				{!!futureShares.length && (
					<div className={styles.sharesGroup}>
						<div className={styles.groupHeader}>
							<div>
								<div className={styles.titleContainer}>
									<Span className={styles.title}>Not shared yet</Span>
									<Span2>These social media posts have not been shared yet.</Span2>
								</div>
								{!!socialScore && (
									<div className={styles.socialScoreContainer}>
										<SocialScore
											socialScore={socialScore.value}
											potentialSocialScore={socialScore.potential}
										/>
									</div>
								)}
							</div>
							<HoverTooltip text={scSuggestion} positions={["top", "bottom"]}>
								<div className={styles.socialScoreSuggestion}>{socialScore?.suggestion}</div>
							</HoverTooltip>
						</div>
						{futureShares.map((se, index) => (
							<PostEditor key={`${se.id}-${index}`} shareEvent={se} totalShareEvents={futureShares.length} />
						))}
					</div>
				)}
				{!!futureShares.length && !!pastShares.length && <div className={styles.separator} />}
				{!!pastShares.length && (
					<div className={styles.sharesGroup}>
						<div className={styles.groupHeader}>
							<div className={styles.titleContainer}>
								<Span className={styles.title}>Shared</Span>
								<Span2>These social media posts were already shared.</Span2>
							</div>
							{!!socialScore && (
								<div className={styles.socialScoreContainer}>
									<SocialScore socialScore={socialScore.value} potentialSocialScore={socialScore.potential} />
								</div>
							)}
						</div>
						{pastShares.map((se, index) => (
							<SharePreview key={`${se.id}-${index}`} shareEvent={se} share={share} />
						))}
					</div>
				)}
			</div>
			<InputRow className={styles.formBottomToolbar}>
				<Button
					value={"Share Again"}
					invert
					onClick={onShareAgain}
					loading={isSubmitting}
					disabled={!isValid}
				/>
			</InputRow>
		</div>
	);
};
