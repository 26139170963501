import {ReactElement, useEffect} from "react";
import {useQuery} from "@apollo/client";

import {GET_ME, GET_MY_STATS} from "../data";
import config from "../config";

type AppcuesProviderProps = {
	children: ReactElement;
};

export const AppcuesProvider = ({children}: AppcuesProviderProps): ReactElement => {
	const {data} = useQuery(GET_ME);
	const {data: myStatsData} = useQuery(GET_MY_STATS);

	const user = data?.me;
	const myStats = myStatsData?.myStats;

	useEffect(() => {
		if (!user || !myStats || !globalThis.Appcues || !config.appcues.enabled) {
			return;
		}

		globalThis.Appcues.identify(user.id, {
			fullName: `${user.firstName} ${user.lastName}`,
			firstName: user.firstName,
			lastName: user.lastName,
			email: user.email,
			role: user.role,
			created: user.created,
			lastInvited: user.lastInvited,
			lastLogin: user.lastLogin,
			lastShared: user.lastShared,
			peakTime: user.peakTime,
			virtualAssistant: user.virtualAssistant,
			timeZone: user.timeZone,
			emailSubscribed: user.emailSubscribed,
			marketingSubscribed: user.marketingSubscribed,

			// Org properties
			companyName: user.org.name,
			orgId: user.org.id,
			subscription: user.org.subscription,

			// Connections
			facebookConnected: user.connections.facebook?.connected,
			facebookExpired: user.connections.facebook?.expired,

			facebookPagesConnected: user.connections.facebookPage?.connected,
			facebookPagesExpired: user.connections.facebookPage?.expired,

			instagramConnected: user.connections.instagram?.connected,
			instagramExpired: user.connections.instagram?.expired,

			linkedinConnected: user.connections.linkedin?.connected,
			linkedinExpired: user.connections.linkedin?.expired,

			xConnected: user.connections.twitter?.connected,
			xExpired: user.connections.twitter?.expired,

			// Stats
			contestsCount: myStats.contestsCount,

			// Integrations
			canvaConnected: user.integrations.canva,
		});

		globalThis.Appcues.group(user.org.id, {
			name: user.org.name,
			created: user.org.created,
			subscription: user.org.subscription,
			type: user.org.type,
			csmUserId: user.org.csmUserId,
			primaryAdmin: user.org.primaryAdmin,

			// Features
			emailBranding: user.org.features.emailBranding,
			facebookAnalytics: user.org.features.facebookAnalytics,
			instagramAnalytics: user.org.features.instagramAnalytics,
			rbac: user.org.features.rbac,
			salesforceLeadsSync: user.org.features.salesforceLeadsSync,
			sso: user.org.features.sso,
			uiBranding: user.org.features.uiBranding,
		});
	}, [user, myStats]);

	return children;
};
