import {useMemo} from "react";
import {useFormikContext} from "formik";

import {FormValues, OnChange} from "./types";
import {DropdownButton} from "../../../../components/input";

import styles from "./styles.module.scss";

interface SharingTimeProps {
	onChange: OnChange;
}

export const SharingTime = ({onChange}: SharingTimeProps) => {
	const {values} = useFormikContext<FormValues>();
	const {schedule} = values;
	const options = useMemo(
		() => [
			{
				label: "Immediately",
				onClick: () => onChange("schedule.immediately", true),
			},
			{
				label: "Schedule",
				onClick: () => onChange("schedule.immediately", false),
			},
		],
		[onChange]
	);

	return (
		<DropdownButton
			className={styles.sharingTime}
			value={`When to share: ${schedule.immediately ? "Immediately" : "Schedule"}`}
			options={options}
			icon="schedule"
			arrow={true}
			invert
			color="black"
		/>
	);
};
