import classNames from "classnames";
import dayjs, {Dayjs} from "dayjs";
import React, {ReactElement, ReactNode, useMemo, useState} from "react";

import {Button, Input, InputComponent, InputRow, useId} from "../index";
import {Icon} from "../../images";
import {Span} from "../../text";
import {ToggleGroup} from "../../toggle";
import {DateRangePicker} from "./date-picker-multiple";
import {AlerterWidget} from "../../alerter";
import {MonthNavigator} from "./month-navigator";

import styles from "./date-range.module.scss";

export type DateRangeType = "7D" | "1M" | "2M" | "3M" | "1Y" | "YTD" | "Max" | [Dayjs, Dayjs | undefined];
export const DateRangeOptions = [
	{value: "7D", label: "7D"},
	{value: "1M", label: "1M"},
	{value: "2M", label: "2M"},
	{value: "3M", label: "3M"},
	{value: "1Y", label: "1Y"},
	{value: "YTD", label: "YTD"},
	{value: "Max", label: "Max"},
] as const;

export const useCustomRange = (customDate: DateRangeType, min: Dayjs): {start: Dayjs; end: Dayjs} =>
	useMemo(() => {
		if (customDate === "Max") return {start: min.startOf("day"), end: dayjs().endOf("day")};
		const sod = dayjs().startOf("day");
		const end = dayjs().endOf("day");
		if (customDate === "7D") return {start: sod.add(-7, "day"), end};
		if (customDate === "1M") return {start: sod.add(-1, "month"), end};
		if (customDate === "2M") return {start: sod.add(-2, "month"), end};
		if (customDate === "3M") return {start: sod.add(-3, "month"), end};
		if (customDate === "1Y") return {start: sod.add(-1, "year"), end};
		if (customDate === "YTD") return {start: dayjs().startOf("year"), end};
		return {start: customDate[0], end: customDate[1] ?? customDate[0].endOf("day")};
	}, [customDate, min]);

export interface DateRangeComponent extends InputComponent<DateRangeType> {
	min: Dayjs;
	max?: Dayjs;
	noToggle?: boolean;
	maxLabel?: string;
}

export interface DateTimePickerComponent extends DateRangeComponent {
	labelComponent?: ReactNode;
	value: [Dayjs, Dayjs | undefined];
}

const DateTimePicker = ({
	id: maybeId,
	value,
	min,
	max,
	labelComponent,
	onChange,
	...props
}: DateTimePickerComponent): ReactElement => {
	const id = useId(maybeId);
	const [isInternalOpen, setIsInternalOpen] = useState(false);
	const [tempValue, setTempValue] = useState(value);
	const [displayMonth, setDisplayMonth] = useState(value[0].startOf("month"));

	const handleCancel = () => setIsInternalOpen(false);

	const handleSave = () => {
		if (!tempValue[1]) {
			tempValue[1] = tempValue[0].endOf("day");
		}
		onChange(tempValue);
		setIsInternalOpen(false);
	};

	const handleOnOpen = v => {
		setIsInternalOpen(v);
		setTempValue(value);
		setDisplayMonth(value[0].startOf("month"));
	};

	return (
		<Input id={id} {...props}>
			<AlerterWidget
				labelComponent={labelComponent}
				isOpen={isInternalOpen}
				onOpen={handleOnOpen}
				className={styles.rangeAlerter}
			>
				<div className={styles.datepicker}>
					<MonthNavigator value={displayMonth} onChange={setDisplayMonth} max={max} min={min} />
					<DateRangePicker
						value={tempValue}
						displayMonth={displayMonth}
						onChange={setTempValue}
						min={min}
						max={max}
					/>
					<InputRow className={styles.divider} position="between">
						<Button value="Cancel" invert onClick={handleCancel} />
						<Button value="Continue" onClick={handleSave} />
					</InputRow>
				</div>
			</AlerterWidget>
		</Input>
	);
};

export const DateRange = ({
	value,
	onChange,
	min,
	max,
	className,
	noToggle,
	id: maybeId,
	maxLabel,
	...props
}: DateRangeComponent): ReactElement => {
	const {start, end} = useCustomRange(value, min);
	const id = useId(maybeId);

	return (
		<Input id={id} {...props}>
			<InputRow>
				<DateTimePicker
					labelComponent={
						<div className={classNames(styles.customRangeContainer, className)}>
							<Icon icon="calendar" className={styles.icon} />
							<Span className={styles.text}>
								{value === "Max"
									? maxLabel ?? "Custom Range"
									: start.isSame(end, "day")
									? start.format("MMM D, YYYY")
									: `${start.format("MMM D, YYYY")} - ${end.format("MMM D, YYYY")}`}
							</Span>
						</div>
					}
					value={[start, end]}
					min={min}
					max={max}
					onChange={onChange}
				/>

				{!noToggle && <ToggleGroup compact options={DateRangeOptions} onChange={onChange} value={value} />}
			</InputRow>
		</Input>
	);
};
