import {useMemo, ReactElement, useState} from "react";
import classnames from "classnames";
import {Dayjs} from "dayjs";
import {useDrag} from "react-dnd";

import {count} from "../../utils/text";
import {useCompanyList} from "../../data";
import {CalendarItem, CalenderDisplayMode, sortDate} from ".";
import {Span4, Span5} from "../../components/text";
import {InputRow, SmallButton} from "../../components/input";
import {MoreButton} from "./more-button";
import {useEventModal} from "./event-modal";
import {Dot, Icon, IconType} from "../../components/images";

import styles from "./schedule.module.scss";

const CalendarDragItem = "calendar-item";

interface CalendarItemsProps {
	data: CalendarItem[];
	day: Dayjs;
	hour: number;
	mode: CalenderDisplayMode;
	setDayView?: (date: Dayjs) => void;
}

interface EventListProps {
	data: CalendarItem;
}

interface CalendarEventItemProps {
	item: CalendarItem;
	itemIndex: number;
	limit: number;
	stack: CalendarItem[];
	day: Dayjs;
	eventHandler: (data: CalendarItem) => void;
	getCardMargin: (item: CalendarItem) => number;
	setDayView?: (date: Dayjs) => void;
}

const CalendarEventItem = ({
	item,
	day,
	itemIndex,
	limit,
	stack,
	eventHandler,
	getCardMargin,
	setDayView,
}: CalendarEventItemProps): ReactElement => {
	const [, drag] = useDrag(
		() => ({
			type: CalendarDragItem,
			item,
			collect: monitor => monitor.isDragging(),
			canDrag: item.date?.isAfter(new Date()),
		}),
		[item]
	);
	return (
		<div
			ref={drag}
			className={classnames(styles.item, styles[item.type])}
			key={`${item.type}-${item.id}`}
			onClick={() => eventHandler(item)}
			style={{top: getCardMargin(item)}}
		>
			{item.date?.isAfter(new Date()) && (
				<Icon icon="drag" height={16} color="grey" className={[styles.draggable]} />
			)}
			<EventList data={item} />
			{itemIndex === limit - 1 && stack.length > limit && (
				<div className={styles.more}>
					{setDayView ? (
						<SmallButton
							color="black"
							value={`+${stack.length - limit} more`}
							onClick={() => setDayView(day)}
							border={false}
							invert
						/>
					) : (
						<MoreButton color="black" value={`+${stack.length - limit} more`} data={stack} />
					)}
				</div>
			)}
		</div>
	);
};

export const EventList = ({data}: EventListProps): ReactElement => {
	const companies = useCompanyList();
	const company = companies.find(c => c.value === data.companyId)?.label;
	return (
		<>
			{data.posts === undefined && (
				<InputRow position="between">
					<Icon icon={data.network as IconType} width={14} />
					<Span5 color="grey">{data.date.formatAs("time")}</Span5>
				</InputRow>
			)}
			<Span4 bold trim={2}>
				{data.opengraph?.title || "Untitled"}
			</Span4>
			<InputRow>
				{data.posts !== undefined && (
					<>
						<Span5 color="grey">{data.date.formatAs("time")}</Span5>
						<InputRow>
							<Dot className={styles.dot} height={2} color="grey" />
							<Span5 color="grey">{count(data.posts, "Post")}</Span5>
						</InputRow>
					</>
				)}
				{company && (
					<InputRow>
						<Dot className={styles.dot} height={2} color="grey" />
						<Span5 color="grey">{company}</Span5>
					</InputRow>
				)}
			</InputRow>
		</>
	);
};

export const CalendarItems = ({data, day, hour, mode, setDayView}: CalendarItemsProps): ReactElement => {
	const [itemData, setItemData] = useState<CalendarItem>();

	const stacks = useMemo(() => {
		const ret: CalendarItem[][] = [];

		data.sort(sortDate).forEach(currentEvent => {
			if (ret.length) {
				const lastStack = ret[ret.length - 1];
				const lastEvent = lastStack[lastStack.length - 1];
				if (currentEvent.date.isBefore(lastEvent.date.add(1, "hour"))) {
					lastStack.push(currentEvent);
					return;
				}
			}
			ret.push([currentEvent]);
		});
		return ret;
	}, [data]);

	const limit = mode === "week" ? 2 : 10;

	const stackInHour = (stack: CalendarItem[]): boolean => {
		const start = day.hour(hour).startOf("hour");
		const end = start && start.hour(hour + 1);

		return stack[0].date.isBetween(start, end, null, "[)") ?? false;
	};

	const {open: openEventModal} = useEventModal({calendarItem: itemData});

	const eventHandler = (data: CalendarItem) => {
		setItemData(data);
		openEventModal();
	};

	const getCardMargin = (item: CalendarItem) => (item.date.hour() - hour) * 80 + item.date.minute() * 1.3;

	return (
		<>
			{stacks
				.filter(item => stackInHour(item))
				.map((stack, stackIndex) => (
					<div key={stackIndex} className={styles.events}>
						{stack.slice(0, limit).map((item, itemIndex) => (
							<CalendarEventItem
								item={item}
								day={day}
								itemIndex={itemIndex}
								limit={limit}
								stack={stack}
								eventHandler={eventHandler}
								getCardMargin={getCardMargin}
								setDayView={setDayView}
								key={itemIndex}
							/>
						))}
					</div>
				))}
		</>
	);
};
