import React, {useMemo, useState, useCallback, FC} from "react";
import {NetworkStatus, useQuery} from "@apollo/client";
import dayjs from "dayjs";
import classnames from "classnames";

import {EmptyList} from "../../../components/empty-list";
import {Button, InputRow, Text} from "../../../components/input";
import {P2, Span1, Span2, Span3, Span4} from "../../../components/text";
import {OpengraphMedia} from "../../../components/opengraph";
import {Icon} from "../../../components/images";
import {GET_COMPANY_LINKEDIN_SHARE_STATS, ShareStats} from "../../../data/analytics";
import {Modal, ModalData} from "../../../modals/new";
import {formatDateInterval, useCustomRange} from "../../../components/input/date-time/date-interval-picker";
import {CompanyAnalyticsFilter, minDate} from "./index";
import {useDebounceCallback} from "../../../hooks/use-debounce-callback";
import {Loading} from "../../../components/loading";
import {useMutationToast, useToast} from "../../../toast";
import {SEND_EMAIL_CSV} from "../../../data/user";

import styles from "./linkedin-posts-modal.module.scss";

const normalizeApostrophes = text => text.replace(/’/g, "'");

interface PopularPostsArgs {
	filter: CompanyAnalyticsFilter;
	modal: ModalData;
}

export interface Stat {
	value: string;
	format?: (v: number) => string;
	label: string;
}
const stats: Stat[] = [
	{
		label: "Views",
		value: "views",
	},
	{
		label: "Clicks",
		value: "clicks",
	},
	{
		label: "Reactions",
		value: "reactions",
	},
	{
		label: "Comments",
		value: "comments",
	},
	{
		label: "Reposts",
		value: "reposts",
	},
	{
		label: "Eng. Rate",
		format: v => `${Math.round(v * 100)}%`,
		value: "engagement",
	},
];

const renderItem = (post: ShareStats, index: number, compactMode?: boolean) => {
	const opengraph = post.shareEvent?.share?.opengraphs?.general || {};

	return (
		<div key={`${index}-${post.shareEvent.id}`} className={styles.post}>
			<div className={styles.infoContainer}>
				{opengraph.image || opengraph.video ? (
					<OpengraphMedia
						className={styles.image}
						openGraph={opengraph}
						width={undefined}
						height={undefined}
					/>
				) : (
					<div className={styles.image}>
						<img src="/default-image.png" alt="" />
					</div>
				)}
				<div className={styles.text}>
					<div className={styles.titleCt}>
						{!compactMode && (
							<a
								href={`${window?.location?.origin}/collections/posts/${post.shareEvent?.share?.id}`}
								rel="noopener noreferrer"
								target="_blank"
							>
								<Icon width={16} height={16} viewBox={"0 0 16 16"} icon={`filled-linkedin`} />
							</a>
						)}
						<P2
							className={styles.title}
							color="black"
							title={opengraph.title}
							bold
							href={post.shareEvent.share.url || ""}
							trim={1}
						>
							{opengraph.title}
						</P2>
					</div>
					{(opengraph.comment || opengraph.description) && (
						<Span4 className={styles.description} title={opengraph.comment || opengraph.description} trim={2}>
							{opengraph.comment || opengraph.description}
						</Span4>
					)}
				</div>
			</div>
			<div className={styles.statsContainer}>
				{compactMode && (
					<a
						href={`${window?.location?.origin}/collections/posts/${post.shareEvent?.share?.id}`}
						rel="noopener noreferrer"
						target="_blank"
					>
						<Icon width={16} height={16} viewBox={"0 0 16 16"} icon={`filled-linkedin`} />
					</a>
				)}
				{stats.map(({value, format, label}) => (
					<div key={`${label}`} className={styles.postStats}>
						<Span2 bold>{format ? format(post[value]) : post[value]}</Span2>
						<Span3 color="grey">{label}</Span3>
					</div>
				))}
			</div>
		</div>
	);
};

export const LinkedinPostsModal: FC<PopularPostsArgs> = ({filter, modal}) => {
	const [search, setSearch] = useState("");
	const [csvEmail, {loading: exporting}] = useMutationToast(SEND_EMAIL_CSV);
	const toast = useToast();
	const dateRange = useCustomRange(filter.dateRange || "Max", minDate);
	const {start = dayjs(), end = dayjs()} = dateRange || {};
	const comparisonInterval = useCustomRange(filter.comparisonDateRange, minDate);
	const {start: comparisonStart, end: comparisonEnd} = comparisonInterval || {};
	const variables = useMemo(
		() => ({
			start,
			end,
			accounts: filter.accounts.length ? filter.accounts : undefined,
			limit: 20,
		}),
		[filter, start, end]
	);
	const comparisonQueryVariables = useMemo(
		() => ({
			start: comparisonStart,
			end: comparisonEnd,
			accounts: filter.accounts.length ? filter.accounts : undefined,
			limit: 20,
		}),
		[filter, comparisonStart, comparisonEnd]
	);
	const Empty = useMemo(() => <EmptyList text={search ? "No matches found" : "No items"} />, [search]);

	const {data, fetchMore, networkStatus} = useQuery(GET_COMPANY_LINKEDIN_SHARE_STATS, {
		variables,
		skip: !modal.open,
		notifyOnNetworkStatusChange: true,
	});

	const {
		data: comparisonData,
		fetchMore: fetchMoreComparisonData,
		networkStatus: comparisonQueryNetworkStatus,
	} = useQuery(GET_COMPANY_LINKEDIN_SHARE_STATS, {
		variables: comparisonQueryVariables,
		skip: !modal.open || !comparisonInterval,
		notifyOnNetworkStatusChange: true,
	});

	const posts = useMemo(() => {
		const searchTrimmed = normalizeApostrophes(search.trim());
		const searchLC = searchTrimmed.toLowerCase();

		return data?.companyLinkedInShareStats?.items.filter(
			posts =>
				!searchTrimmed ||
				normalizeApostrophes(
					posts?.shareEvent?.share.opengraphs?.general?.title?.toLowerCase() || ""
				).includes(searchLC) ||
				normalizeApostrophes(
					posts?.shareEvent?.share.opengraphs?.general?.description?.toLowerCase() || ""
				).includes(searchLC)
		);
	}, [data?.companyLinkedInShareStats, search]);

	const comparisonPosts = useMemo(() => {
		const searchTrimmed = normalizeApostrophes(search.trim());
		const searchLC = searchTrimmed.toLowerCase();

		return comparisonData?.companyLinkedInShareStats?.items.filter(
			posts =>
				!searchTrimmed ||
				normalizeApostrophes(
					posts?.shareEvent?.share.opengraphs?.general?.title?.toLowerCase() || ""
				).includes(searchLC) ||
				normalizeApostrophes(
					posts?.shareEvent?.share.opengraphs?.general?.description?.toLowerCase() || ""
				).includes(searchLC)
		);
	}, [comparisonData?.companyLinkedInShareStats, search]);

	const handleScroll = useCallback(
		(e: React.UIEvent<HTMLElement>) => {
			const target = e.currentTarget || e.target;
			if (Math.ceil(target.scrollTop + target.clientHeight) + 10 < target.scrollHeight) return;
			if (networkStatus === NetworkStatus.fetchMore) return;

			const cursor = data?.companyLinkedInShareStats?.cursor;

			if (!cursor) return;

			fetchMore({
				variables: {cursor},
				updateQuery: (prev, {fetchMoreResult}) => {
					if (!fetchMoreResult) return prev;
					return Object.assign({}, prev, {
						companyLinkedInShareStats: {
							...prev.companyLinkedInShareStats,
							...fetchMoreResult.companyLinkedInShareStats,
							items: [
								...prev.companyLinkedInShareStats.items,
								...fetchMoreResult.companyLinkedInShareStats.items,
							],
						},
					});
				},
			});
		},
		[data?.companyLinkedInShareStats?.cursor, networkStatus, fetchMore]
	);
	const handleComparisonListScroll = useCallback(
		(e: React.UIEvent<HTMLElement>) => {
			const target = e.currentTarget || e.target;
			if (Math.ceil(target.scrollTop + target.clientHeight) < target.scrollHeight) return;
			if (comparisonQueryNetworkStatus === NetworkStatus.fetchMore) return;

			const cursor = comparisonData?.companyLinkedInShareStats?.cursor;

			if (!cursor) return;

			fetchMoreComparisonData({
				variables: {cursor},
				updateQuery: (prev, {fetchMoreResult}) => {
					if (!fetchMoreResult) return prev;
					return Object.assign({}, prev, {
						companyLinkedInShareStats: {
							...prev.companyLinkedInShareStats,
							...fetchMoreResult.companyLinkedInShareStats,
							items: [
								...prev.companyLinkedInShareStats.items,
								...fetchMoreResult.companyLinkedInShareStats.items,
							],
						},
					});
				},
			});
		},
		[comparisonData?.companyLinkedInShareStats?.cursor, comparisonQueryNetworkStatus, fetchMoreComparisonData]
	);

	const handleScrollDebounced = useDebounceCallback(handleScroll, 100);

	const handleExport = useCallback(
		() =>
			Promise.all([
				csvEmail({
					variables: {
						startDate: start,
						endDate: end,
						type: "LINKEDIN_SHARES",
						companies: filter.accounts.length ? filter.accounts : [],
					},
				}),
				...(comparisonStart && comparisonEnd
					? [
							csvEmail({
								variables: {
									startDate: comparisonStart,
									endDate: comparisonEnd,
									type: "LINKEDIN_SHARES",
									companies: filter.accounts.length ? filter.accounts : [],
								},
							}),
					  ]
					: []),
			]).then(() => {
				toast({
					color: "blue",
					text: "Exporting your results into a csv file. It should arrive in your inbox in a few minutes.",
				});
			}),
		[csvEmail, toast, filter.accounts, end, start, comparisonStart, comparisonEnd]
	);

	if (!modal.open) return null;

	return (
		<Modal
			modal={modal}
			title="LinkedIn Post Breakdown"
			size="fit-content"
			className={styles.popularPostsModal}
		>
			<div className={classnames(styles.content, comparisonInterval ? styles.comparisonMode : "")}>
				<InputRow className={styles.toolbar}>
					<Text icon="search" value={search} onChange={setSearch} placeholder="Search" />
					<Button onClick={handleExport} value="Export CSV" loading={exporting} />
				</InputRow>
				<div className={styles.postsContainer}>
					<div className={styles.postsList} onScroll={handleScrollDebounced}>
						{comparisonInterval && (
							<Span1 className={styles.listHeader} bold>
								{formatDateInterval(start, end)}
							</Span1>
						)}
						{[NetworkStatus.loading, NetworkStatus.setVariables, NetworkStatus.refetch].includes(
							networkStatus
						) ? (
							<Loading className={styles.loader} />
						) : posts?.length ? (
							posts.map((item, index) => renderItem(item, index, !!comparisonInterval))
						) : (
							Empty
						)}
						{networkStatus === NetworkStatus.fetchMore && <Loading className={styles.loader} />}
					</div>
					{comparisonInterval && (
						<div className={styles.postsList} onScroll={handleComparisonListScroll}>
							<Span1 className={styles.listHeader} bold>
								{formatDateInterval(comparisonStart, comparisonEnd)}
							</Span1>
							{[NetworkStatus.loading, NetworkStatus.setVariables, NetworkStatus.refetch].includes(
								comparisonQueryNetworkStatus
							) ? (
								<Loading className={styles.loader} />
							) : comparisonPosts?.length ? (
								comparisonPosts.map((item, index) => renderItem(item, index, true))
							) : (
								Empty
							)}
							{comparisonQueryNetworkStatus === NetworkStatus.fetchMore && (
								<Loading className={styles.loader} />
							)}
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
};
