import {ReactElement, useEffect, useRef, useState} from "react";
import {Dayjs} from "dayjs";
import {useDrop} from "react-dnd";
import {FetchResult} from "@apollo/client";

import {Card} from "../../components/card";
import {CalendarItem, CalendarProps} from ".";
import {Span4} from "../../components/text";
import {CalendarItems} from "./calendar-items";
import {scrollTo9Am} from "./calendar-week";
import {Loading} from "../../components/loading";

import styles from "./schedule.module.scss";

interface CalendarDayHourProps {
	hour: number;
	data: CalendarItem[];
	selectedDay: Dayjs;
	updateItem: (item: CalendarItem) => Promise<FetchResult>;
}

const CalendarDragItem = "calendar-item";

export const CalendarDay = ({
	selectedDay,
	data,
	updateItem,
}: Omit<CalendarProps, "setDayView">): ReactElement => {
	const hours: number[] = Array.from({length: 24}, (_, i) => i);
	const containerRef = useRef<HTMLDivElement>(null);
	const filteredData = data.filter(item => item.date?.date() === selectedDay.date());

	useEffect(() => {
		scrollTo9Am(containerRef);
	}, [containerRef]);

	return (
		<Card className={styles.dailyCalendar}>
			<div className={styles.header}>
				<h1>{selectedDay.format("DD")}</h1>
				<Span4 color="grey">{selectedDay.format("ddd")}</Span4>
			</div>

			<div ref={containerRef}>
				{hours.map(hour => (
					<CalendarDayHour
						key={hour}
						selectedDay={selectedDay}
						hour={hour}
						data={filteredData}
						updateItem={updateItem}
					/>
				))}
			</div>
		</Card>
	);
};

const CalendarDayHour = ({hour, data, selectedDay, updateItem}: CalendarDayHourProps): ReactElement => {
	const [loadingItem, setLoadingItem] = useState(false);
	const [over, drop] = useDrop(
		() => ({
			accept: CalendarDragItem,
			drop: (item: CalendarItem) => {
				if (selectedDay.set("hour", hour).isAfter(new Date())) {
					setLoadingItem(true);
					updateItem({...item, date: item.date?.set("hour", hour).set("minute", 0)}).finally(() =>
						setLoadingItem(false)
					);
				}
			},
			collect: monitor => monitor.canDrop() && monitor.isOver(),
			canDrop: (item: CalendarItem) => item.date?.isAfter(new Date()),
		}),
		[hour, selectedDay, updateItem]
	);
	return (
		<div key={hour} className={styles.timeRange} ref={node => drop(node)}>
			{loadingItem && <Loading position="absolute" />}
			<Span4 color="grey">{selectedDay.hour(hour).format("h A")}</Span4>
			<div className={styles.line}>
				{over && <div className={styles.over} />}
				<CalendarItems data={data} day={selectedDay} hour={hour} mode="day" />
			</div>
		</div>
	);
};
