import React, {useCallback, useEffect, useState} from "react";

import {useNewModal} from "../../modals";
import {Modal} from "../../modals/new";
import {Button, Select} from "../../components/input";
import {Icon} from "../../components/images";

import styles from "./peaktime.module.scss";

export type hourValueDay = {
	0: number;
	1: number;
	2: number;
	3: number;
	4: number;
	5: number;
	6: number;
	7: number;
	8: number;
	9: number;
	10: number;
	11: number;
	12: number;
	13: number;
	14: number;
	15: number;
	16: number;
	17: number;
	18: number;
	19: number;
	20: number;
	21: number;
	22: number;
	23: number;
};
export type hourValue = {
	default: hourValueDay;
	Monday?: hourValueDay;
	Tuesday?: hourValueDay;
	Wednesday?: hourValueDay;
	Thursday?: hourValueDay;
	Friday?: hourValueDay;
	Saturday?: hourValueDay;
	Sunday?: hourValueDay;
};

interface HourValuesProps {
	hoursValue: hourValue;
	setHoursValue: React.Dispatch<React.SetStateAction<hourValue>>;
}

const EditHourValues = ({
	hourValueDay: hourValueDay,
	onChange,
}: {
	hourValueDay: hourValueDay;
	onChange: (value: string, key: string) => void;
}) => (
	<>
		{Object.keys(hourValueDay).map((key, index) => (
			<div key={index} className={styles.hourEdit}>
				<span>{key}</span>
				<input
					type="number"
					max={100}
					min={1}
					value={hourValueDay[key]}
					onChange={e => {
						onChange(e.target.value, key);
					}}
				/>
			</div>
		))}
	</>
);

export default function HourValues({hoursValue, setHoursValue}: HourValuesProps) {
	const {modal, open, close} = useNewModal({size: "large"});
	const [copy, setCopy] = useState<hourValue>(hoursValue);

	useEffect(() => {
		if (modal.open) {
			setCopy(hoursValue);
		}
	}, [modal.open, hoursValue]);

	const hourKeys = ["default", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
	const unUsedKeys = hourKeys.filter(key => !Object.keys(copy).includes(key));

	const onSave = useCallback(() => {
		setHoursValue(copy);
		close();
	}, [copy, close, setHoursValue]);

	return (
		<>
			<Button invert value="Edit Hour Presets" onClick={open} />
			<Modal modal={modal} title="Edit Hour Presets">
				{Object.keys(copy).map((key, index) => (
					<div key={index} className="space">
						<h4>{key === "default" ? "Default Hour Values" : key}</h4>
						<div className={styles.hourValues}>
							<EditHourValues
								hourValueDay={copy[key]}
								onChange={(value, hour) => {
									const copyEv = {...copy};
									copyEv[key][Number(hour)] = Number(value);
									setCopy(copyEv);
								}}
							/>
							{key !== "default" && (
								<Icon
									className={styles.delete}
									icon="delete"
									onClick={() => {
										const copyEv = {...copy};
										delete copyEv[key];
										setCopy(copyEv);
									}}
								/>
							)}
						</div>
					</div>
				))}
				{unUsedKeys.length > 0 && (
					<Select
						placeholder="Add Day"
						value={"Add Day"}
						options={unUsedKeys.map(key => ({label: key, value: key}))}
						onChange={day => {
							setCopy(cpy => ({...cpy, [day]: {...copy.default}}));
						}}
					/>
				)}
				<Button value="Save" onClick={onSave} />
			</Modal>
		</>
	);
}
