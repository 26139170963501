import {Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useRef, useState} from "react";
import classnames from "classnames/bind";
import {useQuery} from "@apollo/client";

import {ShuffledComment, Post, UPDATE_POST} from "../../data";
import {useMutationToast} from "../../toast";
import {
	CREATE_SHUFFLED_COMMENT,
	DELETE_SHUFFLED_COMMENT,
	GET_SHUFFLED_COMMENTS,
	UPDATE_SHUFFLED_COMMENT,
} from "../../data/social-shuffle";
import {useConfirmDeleteModal} from "../confirm";
import {Loading} from "../../components/loading";
import {P2} from "../../components/text";
import {CaptionEditor} from "./caption-editor";
import {Button, InputRow} from "../../components/input";
import {ModalData} from "../new";

import styles from "./shuffle-caption-tab.module.scss";

const bStyles = classnames.bind(styles);

export interface DirtyFields {
	[key: string]: boolean;
}

export type SetDirtyFields = Dispatch<SetStateAction<DirtyFields>>;
export type SetActiveEditor = (id: string | null) => void;

interface EditPostCommentProps {
	id: string;
	collectionId: number;
	post: Post;
	aiValue?: string;
	disabled: boolean;
	setDirtyFields: SetDirtyFields;
	activeEditor: string | null;
	changeActiveEditor: SetActiveEditor;
}
interface EditShuffledCommentProps {
	id: string;
	shuffledComment: ShuffledComment;
	aiValue?: string;
	disabled: boolean;
	setDirtyFields: SetDirtyFields;
	activeEditor: string | null;
	changeActiveEditor: SetActiveEditor;
}

interface AddShuffledCommentProps {
	post: Post;
	aiValue?: string;
	disabled: boolean;
	setDirtyFields: SetDirtyFields;
	activeEditor: string | null;
	changeActiveEditor: SetActiveEditor;
}

interface SfuffleCaptionActionsProps {
	modal: ModalData;
	post: Post;
}
interface ShuffleCaptionProps {
	collectionId: number;
	post: Post;
	disabled: boolean;
	dirtyFields: DirtyFields;
	setDirtyFields: SetDirtyFields;
}

const EditPostComment = ({
	id,
	collectionId,
	post,
	aiValue,
	disabled,
	setDirtyFields,
	activeEditor,
	changeActiveEditor,
}: EditPostCommentProps): ReactElement => {
	const defaultComment = post?.opengraphs?.general?.comment || "";
	const [comment, setComment] = useState(defaultComment);
	const [updatePost, {loading: updating}] = useMutationToast(UPDATE_POST);

	const isDirty = comment !== defaultComment;
	const editMode = activeEditor === id;
	useEffect(() => {
		setDirtyFields(dirtyFields => ({
			...dirtyFields,
			[id]: isDirty,
		}));
	}, [setDirtyFields, isDirty, id]);

	const handleSave = useCallback(
		() =>
			updatePost({
				variables: {
					id: collectionId,
					postId: post.id,
					changes: {
						opengraphs: {
							general: {
								comment,
							},
						},
					},
					deletions: {},
				},
				onCompleted: () => {
					changeActiveEditor(null);
				},
			}),
		[updatePost, collectionId, post.id, comment, changeActiveEditor]
	);

	const handleFocus = useCallback(() => {
		changeActiveEditor(id);
	}, [changeActiveEditor, id]);

	const handleCancel = useCallback(() => {
		setComment(defaultComment);
		changeActiveEditor(null);
	}, [setComment, defaultComment, changeActiveEditor]);

	const handleChange = useCallback(
		(value: string) => {
			if (!editMode) {
				return;
			}
			setComment(value);
		},
		[editMode]
	);

	return (
		<CaptionEditor
			id={id}
			value={comment}
			aiValue={aiValue}
			onChange={handleChange}
			onCancel={handleCancel}
			onFocus={handleFocus}
			updating={updating}
			onSave={handleSave}
			disabled={disabled}
			editMode={editMode}
			isDirty={isDirty}
		/>
	);
};

const EditShuffledComment = ({
	id,
	shuffledComment,
	aiValue,
	disabled,
	setDirtyFields,
	activeEditor,
	changeActiveEditor,
}: EditShuffledCommentProps): ReactElement => {
	const defaultComment = shuffledComment?.comment || "";
	const [comment, setComment] = useState(defaultComment);

	const isDirty = comment !== defaultComment;
	const editMode = activeEditor === id;

	useEffect(() => {
		setDirtyFields(dirtyFields => ({
			...dirtyFields,
			[id]: isDirty,
		}));
	}, [setDirtyFields, isDirty, id]);

	const [updateShuffledComment, {loading: updating}] = useMutationToast(UPDATE_SHUFFLED_COMMENT);
	const [deleteShuffleComment, {loading: deleting}] = useMutationToast(DELETE_SHUFFLED_COMMENT, {
		variables: {
			id: shuffledComment?.id,
		},
	});
	const handleDelete = useCallback(close => deleteShuffleComment().then(close), [deleteShuffleComment]);
	const deleteModal = useConfirmDeleteModal({
		what: "Caption",
		onDelete: handleDelete,
		deleting: deleting,
	});

	const handleSave = useCallback(() => {
		updateShuffledComment({
			variables: {
				id: shuffledComment.id,
				comment,
			},
			onCompleted: () => {
				changeActiveEditor(null);
			},
		});
	}, [shuffledComment, comment, updateShuffledComment, changeActiveEditor]);

	const handleFocus = useCallback(() => {
		changeActiveEditor(id);
	}, [changeActiveEditor, id]);

	const handleCancel = useCallback(() => {
		setComment(defaultComment);
		changeActiveEditor(null);
	}, [defaultComment, setComment, changeActiveEditor]);

	const handleChange = useCallback(
		(value: string) => {
			if (!editMode) {
				return;
			}
			setComment(value);
		},
		[editMode]
	);

	return (
		<CaptionEditor
			id={id}
			aiValue={aiValue}
			value={comment}
			onChange={handleChange}
			onCancel={handleCancel}
			onFocus={handleFocus}
			onDelete={deleteModal.open}
			updating={updating}
			onSave={handleSave}
			disabled={disabled}
			editMode={editMode}
			isDirty={isDirty}
		/>
	);
};

const AddShuffledComment = ({
	post,
	aiValue,
	disabled,
	setDirtyFields,
	activeEditor,
	changeActiveEditor,
}: AddShuffledCommentProps) => {
	const defaultComment = "";
	const [comment, setComment] = useState(defaultComment);
	const [createShuffledComment, {loading: updating}] = useMutationToast(CREATE_SHUFFLED_COMMENT);
	const ref = useRef<HTMLDivElement>(null);

	const id = "addShuffledComment";
	const isDirty = comment !== defaultComment;
	const editMode = activeEditor === id;

	useEffect(() => {
		setDirtyFields(dirtyFields => ({
			...dirtyFields,
			[id]: isDirty,
		}));
	}, [setDirtyFields, isDirty]);

	const handleSave = useCallback(() => {
		createShuffledComment({
			variables: {
				queuedUrlId: post?.id,
				comment,
			},
		}).then(() => {
			setComment(defaultComment);
			changeActiveEditor(null);
		});
	}, [defaultComment, createShuffledComment, post, comment, changeActiveEditor]);

	const handleAddShuffledComment = useCallback(() => {
		changeActiveEditor(id);
	}, [changeActiveEditor, id]);

	const handleCancel = useCallback(() => {
		setComment("");
		changeActiveEditor(null);
	}, [setComment, changeActiveEditor]);

	useEffect(() => {
		editMode &&
			ref.current &&
			ref.current.scrollIntoView({
				behavior: "smooth",
				block: "end",
			});
	}, [editMode]);

	if (!editMode) {
		return (
			<Button
				color="blue"
				icon="add"
				value="Add another caption"
				onClick={handleAddShuffledComment}
				loading={updating}
				border={false}
				invert
			/>
		);
	}

	return (
		<div className={bStyles("addContainer", "space")}>
			<CaptionEditor
				id={`add-shuffled-comment`}
				value={comment}
				aiValue={aiValue}
				onChange={setComment}
				onCancel={handleCancel}
				updating={updating}
				onSave={handleSave}
				saveButtonLabel="Add"
				disabled={disabled}
				isDirty={isDirty}
				required
				editMode
			/>
			<div ref={ref} style={{paddingTop: "8px"}} />
		</div>
	);
};

export const SfuffleCaptionActions = ({modal, post}: SfuffleCaptionActionsProps): ReactElement => {
	const {data} = useQuery(GET_SHUFFLED_COMMENTS, {
		variables: {queuedUrlId: post.id},
	});

	const shuffleCommentsNumber = (data?.shuffledComments?.length || 0) + 1;
	const handleClose = useCallback(() => {
		modal.close();
	}, [modal]);
	return (
		<InputRow position="between">
			<P2 color="grey" bold>
				{shuffleCommentsNumber} total captions
			</P2>
			<Button onClick={handleClose} value="Close" invert />
		</InputRow>
	);
};

export const ShuffleCaptionTab = ({
	collectionId,
	post,
	disabled,
	dirtyFields,
	setDirtyFields,
}: ShuffleCaptionProps): ReactElement => {
	const {data, loading} = useQuery(GET_SHUFFLED_COMMENTS, {
		variables: {queuedUrlId: post.id},
		fetchPolicy: "cache-first",
	});

	const [activeEditor, setActiveEditor] = useState<string | null>(null);

	const handleChangeActiveEditor = useCallback(
		id => {
			if (id && activeEditor && dirtyFields[activeEditor]) {
				return;
			}

			setActiveEditor(id);
		},
		[activeEditor, dirtyFields]
	);

	const postCommentId = `post-comment-${post.id}`;

	const postCommentAiValue = post?.opengraphs?.general?.title || post?.opengraphs?.general?.description;
	const postComment = post?.opengraphs?.general?.comment;
	const latestComment = data?.shuffledComments?.[data?.shuffledComments?.length - 1]?.comment;
	const addCommentAiValue = latestComment || postComment || postCommentAiValue;

	return (
		<div className={bStyles("container")}>
			<P2 bold color="grey">
				Create alternative captions that will be randomized for the recipients of this post.
			</P2>

			{loading ? (
				<Loading position="absolute" />
			) : (
				<>
					<div className={bStyles("captionContainer", "space")}>
						<div className={bStyles("labelContainer")}>
							<label htmlFor={postCommentId} className={bStyles("label")}>
								#1
							</label>
						</div>
						<EditPostComment
							id={postCommentId}
							collectionId={collectionId}
							post={post}
							aiValue={postCommentAiValue}
							disabled={disabled}
							activeEditor={activeEditor}
							changeActiveEditor={handleChangeActiveEditor}
							setDirtyFields={setDirtyFields}
						/>
					</div>

					{data?.shuffledComments?.map((shuffledComment, index) => {
						const prevComment = data?.shuffledComments?.[index - 1]?.comment;
						const aiValue = prevComment || postComment || postCommentAiValue;

						return (
							<div className={bStyles("captionContainer", "space")} key={shuffledComment?.id}>
								<div className={bStyles("labelContainer")}>
									<label htmlFor={`shuffled-comment-${shuffledComment.id}`} className={bStyles("label")}>
										#{index + 2}
									</label>
								</div>
								<EditShuffledComment
									id={`shuffled-comment-${shuffledComment.id}`}
									aiValue={aiValue}
									shuffledComment={shuffledComment}
									disabled={disabled}
									activeEditor={activeEditor}
									changeActiveEditor={handleChangeActiveEditor}
									setDirtyFields={setDirtyFields}
								/>
							</div>
						);
					})}
					{!disabled && (
						<AddShuffledComment
							post={post}
							aiValue={addCommentAiValue}
							disabled={disabled}
							activeEditor={activeEditor}
							changeActiveEditor={handleChangeActiveEditor}
							setDirtyFields={setDirtyFields}
						/>
					)}
				</>
			)}
		</div>
	);
};
