import classnames from "classnames/bind";
import {ReactElement} from "react";
import {Argument} from "classnames";

import {EmptyComponent, Color} from "../../types";
import {Icon} from "../images";

import styles from "./pill.module.scss";
const bStyles = classnames.bind(styles);

interface PillComponent extends EmptyComponent {
	color: Color;
	text: string;
	textClassName?: Argument;
	outline?: boolean;
	onDelete?: () => void;
	disabled?: boolean;
	tabIndex?: number;
}

export const Pill = ({
	className,
	text,
	textClassName,
	color,
	onDelete,
	outline,
	disabled,
	tabIndex,
}: PillComponent): ReactElement => (
	<div tabIndex={tabIndex} className={bStyles(className, color, "container", {outline, disabled})}>
		<h5 className={bStyles(textClassName)}>{text}</h5>
		<Icon icon="close" height={16} onClick={disabled ? undefined : onDelete} className={styles.delete} />
	</div>
);
