import {gql} from "@apollo/client";

export interface OpenGraph {
	comment?: string;
	description?: string;
	image?: string | null;
	video?: string | null;
	title?: string;
}

export interface OpenGraphs {
	general: OpenGraph;
	facebook?: OpenGraph;
	instagram?: OpenGraph;
	linkedin?: OpenGraph;
	twitter?: OpenGraph;
	__typename?: string;
}

export const OPENGRAPH_FRAGMENT = gql`
	fragment OpenGraphFields on OpenGraph {
		comment
		image
		video
		description
		title
	}
`;

export const OPENGRAPHS_FRAGMENT = gql`
	fragment OpenGraphsFields on OpenGraphs {
		general {
			...OpenGraphFields
		}
		instagram {
			...OpenGraphFields
		}
		linkedin {
			...OpenGraphFields
		}
		twitter {
			...OpenGraphFields
		}
		facebook {
			...OpenGraphFields
		}
	}
	${OPENGRAPH_FRAGMENT}
`;

export const OPENGRAPH_FROM_URL = gql`
	mutation OpengraphFromURL($url: String!) {
		opengraphFromURL(url: $url) {
			...OpenGraphFields
		}
	}
	${OPENGRAPH_FRAGMENT}
`;
