import {useCallback, useState, FC, useId} from "react";
import classnames from "classnames";
import {useLocalStorage} from "react-use";

import {Button, SmallButton} from "../button";
import {InputRow, SearchableSelect, Separator, Text} from "..";
import {HoverTooltip} from "../../tooltip";
import {useExecutePrompt} from "./use-ai-execute-prompt";
import {useAIMessage} from "./use-ai-message";
import {Span4} from "../../text";

import styles from "./ai-text-picker.module.scss";

export interface AIAssistantFormProps {
	onChange: (value: string) => void;
	onClose: () => void;
	promptLabel?: string;
	promptValue: string;
	maxLength?: number;
}

export const aiToneOptions = [
	{value: "polite", label: "Polite"},
	{value: "witty", label: "Witty"},
	{value: "enthusiastic", label: "Enthusiastic"},
	{value: "friendly", label: "Friendly"},
	{value: "informational", label: "Informational"},
	{value: "funny", label: "Funny"},
	{value: "formal", label: "Formal"},
	{value: "informal", label: "Informal"},
	{value: "humorous", label: "Humorous"},
	{value: "serious", label: "Serious"},
	{value: "optimistic", label: "Optimistic"},
	{value: "motivating", label: "Motivating"},
] as const;

export type AiToneType = typeof aiToneOptions[number];

export const AIAssistantForm: FC<AIAssistantFormProps> = ({
	onClose,
	onChange,
	promptLabel = "Caption",
	promptValue,
	maxLength = 300,
}) => {
	const formId = useId();

	const [prompt, setPrompt] = useState(promptValue);

	const [tone, setTone] = useLocalStorage<AiToneType>("ai-assistant-tone");
	const [hashtag, setHashtag] = useLocalStorage<boolean>("ai-assistant-hashtag", false);
	const [emoji, setEmoji] = useLocalStorage<boolean>("ai-assistant-emoji", false);

	const {aiMessage} = useAIMessage({formId});

	const {mutate, isPending} = useExecutePrompt({formId});

	const handleHashtag = useCallback(() => setHashtag(!hashtag), [setHashtag, hashtag]);
	const handleEmoji = useCallback(() => setEmoji(!emoji), [setEmoji, emoji]);

	return (
		<div className={classnames(styles.actionForm, "space")}>
			<Text
				type="textarea"
				label={`${promptLabel} prompt`}
				placeholder="Create a caption!"
				onChange={setPrompt}
				value={prompt}
				minRows={3}
				autoFocus
				maxRows={10}
			/>

			<div className={classnames(styles.promptActions, "space")}>
				<InputRow className={styles.inputRow}>
					<div className="space">
						<HoverTooltip text="Select Tone">
							<SearchableSelect
								options={aiToneOptions}
								value={[tone || aiToneOptions[0]]}
								onChange={value => {
									setTone(value as AiToneType);
								}}
								className="space"
							/>
						</HoverTooltip>
					</div>

					<div className="space">
						<HoverTooltip text={hashtag ? "Turn off hashtags" : "Turn on hashtags"}>
							<Button
								invert={true}
								icon="hashtag"
								iconSize="medium"
								onClick={handleHashtag}
								border={false}
								color={hashtag ? "blue" : "black"}
								active={hashtag}
							/>
						</HoverTooltip>
					</div>
					<div className="space">
						<HoverTooltip text={emoji ? "Turn off emojis" : "Turn on emojis"}>
							<Button
								invert={true}
								icon="smile"
								iconSize="medium"
								onClick={handleEmoji}
								border={false}
								color={emoji ? "blue" : "black"}
								active={emoji}
							/>
						</HoverTooltip>
					</div>
				</InputRow>
				<div>
					<Button
						value={aiMessage ? "Regenerate" : "Generate"}
						invert={true}
						icon={aiMessage ? "refresh" : "enhance"}
						onClick={() => {
							mutate({prompt, tone: tone?.value, hashtag, emoji, action: "refine", maxLength});
						}}
						border={false}
						loading={isPending}
					/>
				</div>
			</div>

			{!!aiMessage && (
				<>
					<Separator horizontal />
					<div className={classnames(styles.aiCaptionTitle)}>{`Generated ${promptLabel}`}</div>
					<div className={classnames(styles.aiCaptionContainer, "space")}>
						<div className={classnames("space")}>{aiMessage}</div>
						<InputRow className={styles.inputRow}>
							<SmallButton
								value="Shorten"
								disabled={isPending}
								onClick={() => {
									mutate({
										prompt: aiMessage,
										tone: tone?.value,
										hashtag,
										emoji,
										action: "shorten",
										maxLength,
									});
								}}
								color="grey"
								invert
							/>
							<SmallButton
								value="Expand"
								disabled={isPending}
								onClick={() => {
									mutate({prompt: aiMessage, tone: tone?.value, hashtag, emoji, action: "expand", maxLength});
								}}
								color="grey"
								invert
							/>

							<SmallButton
								value="Rephrase"
								disabled={isPending}
								onClick={() => {
									mutate({
										prompt: aiMessage,
										tone: tone?.value,
										hashtag,
										emoji,
										action: "rephrase",
										maxLength,
									});
								}}
								color="grey"
								invert
							/>

							<Span4 className={styles.captionCount} color="grey">
								{aiMessage.length}/{maxLength} characters
							</Span4>
						</InputRow>
					</div>
					<InputRow position="right">
						<Button value="Cancel" onClick={onClose} color="blue" invert border={false} />
						<Button
							value="Insert Caption"
							onClick={() => {
								onChange(aiMessage);
								onClose();
							}}
							color="blue"
						/>
					</InputRow>
				</>
			)}
		</div>
	);
};
