import {EventHandler, FC, FocusEvent, ReactElement, ReactNode, SyntheticEvent, useState} from "react";
import classnames, {Argument} from "classnames";
import {v4 as uuidv4} from "uuid";

import {Component, EmptyComponent} from "../../types";
import {Span3, Span4} from "../text";
import {ValidationCheck} from "./validation";
import {IconType} from "../images";

import styles from "./input.module.scss";

export interface Option<T> {
	value: T;
	label: string;
	icon?: IconType;
	disabled?: boolean;
	hint?: string;
	count?: number;
}
export interface GroupedOptions<T> {
	label: string;
	options: Option<T>[];
}

export interface SingleOptionProps<V, C = V> extends InputComponent<V, C> {
	options: readonly Option<C>[];
}

export interface MultiOptionProps<V, C = V> extends InputComponent<V[], C[]> {
	options: readonly Option<C>[];
}

export interface TabsProps<K extends string, V> extends InputComponent<Record<K, V[]>> {
	tabs: readonly TabProps<K, V>[];
}

export interface TabProps<K extends string, V> extends Omit<InputComponent<V[]>, "value" | "onChange"> {
	loading?: boolean;
	options: Option<V>[];
	label: string;
	value: K;
	onChange?: (newValue: V[]) => void;
}

interface InputContainer extends Component {
	baseClass?: string;
	label?: ReactNode;
	id: string;
	info?: string;
	text?: ReactNode;
	disabled?: boolean;
	labelClassName?: Argument;
	error?: string;
	required?: boolean;
	condensed?: boolean;
	onBlur?: (e: FocusEvent<HTMLDivElement>) => void;
}

export interface InputComponent<V, C = V>
	extends Omit<InputContainer, "baseClass" | "id" | "error" | "required" | "onBlur"> {
	value: V;
	onChange: (newValue: C) => void;
	id?: string;
	validate?: ValidationCheck<V>;
}

export interface InputRowProps extends Component {
	position?: "left" | "center" | "right" | "between" | "around";
}

export interface RadioGroupProps<T> extends SingleOptionProps<T | undefined, T> {
	horizontal?: boolean;
}

export const InputRow = ({position = "left", children, className}: InputRowProps): ReactElement => (
	<div className={classnames(styles.inputRow, "space", styles[position], styles.labelRow, className)}>
		{children}
	</div>
);

interface MultiColumnProps extends Component {
	columns?: 2 | 3 | 4;
	center?: boolean;
	labelRow?: boolean;
}

export const MultiColumn: FC<MultiColumnProps> = ({center, children, className, columns, labelRow}) => (
	<div
		className={classnames(
			className,
			"space",
			styles.twoColumn,
			labelRow && styles.labelRow,
			columns === 3 && styles.threeColumn,
			columns === 4 && styles.fourColumn,
			center && styles.center
		)}
	>
		{children}
	</div>
);

export interface InputHeaderProps extends EmptyComponent {
	children: [ReactElement, ReactElement];
}

export const InputHeader = ({children: [left, right], className}: InputHeaderProps): ReactElement => (
	<InputRow className={className}>
		{left}
		<div className={styles.inputHeaderSpace} />
		{right}
	</InputRow>
);

export const Separator = ({horizontal = false, className}: {horizontal?: boolean; className?: Argument}) => (
	<div
		className={classnames(
			horizontal ? styles.hrSeparator : styles.separator,
			className,
			horizontal && "space"
		)}
	/>
);

export const Input = ({
	baseClass,
	children,
	className,
	disabled,
	error,
	id,
	info,
	label,
	onBlur,
	required,
	condensed,
	text,
	labelClassName,
}: InputContainer): ReactElement => (
	<div
		className={classnames(
			styles.container,
			"space",
			styles[`${baseClass}Container`],
			condensed && styles.condensed
		)}
		onBlur={onBlur}
	>
		<div
			className={classnames(
				styles.input,
				className,
				styles[`${baseClass}Input`],
				disabled && styles.disabled
			)}
		>
			{label && (
				<label htmlFor={id} className={styles.label}>
					<Span3 className={labelClassName}>
						{label}
						{required && "*"}
					</Span3>
				</label>
			)}
			{children}
		</div>
		{(text || info || error) && (
			<div className={styles.under}>
				{error ? <Span4 className={styles.error}>{error}</Span4> : text && <span>{text}</span>}
				{info && (
					<Span4 className={styles.info} color="grey">
						{info}
					</Span4>
				)}
			</div>
		)}
	</div>
);

export const useId = (maybeId?: string): string => {
	const [id] = useState<string>(() => maybeId || uuidv4());
	return id;
};

export function toggle<T>(which: T, value: T[] = []): T[] {
	let found = false;
	const newValue = value.filter(v => {
		if (v === which) {
			found = true;
			return false;
		}
		return true;
	});
	if (found) return newValue;
	return [...value, which];
}

export const triState = (
	items: readonly unknown[],
	selected: readonly unknown[] | undefined
): boolean | undefined => (items.length === selected?.length ? true : selected?.length ? undefined : false);

export const noBubble = <T extends SyntheticEvent>(
	handler?: EventHandler<T> | null
): EventHandler<T> => e => {
	if (handler === undefined) return;
	e.stopPropagation();
	if (handler) handler(e);
};

export {Button, SmallButton} from "./button";
export type {ButtonProps} from "./button";
export {Checkbox, CheckboxGroup} from "./checkbox";
export {CalendarButton} from "./date-time";
export {DropdownButton, SelectSomeMenu} from "./dropdown-button";
export type {DropdownButtonProps, IconOption, TabOption} from "./dropdown-button";
export {EmojiPickerInput} from "./emoji-picker";
export {GroupedDropdown} from "./grouped-dropdown";
export type {Group} from "./grouped-dropdown";
export {File} from "./file";
export type {OnFile} from "./file";
export {FileUpload} from "./file-upload";
export {RadioGroup} from "./radio-group";
export {RichText} from "./rich-text";
export {Select} from "./select";
export {Switch} from "./switch";
export {Text} from "./text";
export {TreeDropdown} from "./tree-dropdown";
export type {TreeOption} from "./tree-dropdown";
export {UserSelect} from "./user-select";
export {Validate, required, useValidate, validEmail} from "./validation";
export type {ValidationCheck, Validator} from "./validation";
export {default as SearchableSelect} from "./searchable-select";
