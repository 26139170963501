import React, {useCallback, useState} from "react";
import {useQuery} from "@apollo/client";

import {BaseModalArgs, ModalHook, useConfirmModal} from "../index";
import {ADD_ORG_KEYWORD, GET_POST_KEYWORDS, PostKeyword} from "../../data";
import {SearchableSelect, Option} from "../../components/input";
import {P, P4} from "../../components/text";
import {useMutationToast} from "../../toast";

export interface EmvSelectModalArgs<T> extends BaseModalArgs<T> {
	selectedKeys: PostKeyword[];
	onConfirm: (selectedKey: PostKeyword[]) => void;
}
const loadKeywords = (keys: PostKeyword[]): Option<number>[] =>
	keys.map(v => ({
		label: `${v.keyword} ${
			v.value
				? `(${new Intl.NumberFormat("en-US", {
						style: "currency",
						currency: "USD",
				  }).format(v.value)})`
				: ""
		}`,
		value: v.id,
	}));
export function useKeywordsModal<T>({selectedKeys, onConfirm}: EmvSelectModalArgs<T>): ModalHook<T> {
	const {data, refetch} = useQuery(GET_POST_KEYWORDS);
	const [addNewKeyword] = useMutationToast(ADD_ORG_KEYWORD);
	const [selectedKeysOptions, setSelectedKeysOptions] = useState<number[]>(selectedKeys.map(v => v.id));
	const reloadKeywords = useCallback(() => setSelectedKeysOptions(() => selectedKeys.map(v => v.id)), [
		selectedKeys,
	]);

	const handleAdd = useCallback(
		keyword =>
			addNewKeyword({variables: {keyword}}).then(
				({
					data: {
						addNewKeyword: {id},
					},
				}) => {
					refetch();
					setSelectedKeysOptions(c => [...c, id]);
				}
			),
		[addNewKeyword, refetch]
	);

	return useConfirmModal(() => {
		const handleSave = close => {
			onConfirm(data.postKeywords.filter(v => selectedKeysOptions.includes(v.id)));
			close();
		};
		const options = loadKeywords(data?.postKeywords ?? []);
		const values = options.filter(v => selectedKeysOptions.includes(v.value));
		const onChange = value => {
			setSelectedKeysOptions(value.map(v => v.value));
		};
		return {
			title: "Earned Media Value (EMV)",
			size: "small",
			body: (
				<>
					<P>We reviewed your post and added keywords to boost your EMV.</P>
					<P4 href="https://cvssupport.wpenginepowered.com/article/how-does-earned-media-value-work">
						Learn more about EMV
					</P4>
					<SearchableSelect
						bare
						isMulti
						value={values}
						onChange={onChange}
						options={options}
						onAdd={handleAdd}
						disableDropdownIndicator
					/>
				</>
			),
			onConfirm: handleSave,
			onOpen: reloadKeywords,
		};
	}, [data?.postKeywords, handleAdd, onConfirm, reloadKeywords, selectedKeysOptions]);
}
