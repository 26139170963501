import {useCallback, useMemo, useRef, useState} from "react";
import {useFormikContext} from "formik";
import {autoUpdate, offset, shift, useFloating} from "@floating-ui/react-dom";
import {Portal} from "react-portal";

import {Icon} from "../../../../components/images";
import {Checkbox, SmallButton, Switch} from "../../../../components/input";
import {Service, services, UPDATE_ME, useMyUser} from "../../../../data";
import {CollectionFormValues, OnChange} from "./collection-form";
import {Alerter} from "../../../../components/alerter";
import {Span, Span3} from "../../../../components/text";
import {useMutationToast} from "../../../../toast";
import {Social, SocialService} from "../../../../components/social";
import {AutoLike} from "../../../../data/collection";

import styles from "./styles.module.scss";

interface AutoLikeButtonProps {
	onChange: OnChange;
}

interface AutoLikeModalProps {
	autoLike: AutoLike;
	isOpen: boolean;
	onClose: () => void;
	onChange: OnChange;
	reference: HTMLButtonElement | null;
}

const AutoLikeModal = ({autoLike, isOpen, onClose, onChange, reference}: AutoLikeModalProps) => {
	const me = useMyUser();
	const [updateMe] = useMutationToast(UPDATE_ME);
	const [saveAutoLikePreferences, setSaveAutoLikePreferences] = useState(false);
	const {refs, floatingStyles} = useFloating({
		placement: "bottom-start",
		whileElementsMounted: autoUpdate,
		elements: {reference},
		middleware: [shift(), offset(1)],
	});

	const updateAutoLikePreferences = useCallback(
		autoLike => {
			updateMe({
				variables: {
					changes: {
						options: {
							autoLikePreferences: {
								twitter: autoLike.twitter,
								linkedin: autoLike.linkedin,
							},
						},
					},
				},
			});
		},
		[updateMe]
	);

	const setAutoLikeHandler = useCallback(
		(autoLike: {twitter: boolean; linkedin: boolean}) => {
			onChange("autoLike", autoLike);

			if (saveAutoLikePreferences) updateAutoLikePreferences(autoLike);
		},
		[onChange, saveAutoLikePreferences, updateAutoLikePreferences]
	);

	const connections = useMemo(
		() =>
			Object.entries(me.connections).filter(
				([network, connection]) => ["linkedin", "twitter"].includes(network) && connection?.connected
			),
		[me.connections]
	);
	const autoLikeAll = connections.every(([network]) => autoLike[network]);

	const setAutoLikeAsDefault = useCallback(
		checked => {
			const autoLikePref = checked
				? autoLike
				: connections.reduce((acc, [network]) => ({...acc, [network]: false}), {} as AutoLike);
			setSaveAutoLikePreferences(checked);
			updateAutoLikePreferences(autoLikePref);
		},
		[setSaveAutoLikePreferences, updateAutoLikePreferences, autoLike, connections]
	);

	if (!isOpen) return null;

	return (
		<Portal>
			<div className={styles.autoLikeModal} ref={refs.setFloating} style={floatingStyles}>
				<Alerter onOutsideClick={onClose} className={styles.content}>
					<div className={styles.selectNetworks}>
						<Switch
							label={<Span bold>Select All</Span>}
							onChange={checked =>
								setAutoLikeHandler(
									connections.reduce((acc, [network]) => ({...acc, [network]: checked}), {} as AutoLike)
								)
							}
							value={autoLikeAll}
							condensed
						/>
						{connections.map(([network, connection], index) => (
							<div className={styles.networkOption} key={`${network}-${index}`}>
								<Social name={network as SocialService} active={autoLike[network]} size="x-small" />
								<Switch
									label={connection?.identifier}
									className={styles.switch}
									onChange={checked => {
										const value = {...autoLike, [network]: checked};
										setAutoLikeHandler(value);
									}}
									value={autoLike[network] ?? false}
									condensed
								/>
							</div>
						))}
					</div>
					<div className={styles.autoLikePreferences}>
						<Checkbox
							label="Use these as the default Auto-like settings"
							onChange={setAutoLikeAsDefault}
							value={saveAutoLikePreferences}
						/>
					</div>
					<div className={styles.autoLikeLink}>
						<a
							href="https://help.clearviewsocial.com/article/amplification-v-2"
							target="_blank"
							rel="noreferrer"
						>
							Learn More
						</a>
					</div>
				</Alerter>
			</div>
		</Portal>
	);
};

export const AutoLikeButton = ({onChange}: AutoLikeButtonProps) => {
	const buttonRef = useRef<HTMLButtonElement>(null);
	const [open, setOpen] = useState(false);
	const {values} = useFormikContext<CollectionFormValues>();
	const {autoLike} = values;
	const autoLikeNetworks = useMemo(() => services.filter(n => autoLike[n]) as Service[], [autoLike]);

	return (
		<>
			<SmallButton
				ref={buttonRef}
				value={
					<div className={styles.autoLikeContent}>
						{" "}
						Auto-Like All Shared Posts{" "}
						<div>
							{autoLikeNetworks.length ? (
								autoLikeNetworks.map(n => (
									<Icon key={n} icon={`filled-${n}`} width={16} viewBox={"0 0 16 16"} />
								))
							) : (
								<Span3 color="pink">Off</Span3>
							)}
						</div>
					</div>
				}
				IconComponent={<> </>}
				color="black"
				onClick={() => setOpen(!open)}
				icon="like"
				arrow={"down"}
				border={false}
				invert
			/>
			<AutoLikeModal
				autoLike={autoLike}
				isOpen={open}
				onClose={() => setOpen(false)}
				onChange={onChange}
				reference={buttonRef?.current}
			/>
		</>
	);
};
