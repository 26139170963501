import {debounce} from "lodash";
import {useCallback, useEffect, useState} from "react";

// const phoneMin = 414;
const tabletMin = 768;
const desktopMin = 1024;

export function useDeviceSize() {
	const [width, setWidth] = useState(window.innerWidth);
	const onResize = useCallback(() => {
		setWidth(window.innerWidth);
	}, []);
	const debouncedResize = debounce(onResize, 300);
	useEffect(() => {
		window.addEventListener("resize", debouncedResize);
		return () => {
			window.removeEventListener("resize", debouncedResize);
		};
	}, [debouncedResize]);

	return width;
}

export function useWindowDeviceSize() {
	const size = useDeviceSize();
	return size < tabletMin ? "phone" : size < desktopMin ? "tablet" : "desktop";
}
