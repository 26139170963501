/* eslint-disable @typescript-eslint/no-explicit-any */
import {useMemo} from "react";
import {captureException} from "@sentry/react";

import {useToast} from "./toast";
import {config} from "./config";

export async function api<T>(
	path: string,
	method: string,
	body?: any,
	headers?: {[key: string]: string},
	json?: boolean,
	jsonResponse?: boolean
): Promise<T> {
	const outside = path.match(/^https?:\/\//);
	const url = outside ? path : `${config.apiHost}${path.startsWith("/") ? "" : "/"}${path}`;

	const options: RequestInit = {
		headers: json ? {"Content-Type": "application/json;charset=UTF-8", ...headers} : headers,
		credentials: outside ? undefined : "include",
		method,
		body: body && json ? JSON.stringify(body) : body,
	};

	return fetch(url, options).then(r => {
		if (r.status === 401 && window.location.pathname !== "/login") {
			window.location.href = "/login";
			return;
		}

		if (!r.ok) throw r.statusText;
		if (r.status === 204) return;
		return jsonResponse ? r.json() : r.text();
	});
}

export async function get<T>(path: string): Promise<T> {
	return api(path, "GET", undefined, {}, true, true);
}

export async function post<T>(
	path: string,
	body?: any,
	headers?: {[key: string]: string},
	json = true,
	jsonResponse = true
): Promise<T> {
	return api(path, "POST", body, headers, json, jsonResponse);
}

export async function put<T>(
	path: string,
	body?: any,
	headers?: {[key: string]: string},
	json = true,
	jsonResponse = true
): Promise<T> {
	return api(path, "PUT", body, headers, json, jsonResponse);
}

export async function deleteApi(
	path: string,
	body?: any,
	headers?: {[key: string]: string},
	json = true,
	jsonResponse = true
): Promise<void> {
	return api(path, "DELETE", body, headers, json, jsonResponse);
}

interface ToastApiReturn {
	get: <T = any>(what: string, path: string, onComplete: (data: T) => void, catchErr?: () => void) => void;
	post: <T = any>(
		what: string,
		path: string,
		body: any,
		onComplete: (data: T) => void,
		catchErr?: () => void,
		headers?: {[key: string]: string},
		json?: boolean,
		jsonResponse?: boolean
	) => void;
	put: <T = any>(
		what: string,
		path: string,
		body: any,
		onComplete: (data: T) => void,
		catchErr?: () => void,
		headers?: {[key: string]: string},
		json?: boolean,
		jsonResponse?: boolean
	) => void;
	del: (
		what: string,
		path: string,
		body: any,
		onComplete: () => void,
		catchErr?: () => void,
		headers?: {[key: string]: string},
		json?: boolean,
		jsonResponse?: boolean
	) => void;
}

export const useToastApi = (): ToastApiReturn => {
	const toast = useToast();

	return useMemo(() => {
		const catchGet = (what, path, onComplete, catchErr) =>
			get(path).then(onComplete, e => {
				if (catchErr) catchErr();
				toast({color: "red", text: `Could not load ${what}!`});
				captureException(e);
			});
		const catchPost = (what, path, body, onComplete, catchErr, headers, json = true, jsonResponse = true) =>
			post(path, body, headers, json, jsonResponse).then(onComplete, e => {
				if (catchErr) catchErr();
				toast({color: "red", text: `Could not ${what}`});
				captureException(e);
			});
		const catchPut = (what, path, body, onComplete, catchErr, headers, json = true, jsonResponse = true) =>
			put(path, body, headers, json, jsonResponse).then(onComplete, e => {
				if (catchErr) catchErr();
				toast({color: "red", text: `Could not ${what}`});
				captureException(e);
			});
		const catchDelete = (what, path, body, onComplete, catchErr, headers, json = true, jsonResponse = true) =>
			deleteApi(path, body, headers, json, jsonResponse).then(onComplete, e => {
				if (catchErr) catchErr();
				toast({color: "red", text: `Could not ${what}`});
				captureException(e);
			});

		return {get: catchGet, post: catchPost, put: catchPut, del: catchDelete};
	}, [toast]);
};
