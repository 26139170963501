import {ReactElement, useCallback} from "react";
import classnames from "classnames";

import {P2, Span1, Span2, Span4} from "../../../components/text";
import {Button, File, InputRow, Separator} from "../../../components/input";
import {useMutationToast} from "../../../toast";
import {useDirtyCopy} from "../../../dirty-copy";
import {useModal} from "../../../modals";
import {OrgSettings, UPLOAD_LOGO, REMOVE_LOGO, useMyUser} from "../../../data";

import styles from "./logo.module.scss";

export const LogoUpload = (): ReactElement => {
	const me = useMyUser();
	const [uploadLogo, {loading: uploading}] = useMutationToast(UPLOAD_LOGO);
	const [removeLogo, {loading: removing}] = useMutationToast(REMOVE_LOGO);
	const onUpdate = useCallback(
		async ({discard, changes}) => {
			if (changes && changes.uiLogo) {
				uploadLogo({variables: {file: changes?.uiLogo, type: "UI"}}).then(discard);
			} else {
				removeLogo({variables: {type: "UI"}}).then(discard);
			}
		},
		[removeLogo, uploadLogo]
	);
	const {dirty, val, update, flush} = useDirtyCopy<
		Omit<OrgSettings, "uiLogo"> & {uiLogo?: File | string | undefined}
	>(me.org?.settings, {debounce: 2000, onUpdate});

	const handleConfirm = useCallback(
		([file]: File[], resetState: () => void) => {
			update({uiLogo: file});
			resetState();
		},
		[update]
	);

	const removeLogoModal = useModal(
		({close}) => ({
			size: "small",
			body: (
				<div className={styles.removeLogoModal}>
					<InputRow position="center">
						<P2 bold>You’re about remove the logo</P2>
					</InputRow>
					<InputRow position="center">
						<P2>Please confirm below.</P2>
					</InputRow>
				</div>
			),
			footer: (
				<InputRow position="center">
					<Button
						value="Remove Current Logo"
						loading={removing}
						onClick={() => {
							update({uiLogo: undefined});
							close();
						}}
					/>
				</InputRow>
			),
		}),
		[removing, update]
	);

	return (
		<div>
			<Span1 bold>Logos</Span1>
			<div className={classnames(styles.container, "space")}>
				<Span2 color="grey">
					Personalize your platform&apos;s visual by uploading a logo. This will appear on the platform and
					emails, providing a consistent brand experience for your users.
				</Span2>
			</div>
			<div className={classnames(styles.container, "space")}>
				<File
					what="image"
					accept={{image: [".png", ".jpg", ".jpeg"]}}
					onFile={handleConfirm}
					loading={uploading}
				/>
				<div className={styles.imageContainer}>
					<img
						src={
							!val.uiLogo
								? "/no_clogo.svg"
								: typeof val.uiLogo === "string"
								? val.uiLogo
								: URL.createObjectURL(val.uiLogo)
						}
						alt="Company Logo"
						height={200}
					/>
					<Span4 color="grey">Web User Interface Logo</Span4>
					{val.uiLogo && (
						<Span2 bold onClick={removeLogoModal.open}>
							Remove Current Logo
						</Span2>
					)}
				</div>
			</div>
			<Separator horizontal />
			<Button onClick={flush} loading={uploading || removing} disabled={!dirty} value="Save Changes" />
		</div>
	);
};
