import {useQuery} from "@apollo/client";
import {useMemo} from "react";

import {Collection, GET_SLACK_CHANNELS, SlackChannel, useCompanies, useSimpleUsers} from "../../../../data";
import {InputRow} from "../../../../components/input";
import {P2, Span2} from "../../../../components/text";
import {useSimpleGroup} from "../../../../data/group";
import {GET_TEAMS_CHANNELS, TeamsChannel} from "../../../../data/teams";

import styles from "./style.module.scss";

export const Recepients = ({collection}: {collection: Collection}) => {
	const {groups} = useSimpleGroup();
	const corporateAccounts = useCompanies();
	const groupNames = useMemo(
		() =>
			groups && collection.to
				? collection.to.groupIds.map(groupId => {
						const group = groups.find(g => g.value === groupId);
						if (group) return group.label;
						return null;
				  })
				: [],
		[groups, collection]
	);
	const {users: allUsers} = useSimpleUsers({limit: null, filter: {virtualAssistant: true}});
	const virtualAssistants = useMemo(
		() =>
			allUsers.filter(u => collection.to.virtualAssistantIds.includes(u.id)).map(u => `${u.fullName}'s VA`) ??
			[],
		[allUsers, collection.to.virtualAssistantIds]
	);
	const {data: slack} = useQuery<{slackChannels?: SlackChannel[]}>(GET_SLACK_CHANNELS);
	const slackChannels = useMemo(
		() =>
			slack?.slackChannels
				?.filter(s => collection.to.slackChannels.includes(s.channelId))
				.map(s => s.channelName) ?? [],
		[slack, collection.to.slackChannels]
	);

	const {data: teams} = useQuery<{teamsChannels?: TeamsChannel[]}>(GET_TEAMS_CHANNELS);
	const teamsChannels = useMemo(
		() =>
			teams?.teamsChannels
				?.filter(s => collection.to.teamsChannels.includes(s.channelId))
				.map(s => s.channelId) ?? [],
		[teams, collection.to.teamsChannels]
	);
	const companyAccounts = useMemo(
		() => corporateAccounts.filter(c => collection.to.companyIds.includes(c.id)).map(c => c.name) ?? [],
		[corporateAccounts, collection.to.companyIds]
	);

	return (
		<InputRow>
			<P2 bold className={styles.recipients}>
				To:{" "}
			</P2>
			{[...slackChannels, ...groupNames, ...virtualAssistants, ...companyAccounts, ...teamsChannels].map(
				(groupName, index) => (
					<Span2 bold key={index} color="grey">
						{index !== 0 ? ", " : ""}
						{groupName}
					</Span2>
				)
			)}
		</InputRow>
	);
};
