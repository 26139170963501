import {FC, useCallback, useContext, useEffect, useMemo, useState} from "react";
import dayjs from "dayjs";
import {useQuery} from "@apollo/client";

import {CompanyAnalyticsContext} from ".";
import {ChartCard, ChartLine} from "../chart-card";
import {AnalyticsCards} from "../card";
import {
	formatDateInterval,
	formatDateIntervalShort,
	useCustomRange,
} from "../../../components/input/date-time/date-interval-picker";
import {
	GET_COMPANY_LINKEDIN_ANALYTICS,
	GET_COMPANY_LINKEDIN_ANALYTICS_CARDS,
	ShareEventAnalytics,
} from "../../../data/analytics";
import {LinkedinPostsCard} from "./linkedin-posts-card";
import {AnyColor} from "../../../types";
import {Text} from "../../../components/text";

import styles from "./company.module.scss";

const keys = ["pageViews", "uniqueVisitors", "followers", "reactions", "clicks", "comments"] as const;
type Keys = typeof keys[number];

const MIN_DATE = dayjs().subtract(13, "month");

export const LinkedIn: FC = () => {
	const {accounts, dateRange, comparisonDateRange} = useContext(CompanyAnalyticsContext);
	const interval = useCustomRange(dateRange || "Max", MIN_DATE);
	const {start = dayjs(), end = dayjs()} = interval || {};
	const comparisonInterval = useCustomRange(comparisonDateRange, MIN_DATE);
	const {start: comparisonStart, end: comparisonEnd} = comparisonInterval || {};
	const [errorCount, setErrorCount] = useState(0);
	const {data: analyticsData, loading: analyticsLoading} = useQuery(GET_COMPANY_LINKEDIN_ANALYTICS, {
		variables: {ids: accounts.length ? accounts : undefined, start, end},
	});
	const {data: comparisonAnalyticsData, loading: comparisonAnalyticsLoading} = useQuery(
		GET_COMPANY_LINKEDIN_ANALYTICS,
		{
			variables: {ids: accounts.length ? accounts : undefined, start: comparisonStart, end: comparisonEnd},
			skip: !comparisonInterval,
		}
	);
	const {data: cardsData, loading: cardsLoading, error, refetch} = useQuery(
		GET_COMPANY_LINKEDIN_ANALYTICS_CARDS,
		{
			variables: {ids: accounts.length ? accounts : undefined, start, end},
			onError: () => setErrorCount(errorCount + 1),
			notifyOnNetworkStatusChange: true,
		}
	);
	useEffect(() => {
		if (errorCount > 2 || errorCount === 0) return;
		const timer = setTimeout(() => {
			refetch();
		}, 300);

		return () => clearTimeout(timer);
	}, [errorCount, refetch]);

	const {data: comparisonCardsData, loading: comparisonCardsLoading} = useQuery(
		GET_COMPANY_LINKEDIN_ANALYTICS_CARDS,
		{
			variables: {ids: accounts.length ? accounts : undefined, start: comparisonStart, end: comparisonEnd},
			skip: !comparisonInterval,
		}
	);
	const cards = useMemo(() => {
		const lastLabel = formatDateIntervalShort(start, end);

		const values = cardsData?.companyLinkedInAnalyticsCards ?? {};
		const comparisonValues = comparisonCardsData?.companyLinkedInAnalyticsCards ?? {};

		return [
			{
				value: values.totalEngagement || 0,
				label: "Total Engagements",
				lastValue: comparisonValues.totalEngagement || 0,
				lastLabel,
			},
			{
				value: values.totalClicks || 0,
				label: "Total Clicks",
				lastValue: comparisonValues.totalClicks || 0,
				lastLabel,
			},
			{
				value: values.postViews || 0,
				label: "Posts Views",
				lastValue: comparisonValues.postViews || 0,
				lastLabel,
			},
			{
				value: values.pageViews || 0,
				label: "Page Views",
				lastValue: comparisonValues.pageViews || 0,
				lastLabel,
			},
			{
				value: values.newFollowers || 0,
				label: "New Followers",
				lastValue: comparisonValues.newFollowers || 0,
				lastLabel,
			},
		];
	}, [cardsData, comparisonCardsData, start, end]);
	const xAxisLabel = useMemo(() => {
		if (start?.isSame(end, "day")) {
			return start.format("MM/DD/YYYY");
		} else if (start.endOf("week").isSame(end, "day")) {
			return `Week ${start?.format("w, YYYY")}`;
		} else if (start.endOf("month").isSame(end, "day")) {
			return start.format("MMMM YYYY");
		} else if (start.endOf("year").isSame(end, "day")) {
			return start.format("YYYY");
		}

		return `${start?.format("MM/DD/YYYY")} - ${end?.format("MM/DD/YYYY")}`;
	}, [start, end]);
	const formatLabel = useCallback(
		value => {
			const date = dayjs(value);
			if (start.isSame(end, "day")) {
				return "";
			} else if (start.isSame(end, "week")) {
				return date.format("ddd");
			} else if (start.isSame(end, "month")) {
				return date.format("D");
			} else if (start.isSame(end, "year")) {
				return date.format("MMM DD");
			} else {
				return date.format("MM/DD/YYYY");
			}
		},
		[start, end]
	);
	const [labels, shareData, visitorData, followerData] = useMemo<
		[string[], ChartLine<string>[], ChartLine<string>[], ChartLine<string>[]]
	>(() => {
		const labels: string[] = [];
		const data: Record<number, ShareEventAnalytics> = analyticsData?.companyLinkedInAnalytics ?? {};
		const comparisonData: Record<number, ShareEventAnalytics> =
			comparisonAnalyticsData?.companyLinkedInAnalytics ?? {};
		const aggregate = {} as Record<Keys, number[]>;
		const comparisonAggregate = {} as Record<Keys, number[]>;
		const timezoneOffset = new Date().getTimezoneOffset();
		const systemStart = dayjs(start).utcOffset(timezoneOffset);
		const systemEnd = dayjs(end).utcOffset(timezoneOffset);

		let day = systemStart;
		let i = 0;
		keys.forEach(k => (aggregate[k] = []));
		keys.forEach(k => (comparisonAggregate[k] = []));

		while (day.isSameOrBefore(systemEnd)) {
			labels.push(day.toISOString());
			keys.forEach(k => {
				const value = Object.keys(data).reduce((acc, c) => acc + data[c][k][i], 0);
				aggregate[k].push(value);

				const comparisonValue = Object.keys(comparisonData).reduce(
					(acc, c) => acc + comparisonData[c][k][i],
					0
				);
				comparisonAggregate[k].push(comparisonValue);
			});
			day = day.add(1, "day");
			i++;
		}

		const tooltipLabelInterval = formatDateInterval(start, end);
		const comparisonTooltipLabelInterval = formatDateInterval(comparisonStart, comparisonEnd);
		return [
			labels,
			[
				{
					label: "Reactions",
					value: "reactions",
					color: "pink-primary" as AnyColor,
					data: aggregate.reactions,
					tooltipLabel: tooltipLabelInterval,
				},
				...(comparisonInterval
					? [
							{
								label: "",
								value: "reactions",
								color: "pink-primary-alpha" as AnyColor,
								data: comparisonAggregate.reactions,
								tooltipLabel: comparisonTooltipLabelInterval,
							},
					  ]
					: []),
				{
					label: "Comments",
					value: "comments",
					color: "blue-primary" as AnyColor,
					data: aggregate.comments,
					tooltipLabel: tooltipLabelInterval,
				},
				...(comparisonInterval
					? [
							{
								label: "",
								value: "comments",
								color: "blue-primary-alpha" as AnyColor,
								data: comparisonAggregate.comments,
								tooltipLabel: comparisonTooltipLabelInterval,
							},
					  ]
					: []),
				{
					label: "Clicks",
					value: "clicks",
					color: "green" as AnyColor,
					data: aggregate.clicks,
					tooltipLabel: tooltipLabelInterval,
				},
				...(comparisonInterval
					? [
							{
								label: "",
								value: "clicks",
								color: "green-alpha" as AnyColor,
								data: comparisonAggregate.clicks,
								tooltipLabel: comparisonTooltipLabelInterval,
							},
					  ]
					: []),
			],
			[
				{
					label: "Page Views",
					value: "views",
					color: "green-100" as AnyColor,
					data: aggregate.pageViews,
					tooltipLabel: tooltipLabelInterval,
				},
				...(comparisonInterval
					? [
							{
								label: "",
								value: "views",
								color: "green-100-alpha" as AnyColor,
								data: comparisonAggregate.pageViews,
								tooltipLabel: comparisonTooltipLabelInterval,
							},
					  ]
					: []),
				{
					label: "Unique Visitors",
					value: "visitors",
					color: "blue-primary" as AnyColor,
					data: aggregate.uniqueVisitors,
					tooltipLabel: tooltipLabelInterval,
				},
				...(comparisonInterval
					? [
							{
								label: "",
								value: "visitors",
								color: "blue-primary-alpha" as AnyColor,
								data: comparisonAggregate.uniqueVisitors,
								tooltipLabel: comparisonTooltipLabelInterval,
							},
					  ]
					: []),
			],
			[
				{
					label: "",
					value: "followers",
					color: "blue-50" as AnyColor,
					data: aggregate.followers,
					tooltipLabel: tooltipLabelInterval,
				},
				...(comparisonInterval
					? [
							{
								label: "",
								value: "followers",
								color: "blue-50-alpha" as AnyColor,
								data: comparisonAggregate.followers,
								tooltipLabel: comparisonTooltipLabelInterval,
							},
					  ]
					: []),
			],
		];
	}, [
		analyticsData,
		comparisonAnalyticsData,
		start,
		end,
		comparisonInterval,
		comparisonStart,
		comparisonEnd,
	]);

	const chartInteractionMode = comparisonInterval ? "index" : undefined;
	const intervalTxt = `${formatDateIntervalShort(start, end)}${
		comparisonInterval ? ` vs ${formatDateIntervalShort(comparisonStart, comparisonEnd)}` : ""
	}`;

	return (
		<div className={styles.container}>
			{!!error && (
				<Text type="p">
					There was an error loading the data, please{" "}
					<Text
						type="span"
						color="blue"
						onClick={() => {
							refetch();
						}}
					>
						{" "}
						try again
					</Text>
				</Text>
			)}
			<AnalyticsCards
				cards={cards}
				showComparison={!!comparisonDateRange}
				loading={cardsLoading || comparisonCardsLoading}
			/>
			<ChartCard
				labels={labels}
				title="LinkedIn Post Engagement"
				interval={intervalTxt}
				data={shareData}
				allLabel="Engagements"
				loading={analyticsLoading || comparisonAnalyticsLoading}
				fillArea={false}
				useCustomTooltip={true}
				interactionMode={chartInteractionMode}
				formatLabel={formatLabel}
				xAxisLabel={xAxisLabel}
			/>
			<div className={styles.cardsContainer}>
				<ChartCard
					labels={labels}
					title="LinkedIn Page Visitor Highlights"
					interval={intervalTxt}
					data={visitorData}
					allLabel="Visitors"
					select={false}
					fillArea={false}
					loading={analyticsLoading || comparisonAnalyticsLoading}
					useCustomTooltip={true}
					interactionMode={chartInteractionMode}
					formatLabel={formatLabel}
					xAxisLabel={xAxisLabel}
				/>
				<ChartCard
					labels={labels}
					title="LinkedIn Page Total Followers"
					interval={intervalTxt}
					data={followerData}
					allLabel="Visitors"
					select={false}
					fillArea={false}
					loading={analyticsLoading || comparisonAnalyticsLoading}
					useCustomTooltip={true}
					interactionMode={chartInteractionMode}
					formatLabel={formatLabel}
					xAxisLabel={xAxisLabel}
				/>
			</div>
			<LinkedinPostsCard filter={{accounts, dateRange, comparisonDateRange, network: "linkedin"}} />
		</div>
	);
};
