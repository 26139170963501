import {Social} from "../../../../components/social";
import {Service} from "../../../../data";
import {Button, InputRow} from "../../../../components/input";
import {Icon} from "../../../../components/images";
import {P2} from "../../../../components/text";
import {NewModal as Modal} from "../../../../modals";
import {UserShareEventsResult} from "../../../../data/share";
import {ModalData} from "../../../../modals/new";

import styles from "./styles.module.scss";

interface SharingResultsProps {
	modal: ModalData;
	results?: UserShareEventsResult;
	onClose: () => void;
}

export const SharingResults = ({modal, results, onClose}: SharingResultsProps) => {
	const eventsResult = Object.entries(results ?? []).filter(([key, post]) => key !== "__typename" && post);
	const allPosted = eventsResult.every(([, {success}]) => success);

	if (!results) return null;

	return (
		<Modal
			className={styles.sharingResultsModal}
			modal={modal}
			onClose={onClose}
			title={
				allPosted
					? `You’ve done it! The share was posted successfully${
							eventsResult.length > 1 ? " to all the networks" : ""
					  }.`
					: `Sorry, we’re unable to post to${eventsResult.length > 1 ? " all of" : ""} your networks.`
			}
		>
			<div className={styles.socialNetworkIcons}>
				{eventsResult.map(([key, post], i) => (
					<Social
						name={key as Service}
						active={post.success}
						size="large"
						key={i}
						onClick={post.success && post.url ? () => window.open(post.url, "_blank") : undefined}
					/>
				))}
			</div>
			{!allPosted &&
				eventsResult
					.filter(([, {success}]) => !success)
					.map(([, post], i) => (
						<InputRow position="center" key={i}>
							<Icon icon="information" color="pink" />
							<P2 color="pink">{post.message}</P2>
						</InputRow>
					))}
			<Button large value="Back to Posts" onClick={onClose} />
		</Modal>
	);
};
