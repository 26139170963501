import {ReactElement, useCallback, useState} from "react";
import TimePicker from "react-time-picker";
import {Dayjs} from "dayjs";
import classnames from "classnames";

import {Input, InputComponent, useId} from "../index";
import "./react-time-picker.scss";

import styles from "./hour-picker.module.scss";

export interface HourPickerComponent extends InputComponent<Dayjs> {
	min?: Dayjs;
	max?: Dayjs;
}

export const HourPicker = ({
	id: maybeId,
	value,
	onChange,
	labelClassName,
	className,
	disabled,
	label,
	min,
	max,
	...props
}: HourPickerComponent): ReactElement => {
	const [error, setError] = useState<string>("");
	const id = useId(maybeId);

	const handleChange = useCallback(
		time => {
			if (time?.split(":").length === 2) {
				const [hoursStr, minutesStr] = time.split(":");
				const hours = parseInt(hoursStr, 10);
				const minutes = parseInt(minutesStr, 10);
				const newDate = value.hour(hours).minute(minutes);

				setError("");
				onChange(newDate);
			}
		},
		[value, onChange]
	);

	const handleInvalidChange = useCallback(() => {
		setError("Invalid time");
	}, [setError]);

	return (
		<Input
			id={id}
			className={classnames(styles.container, className)}
			label={label ?? "Time"}
			error={error}
			labelClassName={[styles.timeSpan, labelClassName]}
			disabled={disabled}
			{...props}
		>
			<TimePicker
				id={id}
				disabled={disabled}
				className={classnames(styles.clockContainer, disabled && styles.disabled)}
				clearIcon={null}
				clockIcon={null}
				minTime={value.isSame(min, "day") ? min?.format("H:mm") : undefined}
				maxTime={value.isSame(max, "day") ? max?.format("H:mm") : undefined}
				format={disabled ? ":" : "h:mm a"}
				disableClock={true}
				onChange={handleChange}
				onInvalidChange={handleInvalidChange}
				value={disabled ? "" : value.toDate()}
			/>
		</Input>
	);
};
