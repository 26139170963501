import {
	FC,
	KeyboardEvent,
	ReactElement,
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import {useQuery} from "@apollo/client";
import {useNavigate} from "react-router-dom";
import timeZones from "timezones-list";
import classnames, {Argument} from "classnames";
import dayjs, {Dayjs} from "dayjs";

import {count} from "../../utils/text";
import {AlerterWidget} from "../../components/alerter";
import {
	Button,
	Checkbox,
	FileUpload,
	InputRow,
	MultiColumn,
	SearchableSelect,
	Select,
	Separator,
	SmallButton,
	Switch,
	Text,
	toggle,
	Option,
} from "../../components/input";
import {Card} from "../../components/card";
import config from "../../config";
import {HourPicker} from "../../components/input/date-time";
import {UserAvatar} from "../../components/user-avatar";
import {
	Connection,
	ConnectionProfile,
	DISCONNECT_USER_SOCIAL,
	GET_USER_CONNECTION_PAGES,
	GET_USER_CONNECTION_PROFILES,
	Service,
	UPDATE_ME,
	accountNames,
	useGroupList,
	useMyUser,
} from "../../data";
import {useDirtyCopy} from "../../dirty-copy";
import {Icon} from "../../components/images";
import {validConfirmPassword, validPassword} from "../../components/input/validation";
import {P, Span, Span2, Span3, Span4} from "../../components/text";
import {useMutationToast} from "../../toast";
import {Social} from "../../components/social";
import {useConfirmModal, useModal} from "../../modals";
import {Loading} from "../../components/loading";
import {CONNECT_FACEBOOK_PAGE} from "../../data/user";
import {REGISTER_PAGE_EVENT} from "../../data/badges";

import styles from "./account.module.scss";

export const SocialMedia: FC<{
	account: Service;
	name: string;
	connection?: Connection;
	onClick: () => void;
	onLinkClick: () => void;
	onDisconnectClick: () => void;
	className?: Argument;
	text?: ReactNode;
	linking?: boolean;
	unlinking?: boolean;
}> = ({
	className,
	account,
	name,
	connection,
	onClick,
	onDisconnectClick,
	onLinkClick,
	text,
	linking,
	unlinking,
}) => (
	<div className={styles.socialMediaContainer}>
		<div className={classnames(styles.actionsContainer, className)}>
			<div className={styles.socialIcon}>
				<Social
					hasError={connection?.expired}
					size="small"
					name={account}
					active={connection?.connected}
					onClick={onClick}
				/>
				<Button
					loading={!connection?.connected && linking}
					border={false}
					invert
					className={styles.identifier}
					color="blue"
					onClick={connection?.connected ? onClick : onLinkClick}
					value={connection?.connected ? name : "Link account"}
				/>
			</div>
			{connection?.connected && (
				<InputRow>
					{(account === "linkedin" || account === "facebook") && (
						<Button
							loading={!connection.connected && linking}
							color="blue"
							onClick={onLinkClick}
							value="Refresh"
						/>
					)}
					<Button
						border={false}
						loading={unlinking}
						invert
						onClick={onDisconnectClick}
						value="Unlink"
						color="black"
					/>
				</InputRow>
			)}
		</div>
		{text}
	</div>
);

export const PeakTimeSelector: FC<{
	peakTime: boolean;
	peakTimeSlots: Dayjs[];
	onChange: (values: Partial<{peakTime: boolean; peakTimeSlots: Dayjs[]}>) => void;
	text?: ReactNode;
}> = ({onChange, peakTime, peakTimeSlots, text}) => {
	const handlePeakTime = (value: boolean) => {
		if (value) {
			return onChange({peakTime: value, peakTimeSlots: []});
		}

		return onChange({
			peakTime: value,
			peakTimeSlots: value
				? []
				: [
						dayjs().startOf("day").add(7, "hours").add(30, "minutes"),
						dayjs().startOf("day").add(17, "hours").add(30, "minutes"),
				  ],
		});
	};
	const handleUpdateTimeSlots = (newItems: Dayjs[]) =>
		onChange({peakTimeSlots: newItems.sort((a, b) => (a.isBefore(b) ? -1 : 1))});
	const handleAddTimeslot = () => {
		const minutes = peakTimeSlots.map(date => date.hour() * 60 + date.minute());
		const newItem = dayjs()
			.startOf("day")
			.add(
				!minutes.includes(14 * 60) ? 14 * 60 : Math.floor(minutes[minutes.length - 1] / 60) * 60 + 60,
				"minutes"
			);
		handleUpdateTimeSlots([...peakTimeSlots, newItem]);
	};
	const handleUpdateTimeslot = (index: number, value: Dayjs) => {
		const newItems = [...peakTimeSlots];
		newItems[index] = value;
		handleUpdateTimeSlots(newItems);
	};
	const handleDeleteTimeslot = (index: number) => {
		const newItems = [...peakTimeSlots];
		newItems.splice(index, 1);
		onChange({peakTimeSlots: newItems});
	};

	return (
		<div>
			<Checkbox
				label="PeakTime™"
				value={peakTime}
				onChange={handlePeakTime}
				text={
					text ?? (
						<Span>
							Our PeakTime™ smart algorithm will optimize your posts and shares by scheduling best times to
							receive the most attention.{" "}
							<Span color="blue" href="https://cvssupport.wpenginepowered.com/article/what-is-peaktime">
								Learn more
							</Span>
						</Span>
					)
				}
			/>
			{!peakTime && (
				<>
					<Span>Sharing Time Slots</Span>

					<div className={styles.sharingTimeSlotsContainer}>
						{peakTimeSlots.map((value, index) => (
							<PeakTimeSelect
								key={index}
								value={value}
								onChange={val => handleUpdateTimeslot(index, val)}
								onDelete={() => handleDeleteTimeslot(index)}
							/>
						))}
					</div>

					<SmallButton icon="add" value="Add Time Slot" onClick={handleAddTimeslot} border={false} invert />
				</>
			)}
		</div>
	);
};

const PeakTimeSelect: FC<{value: Dayjs; onChange: (value: Dayjs) => void; onDelete: () => void}> = ({
	value,
	onChange,
	onDelete,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [valueCpy, setValueCpy] = useState(value);

	useEffect(() => {
		if (!isOpen) setValueCpy(value);
	}, [value, isOpen]);

	const handleOpen = useCallback(
		open => {
			if (!open && valueCpy !== value) onChange(valueCpy);
			setIsOpen(open);
		},
		[value, valueCpy, onChange]
	);

	return (
		<AlerterWidget
			onOpen={handleOpen}
			isOpen={isOpen}
			labelComponent={
				<div className={styles.sharingTimeSlot}>
					<Span>{valueCpy.formatAs("time")}</Span>
					<Icon height={10} width={10} icon="close" onClick={onDelete} />
				</div>
			}
		>
			<HourPicker className={styles.hourPicker} value={valueCpy} onChange={setValueCpy} />
		</AlerterWidget>
	);
};

const ProfilePicSelect: FC<{
	account: Service;
	imageUrl: string;
	onClick(imageUrl: string): void;
}> = ({account, imageUrl, onClick}) => {
	const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
		switch (e.key) {
			case "Enter":
			case " ":
				onClick(imageUrl);
				break;
			default:
				return;
		}
		e.stopPropagation();
		e.preventDefault();
	};

	return (
		<div
			tabIndex={0}
			className={styles.profilePicContainer}
			onClick={() => onClick(imageUrl)}
			onKeyDown={handleKeyDown}
		>
			<img
				className={classnames(styles.uploadImageContainer, styles.social)}
				src={imageUrl}
				alt={`Profile picture from ${account}`}
			/>
			<Span color="blue">{accountNames[account]}</Span>
		</div>
	);
};

export const Account = (): ReactElement => {
	const user = useMyUser();
	const {data: profiles} = useQuery(GET_USER_CONNECTION_PROFILES);
	const [disconnect, {loading: disconnecting}] = useMutationToast(DISCONNECT_USER_SOCIAL);
	const [registerPageEvent] = useMutationToast(REGISTER_PAGE_EVENT);

	const [connectFacebookPage, {loading: connecting}] = useMutationToast(CONNECT_FACEBOOK_PAGE);
	const {data: availablePages, loading: loadingPages} = useQuery(GET_USER_CONNECTION_PAGES);

	const [updateUser, {loading: saving}] = useMutationToast(UPDATE_ME);
	const fbPages = availablePages?.userConnectionPages["facebook"];
	const {groups, loading: loadingGroups} = useGroupList();
	const navigate = useNavigate();
	const [passIsDirty, setPassIsDirty] = useState(false);
	const [confirmPassIsDirty, setConfirmPassIsDirty] = useState(false);
	const [expandedGroups, setExpandedGroups] = useState(false);
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const {update, val, inputFunc} = useDirtyCopy(user, {
		debounce: 2000,
		onUpdate: ({discard, changes}) =>
			updateUser({
				variables: {
					id: val.id,
					changes,
				},
				onCompleted: () => {
					discard();
				},
			}),
	});
	const connectedPage = useMemo(() => fbPages?.find(p => p.id === val.connections.facebookPage?.identifier), [
		fbPages,
		val.connections.facebookPage?.identifier,
	]);

	useEffect(() => {
		registerPageEvent({
			variables: {
				type: "settings",
				userId: user.id,
			},
		});
	}, [registerPageEvent, user.id]);

	const handleUserUpdate = useCallback(
		() =>
			updateUser({
				variables: {
					id: val.id,
					changes: {
						password,
						confirmPassword,
					},
				},
				onCompleted: () => {
					setPassIsDirty(false);
					setConfirmPassIsDirty(false);
					setPassword("");
					setConfirmPassword("");
				},
			}),
		[password, confirmPassword, updateUser, val.id]
	);
	const handleLogout = () => window.open(`${config.appUrl}/logout`, "_self");
	const userGroups = useMemo(
		() => groups.filter(g => val.groups.includes(g.value)).slice(0, expandedGroups ? groups.length : 5),
		[expandedGroups, groups, val.groups]
	);
	const fileUploadRef = useRef<HTMLInputElement | null>(null);

	const handleDisconnect = useCallback(
		type =>
			disconnect({
				variables: {
					type,
				},
				onCompleted: () => {
					registerPageEvent({
						variables: {
							type: `remove-${type.toLowerCase()}`,
							userId: user.id,
						},
					});
				},
			}),
		[user.id, registerPageEvent, disconnect]
	);

	const handleConnect = useCallback(type => {
		window.open(
			`${
				type === "twitter" ? config.twitterUrl : type === "facebook" ? config.facebookUrl : config.linkedinUrl
			}?redirect_override=settings`,
			"_self"
		);
	}, []);

	const {open, close} = useModal(() => {
		const handleUpdate = (imageUrl: string) => {
			update({profilePic: imageUrl});
			close();
		};

		const handleFileUpload = (file?: File) => {
			if (!file) return;

			updateUser({
				variables: {
					id: val.id,
					changes: {
						profilePicFile: file,
					},
				},
				onCompleted: () => {
					close();
				},
			});
		};
		const onUploadImageClick = () => {
			fileUploadRef?.current?.click();
		};

		return {
			size: "small",
			header: <h3>Select Profile Image</h3>,
			body: (
				<div className={styles.profilePicModalContainer}>
					<div className={styles.profilePicContainer}>
						<FileUpload
							className={styles.uploadImageContainer}
							type="image"
							ref={fileUploadRef}
							onlyIcon
							iconSize={24}
							value={undefined}
							onChange={handleFileUpload}
							disableTooltip={true}
						/>
						<Span color="blue" onClick={onUploadImageClick}>
							Upload Image
						</Span>
					</div>

					{(!!profiles?.userConnectionProfiles?.facebook?.imageUrl ||
						!!profiles?.userConnectionProfiles?.twitter?.imageUrl ||
						!!profiles?.userConnectionProfiles?.linkedin?.imageUrl) && (
						<>
							<div className={styles.textContainer}>
								<Span bold color="grey">
									OR
								</Span>
							</div>
							{(["linkedin", "facebook", "twitter"] as Service[]).map(
								n =>
									!!profiles?.userConnectionProfiles?.[n]?.imageUrl && (
										<ProfilePicSelect
											account={n}
											key={n}
											onClick={handleUpdate}
											imageUrl={profiles?.userConnectionProfiles?.[n].imageUrl}
										/>
									)
							)}
						</>
					)}
				</div>
			),
		};
	}, [val.id, profiles?.userConnectionProfiles, update, updateUser]);
	const onSetPassword = useCallback(pass => {
		setPassIsDirty(true);
		setPassword(pass);
	}, []);
	const onSetConfirmPassword = useCallback(pass => {
		setConfirmPassIsDirty(true);
		setConfirmPassword(pass);
	}, []);
	const [selectedPage, setSelectedPage] = useState<ConnectionProfile>();
	const {open: openConfirm, close: closeConfirm} = useConfirmModal(() => {
		const handleLink = () =>
			connectFacebookPage({
				variables: {
					id: selectedPage?.id,
					accessToken: selectedPage?.accessToken,
				},
				onCompleted: () => {
					closeConfirm();
					setSelectedPage(undefined);
				},
			});

		return {
			title: `Link a Facebook Page`,
			size: "medium",
			body: loadingPages ? (
				<Loading position="center" />
			) : (
				<div className={styles.connectPageContainer}>
					<Span color="grey">
						To link the Facebook page, ensure you&apos;re logged out of any personal account first. Using
						private browsing or a different browser can help avoid confusion.
					</Span>
					<Select
						key={selectedPage?.id}
						onChange={setSelectedPage}
						options={fbPages?.map(page => ({value: page, label: page.fullName})) ?? []}
						value={selectedPage}
					/>
				</div>
			),
			onConfirm: handleLink,
			confirmText: "Link",
			confirming: connecting,
		};
	}, [loadingPages, fbPages, selectedPage, connecting, connectFacebookPage]);

	const selectedGroups = useMemo<Option<number>[]>(
		() =>
			val?.groups
				?.filter(groupId =>
					groups.find(
						({value, allowSubscription}) => value === groupId && (user.role === "admin" || allowSubscription)
					)
				)
				.map(groupId => groups.find(({value}) => value === groupId) || {label: "", value: groupId}) ?? [],
		[val, groups, user.role]
	);
	const onSubscribeChange = useCallback(
		groups => {
			update({groups: groups.map(({value}) => value)});
		},
		[update]
	);

	const unsubscribedGroups = useMemo(
		() =>
			groups
				.filter(
					group =>
						(user.role === "admin" || group?.allowSubscription) &&
						!selectedGroups.find(selectedGroup => selectedGroup.value === group.value)
				)
				.map(group => ({
					value: group.value,
					label: group.label,
				})),
		[groups, selectedGroups, user.role]
	);

	return (
		<div className={styles.container}>
			<h3 className="space">Personal Account</h3>

			<Card className={styles.cardContainer}>
				<div className={styles.header}>
					<h4>Profile Info</h4>
					<Button icon="logout" value="Log Out" onClick={handleLogout} invert border={false} />
				</div>

				<div className={styles.profileInfo}>
					<UserAvatar userId={val.id} size="extraLarge" onEdit={open} />
					<div className={styles.info}>
						<Span2>{user.fullName}</Span2>
						<Span2>{user.email}</Span2>
						{user.role === "admin" && <Span3 color="grey">Logged in as Admin</Span3>}
					</div>
				</div>
				<Separator horizontal />
				<h4>Personal Settings</h4>
				<MultiColumn>
					<Text label="First Name" {...inputFunc("firstName")} />
					<Text label="Last Name" {...inputFunc("lastName")} />
					<Text label="Email" {...inputFunc("email")} />
				</MultiColumn>
				<Separator horizontal />
				<h4>Social Media Pages</h4>
				<SocialMedia
					account="linkedin"
					connection={val.connections.linkedin}
					name={`${
						profiles?.userConnectionProfiles?.linkedin?.fullName ?? val.connections.linkedin?.identifier
					}`}
					onClick={() =>
						profiles?.userConnectionProfiles?.linkedin?.url &&
						window.open(profiles?.userConnectionProfiles?.linkedin?.url, "_blank")
					}
					onLinkClick={() => handleConnect("linkedin")}
					onDisconnectClick={() => handleDisconnect("LINKEDIN")}
				/>
				<SocialMedia
					account="twitter"
					connection={val.connections.twitter}
					name={`@${val.connections.twitter?.identifier}`}
					onClick={() => window.open(`https://twitter.com/${val.connections.twitter?.identifier}`, "_blank")}
					onLinkClick={() => handleConnect("twitter")}
					onDisconnectClick={() => handleDisconnect("TWITTER")}
				/>
				<SocialMedia
					account="facebook"
					connection={val.connections.facebook}
					name={`/${
						profiles?.userConnectionProfiles?.facebook?.fullName ?? val.connections.facebook?.identifier
					}`}
					onClick={() =>
						window.open(
							`https://facebook.com/profile.php?id=${val.connections.facebook?.identifier}`,
							"_blank"
						)
					}
					onLinkClick={() => handleConnect("facebook")}
					onDisconnectClick={() => handleDisconnect("FACEBOOK")}
				/>
				<div />
				{val.connections.facebook?.connected && (
					<div>
						<h5 className="space">Available Facebook Pages</h5>
						<P>
							Connect a Page to allow PeakTime™ scheduled sharing and Share All™ for Facebook. Facebook does
							not allow sharing to individual profile pages via scheduled sharing.
						</P>
						{connectedPage ? (
							<>
								<P size={2}>
									<Span color="grey">Currently Connected Page: </Span>
									{connectedPage.fullName} - <Span href={connectedPage.url}>{connectedPage.url}</Span>
								</P>
								<Button
									onClick={() => disconnect({variables: {type: "FACEBOOK_PAGE"}})}
									value="Remove Facebook Page"
									loading={disconnecting}
								/>
							</>
						) : fbPages?.length > 0 ? (
							<Button onClick={openConfirm} value="Connect Facebook Page" />
						) : (
							<P>There are no available Facebook pages to select.</P>
						)}
					</div>
				)}

				<Separator horizontal />
			</Card>

			<Card className={styles.cardContainer}>
				<h4>Communication Preferences</h4>
				<div>
					<h5>Email Notifications</h5>
					<P>Emails will be sent to {user.email}</P>
					<Switch
						label="Get notified on Clearview Social’s products and services. (recommended)"
						{...inputFunc("marketingSubscribed")}
					/>
					<Switch
						label="Get notified on your subscriptions, collections and feed updates"
						{...inputFunc("emailSubscribed")}
					/>
				</div>
				<Separator horizontal />
				<div>
					<h5>Follow</h5>
					<div className={styles.header}>
						<Span color="grey">
							Follow to receive updates on your{" "}
							<Span color="blue" onClick={() => navigate("/feed")}>
								feeds
							</Span>
							.
						</Span>
						<Span color="grey">You&apos;re following {count(val.followedGroups, "group")}</Span>
					</div>
					{loadingGroups ? (
						<Loading position="center" />
					) : (
						userGroups.map(group => (
							<Switch
								key={group.value}
								label={group.label}
								value={val.followedGroups.includes(group.value)}
								onChange={() => update({followedGroups: toggle(group.value, val.followedGroups)})}
							/>
						))
					)}
					<InputRow position="center">
						<Button
							invert
							onClick={() => setExpandedGroups(c => !c)}
							value={expandedGroups ? "Show Less" : "Show All"}
							icon={expandedGroups ? "collapse" : "expand"}
							border={false}
						/>
					</InputRow>
				</div>
				<Separator horizontal />
				<div className={styles.groupSubscription}>
					<div>
						<h5>Manage group subscriptions</h5>
						<div className={styles.header}>
							<Span color="grey">
								{user.role !== "admin" &&
									"Your admins may make some groups available as subscriptions to receive additional content."}
								You can manage your group subscriptions here.
							</Span>
						</div>
					</div>
					{loadingGroups ? (
						<Loading position="center" />
					) : (
						<SearchableSelect
							className={styles.groupSearchableSelect}
							onChange={onSubscribeChange}
							value={selectedGroups}
							isMulti
							options={unsubscribedGroups}
							bare
							placeholder="Enter group name"
							pillColor="blue"
						/>
					)}
				</div>
			</Card>

			<Card className={styles.cardContainer}>
				<h4>Automated Actions</h4>
				<MultiColumn>
					<Select
						label="Timezone"
						options={timeZones.map(zone => ({value: zone.tzCode, label: zone.label}))}
						{...inputFunc("timeZone")}
					/>
					<div />
					<PeakTimeSelector peakTime={val.peakTime} peakTimeSlots={val.peakTimeSlots} onChange={update} />
					<Checkbox
						text="Content recommended by your company will be shared automatically."
						label="Virtual Assistant"
						{...inputFunc("virtualAssistant")}
					/>
				</MultiColumn>

				<Separator horizontal />
				<div>
					<h5>Password</h5>
					<Span4 color="grey">
						This is your Clearview Social Password only. It is not associated with any other social networks.
					</Span4>
				</div>
				<MultiColumn>
					<Text
						validate={passIsDirty ? validPassword : {check: () => false, required: true}}
						type="password"
						label="New Password"
						placeholder="Password"
						value={password}
						onChange={onSetPassword}
					/>
					<Text
						validate={
							confirmPassIsDirty ? validConfirmPassword(password) : {check: () => false, required: true}
						}
						type="password"
						label="Confirm Password"
						placeholder="Password"
						text="* Your password must be at least 8 characters, and contain at least 1 uppercase letter, 1 lowercase
					letter, and 1 number"
						value={confirmPassword}
						onChange={onSetConfirmPassword}
					/>
				</MultiColumn>
				<Button
					onClick={handleUserUpdate}
					value="Change Password"
					color="black"
					disabled={
						!!validPassword.check(password) || !!validConfirmPassword(password).check(confirmPassword)
					}
					loading={saving && !!confirmPassword}
				/>
			</Card>
		</div>
	);
};
