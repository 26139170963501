import {FC, useMemo} from "react";
import {useQuery} from "@apollo/client";
import classnames from "classnames";
import dayjs from "dayjs";

import {useCustomRange} from "../../../components/input/date-time/date-interval-picker";
import {GET_VISITORS} from "../../../data";
import {inflateVisitor} from "../../../data/visitor";
import {Card} from "../../../components/card";
import {Span, Span4} from "../../../components/text";
import {Loading} from "../../../components/loading";
import {EmployeeAdvocacyFilter, minDate} from "./index";
import {useFindOutWhoModal} from "../../feed/find-out-who-modal";
import {EmptyList} from "../../../components/empty-list";

import styles from "./employee-advocacy.module.scss";
const bStyles = classnames.bind(styles);

export const FindOutWhoCard: FC<{filter: EmployeeAdvocacyFilter}> = ({filter}) => {
	const dateRange = useCustomRange(filter.dateRange || "Max", minDate);
	const {start = dayjs(), end = dayjs()} = dateRange || {};
	const variables = useMemo(
		() => ({
			start: start.isBefore(minDate) ? minDate : start,
			end: end.isBefore(minDate) ? minDate : end,
			groups: filter.groups?.length ? filter.groups : undefined,
			network: filter.network,
		}),
		[start, end, filter.groups, filter.network]
	);
	const {data, loading} = useQuery(GET_VISITORS, {
		variables,
	});
	const visitors = useMemo(() => data?.visitors?.items?.map(visitor => inflateVisitor(visitor)) || [], [
		data?.visitors,
	]);

	const subtitle = start.isSame(end, "day")
		? start.formatAs("shortDate")
		: `${start.formatAs("shortDate")} - ${end.formatAs("shortDate")}`;

	const findOutWhoModal = useFindOutWhoModal({
		title: "Find Out Who",
		subtitle,
		visitors: data?.visitors?.items || [],
	});

	return (
		<Card className={styles.card}>
			<div className={styles.cardHeader}>
				<div className={styles.sameLine}>
					<h3>Find Out Who</h3>
					<Span4 color={"grey"}>{`${start.formatAs("shortDate")} - ${end.formatAs("shortDate")}`}</Span4>
				</div>
				{!!visitors.length && (
					<Span
						color={"blue"}
						onClick={findOutWhoModal.open}
						bold
					>{`See all ${visitors.length} visitors`}</Span>
				)}
			</div>
			<div className={classnames(styles.visitorsTable)}>
				<div className={classnames(styles.visitorsRow, styles.row)}>
					<Span bold className={styles.visitorsCell}>
						Company
					</Span>
					<Span bold className={bStyles(styles.visitorsCell, styles.contact)}>
						Contact
					</Span>
					<Span bold className={bStyles(styles.visitorsCell, styles.date)}>
						Date Visited
					</Span>
					<Span bold className={bStyles(styles.visitorsCell, styles.clicks)}>
						Clicks
					</Span>
				</div>
				{loading ? (
					<Loading className={styles.loader} position={"center"} />
				) : (
					<div className={styles.visitorsItems}>
						{visitors?.length ? (
							visitors.slice(0, 9).map((visitor, index) => (
								<div key={`${visitor.name}_${index}`} className={classnames(styles.visitorsRow, styles.row)}>
									<Span className={styles.visitorsCell}>{visitor.name}</Span>
									<Span className={bStyles(styles.visitorsCell, styles.contact)}>{visitor.contact}</Span>
									<Span className={bStyles(styles.visitorsCell, styles.date)}>
										{visitor.dateVisited || "-"}
									</Span>
									<Span className={bStyles(styles.visitorsCell, styles.clicks)}>{visitor.clicks}</Span>
								</div>
							))
						) : (
							<EmptyList className={styles.emptyMsg} text={"No known visitors found for this time period"} />
						)}
					</div>
				)}
			</div>
		</Card>
	);
};
