import {ReactElement, useEffect} from "react";
import {useQuery} from "@apollo/client";

import {GET_ME} from "../data";
import config from "../config";

type GainsightProviderProps = {
	children: ReactElement;
};

const appendAptrinsicScript = aptrinsicKey => {
	// Check if the script is already present in the document
	if (document.getElementById("gainsight-px-script")) {
		return;
	}

	// Define Aptrinsic function
	const i = "aptrinsic";
	window[i] =
		window[i] ||
		function (...args) {
			(window[i].q = window[i].q || []).push(args);
		};
	window[i].p = aptrinsicKey;
	window[i].c = aptrinsicKey;

	// Create and insert the script element
	const scriptElement = document.createElement("script");
	scriptElement.id = "gainsight-px-script"; // Ensure we only add one script
	scriptElement.async = true;
	scriptElement.src = `https://web-sdk.aptrinsic.com/api/aptrinsic.js?a=${aptrinsicKey}`;

	// Insert the script element before the first script tag, or append to the body if none exist
	const firstScriptTag = document.getElementsByTagName("script")[0];
	if (firstScriptTag) {
		firstScriptTag.parentNode?.insertBefore(scriptElement, firstScriptTag);
	} else {
		document.body.appendChild(scriptElement);
	}
};

export const GainsightProvider = ({children}: GainsightProviderProps): ReactElement => {
	const {data} = useQuery(GET_ME);

	const user = data?.me;

	useEffect(() => {
		if (!config.aptrinsic.enabled) {
			return;
		}
		appendAptrinsicScript(config.aptrinsic.key);
	}, []);

	useEffect(() => {
		if (!user || !globalThis.aptrinsic || !config.aptrinsic.enabled) {
			return;
		}

		globalThis.aptrinsic(
			"identify",

			{
				id: user.id,
				email: user.email,
				firstName: user.firstName,
				lastName: user.lastName,
				signupDate: user.created,
				plan: user.org.subscription,
				sfdcContactId: user.org.salesforceId,

				role: user.role,
				lastInvited: user.lastInvited,
				lastLogin: user.lastLogin,
				lastShared: user.lastShared,
				peakTime: user.peakTime,
				virtualAssistant: user.virtualAssistant,
				timeZone: user.timeZone,
				emailSubscribed: user.emailSubscribed,
				marketingSubscribed: user.marketingSubscribed,
			},

			{
				//Account Fields
				id: user.org.id,
				name: user.org.name,
				plan: user.org.subscription,
				created: user.org.created,
				type: user.org.type,
				csmUserId: user.org.csmUserId,
				primaryAdmin: user.org.primaryAdmin,
				sfdcId: user.org.salesforceId,

				emailBranding: user.org.features.emailBranding,
				facebookAnalytics: user.org.features.facebookAnalytics,
				instagramAnalytics: user.org.features.instagramAnalytics,
				rbac: user.org.features.rbac,
				salesforceLeadsSync: user.org.features.salesforceLeadsSync,
				sso: user.org.features.sso,
				uiBranding: user.org.features.uiBranding,
			}
		);
	}, [user]);

	return children;
};
