import React, {ReactElement, useEffect, useRef} from "react";

import {SVG} from "../images";
import {Input, InputComponent, MultiOptionProps, toggle, useId} from ".";

import styles from "./input.module.scss";

export const Checkbox = ({
	id: maybeId,
	className,
	value,
	onChange,
	disabled,
	...props
}: InputComponent<boolean | undefined, boolean>): ReactElement => {
	const id = useId(maybeId);
	const checkRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (!checkRef.current) return;
		checkRef.current.checked = value === true;
		checkRef.current.indeterminate = value === undefined;
	}, [value]);
	return (
		<Input
			id={id}
			className={[styles.labelLast, className, styles.checkboxInput, value !== false && styles.selected]}
			disabled={disabled}
			{...props}
		>
			<div className={styles.position}>
				<input
					ref={checkRef}
					id={id}
					type="checkbox"
					onChange={e => onChange(e.target.checked)}
					className={styles.checkbox}
					disabled={disabled}
				/>
				<SVG
					svg={{
						width: 18,
						height: 18,
						pathing:
							value === undefined ? (
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M2 0C0.895431 0 0 0.89543 0 2V16C0 17.1046 0.89543 18 2 18H16C17.1046 18 18 17.1046 18 16V2C18 0.895431 17.1046 0 16 0H2ZM2.287 9.712C2.479 9.904 2.71667 10 3 10H15C15.2833 10 15.5207 9.904 15.712 9.712C15.904 9.52067 16 9.28333 16 9C16 8.71667 15.904 8.479 15.712 8.287C15.5207 8.09567 15.2833 8 15 8H3C2.71667 8 2.479 8.09567 2.287 8.287C2.09567 8.479 2 8.71667 2 9C2 9.28333 2.09567 9.52067 2.287 9.712Z"
								/>
							) : value ? (
								<path d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM7.71 13.29C7.32 13.68 6.69 13.68 6.3 13.29L2.71 9.7C2.32 9.31 2.32 8.68 2.71 8.29C3.1 7.9 3.73 7.9 4.12 8.29L7 11.17L13.88 4.29C14.27 3.9 14.9 3.9 15.29 4.29C15.68 4.68 15.68 5.31 15.29 5.7L7.71 13.29Z" />
							) : (
								<path d="M15 16H3C2.45 16 2 15.55 2 15V3C2 2.45 2.45 2 3 2H15C15.55 2 16 2.45 16 3V15C16 15.55 15.55 16 15 16ZM16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0Z" />
							),
					}}
				/>
			</div>
		</Input>
	);
};

export function CheckboxGroup<T = string>({
	id: maybeId,
	className,
	value,
	onChange,
	options,
	...props
}: MultiOptionProps<T>): ReactElement {
	const id = useId(maybeId);
	const handleChange = (which: T): void => onChange(toggle(which, value));

	return (
		<Input id={id} {...props} className={[styles.checkboxGroup, className]}>
			{options.map((o, i) => (
				<Checkbox
					id={`${id}-${o.value}`}
					key={i}
					value={value.findIndex(v => v === o.value) !== -1}
					onChange={() => handleChange(o.value)}
					label={o.label}
				/>
			))}
		</Input>
	);
}
