import {useMemo, useState, useCallback} from "react";

import {EmptyList} from "../../components/empty-list";
import {BaseModalArgs, ModalHook, useModal} from "../../modals";
import {Button, InputRow, Text} from "../../components/input";
import {Span} from "../../components/text";
import {downloadCsv} from "../../utils/csv";
import {TopPost} from "../../data/collection";

import styles from "./popular-posts-modal.module.scss";

interface PopularPostsArgs<T> extends BaseModalArgs<T> {
	popularPosts: TopPost[];
}

const getFieldValue = (post, field: string) => {
	let value = post?.[field];

	if (field === "title") {
		value = post.openGraph.title;
	}

	if (field === "description") {
		value = post.openGraph.description;
	}

	return value || "-";
};

export function usePopularPostsModal<T>({popularPosts}: PopularPostsArgs<T>): ModalHook<T> {
	const [search, setSearch] = useState("");

	const csvData = useMemo(() => {
		const headers = ["Title", "Description", "Clicks", "EMV", "Shares"];
		const searchTrimmed = search.trim();
		const searchLC = search.toLowerCase();

		const items = popularPosts
			?.filter(
				posts =>
					!searchTrimmed ||
					posts?.openGraph?.title?.toLowerCase()?.includes(searchLC) ||
					posts?.openGraph?.description?.toLowerCase()?.includes(searchLC)
			)
			?.map(post => [
				getFieldValue(post, "title"),
				getFieldValue(post, "description"),
				getFieldValue(post, "clicks"),
				getFieldValue(post, "emv"),
				getFieldValue(post, "shares"),
			]);

		return [headers, ...items];
	}, [popularPosts, search]);

	const isEmpty = csvData.length <= 1;

	const handleExport = useCallback(() => {
		downloadCsv(csvData.slice(1), csvData[0], "popular-posts.csv");
	}, [csvData]);

	return useModal(
		() => ({
			size: "large",
			header: <h3>Popular Posts</h3>,
			body: (
				<>
					<InputRow>
						<Text icon="search" value={search} onChange={setSearch} placeholder="Search" />
						<Button onClick={handleExport} value="Export CSV" />
					</InputRow>

					<div className={styles.items}>
						{csvData.map((row, rowIndex) => (
							<div className={styles.row} key={rowIndex}>
								{row.map((rowItem, rowItemIndex) => (
									<Span key={rowItemIndex} bold={rowIndex === 0} className={styles.rowItem}>
										{rowItem}
									</Span>
								))}
							</div>
						))}
						{isEmpty && <EmptyList text={search ? "No matches found" : "No items"} />}
					</div>
				</>
			),
		}),
		[csvData, search, isEmpty, handleExport]
	);
}
