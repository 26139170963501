import * as Yup from "yup";

import {networkCaptionMaxLength, networkDescriptionMaxLength, networkTitleMaxLength} from "../../components";
import {PostServices} from "../../../../data";

const getOpengraphSchema = (n: PostServices) => ({
	comment: Yup.string()
		.notRequired()
		.test("comment-length", "Caption too long", value => (value?.length || 0) <= networkCaptionMaxLength[n])
		.test("comment-required", "Caption is required", (value, schema) => {
			const context = schema?.options?.context || {};
			const {image, video} = context.opengraphs?.[n] || {};
			const shareType = context.url ? "url" : image || video ? "media" : "text";
			if (shareType === "media") return true;
			return (
				!!context.url ||
				(n === "general" && context.perNetwork) ||
				!!value?.length ||
				!context.opengraphs?.[n]
			);
		}),
	video: Yup.string().url("Invalid video URL").notRequired(),
	image: Yup.string().url("Invalid image URL").notRequired(),
	title: Yup.string()
		.test(
			"title-length",
			"Title too long",
			value => !networkTitleMaxLength[n] || (value?.length || 0) <= (networkTitleMaxLength[n] ?? 0)
		)
		.notRequired(),
	description: Yup.string()
		.test(
			"title-length",
			"Description too long",
			value => (value?.length || 0) <= networkDescriptionMaxLength[n]
		)
		.notRequired(),
});

export const postValidationSchema = Yup.object().shape({
	opengraphs: Yup.object().shape({
		general: Yup.object().shape(getOpengraphSchema("general")),
		facebook: Yup.object().shape(getOpengraphSchema("facebook")).notRequired(),
		linkedin: Yup.object().shape(getOpengraphSchema("linkedin")).notRequired(),
		twitter: Yup.object().shape(getOpengraphSchema("twitter")).notRequired(),
	}),
});
