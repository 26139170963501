import React, {ReactElement, useCallback, useRef} from "react";
import {
	Chart,
	ChartArea,
	ChartData,
	ChartOptions,
	LegendElement,
	LegendItem,
	Plugin,
	Scale,
	InteractionMode,
} from "chart.js";
import {Line} from "react-chartjs-2";
import classnames from "classnames";
import dayjs from "dayjs";

import {ChartComponent} from ".";
import {nFormatter} from "../../format";
import {Button} from "../input";
import {AnyColor} from "../../types";

import styles from "./chart.module.scss";

const getGradient = (
	context: CanvasRenderingContext2D,
	chartArea: ChartArea,
	color: AnyColor,
	fill = true
): CanvasGradient => {
	const gradient = context.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
	if (styles[color] && fill) gradient.addColorStop(1, styles[`transparent-${color}`]);

	return gradient;
};

const getOrCreateTooltip = chart => {
	let tooltipEl = chart.canvas.parentNode.querySelector("div.chartjs_tooltip");

	if (!tooltipEl) {
		tooltipEl = document.createElement("div");
		tooltipEl.className = "chartjs_tooltip";
		chart.canvas.parentNode.appendChild(tooltipEl);
	}

	tooltipEl.style.color = "#000000";
	tooltipEl.style.opacity = 1;
	tooltipEl.style.pointerEvents = "none";
	tooltipEl.style.position = "absolute";
	tooltipEl.style.transform = "translate(10px, -50%)";
	tooltipEl.style.transition = "all .1s ease";
	tooltipEl.style.background = "#FFFFFF";
	tooltipEl.style.border = "1px solid #EEF1F5";
	tooltipEl.style.boxShadow = "0px 20px 30px 0px rgba(0, 0, 0, 0.15)";
	tooltipEl.style.borderRadius = "16px";
	tooltipEl.style.padding = "16px";
	tooltipEl.style.display = "flex";
	tooltipEl.style.flexDirection = "column";
	tooltipEl.style.gap = "4px";
	tooltipEl.style.zIndex = 1;

	return tooltipEl;
};

const externalTooltipHandler = context => {
	// Tooltip Element
	const {chart, tooltip} = context;
	const tooltipEl = getOrCreateTooltip(chart);

	// Hide if no tooltip
	if (tooltip.opacity === 0) {
		tooltipEl.style.opacity = 0;
		return;
	}

	// Remove the old content
	tooltipEl.replaceChildren();

	// Set Text
	if (tooltip.body) {
		const titleLines = tooltip.title || [];
		titleLines.forEach(title => {
			if (dayjs(title).isValid()) {
				title = dayjs(title).format("M.DD.YYYY");
			}
			const text = document.createTextNode(title);
			const span = document.createElement("span");
			span.style.color = "#818B9A";
			span.style.whiteSpace = "nowrap";
			span.appendChild(text);
			tooltipEl.appendChild(span);
		});

		tooltip.dataPoints.forEach(dataPoint => {
			const {dataset, formattedValue} = dataPoint;
			const {tooltipLabel = "", borderColor} = dataset;
			const div = document.createElement("div");
			const span1 = document.createElement("span");
			const span2 = document.createElement("span");

			span1.style.color = borderColor;
			span1.style.whiteSpace = "nowrap";
			div.style.fontWeight = "600";
			div.style.display = "flex";
			div.style.justifyContent = "space-between";
			div.style.gap = "20px";

			const label = document.createTextNode(tooltipLabel);
			span1.appendChild(label);
			div.appendChild(span1);

			const valueText = document.createTextNode(formattedValue);
			span2.appendChild(valueText);
			div.appendChild(span2);

			tooltipEl.appendChild(div);
		});
	}

	const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

	// Display, position, and set styles for font

	tooltipEl.style.font = tooltip.options.bodyFont.string;
	tooltipEl.style.opacity = 1;
	tooltipEl.style.left = positionX + tooltip.caretX + "px";
	tooltipEl.style.top = positionY + tooltip.caretY + "px";
};

export type OptionsType = {
	showLegend: boolean;
	subtitle: string;
	data: ChartData<"line">;
	useCustomTooltip?: boolean;
	interactionMode?: InteractionMode;
	xAxisLabel?: string;
	labels?: string[];
	formatLabel?: (value: string) => string;
};

const Options = ({
	showLegend,
	subtitle,
	data,
	useCustomTooltip,
	interactionMode,
	xAxisLabel,
	labels,
	formatLabel,
}: OptionsType): ChartOptions<"line"> => ({
	responsive: true,
	plugins: {
		subtitle: subtitle
			? {
					display: true,
					text: subtitle,
					align: "center",
					position: "left",
					padding: 10,
			  }
			: undefined,
		legend: {
			display: showLegend,
			align: "start",
			labels: {
				boxWidth: 18,
				boxHeight: 18,
				padding: 10,
				usePointStyle: true,
				pointStyle: "rectRounded",
				generateLabels: () =>
					data.datasets.reduce((items, {label, borderColor, data}) => {
						const total = ((data as unknown) as number[]).reduce((acc, c) => acc + c, 0);

						if (label) {
							items.push({
								text: `${Number.isInteger(total) ? total : total.toFixed(2)} ${label}`,
								fillStyle: borderColor as string,
								fontColor: "#666",
								hidden: false,
								lineWidth: 2,
								strokeStyle: borderColor as string,
								lineJoin: "round",
								lineCap: "round",
								pointStyle: "rectRounded",
								rotation: 0,
								datasetIndex: 0,
								borderRadius: 8,
							});
						}
						return items;
					}, [] as LegendItem[]),
			},
		},
		title: {
			display: false,
			padding: {
				bottom: 10,
			},
		},
		tooltip: useCustomTooltip
			? {
					enabled: false,
					position: "nearest",
					external: externalTooltipHandler,
			  }
			: {
					displayColors: false,
					callbacks: {
						label: () => "",
						title: context => `${context[0].parsed.y}` ?? context[0].label,
					},
			  },
	},
	scales: {
		y: {
			grid: {
				color: "rgba(28, 55, 90, 0.16)",
			},
			border: {
				// dash: [2, 4],
			},
			ticks: {
				precision: 0,
				color: "#818B9A",
				callback: value => nFormatter(value, 2),
			},
		},
		x: {
			...(xAxisLabel
				? {
						title: {
							display: true,
							text: xAxisLabel,
							color: "#818B9A",
							font: {
								size: 14,
								lineHeight: 1.2,
							},
						},
				  }
				: {}),
			ticks: {
				maxTicksLimit: 0,
				color: "#818B9A",
				callback: value => {
					if (formatLabel) {
						return formatLabel(labels?.[value]);
					}

					return labels?.[value];
				},
			},
		},
	},
	interaction: {
		intersect: false,
		mode: interactionMode ?? "nearest",
	},
});

const legendPlugin: Plugin = {
	beforeInit(chart: Chart & {legend: LegendElement<"line"> & Scale}) {
		const originalFit = chart.legend.fit;
		chart.legend.fit = function fit() {
			originalFit.bind(chart.legend)();
			this.height += 10;
		};
	},
	id: "legendPlugin",
};

export type GradientChartType = ChartComponent & {chartName: string; fillArea?: boolean} & Pick<
		OptionsType,
		"interactionMode" | "useCustomTooltip" | "xAxisLabel" | "formatLabel"
	>;

export const GradientChart = ({
	data,
	labels,
	showLegend = true,
	subtitle = "",
	className,
	chartName,
	fillArea,
	useCustomTooltip,
	interactionMode,
	xAxisLabel,
	formatLabel,
}: GradientChartType): ReactElement => {
	const chartData = {
		labels,
		datasets: data.map(({data, label, color, tooltipLabel}) => ({
			fill: true,
			label,
			data: data as number[],
			pointStyle: "circle",
			pointRadius: 1,
			pointHoverRadius: 6,
			pointBackgroundColor: styles[color],
			borderColor: styles[color],
			pointHitRadius: 30,
			tooltipLabel,
			backgroundColor: ({chart: {ctx, chartArea}}) =>
				chartArea && getGradient(ctx, chartArea, color, fillArea),
		})),
	} as ChartData<"line">;

	const chartRef = useRef<Chart<"line", unknown> | null>(null);
	const clickHandler = useCallback(() => {
		const link = document.createElement("a");
		link.download = `${chartName}.jpeg`;
		link.href = chartRef.current?.toBase64Image("image/jpeg", 1) ?? "";
		link.click();
	}, [chartRef, chartName]);

	return (
		<div className={styles.container}>
			<Line
				ref={chartRef}
				plugins={[legendPlugin]}
				className={classnames(className)}
				options={Options({
					showLegend,
					subtitle,
					data: chartData,
					useCustomTooltip,
					interactionMode,
					xAxisLabel,
					labels,
					formatLabel,
				})}
				data={chartData}
			/>

			<Button className={styles.downloadButton} icon="download" onClick={clickHandler} />
		</div>
	);
};
