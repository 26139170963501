import {Feature} from "../data/org";

import type {User} from "../data/user";

export interface Perms {
	isVisible?: string;
	adminOnly?: boolean;
	feature?: Feature | Feature[];
	internalOnly?: boolean;
}
export interface PathPerms extends Perms {
	path: string;
	end?: boolean;
}

export const itemAllowed = (item: Perms, user: User): boolean => {
	const isAdmin = user.role.toLowerCase() === "admin";
	const options = user.org.options;
	const isInternal = user.internal;

	if (item.adminOnly && !isAdmin) return false;
	if (item.internalOnly && !isInternal) return false;
	if (item.isVisible) return options[item.isVisible];
	if (!item.feature) return true;

	const features: Feature[] = Array.isArray(item.feature) ? item.feature : [item.feature];
	return features.some(f => user.org.features[f]);
};
