import {FC} from "react";
import {NavLink, Outlet, useLocation} from "react-router-dom";
import classnames from "classnames";

import {TabsProps} from "./tabs";
import {useMyUser} from "../data/user";
import {itemAllowed} from "../layout/perms";

import styles from "./side-nav.module.scss";

type SideNavProps = Omit<TabsProps, "scroll" | "header">;

export const SideNav: FC<SideNavProps> = ({className, onScroll, parentClassName, tabs}) => {
	const me = useMyUser();
	const location = useLocation();
	const pathname = location.pathname;
	const currentTab = tabs.find(t => t.path === pathname);
	const width = currentTab?.pageWidth ?? "auto";
	return (
		<div className={classnames(styles.container, parentClassName)}>
			<div className={styles.sidenav}>
				{tabs
					.filter(t => itemAllowed(t, me))
					.map(t => (
						<NavLink
							to={t.path}
							key={t.path}
							className={({isActive}) => classnames(styles.item, isActive && styles.active)}
							end={t.end}
						>
							<div className={styles.title}>{t.name}</div>
						</NavLink>
					))}
			</div>
			<div className={styles.scrollContent} onScroll={onScroll}>
				<div
					className={classnames(
						className,
						styles.page,
						width === "fit-content" && styles.fitContent,
						width === "full" && styles.fullWidth
					)}
				>
					<Outlet />
				</div>
			</div>
		</div>
	);
};
