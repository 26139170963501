import {gql} from "@apollo/client";

export const GET_AI_TEXT_INPUT_SESSION = gql`
	query AITextInputSession {
		aiTextInputSession {
			sessionId
			apiUrl
		}
	}
`;
