import {BadgeComponent} from "../../components/badge";
import {Collection, ShareEvent} from "../../data";

export const statusBadges: Record<string, BadgeComponent> = {
	draft: {
		color: "black",
		text: "Draft",
	},
	scheduled: {
		color: "black",
		text: "Scheduled",
	},
	delivered: {
		color: "blue",
		text: "Delivered",
	},
	failed: {
		color: "pink",
		text: "Failed To Share",
	},
};

export const networkMaxCharacters = {
	twitter: 255,
	linkedin: 3000,
	facebook: 3000,
	instagram: 3000,
	general: 3000,
};

export const getCollectionStatusBadge = (item: Collection, saved?: "saved" | "saving"): BadgeComponent => {
	const badge = item.sent
		? statusBadges.delivered
		: statusBadges[item.status === "approved" ? "scheduled" : item.status];
	if (saved) return {...badge, text: `${badge.text} - ${saved.uFirst()}`};
	return badge;
};

export const getShareStatusBadge = (item: ShareEvent, saved?: "saved" | "saving"): BadgeComponent => {
	const badge =
		item.sharedAt && !item.result
			? statusBadges.failed
			: item.sharedAt && item.result
			? statusBadges.delivered
			: item.scheduledFor
			? statusBadges.scheduled
			: statusBadges.draft;

	if (saved) return {...badge, text: `${badge.text} - ${saved.uFirst()}`};
	return badge;
};

export {Collections} from "./collections";
export {CollectionItem} from "./collection-item";
export {EditCollection} from "./edit";
export {EditShare} from "./share-editor";
export {Posts} from "./posts";
