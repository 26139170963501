import classnames from "classnames";

import {Icon} from "../images";
import {Button} from "../input";
import {Span} from "../text";

import styles from "./error.module.scss";

export const PageErrorBoundary = () => (
	<div className={styles.errorMessagePage}>
		<Icon viewBox="0 0 128 128" height={128} width={128} icon="error" />
		<div className={classnames(styles.message, styles.smallGap)}>
			<h3 className={styles.uhoh}>Uh, oh!</h3>
			<div className={styles.message}>
				<Span>We encountered an unexpected error.</Span>
				<Span>Try reloading the page or navigate to another section.</Span>
			</div>
		</div>
		<Button invert icon={"refresh"} onClick={() => window.location.reload()} value="Reload" />
	</div>
);

export const FallbackComponent = ({close}: {close: () => void}) => (
	<div className={classnames(styles.errorMessage)}>
		<Icon viewBox="0 0 128 128" height={128} width={128} icon="error" />
		<div className={classnames(styles.errorMessage, styles.smallGap)}>
			<h3 className={styles.uhoh}>Uh, oh!</h3>

			<div className={styles.errorMessage}>
				<Span>We encountered an unexpected error.</Span>
				<Span>Please close this popup and retry your last action again.</Span>
			</div>
		</div>
		<Button invert onClick={() => close()} value="Close" />
	</div>
);
