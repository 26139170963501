export const stripHtmlTags = (input: string | null | undefined): string =>
	input?.replace(/<[^>]*>/g, "") ?? "";

export const isURL = (input: string): boolean => /^(https?):\/\/[^\s/$.?#].[^\s]*$/.test(input.trim());

const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/g;
export const hasURL = (input: string): boolean => urlRegex.test(input.trim());
export const getURL = (input: string): string | null => {
	const matches = input.match(urlRegex);
	return matches ? matches[0] : null;
};
export const stripURL = (input: string): string => input.replace(urlRegex, "").trim();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const count = (count: number | any[] | null | undefined, single: string, plural = `${single}s`) => {
	if (Array.isArray(count)) count = count.length;
	return `${count ?? 0} ${count === 1 ? single : plural}`;
};

const PLACEHOLDERS = {
	id: "__id__",
	display: "__display__",
};

const defaultMarkup = "@[__display__](__id__)";

const escapeRegex = str => str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");

const markupToRegex = markup => {
	const escapedMarkup = escapeRegex(markup);
	const charAfterDisplay = markup[markup.indexOf(PLACEHOLDERS.display) + PLACEHOLDERS.display.length];
	const charAfterId = markup[markup.indexOf(PLACEHOLDERS.id) + PLACEHOLDERS.id.length];
	return escapedMarkup
		.replace(PLACEHOLDERS.display, `([^${escapeRegex(charAfterDisplay || "")}]+?)`)
		.replace(PLACEHOLDERS.id, `([^${escapeRegex(charAfterId || "")}]+?)`);
};

const findPositionOfCapturingGroup = (markup, parameterName) => {
	let indexDisplay = markup.indexOf(PLACEHOLDERS.display);
	let indexId = markup.indexOf(PLACEHOLDERS.id);
	if (indexDisplay < 0) indexDisplay = null;
	if (indexId < 0) indexId = null;
	if (indexDisplay !== null && indexId !== null) {
		return (parameterName === "id" && indexId <= indexDisplay) ||
			(parameterName === "display" && indexDisplay <= indexId)
			? 0
			: 1;
	}
	return 0;
};

const defaultRenderDisplay = (_id, display) => display;

export const renderMentions = (value, displayTransform = defaultRenderDisplay) => {
	if (!value) {
		return null;
	}

	let finalText = value;
	const regex = markupToRegex(defaultMarkup);

	const accOffset = 1;

	finalText.match(new RegExp(regex, "g"))?.forEach(text => {
		const match = new RegExp(regex).exec(text) || [];
		const idPos = accOffset + findPositionOfCapturingGroup(defaultMarkup, "id");
		const displayPos = accOffset + findPositionOfCapturingGroup(defaultMarkup, "display");

		const id = match[idPos];
		const display = displayTransform(id, match[displayPos]);

		finalText = finalText.replace(match[0], display);
	});

	return finalText;
};
