import {FC, useCallback} from "react";
import dayjs from "dayjs";

import {Text, Validator} from "../components/input";
import {useDirtyCopy} from "../dirty-copy";
import {P} from "../components/text";
import config from "../config";
import {useMutationToast} from "../toast";
import {SEND_FEEDBACK} from "../data";
import {ConfirmModal, ModalData} from "../modals/new";

interface SwitchRedirectModalProps {
	modal: ModalData;
}

const required: Validator<string | undefined> = {
	check: value =>
		value ? false : "Your valuable feedback is required prior to returning to the classic version",
	required: true,
};

export const SwitchRedirectModal: FC<SwitchRedirectModalProps> = ({modal}) => {
	const {val, inputFunc} = useDirtyCopy({feedback: ""});
	const [updateMe, {loading}] = useMutationToast(SEND_FEEDBACK);

	const handleSave = useCallback(
		() =>
			updateMe({
				variables: {message: val.feedback, changes: {options: {redirect: false}}},
				onCompleted: () => (window.location.href = config.appUrl),
			}),
		[updateMe, val.feedback]
	);

	return (
		<ConfirmModal
			title="Switch to the Classic version"
			modal={modal}
			onConfirm={handleSave}
			loading={loading}
			confirmText="Switch"
			disabled={!val.feedback}
		>
			<P size={1} color="pink" className="space">
				{Math.ceil(dayjs(config.classicExpirationDate).diff(dayjs(), "days", true))} days remaining
			</P>
			<P size={2}>
				We hope you&apos;ve been enjoying the new redesign. If you decide to return to our classic version, we
				would be grateful for your feedback on your decision to return to the classic version. Please be aware
				that Clearview Social&apos;s classic version will no longer be available after{" "}
				{config.classicExpirationDate.format("MMMM Do, YYYY")}, and all users will be onboarded to the new
				redesign application.
			</P>
			<Text
				type="textarea"
				label="Your feedback"
				placeholder="Tell us what you think"
				validate={required}
				{...inputFunc("feedback")}
			/>
		</ConfirmModal>
	);
};
