import {KeyboardEvent, ReactElement, useMemo} from "react";
import classnames from "classnames";
import {Dayjs} from "dayjs";

import {InputComponent} from "..";
import {Span} from "../../text";

import styles from "./date-picker.module.scss";

const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

export interface DatePickerComponent extends InputComponent<Dayjs> {
	min?: Dayjs;
	max?: Dayjs;
	displayMonth: Dayjs;
}

export const DatePicker = ({value, onChange, min, max, displayMonth}: DatePickerComponent): ReactElement => {
	const weeks = useMemo(() => displayMonth.getWeeksInRange(), [displayMonth]);
	const handleChange = (canClick: boolean, isSelected: boolean, day: Dayjs) => {
		if (!canClick || isSelected) return;
		onChange(
			day
				.startOf("day")
				.add(Number(value.format("HH")), "hours")
				.add(Number(value.format("mm")), "minutes")
		);
	};

	const week = week =>
		week.days.map(day => {
			const d = day.format("DD");
			const canClick =
				(min ? day.isSameOrAfter(min, "day") : true) && (max ? day.isSameOrBefore(max, "day") : true);
			const isSelected = value.isSame(day, "day");

			return (
				<div
					tabIndex={canClick ? 0 : undefined}
					key={day.unix()}
					className={classnames(
						styles.weekContainer,
						isSelected && styles.selectedDay,
						!canClick && styles.disabledDay
					)}
					onClick={() => handleChange(canClick, isSelected, day)}
					onKeyDown={(e: KeyboardEvent<HTMLDivElement>) =>
						(e.key === "Enter" || e.key === " ") && handleChange(canClick, isSelected, day)
					}
				>
					<Span color={isSelected ? "blue" : canClick ? "black" : "grey"}>{d}</Span>
				</div>
			);
		});

	return (
		<div className={styles.datePicker}>
			{days.map(day => (
				<div className={styles.dayContainer} key={day}>
					<Span color="grey">{day}</Span>
				</div>
			))}
			{weeks.map(week)}
		</div>
	);
};
