import {ReactElement, useCallback, useMemo, useState} from "react";
import dayjs from "dayjs";

import {Page} from "../../layout";
import {createDays, getSlotsParsedByEvents, timeSlots, getBiggestValue} from "../../functions/peaktime";
import Grid from "./grid";
import Results from "./results";
import VariableSetters from "./variable-setters";
import DayValues from "./day-values";
import {Button, InputRow} from "../../components/input";
import HourValues, {hourValue} from "./hour-values";
import Rules from "./rules";

// if we have an event in a day, we reduce the value of the day by 20%
const dayImpact = 0.2;

const eventSpeadPenalty = 4;
const longHourImpact = 0.1;
const shortHourImpact = 0.5;

// value in %
const maxTimeLostOverTime = 90;

const defaultHoursValue = {
	default: {
		0: 10,
		1: 5,
		2: 5,
		3: 5,
		4: 10,
		5: 40,
		6: 80,
		7: 90,
		8: 100, //
		9: 100,
		10: 100,
		11: 100,
		12: 100, //
		13: 90,
		14: 100, //
		15: 100,
		16: 100, //
		17: 95,
		18: 100, //
		19: 100, //
		20: 90,
		21: 80,
		22: 70,
		23: 50,
	},
};

export const Peaktime = (): ReactElement => {
	const [events, setEvents] = useState<string[]>([]);
	const [dayValuesObject, setDayValuesObject] = useState({
		Monday: 98,
		Tuesday: 100,
		Wednesday: 100,
		Thursday: 98,
		Friday: 100,
		Saturday: 80,
		Sunday: 85,
	});
	const [hoursValue, setHoursValue] = useState<hourValue>(defaultHoursValue);

	const [startDate, setStartDate] = useState(dayjs().startOf("day").format("YYYY-MM-DDTHH:mm"));
	const [totalDays, setTotalDays] = useState(7);
	const [timeSlotsPerHour, setTimeSlotsPerHour] = useState(4);
	const timeSlotInMinutes = useMemo(() => 60 / timeSlotsPerHour, [timeSlotsPerHour]);

	const days = useMemo(() => createDays(totalDays, dayjs(startDate)), [totalDays, startDate]);

	const daysObject = useMemo(
		() =>
			days.map(day => ({
				date: day.format("YYYY-MM-DD"),
				day: day.format("dddd"),
				slots: timeSlots({
					day,
					timeSlotsPerHour,
					timeSlotInMinutes,
					hoursValue,
				}),
				value:
					dayValuesObject[day.format("dddd")] -
					day.diff(startDate, "days") * ((100 - maxTimeLostOverTime) / totalDays),
			})),
		[days, timeSlotsPerHour, timeSlotInMinutes, hoursValue, startDate, totalDays, dayValuesObject]
	);

	const gridElements = useMemo(
		() =>
			getSlotsParsedByEvents({
				startDate: dayjs(startDate),
				daysObject,
				events,
				dayImpact,
				eventSpeadPenalty,
				longHourImpact,
				timeSlotsPerHour,
				shortHourImpact,
			}),
		[daysObject, events, startDate, timeSlotsPerHour]
	);

	const biggestValueDate = useMemo(() => getBiggestValue(gridElements, timeSlotsPerHour), [
		gridElements,
		timeSlotsPerHour,
	]);
	const selectNextEvent = useCallback(() => {
		setEvents(prev => [...prev, `${biggestValueDate.date}T${biggestValueDate.time}`]);
	}, [biggestValueDate]);

	return (
		<Page title="Peaktime">
			<Rules
				eventSpeadPenalty={eventSpeadPenalty}
				maxTimeLostOverTime={maxTimeLostOverTime}
				dayImpact={dayImpact}
				longHourImpact={longHourImpact}
				shortHourImpact={shortHourImpact}
			/>
			<VariableSetters
				{...{
					startDate,
					setStartDate,
					totalDays,
					setTotalDays,
					timeSlotsPerHour,
					setTimeSlotsPerHour,
					shortHourImpact,
				}}
			/>
			<div className="space" />
			<InputRow>
				<Button
					invert
					value="Reset Events"
					onClick={() => {
						setEvents([]);
					}}
				/>
				<DayValues dayValuesObject={dayValuesObject} setDayValuesObject={setDayValuesObject} />
				<HourValues hoursValue={hoursValue} setHoursValue={setHoursValue} />
			</InputRow>
			<Grid
				gridElements={gridElements}
				days={days}
				timeSlotsPerHour={timeSlotsPerHour}
				biggestValueDate={biggestValueDate}
			/>
			<Button onClick={selectNextEvent} value="Select next peak time event" />
			<Results biggestValueDate={biggestValueDate} events={events} />
		</Page>
	);
};
