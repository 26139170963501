import {ReactElement, useCallback, useEffect, useMemo, useRef, useState} from "react";

import {HidablePanel} from "../../components/hidable-panel";
import {useMyUser} from "../../data";
import {UserSpan} from "../../components/user-avatar";
import {useToastApi} from "../../api";
import {Badge} from "../../components/badge";
import {RssFeedPost, GET_RSS_FEED_POSTS, inflateRssFeedPost} from "../../data/rssFeed";
import {useDebounce} from "../../debounce";
import {FeedPost} from ".";
import {defaultLoadingProps as loadingProps, usePaginatedQuery} from "../../paginated-query";
import {TreeView} from "./tree-view";
import {P, P4, Span, Span1} from "../../components/text";
import {Button, InputRow, Text} from "../../components/input";
import {FeedEmptyList} from "./feed-empty-list";
import {useNewModal as useModal} from "../../modals";
import {AddFeedModal} from "./add-feed-modal";
import {useMutationToast} from "../../toast";
import {REGISTER_PAGE_EVENT} from "../../data/badges";
import {AutoQueueModal} from "./auto-queue-modal";
import {Loading} from "../../components/loading";

import styles from "./feed.module.scss";

type FeedSource = "Manzama" | "JDSupra";
const suggestToAdminLabel = "Suggested to Admins";

export interface RSSFeed {
	id: number;
	label: string;
	bookmarkId?: number;
	source?: FeedSource;
	disabled?: boolean;
	dayOfWeek?: number[];
	autoFeed?: boolean;
}

/* This has to be a type so TreeView accepts it. */
type RSSFeeds = {
	Bookmarks: RSSFeed[];
	"Industry News": Record<string, RSSFeed[]>;
	"RSS Feeds": RSSFeed[];
};

function inflateFeed(this: Record<number, number> | void, raw): RSSFeed {
	return {
		id: raw.feed_id,
		label: raw.title,
		disabled: raw?.feed?.most_recent_status === "unparsed",
		bookmarkId: raw.user_feed_id ?? this?.[raw.feed_id],
		source: raw.is_manzama_feed ? "Manzama" : raw.is_jdsupra_feed ? "JDSupra" : undefined,
		dayOfWeek: (raw.day_of_week || "").split(",").filter(Boolean).map(Number),
		autoFeed: !!raw.autofeed,
	};
}

export const Explore = (): ReactElement => {
	const me = useMyUser();
	const {del, get, post} = useToastApi();
	const [feed, setFeed] = useState<RSSFeed | undefined>();
	const feedItemRef = useRef();
	const [feedIds, setFeedIds] = useState<number[]>([]);
	const [feeds, setFeeds] = useState<RSSFeeds>();
	const [search, setSearch] = useState("");
	const [articleSearch, setArticleSearch] = useState("");
	const [registerPageEvent] = useMutationToast(REGISTER_PAGE_EVENT);
	const [loading, setLoading] = useState(false);
	const articleSearchDebounced = useDebounce(articleSearch, 400);
	const feedsById = useMemo(() => {
		if (!feeds) return {};
		const ret: Record<number, string> = {};
		[...Object.values(feeds["Industry News"]).flat(), ...feeds["RSS Feeds"], ...feeds["Bookmarks"]].forEach(
			f => (ret[f.id] = f.label)
		);
		return ret;
	}, [feeds]);
	const [filteredFeedIds, includeSuggestedToAdmins] = useMemo(
		() => [feedIds.filter(id => id !== undefined), feedIds.some(value => value === undefined)],
		[feedIds]
	);

	const renderPost = useCallback(
		(r: RssFeedPost): ReactElement => (
			<FeedPost
				key={r.id}
				post={{...r, createdAt: r.sharedAt}}
				title={<Span1>{feedsById[r.feedId]}</Span1>}
				comment={
					!!r.suggestedBy && <UserSpan before="Suggested by" userId={r.suggestedBy} color="pink" size={2} />
				}
				suggested={!!r.suggestedBy}
				rss
			/>
		),
		[feedsById]
	);

	const {handleScroll, render} = usePaginatedQuery<RssFeedPost>(GET_RSS_FEED_POSTS, {
		loadingProps,
		inflateItem: inflateRssFeedPost,
		variables: {
			ids: filteredFeedIds,
			search: articleSearchDebounced,
			includeSuggestedToAdmins,
		},
		skip: !feedIds.length,
		renderItem: renderPost,
		empty: <FeedEmptyList text="Customize your feed to start exploring content." />,
	});

	const loadFeeds = useCallback(
		(id: number) => {
			setLoading(true);
			get(
				"feeds",
				`users/${id}/feeds?show_global_content_feeds=1&show_org_added_feeds=1&show_content_connect_feeds=1`,
				res => {
					setFeedIds(c => {
						if (c.length) return c;

						if (res?.userFeeds?.length)
							return res.userFeeds
								.filter(f => f?.feed?.most_recent_status !== "unparsed")
								.map(f => f.feed_id);
						const id = res?.globalFeeds?.org_feeds?.[0]?.feeds?.[0]?.feed_id;
						return id ? [id] : [];
					});

					const bookmarkedIds: Record<number, number> = {};
					const bookmarks = [
						...(me.role === "admin" ? [{id: undefined, label: suggestToAdminLabel}] : []),
						...(res?.userFeeds?.map(f => {
							const r = inflateFeed(f);
							if (r.bookmarkId) bookmarkedIds[r.id] = r.bookmarkId;
							return r;
						}) ?? []),
					];

					const industryNews: Record<string, RSSFeed[]> = {};
					[...(res?.globalFeeds?.jdsupra ?? []), ...(res?.globalFeeds?.manzama ?? [])].forEach(c => {
						industryNews[c.title] ||= [];
						industryNews[c.title].push(...c.feeds.map(inflateFeed, bookmarkedIds));
					});

					setFeeds({
						Bookmarks: bookmarks,
						"RSS Feeds": res?.globalFeeds?.org_feeds?.[0]?.feeds?.map(inflateFeed, bookmarkedIds) ?? [],
						"Industry News": industryNews,
					});

					setLoading(false);
				},
				() => setLoading(false)
			);
		},
		[get, me]
	);

	const onSuccess = useCallback(() => {
		loadFeeds(me.id);
	}, [loadFeeds, me]);

	useEffect(() => {
		if (!me) return;

		registerPageEvent({
			variables: {
				type: "industry",
				userId: me.id,
			},
		});
		loadFeeds(me.id);
	}, [me, loadFeeds, registerPageEvent]);
	const {modal, open} = useModal({});
	const [autoQueueOpen, setAutoQueueModalOpen] = useState(false);
	const Panel = useCallback(() => {
		const handleBookmark = (id: number, bookmarkId?: number) => {
			if (!feeds) return;
			if (bookmarkId) {
				del("remove bookmark", `users/${me.id}/feeds/${bookmarkId}`, undefined, () => loadFeeds(me.id));
			} else {
				const feed = [...Object.values(feeds["Industry News"]).flat(), ...feeds["RSS Feeds"]].find(
					f => f.id === id
				);
				if (!feed) return;
				const json = {feed_id: id, title: feedsById[id]};
				post("add bookmark", `users/${me.id}/feeds`, json, () => loadFeeds(me.id));
			}
		};
		const handleAutoQueue = (feedId: number, el) => {
			setFeed(feeds?.Bookmarks?.find(f => f.id === feedId));
			feedItemRef.current = el;
			setAutoQueueModalOpen(true);
		};
		const scheduled = feeds?.Bookmarks?.reduce(
			(acc, f) => (f.autoFeed && f.dayOfWeek?.length ? [...acc, Number(f.bookmarkId)] : acc),
			[] as number[]
		);

		return (
			<div className={styles.panel}>
				<div className={styles.header}>
					<h4>Customize your Feed</h4>
					<P4 color="grey">Pick and choose from various industry updates.</P4>
					<Text value={search} onChange={setSearch} icon="search" placeholder="Search feed" />
					{loading && <Loading className={styles.loader} size="small" />}
				</div>
				<TreeView
					items={feeds ?? {}}
					onSelect={setFeedIds}
					selected={feedIds}
					scheduled={scheduled}
					onBookmark={handleBookmark}
					onCalendarClick={handleAutoQueue}
					search={search}
				/>
				<Button invert value="Add Feed" onClick={open} className={styles.feedButton} />
			</div>
		);
	}, [open, del, feedIds, feeds, feedsById, loadFeeds, me.id, post, search, loading]);

	const header = (
		<InputRow position="right">
			{feedIds.length !== 0 && (
				<>
					<P>Showing:</P>
					<div className={styles.showingContainer}>
						<Badge solid text={feedsById[feedIds[0]]} color="blue" key={feedIds[0]} />
						{feedIds.length > 1 && (
							<Span>
								+{feedIds.length - 1} more {feedIds.length === 2 ? "bookmark" : "bookmarks"}
							</Span>
						)}
					</div>
				</>
			)}
			<Text value={articleSearch} onChange={setArticleSearch} icon="search" placeholder="Search article" />
		</InputRow>
	);

	const onAutoQueueClose = useCallback(
		updated => {
			setAutoQueueModalOpen(false);

			if (updated) onSuccess();
		},
		[onSuccess]
	);

	return (
		<HidablePanel header={header} Panel={Panel} what="Feed List" onScroll={handleScroll}>
			{render}
			<AddFeedModal modal={modal} onSuccess={onSuccess} />
			<AutoQueueModal
				isOpen={autoQueueOpen}
				onClose={onAutoQueueClose}
				feed={feed}
				reference={feedItemRef?.current}
			/>
		</HidablePanel>
	);
};
