import {FC} from "react";
import classnames from "classnames/bind";

import {Color, EmptyComponent} from "../../types";
import {Icon, IconType} from "../images";

import styles from "./badge.module.scss";
const bStyles = classnames.bind(styles);

export interface BadgeComponent extends EmptyComponent {
	color: Color;
	text: string | number;
	outline?: boolean;
	solid?: boolean;
	size?: "small" | "regular";
	icon?: IconType;
}

export const Badge: FC<BadgeComponent> = ({
	className,
	color,
	icon,
	outline,
	solid,
	size = "regular",
	text,
}) => (
	<div
		tabIndex={0}
		className={bStyles(className, {
			[color]: true,
			container: true,
			outline,
			solid,
			[size]: true,
		})}
	>
		{icon && <Icon height={18} icon={icon} />}
		{text}
	</div>
);
