import React, {useMemo, useState, useCallback, FC} from "react";
import dayjs from "dayjs";

import {Modal, ModalData} from "../../../modals/new";
import {EmployeeAdvocacyFilter, minDate} from "./index";
import {Button, RadioGroup, Separator} from "../../../components/input";
import {Span1, Span4} from "../../../components/text";
import {useMutationToast, useToast} from "../../../toast";
import {SEND_EMAIL_CSV} from "../../../data/user";
import {useCustomRange} from "../../../components/input/date-time/date-interval-picker";

import styles from "./export-report-modal.module.scss";

interface ExportReportArgs {
	filter: EmployeeAdvocacyFilter;
	modal: ModalData;
}

type EmailType = "SUMMARY" | "SHARES" | "CLICKS" | "VISITORS";

const reportTypes = [
	{value: "SUMMARY", label: "Share & Click Totals by User"},
	{value: "SHARES", label: "Share Records"},
	{value: "CLICKS", label: "Click Records"},
	{value: "VISITORS", label: "LeadForensics Visitor Records"},
] as const;

export const ExportReportModal: FC<ExportReportArgs> = ({filter, modal}) => {
	const dateRange = useCustomRange(filter.dateRange || "Max", minDate);
	const {start = dayjs(), end = dayjs()} = dateRange || {};
	const [reportType, setReportType] = useState<EmailType>("SUMMARY");
	const [csvEmail, {loading}] = useMutationToast(SEND_EMAIL_CSV);
	const toast = useToast();
	const variables = useMemo(
		() => ({
			startDate: start,
			endDate: end,
			type: reportType,
			groupIds: filter.groups || [],
		}),
		[start, end, filter?.groups, reportType]
	);
	const handleExport = useCallback(() => {
		csvEmail({
			variables,
			onCompleted: () => {
				modal.close();
				toast({
					color: "blue",
					text: "Exporting your results into a csv file. It should arrive in your inbox in a few minutes.",
				});
			},
		});
	}, [csvEmail, variables, modal, toast]);

	if (!modal.open) return null;

	return (
		<Modal
			modal={modal}
			header={
				<div className={styles.header}>
					<h3>Export & Email CSV</h3>
					<Span4 color={"grey"}>{`${start.formatAs("shortDate")} - ${end.formatAs("shortDate")}`}</Span4>
				</div>
			}
			size="fit-content"
			className={styles.exportReportModal}
		>
			<div className={styles.content}>
				<div className={styles.optionsCt}>
					<Span1 bold>Choose report type:</Span1>
					<RadioGroup options={reportTypes} value={reportType} onChange={setReportType} />
				</div>
				<Separator horizontal />
				<div className={styles.toolbar}>
					<Button value="Cancel" invert onClick={modal.close} />
					<Button value="Email CSV" onClick={handleExport} loading={loading} />
				</div>
			</div>
		</Modal>
	);
};
