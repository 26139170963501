import {FC, useMemo} from "react";
import {useQuery} from "@apollo/client";
import classnames from "classnames";
import dayjs from "dayjs";

import {
	formatDateInterval,
	formatDateIntervalShort,
	useCustomRange,
} from "../../../components/input/date-time/date-interval-picker";
import {Card} from "../../../components/card";
import {P, Span1, Span, Span2, Span3, Span4} from "../../../components/text";
import {Loading} from "../../../components/loading";
import {minDate, CompanyAnalyticsFilter} from "./index";
import {EmptyList} from "../../../components/empty-list";
import {GET_COMPANY_LINKEDIN_SHARE_STATS, ShareStats} from "../../../data/analytics";
import {OpengraphMedia} from "../../../components/opengraph";
import {Icon} from "../../../components/images";
import {LinkedinPostsModal} from "./linkedin-posts-modal";
import {useModal} from "../../../modals/new";
import {InputRow} from "../../../components/input";

import styles from "./company.module.scss";

const LIMIT = 5;

interface Stat {
	value: string;
	format?: (v: number) => string;
	label: string;
}

const stats: Stat[] = [
	{
		label: "Views",
		value: "views",
	},
	{
		label: "Clicks",
		value: "clicks",
	},
	{
		label: "Reactions",
		value: "reactions",
	},
	{
		label: "Comments",
		value: "comments",
	},
	{
		label: "Reposts",
		value: "reposts",
	},
	{
		label: "Eng. Rate",
		format: v => `${Math.round(v * 100)}%`,
		value: "engagement",
	},
];

const renderPost = (post, index, isComparison) => {
	const opengraph = post.shareEvent?.share?.opengraphs?.general || {};

	return (
		<div key={`${index}-${post.shareEvent.id}`} className={classnames(styles.post, "space")}>
			{opengraph.image || opengraph.video ? (
				<OpengraphMedia className={styles.image} openGraph={opengraph} width={64} height={50} />
			) : (
				<div className={styles.image}>
					<img src="/default-image.png" alt="" />
				</div>
			)}
			<div className={styles.text}>
				<div className={styles.postHeader}>
					<a
						href={`${window?.location?.origin}/collections/posts/${post.shareEvent?.share?.id}`}
						rel="noopener noreferrer"
						target="_blank"
					>
						<Icon viewBox={"0 0 16 16"} width={16} height={16} icon={`filled-linkedin`} />
					</a>
					<P title={opengraph.title} bold href={post.shareEvent.share.url} trim={1}>
						{opengraph.title}
					</P>
				</div>
				{(opengraph.comment || opengraph.description) && (
					<Span4 title={opengraph.comment || opengraph.description} trim={2}>
						{opengraph.comment || opengraph.description}
					</Span4>
				)}
			</div>
			{!isComparison && (
				<div className={styles.statsContainer}>
					{stats.map(({value, format, label}) => (
						<div key={`${label}`} className={styles.postStats}>
							<Span2 bold>{format ? format(post[value]) : post[value]}</Span2>
							<Span3 color="grey">{label}</Span3>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export const LinkedinPostsCard: FC<{filter: CompanyAnalyticsFilter}> = ({filter}) => {
	const dateRange = useCustomRange(filter?.dateRange || "Max", minDate);
	const {start = dayjs(), end = dayjs()} = dateRange || {};
	const comparisonInterval = useCustomRange(filter.comparisonDateRange, minDate);
	const {start: comparisonStart, end: comparisonEnd} = comparisonInterval || {};
	const {open, modal} = useModal({});
	const variables = useMemo(
		() => ({
			start,
			end,
			accounts: filter?.accounts?.length ? filter.accounts : undefined,
			limit: LIMIT,
		}),
		[filter?.accounts, start, end]
	);
	const comparisonQueryVariables = useMemo(
		() => ({
			start: comparisonStart,
			end: comparisonEnd,
			accounts: filter?.accounts?.length ? filter.accounts : undefined,
			limit: LIMIT,
		}),
		[filter?.accounts, comparisonStart, comparisonEnd]
	);
	const {data, loading} = useQuery(GET_COMPANY_LINKEDIN_SHARE_STATS, {
		variables,
		skip: !variables.start || !variables.end,
	});
	const posts = useMemo(() => data?.companyLinkedInShareStats?.items || [], [
		data?.companyLinkedInShareStats,
	]) as ShareStats[];

	const {data: comparisonData, loading: comparisonQueryLoading} = useQuery(GET_COMPANY_LINKEDIN_SHARE_STATS, {
		variables: comparisonQueryVariables,
		skip: !comparisonQueryVariables.start || !comparisonQueryVariables.end,
	});
	const comparisonPosts = useMemo(() => comparisonData?.companyLinkedInShareStats?.items || [], [
		comparisonData?.companyLinkedInShareStats,
	]) as ShareStats[];

	return (
		<>
			<Card className={classnames(styles.card, styles.postsCard)}>
				<div className={styles.cardHeader}>
					<InputRow className={styles.headerTitle}>
						<h3>LinkedIn Post Breakdown</h3>
						<Span4 color="grey">
							{formatDateIntervalShort(start, end)}
							{comparisonInterval && ` vs ${formatDateIntervalShort(comparisonStart, comparisonEnd)}`}
						</Span4>
					</InputRow>
					{!!(posts?.length || comparisonPosts?.length) && (
						<Span color={"blue"} onClick={open} bold>
							See all
						</Span>
					)}
				</div>
				<div className={styles.postsContainer}>
					<div className={styles.postsTable}>
						{comparisonInterval && (
							<Span1 className={styles.listHeader}>{formatDateInterval(start, end)}</Span1>
						)}
						{loading ? (
							<Loading className={styles.loader} position={"center"} />
						) : posts?.length ? (
							posts.map((post, index) => renderPost(post, index, !!comparisonInterval))
						) : (
							<EmptyList
								className={styles.emptyMsg}
								text={"No posts have been shared during this time period."}
							/>
						)}
					</div>
					{comparisonInterval && (
						<div className={styles.postsTable}>
							<Span1 className={styles.listHeader}>
								{formatDateInterval(comparisonStart, comparisonEnd)}
							</Span1>
							{comparisonQueryLoading ? (
								<Loading className={styles.loader} position={"center"} />
							) : comparisonPosts?.length ? (
								comparisonPosts.map((post, index) => renderPost(post, index, !!comparisonInterval))
							) : (
								<EmptyList
									className={styles.emptyMsg}
									text={"No posts have been shared during this time period."}
								/>
							)}
						</div>
					)}
				</div>
			</Card>
			<LinkedinPostsModal filter={filter} modal={modal} />
		</>
	);
};
