import {ReactElement, useCallback, useMemo, useState} from "react";
import {useQuery} from "@apollo/client";

import {Card} from "../../../../components/card";
import {P, Span, Span2, Span3} from "../../../../components/text";
import {UserAvatar} from "../../../../components/user-avatar";
import {Button, InputRow, Option, Select, SmallButton} from "../../../../components/input";
import {Icon} from "../../../../components/images";
import {
	GET_SUGGESTED_SHARES,
	GET_USER_RSS_FEEDS,
	HIDE_SUGGESTED_SHARE,
	SuggestedShare,
	inflateSuggestedShare,
	useMyUser,
} from "../../../../data";
import {defaultLoadingProps as loadingProps, usePaginatedQuery} from "../../../../paginated-query";
import {useMutationToast} from "../../../../toast";
import {AddFeedModal} from "../../../feed/add-feed-modal";
import {useNewModal as useModal} from "../../../../modals";

import styles from "./content-library.module.scss";

export interface ContentLibraryProps {
	type: "Post" | "Collection";
	excludedUrls?: string[];
	onAdd(arg: {
		url?: string;
		shareId?: number;
		feedId?: number | undefined;
		type?: "Post" | "Collection";
	}): void;
}

export const ContentLibrary = ({type, onAdd, excludedUrls}: ContentLibraryProps): ReactElement => {
	const user = useMyUser();
	const {loading, data} = useQuery(GET_USER_RSS_FEEDS);
	const [hideSuggestedShare] = useMutationToast(HIDE_SUGGESTED_SHARE);
	const [feedId, _setFeedId] = useState<number>();
	const setFeedId = (v: number | null | undefined) => v !== null && _setFeedId(v);
	const options = useMemo(() => {
		const ret: Option<number | null | undefined>[] =
			user.role === "admin" ? [{label: "From Your Professionals", value: undefined}] : [];

		if (loading) {
			ret.push({
				label: "Loading...",
				value: null,
			});
		}

		ret.push(
			...(data?.userFeeds ?? []).map(f => ({
				label: f.title,
				value: f.feedId,
				disabled: f?.status === "unparsed",
			}))
		);
		ret.push(...(data?.rssFeeds ?? []).map(f => ({label: f.title, value: f.feedId})));

		return ret;
	}, [data, loading, user.role]);

	const renderShare = useCallback(
		(share: SuggestedShare): ReactElement =>
			excludedUrls && excludedUrls.includes(share.url) ? (
				<></>
			) : (
				<Card className={styles.card} key={share.id}>
					<InputRow position="between">
						<Span3 className={styles.date}>{share.created.format("MMMM Do, YYYY")}</Span3>
						{share.userId && (
							<Icon
								icon="close"
								className={styles.close}
								onClick={() => hideSuggestedShare({variables: {id: share.id}})}
								height={16}
							/>
						)}
					</InputRow>
					<InputRow>
						{share.opengraph.image ? (
							<img className={styles.image} src={share.opengraph.image} alt={share.opengraph.description} />
						) : (
							<img className={styles.image} src="/default-image.png" alt="" />
						)}
						<div className={styles.content}>
							<Span2 className={styles.cardTitle} href={share.url} trim={4} bold>
								{share.opengraph.title}
							</Span2>
							{share.userId && (
								<UserAvatar className={styles.avatar} userId={share.userId} size="small" name />
							)}
						</div>
					</InputRow>
					{share.comment && <P>{share.comment}</P>}
					<Button
						value={`Add to ${type}`}
						invert
						large
						onClick={() => {
							if (type === "Post") {
								onAdd({
									url: share.url,
								});
								return;
							}
							onAdd({
								feedId: feedId,
								shareId: share.id,
								type: type,
							});
							// .finally(() => {
							//     if (share.userId) {
							//         return hideSuggestedShare({variables: {id: share.id}});
							//     }
							// });
						}}
					/>
				</Card>
			),
		[excludedUrls, onAdd, type, feedId, hideSuggestedShare]
	);

	const {modal, open} = useModal({});

	const {handleScroll, render} = usePaginatedQuery(GET_SUGGESTED_SHARES, {
		inflateItem: inflateSuggestedShare,
		renderItem: renderShare,
		variables: {id: feedId},
		loadingProps,
		skip: user.role !== "admin" && !feedId,
	});

	return (
		<div className={styles.contentLibrary}>
			<div className={styles.title}>
				<Span color={"pink"}>Content Library</Span>
			</div>
			<Select
				className={styles.selection}
				placeholder="Select a library"
				value={feedId}
				onChange={setFeedId}
				options={options}
			/>
			<InputRow className={styles.addRssBtn} position="right">
				<SmallButton value="Add RSS Feed" onClick={open} border={false} invert />
			</InputRow>

			<div className={styles.items} onScroll={handleScroll}>
				{render}
				<AddFeedModal modal={modal} />
			</div>
		</div>
	);
};
