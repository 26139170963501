import dayjs, {Dayjs} from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeeksInYear from "dayjs/plugin/isoWeeksInYear";
import isLeapYear from "dayjs/plugin/isLeapYear";
import updateLocale from "dayjs/plugin/updateLocale";
import relativeTime from "dayjs/plugin/relativeTime";
import isBetween from "dayjs/plugin/isBetween";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(utc);
dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear);
dayjs.extend(weekOfYear);
dayjs.extend(updateLocale);
dayjs.extend(isBetween);
dayjs.extend(timezone);
dayjs.updateLocale("en", {
	weekStart: 0,
});
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

interface Week {
	week: number;
	days: Dayjs[];
}

export const weeksPlugin = (_, dayJsClass: typeof Dayjs) => {
	dayJsClass.prototype.getWeeksInRange = function (this: Dayjs): Week[] {
		let startDay = this.startOf("month").startOf("week");
		const endDay = this.endOf("month").endOf("week");
		const calendar: Week[] = [];

		while (startDay.isBefore(endDay)) {
			calendar.push({
				week: startDay.week(),
				days: Array(7)
					.fill(0)
					.map((n, i) => startDay.add(n + i, "day")),
			});
			startDay = startDay.add(1, "week");
		}
		if (calendar.length === 5) {
			calendar.push({
				week: startDay.week(),
				days: Array(7)
					.fill(0)
					.map((n, i) => startDay.add(n + i, "day")),
			});
		}

		return calendar;
	};
};

type DateType = "numDate" | "longDate" | "shortDate";
type FormatType = DateType | `${DateType}AndTime` | "time";

export const formatPlugin = (_, dayJsClass: typeof Dayjs) => {
	dayJsClass.prototype.formatAs = function (this: Dayjs, format: FormatType = "numDateAndTime"): string {
		const showTime = format.endsWith("AndTime");
		const dateFormat = format.replace("AndTime", "") as DateType;
		// prettier-ignore
		let formatString =
			dateFormat === "numDate" ? "M/D/YYYY" :
			dateFormat === "longDate" ? "dddd, MMMM D, YYYY" :
			dateFormat === "shortDate" ? "MMM D, YYYY" :
			"h:mm A";
		if (showTime) formatString += " h:mm A";
		return this.format(formatString);
	};
};

export const copyPlugin = (_, dayJsClass: typeof Dayjs) => {
	dayJsClass.prototype.copyTime = function (this: Dayjs, from: Dayjs): Dayjs {
		const msOffset = ((from.hour() * 24 + from.minute()) * 60 + from.second()) * 1000 + from.millisecond();
		return this.millisecond(msOffset);
	};
};

declare module "dayjs" {
	interface Dayjs {
		getWeeksInRange(): Week[];
		formatAs(format?: FormatType): string;
		copyTime(from: Dayjs): Dayjs;
	}
}

dayjs.extend(weeksPlugin);
dayjs.extend(formatPlugin);
dayjs.extend(copyPlugin);
