import {FC} from "react";
import classnames from "classnames";

import {EmptyComponent} from "../../types";

import styles from "./loading.module.scss";

export interface LoadingProps extends EmptyComponent {
	position?: "inline" | "absolute" | "center" | "input";
	size?: "small" | "medium" | "large";
}

export const Loading: FC<LoadingProps> = ({className, position = "inline", size = "medium"}) => (
	<div
		className={classnames(
			styles.container,
			className,
			styles[position],
			position !== "absolute" && "space",
			styles[size]
		)}
	>
		<div className={styles.image}>
			<img src="/loading.png" alt="Loading" />
		</div>
	</div>
);
