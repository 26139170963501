import {ReactElement} from "react";
import classnames from "classnames";

import {AvatarSize, EmptyComponent} from "../../types";
import {getUserRank, useBaseUser} from "../../data/user";
import {Icon} from "../images";
import {Span, Span4, SpanProps} from "../text";
import {useImageLoaded} from "../../image-loaded";

import styles from "./user-avatar.module.scss";

interface UserAvatarProps extends EmptyComponent {
	size?: AvatarSize;
	onClick?: () => void;
	onEdit?: () => void;
	userId?: number;
	name?: boolean;
	disabled?: boolean;
	text?: string | false | null;
	experience?: number;
}

interface UserSpanProps extends Omit<SpanProps, "children"> {
	userId?: number;
	before?: string;
}

export const UserAvatar = ({
	size = "large",
	name,
	userId,
	disabled,
	className,
	onClick,
	text,
	onEdit,
	experience,
}: UserAvatarProps): ReactElement => {
	const user = useBaseUser(userId);
	const loaded = useImageLoaded(user.profilePic);

	return (
		<div className={classnames(styles.container, className)}>
			<div
				className={classnames(
					styles.component,
					styles[size],
					styles.initials,
					disabled && styles.disabled,
					onClick && !disabled && styles.clickable
				)}
				onClick={onClick}
				title={user.fullName}
			>
				{user.profilePic && loaded !== "error" ? (
					<>
						<img src={user.profilePic} alt={user.fullName} />
						<div className={styles.opacity} />
					</>
				) : (
					<span>
						{`${user.firstName.substring(0, 1).toUpperCase()}${user.lastName.substring(0, 1).toUpperCase()}`}
					</span>
				)}
				{experience && (
					<div className={styles.experience}>
						<Span4 color="blue" className={styles.text}>
							Level {getUserRank(experience)}
						</Span4>
					</div>
				)}

				{onEdit && <Icon icon="edit" height={14} onClick={onEdit} className={styles.editIcon} />}
			</div>

			{(name || text) && (
				<div className={styles.name}>
					{name && <Span>{user.fullName}</Span>}
					{text && <Span4 color="grey">{text}</Span4>}
				</div>
			)}
		</div>
	);
};

export const UserSpan = ({before, userId, ...props}: UserSpanProps): ReactElement => {
	const user = useBaseUser(userId);

	return (
		<Span {...props}>
			{before && `${before} `}
			{user ? user.fullName : "Unknown"}
		</Span>
	);
};
