import React, {useMemo, useState, useCallback, FC} from "react";
import {NetworkStatus, useQuery} from "@apollo/client";
import dayjs from "dayjs";

import {EmptyList} from "../../../components/empty-list";
import {Button, InputRow, Text} from "../../../components/input";
import {P2, Span2, Span3, Span4} from "../../../components/text";
import {downloadCsv} from "../../../utils/csv";
import {OpengraphMedia} from "../../../components/opengraph";
import {Icon} from "../../../components/images";
import {Service} from "../../../data";
import {GET_POPULAR_POSTS, PopularPost} from "../../../data/analytics";
import {Modal, ModalData} from "../../../modals/new";
import {useCustomRange} from "../../../components/input/date-time/date-interval-picker";
import {EmployeeAdvocacyFilter, minDate} from "./index";
import {useDebounceCallback} from "../../../hooks/use-debounce-callback";
import {Loading} from "../../../components/loading";

import styles from "./user-popular-posts-modal.module.scss";

interface PopularPostsArgs {
	filter: EmployeeAdvocacyFilter;
	modal: ModalData;
}

const getFieldValue = (post, field: string) => {
	let value = post?.[field];

	if (field === "title") {
		value = post.openGraph.title;
	}

	if (field === "description") {
		value = post.openGraph.description;
	}

	return value || "-";
};

const renderItem = (post: PopularPost, index: number) => (
	<div key={`${index}-${post.openGraph?.title?.slice(0, 5)}`} className={styles.post}>
		{post.openGraph.image || post.openGraph.video ? (
			<OpengraphMedia className={styles.image} openGraph={post.openGraph} width={128} height={88} />
		) : (
			<div className={styles.image}>
				<img src="/default-image.png" alt="" />
			</div>
		)}
		<div className={styles.text}>
			<P2 className={styles.title} color="black" title={post.openGraph.title} bold trim={1}>
				{post.openGraph.title}
			</P2>
			{(post.openGraph.comment || post.openGraph.description) && (
				<Span4
					className={styles.description}
					title={post.openGraph.comment || post.openGraph.description}
					trim={2}
				>
					{post.openGraph.comment || post.openGraph.description}
				</Span4>
			)}
		</div>
		<div className={styles.statsContainer}>
			<div>
				<Icon width={32} height={32} viewBox={"0 0 16 16"} icon={`filled-${post.network as Service}`} />
			</div>
			<div className={styles.postStats}>
				<Span2 bold>{post.clicks}</Span2>
				<Span3 color="grey">Clicks</Span3>
			</div>
			<div className={styles.postStats}>
				<Span2 bold>{post.shares}</Span2>
				<Span3 color="grey">Shares</Span3>
			</div>
			<div className={styles.postStats}>
				<Span2 bold color="blue">
					${post.emv}
				</Span2>
				<Span3 color="grey">EMV</Span3>
			</div>
		</div>
	</div>
);

export const UserPopularPostsModal: FC<PopularPostsArgs> = ({filter, modal}) => {
	const [search, setSearch] = useState("");
	const dateRange = useCustomRange(filter.dateRange || "Max", minDate);
	const {start = dayjs(), end = dayjs()} = dateRange || {};
	const variables = useMemo(
		() => ({
			start: start.isBefore(minDate) ? minDate : start,
			end: end.isBefore(minDate) ? minDate : end,
			groups: filter.groups.length ? filter.groups : undefined,
			network: filter.network,
			limit: 20,
		}),
		[filter?.groups, filter?.network, start, end]
	);
	const Empty = useMemo(() => <EmptyList text={search ? "No matches found" : "No items"} />, [search]);

	const {data, fetchMore, networkStatus} = useQuery(GET_POPULAR_POSTS, {
		variables,
		skip: !modal.open,
		notifyOnNetworkStatusChange: true,
	});

	const posts = useMemo(() => {
		const searchTrimmed = search.trim();
		const searchLC = search.toLowerCase();

		return data?.popularPosts?.items.filter(
			posts =>
				!searchTrimmed ||
				posts?.openGraph?.title?.toLowerCase()?.includes(searchLC) ||
				posts?.openGraph?.description?.toLowerCase()?.includes(searchLC)
		);
	}, [data?.popularPosts, search]);

	const handleScroll = useCallback(
		(e: React.UIEvent<HTMLElement>) => {
			const target = e.currentTarget || e.target;
			if (Math.ceil(target.scrollTop + target.clientHeight) + 10 < target.scrollHeight) return;
			if (networkStatus === NetworkStatus.fetchMore) return;

			const cursor = data?.popularPosts?.cursor;

			if (!cursor) return;

			fetchMore({
				variables: {cursor},
				updateQuery: (prev, {fetchMoreResult}) => {
					if (!fetchMoreResult) return prev;
					return Object.assign({}, prev, {
						popularPosts: {
							...prev.popularPosts,
							...fetchMoreResult.popularPosts,
							items: [...(prev?.popularPosts?.items || []), ...(fetchMoreResult?.popularPosts?.items || [])],
						},
					});
				},
			});
		},
		[data?.popularPosts?.cursor, networkStatus, fetchMore]
	);

	const handleScrollDebounced = useDebounceCallback(handleScroll, 100);

	const csvData = useMemo(() => {
		const headers = ["Title", "Description", "Network", "Clicks", "EMV", "Shares"];

		const items =
			posts?.map(post => [
				getFieldValue(post, "title"),
				getFieldValue(post, "description"),
				getFieldValue(post, "network"),
				getFieldValue(post, "clicks"),
				getFieldValue(post, "emv"),
				getFieldValue(post, "shares"),
			]) || [];

		return [headers, ...items];
	}, [posts]);

	const handleExport = useCallback(() => {
		downloadCsv(csvData.slice(1), csvData[0], "popular-posts.csv");
	}, [csvData]);

	if (!modal.open) return null;

	return (
		<Modal modal={modal} title="User Popular Posts" size="fit-content" className={styles.popularPostsModal}>
			<div className={styles.content}>
				<InputRow className={styles.toolbar}>
					<Text icon="search" value={search} onChange={setSearch} placeholder="Search" />
					<Button onClick={handleExport} value="Export CSV" />
				</InputRow>
				{
					<div className={styles.postsList} onScroll={handleScrollDebounced}>
						{[NetworkStatus.loading, NetworkStatus.setVariables, NetworkStatus.refetch].includes(
							networkStatus
						) ? (
							<Loading className={styles.loader} />
						) : posts?.length ? (
							posts.map(renderItem)
						) : (
							Empty
						)}
						{networkStatus === NetworkStatus.fetchMore && <Loading className={styles.loader} />}
					</div>
				}
			</div>
		</Modal>
	);
};
