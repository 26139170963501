import {useCallback, useMemo, useRef, useState} from "react";
import {useFormikContext} from "formik";

import {BottomToolbar, CommentToolbar, MediaToolbar, ShuffleCommentsToolbar} from "./toolbars";
import {Editor, networkCaptionMaxLength} from "../../components";
import {getURL, isURL} from "../../../../utils/text";
import {useCanvaDesign} from "../../../../hooks/use-canva-design";
import {clearTypename, OPENGRAPH_FROM_URL, useMyUser, services, UPLOAD_IMAGE} from "../../../../data";
import {useDebounceCallback} from "../../../../hooks/use-debounce-callback";
import {useMutationToast} from "../../../../toast";
import {PostCollectionFormValues, OnChange} from "./post";
import {PostEditorHeader} from "./post-editor-header";
import {DragHandleProps} from "./drag-drop";

import styles from "./styles.module.scss";

interface PostEditorProps {
	onRemove?: () => void;
	loadingAddShuffle: boolean;
	dragHandleProps?: DragHandleProps;
}

const getOpengraphType = opengraph => (!!opengraph?.image || !!opengraph?.video ? "media" : "text");

export const PostEditorShuffle = ({onRemove, dragHandleProps, loadingAddShuffle}: PostEditorProps) => {
	const me = useMyUser();
	const aiEnabled = me.org.options.ai;
	const [opengraphFromURL, {loading: loadingURL}] = useMutationToast(OPENGRAPH_FROM_URL);
	const [uploadImage, {loading: uploading}] = useMutationToast(UPLOAD_IMAGE);
	const textareaRef = useRef<HTMLTextAreaElement>(null);
	const {values, errors, setFieldValue, submitForm} = useFormikContext<PostCollectionFormValues>();
	const {url, opengraphs, activeNetwork, perNetwork, shuffledComments, shuffledImages} = values;
	const [shuffledCommentIndex, setShuffledCommentIndex] = useState<number>(1);
	const [optimizedForTwitter, setOptimizedForTwitter] = useState(false);
	const editorData = useMemo(() => {
		if (!shuffledCommentIndex || shuffledCommentIndex === 1) {
			return opengraphs?.[activeNetwork] ?? opengraphs?.general;
		}

		const comment = shuffledComments[shuffledCommentIndex - 2]?.comment;

		return {
			...opengraphs?.general,
			comment,
		};
	}, [shuffledCommentIndex, opengraphs, activeNetwork, shuffledComments]);

	const title = editorData?.title;
	const comment = editorData?.comment ?? "";
	const description = editorData?.description;
	const image = editorData?.image;
	const video = editorData?.video;
	const {opengraphs: opengraphsErrors} = errors;
	const canAddCaption = (shuffledComments ?? []).every(sc => sc.comment);
	const editorErrors = opengraphsErrors?.[activeNetwork] ?? opengraphsErrors?.general;
	const editorErrorsMsg =
		typeof editorErrors === "object"
			? [
					editorErrors?.comment,
					editorErrors?.video,
					editorErrors?.image,
					editorErrors?.title,
					editorErrors?.description,
			  ]
					.filter(v => !!v)
					.map(error => (typeof error === "string" ? error : error?.[0] ?? ""))
			: [];

	const shuffleComentError = !canAddCaption ? "One or more captions have missing content" : "";
	const debouncedSubmit = useDebounceCallback(submitForm, 2000);
	const onChange = useCallback(
		(field, value) => {
			setFieldValue(field, value);
			debouncedSubmit();
		},
		[setFieldValue, debouncedSubmit]
	);

	const handleShuffledCommentChange = useCallback(
		value => {
			const shuffledComment = shuffledComments[shuffledCommentIndex - 2];
			const newShuffledComments = shuffledComments?.map((sc, index) =>
				index === shuffledCommentIndex - 2 ? {...sc, comment: value} : sc
			) ?? [{...shuffledComment, comment: value}];
			onChange("shuffledComments", newShuffledComments);
		},
		[shuffledCommentIndex, shuffledComments, onChange]
	);

	const postType = useMemo(() => {
		if (url) return "url";

		const activeOpengraphs = Object.keys(opengraphs).filter(key =>
			perNetwork ? key !== "general" : key === "general"
		);
		if (activeOpengraphs.length === 0) return getOpengraphType(opengraphs.general);
		const types = activeOpengraphs.map(key => getOpengraphType(opengraphs[key]));
		if (types.find(type => type === "media")) return "media";

		return "text";
	}, [url, opengraphs, perNetwork]);

	const handleOpengraphChange = useCallback(
		async value => {
			if (typeof value === "string" && isURL(value) && postType === "text") {
				const url = getURL(value);
				const {data} = await opengraphFromURL({variables: {url}});
				const opengraph = clearTypename(data?.opengraphFromURL);

				if (Object.keys(opengraph).length) {
					onChange(`opengraphs`, {
						general: {
							...opengraph,
							comment: "",
						},
						...services.reduce((acc, service) => {
							acc[service] = null;
							return acc;
						}, {}),
					});
					setFieldValue("url", url);
					return;
				}
			}

			if (!opengraphs[activeNetwork]) {
				setFieldValue(`opengraphs.${activeNetwork}`, {...opengraphs.general});
			}

			onChange(`opengraphs.${activeNetwork}.comment`, value);
		},
		[onChange, activeNetwork, opengraphs, setFieldValue, opengraphFromURL, postType]
	);

	const handleCommentChange = useCallback<OnChange>(
		async (field, value) => {
			let focus = false;
			const pos = textareaRef?.current?.selectionStart ?? 0;
			const focusInput = () => {
				textareaRef?.current?.focus();
				if (typeof value === "string") {
					textareaRef?.current?.setSelectionRange(pos + value.length, pos + value.length);
				}
			};

			if (field === "emoji") {
				value = `${comment.slice(0, pos)}${value}${comment.slice(pos)}`;
				focus = true;
			}

			if (shuffledCommentIndex && shuffledCommentIndex !== 1) {
				handleShuffledCommentChange(value);
			} else {
				await handleOpengraphChange(value);
			}

			if (focus) {
				setTimeout(focusInput, 0);
			}
		},
		[comment, textareaRef, shuffledCommentIndex, handleShuffledCommentChange, handleOpengraphChange]
	);

	const handleClearImage = useCallback(async () => {
		const firstShuffledImage = shuffledImages[0];

		if (firstShuffledImage) {
			setFieldValue(
				"shuffledImages",
				shuffledImages.filter(si => si.id !== firstShuffledImage?.id)
			);
		}

		onChange(`opengraphs.general.image`, firstShuffledImage?.image ?? null);
	}, [shuffledImages, onChange, setFieldValue]);

	const onEditorChange = useCallback<OnChange>(
		(field, value) => {
			if (field === "comment" || field === "emoji") {
				handleCommentChange(field, value);
				return;
			}

			if (field === "image" && !value && shuffledImages?.length && !perNetwork) {
				handleClearImage();
				return;
			}

			if (!opengraphs[activeNetwork]) {
				setFieldValue(`opengraphs.${activeNetwork}`, {...opengraphs.general});
			}

			onChange(`opengraphs.${activeNetwork}.${field}`, value);
		},
		[
			onChange,
			activeNetwork,
			opengraphs,
			perNetwork,
			shuffledImages,
			setFieldValue,
			handleCommentChange,
			handleClearImage,
		]
	);
	globalThis.onChange = onChange;

	const onClear = useCallback(
		field => {
			if (field === "comment") {
				if (!shuffledCommentIndex || shuffledCommentIndex === 1) {
					onEditorChange(field, "");
				} else {
					setFieldValue("shuffledCommentIndex", 1);
					shuffledComments.splice(shuffledCommentIndex - 1, 1);
					onChange("shuffledComments", shuffledComments);
				}
				return;
			}

			if (field === "url") {
				onChange("url", null);
				onChange(`opengraphs`, {
					general: {
						comment: opengraphs.general.comment,
						description: "",
						image: null,
						title: "",
						video: null,
					},
				});
				return;
			}
		},
		[shuffledCommentIndex, setFieldValue, onEditorChange, onChange, shuffledComments, opengraphs]
	);

	const onCanvaFileSelect = useCallback(
		(url: string) => {
			onEditorChange("image", url);
		},
		[onEditorChange]
	);
	const {createDesignJob, loading: loadingCanvaMedia} = useCanvaDesign({
		onCompleted: onCanvaFileSelect,
	});

	const validShuffleComments = shuffledComments.filter(e => e.comment);
	const lastValidShuffleComment = validShuffleComments[validShuffleComments.length - 1]?.comment;
	const aiComment = [lastValidShuffleComment, opengraphs?.general?.comment, title, description].find(e => e);

	return (
		<Editor
			className={styles.editor}
			title={title}
			comment={comment}
			video={video}
			image={image}
			url={url}
			description={description}
			activeNetwork={activeNetwork}
			onChange={onEditorChange}
			onClear={onClear}
			textareaRef={textareaRef}
			maxLength={
				optimizedForTwitter ? networkCaptionMaxLength.twitter : networkCaptionMaxLength[activeNetwork]
			}
			loading={loadingURL}
			loadingMedia={uploading || loadingCanvaMedia}
			Header={<PostEditorHeader onChange={onChange} dragHandleProps={dragHandleProps} />}
			TopToolbar={
				<ShuffleCommentsToolbar
					shuffledCommentIndex={shuffledCommentIndex}
					setShuffledCommentIndex={setShuffledCommentIndex}
				/>
			}
			BottomToolbar={
				<BottomToolbar
					comment={aiComment}
					createDesignJob={createDesignJob}
					uploadImage={uploadImage}
					onChange={onChange}
					onOpengraphChange={onEditorChange}
					onDelete={onRemove}
					optimizedForTwitter={optimizedForTwitter}
					setOptimizedForTwitter={setOptimizedForTwitter}
				/>
			}
			CommentToolbar={
				<CommentToolbar
					loadingAddShuffle={loadingAddShuffle}
					setShuffledCommentIndex={setShuffledCommentIndex}
					shuffledCommentIndex={shuffledCommentIndex}
					canAddCaption={canAddCaption}
					onChange={onChange}
				/>
			}
			MediaToolbar={shuffledImages && <MediaToolbar onChange={onChange} />}
			aiEnabled={aiEnabled}
			showDescription={["general", "facebook"].includes(activeNetwork)}
			errors={[shuffleComentError, ...editorErrorsMsg]}
		/>
	);
};
