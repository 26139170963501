import {Dispatch, ReactElement, SetStateAction, useCallback, useMemo, useState} from "react";
import {useNavigate} from "react-router";
import classNames from "classnames";
import dayjs from "dayjs";
import useVirtual from "react-cool-virtual";

import {count} from "../../utils/text";
import {
	Button,
	InputRow,
	Option,
	Switch,
	Text,
	Checkbox,
	DropdownButton,
	Separator,
	toggle,
	SearchableSelect,
} from "../../components/input";
import {Collapsible} from "../../components/card";
import {Icon} from "../../components/images";
import {Group as GroupSetting, DELETE_GROUP, UPDATE_GROUP, Group as GroupType} from "../../data/group";
import {User, useMyUser} from "../../data/user";
import {useCompanyList} from "../../data/company";
import {useDirtyCopy} from "../../dirty-copy";
import {useConfirmDeleteModal} from "../../modals";
import {useMutationToast} from "../../toast";
import {Badge} from "../../components/badge";
import {P, Span2, Span3, Span4} from "../../components/text";
import {ToggleGroup} from "../../components/toggle";
import {UserAvatar} from "../../components/user-avatar";

import styles from "./group.module.scss";

interface GroupComponent {
	group: GroupSetting;
	groups: GroupSetting[];
	users: User[];
	usersObject: Record<number, User>;
}

const getNrVASUsers = users => users.filter(({virtualAssistant}) => virtualAssistant).length;

const VirtualUserList = ({
	users,
	setBulkRemove,
	bulkRemove,
	onUserRemove,
}: {
	users: Partial<User>[];
	bulkRemove: (number | undefined)[];
	setBulkRemove: Dispatch<SetStateAction<(number | undefined)[]>>;
	onUserRemove: (value: number | undefined) => void;
}) => {
	const navigate = useNavigate();
	const {outerRef, innerRef, items} = useVirtual<HTMLDivElement>({itemCount: users.length});
	return (
		<div className={classNames(styles.userList, "space")} ref={outerRef}>
			<div ref={innerRef}>
				{items.map(({index, measureRef}) => {
					const user = users[index];
					if (!user) return null;
					const {id, role, firstName, lastName, virtualAssistant} = user;
					return (
						<div className="space" key={index} ref={measureRef}>
							<InputRow key={id} className={styles.userBox}>
								<Checkbox
									value={bulkRemove.includes(id)}
									onChange={() => setBulkRemove(old => toggle(id, old))}
								/>
								<UserAvatar className="space" size="small" userId={id} />
								<div className="space">
									<P>{[firstName, lastName].join(" ")}</P>
									<Span4>{role}</Span4>
								</div>
								<div className={classNames("space", styles.userVA)}>
									{virtualAssistant && <Badge color="blue" text="VA" />}
								</div>
								<DropdownButton
									icon="ellipsis"
									invert
									color="black"
									border={false}
									options={[
										{
											label: "Open details",
											onClick: () => navigate("/users/list", {state: {id}}),
											icon: "open",
										},
										{label: "Remove from group", onClick: () => onUserRemove(id), icon: "delete"},
									]}
								/>
							</InputRow>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export const Group = ({group, groups, users: allUsers, usersObject}: GroupComponent): ReactElement => {
	const {val, update, dirty, discard, changes, inputFunc} = useDirtyCopy(group);
	const [view, setView] = useState(false);
	const [bulkRemove, setBulkRemove] = useState<(number | undefined)[]>([]);
	const me = useMyUser();
	// prettier-ignore
	const groupOptions: Option<number>[] = useMemo(() => groups.map(g => ({value: g.id, label: g.name})), [
		groups,
	]);
	const companies = useCompanyList();

	const onChangeView = useCallback(val => {
		setView(val);
		setBulkRemove([]);
	}, []);
	const [deleteGroup, {loading: deleting}] = useMutationToast(DELETE_GROUP);
	const [updateGroup, {loading}] = useMutationToast(UPDATE_GROUP);

	const handleDelete = (close: () => void) =>
		deleteGroup({
			variables: {id: group.id},
			update(cache, {data}) {
				if (data) {
					cache.evict({id: `Group:${data.deleteGroup.id}`, broadcast: false});
					cache.gc();
				}
			},
			onCompleted: close,
		});

	const {open} = useConfirmDeleteModal({
		what: "group",
		onDelete: handleDelete,
		deleting,
	});

	const handleSave = useCallback(
		() =>
			updateGroup({
				variables: {id: group.id, changes: {...changes, users: changes?.users?.map(({id}) => id)}},
			}).then(({data}) => {
				if (data) discard();
			}),
		[updateGroup, group, changes, discard]
	);

	const groupEditorsAndAdmins = useMemo(() => val.users.filter(({role}) => role?.toLowerCase() === "admin"), [
		val.users,
	]);
	const groupFollowers = val.users.filter(({role}) => role?.toLowerCase() !== "admin");
	const header = show => (
		<>
			{show ? (
				<Text placeholder={group.name} {...inputFunc("name")} />
			) : (
				<h6 className={styles.nameText}>{group.name}</h6>
			)}
			<div>{group.vas !== 0 && <Badge color="blue" text="VA" />}</div>
			<div className={styles.textItem}>
				<h6>{count(groupEditorsAndAdmins, "Editor")}</h6>
				<Span4 color="grey">{getNrVASUsers(groupEditorsAndAdmins)} with Virtual Assistant</Span4>
			</div>

			<div className={classNames(styles.textItem)}>
				<h6>{count(groupFollowers, "Follower")}</h6>
				<Span4 color="grey">{getNrVASUsers(groupFollowers)} with Virtual Assistant</Span4>
			</div>
			<div className={styles.textItem}>
				<h6>{count(group.queues, "Collection")}</h6>
				<Span4 color="grey">
					{group.lastCollection ? `Last received ${dayjs(group.lastCollection).fromNow()}` : ""}
				</Span4>
			</div>
		</>
	);
	const onUserSelect = useCallback(
		value => {
			update({users: [...val.users, usersObject[value.value]]});
		},
		[val.users, update, usersObject]
	);
	const onUserRemove = useCallback(
		value => {
			update({users: val.users.filter(({id}) => id !== value)});
		},
		[val.users, update]
	);

	const selectUsers = useMemo(
		() =>
			allUsers
				.map(user => ({value: user.id, role: user.role, label: user.fullName}))
				.filter(
					({role, value}) =>
						(view ? role !== "admin" : role === "admin") && !val.users.map(({id}) => id).includes(value)
				),
		[view, allUsers, val.users]
	);

	const inputFuncPrettied = useCallback(
		(key: keyof GroupType, options) => {
			const {value, onChange} = inputFunc(key);
			return {
				value: options.filter(({value: optionValue}) =>
					Array.isArray(value) ? value.includes(optionValue) : optionValue === value
				),
				onChange: items => {
					onChange(items.map(({value}) => value));
				},
			};
		},
		[inputFunc]
	);

	return (
		<Collapsible header={header} headerClassName={styles.header}>
			<div className={styles.columns}>
				<div className={styles.members}>
					<h4 className="space">Members</h4>
					<ToggleGroup
						options={[
							{value: false, label: "Editors"},
							{value: true, label: "Followers"},
						]}
						value={view}
						onChange={onChangeView}
					/>
					<Switch label="Allow subscription" {...inputFunc("allowSubscription")} />
					<div className={classNames("space", styles.addUser)}>
						<Span3>Add {view ? "Followers" : "Editors"}</Span3>
						<SearchableSelect value={[]} onChange={onUserSelect} options={selectUsers} />
						<Span4>
							{view
								? "Followers receive all emails sent to this group."
								: "Only Admins can be Editors. By default Editors will follow and receive all emails sent to this group. Manage follow settings below."}
						</Span4>
					</div>

					<InputRow position="between">
						<Checkbox
							className={classNames(
								styles.checkboxAll,
								!(view ? groupFollowers : groupEditorsAndAdmins).length && styles.hidden
							)}
							value={
								bulkRemove.length === 0
									? false
									: bulkRemove.length === (view ? groupFollowers : groupEditorsAndAdmins).length
									? true
									: undefined
							}
							onChange={() => {
								if (bulkRemove.length === 0) {
									setBulkRemove(() => val.users.map(({id}) => id));
								} else {
									setBulkRemove(() => []);
								}
							}}
						/>
						<Span2
							bold
							onClick={() => {
								update({users: val.users.filter(({id}) => !bulkRemove.includes(id))});
								setBulkRemove([]);
							}}
							className={classNames(styles.removeMultiple, bulkRemove.length === 0 && styles.hidden)}
						>
							<Icon icon="remove-person" />
							Remove from group
						</Span2>
					</InputRow>
					<VirtualUserList
						users={view ? groupFollowers : groupEditorsAndAdmins}
						bulkRemove={bulkRemove}
						setBulkRemove={setBulkRemove}
						onUserRemove={onUserRemove}
					/>
				</div>
				{view ? null : <div className={styles.divider} />}
				{me.org.features.rbac && !view ? (
					<div className={styles.permissions}>
						<h5>Editor Permissions</h5>
						<div className="space">
							<Switch label="Allow editors to change permissions" {...inputFunc("manageGroups")} />
							<Separator horizontal />
						</div>
						<Span3 className="space">Editors in this group can do the following:</Span3>
						<Checkbox label="Send Collections to the entire organization" {...inputFunc("sendAll")} />
						<Checkbox
							label="Make changes to branding and email templates"
							{...inputFunc("editCompanyBranding")}
						/>
						<Checkbox label="Changes the organization’s primary admin" {...inputFunc("setPrimaryAdmin")} />
						<Checkbox
							label="Make changes social media approval requirement"
							{...inputFunc("manageSocialMediaConnection")}
						/>
						<Checkbox label="Create Collections" {...inputFunc("createCollections")} />
						<Checkbox label="Add content to Collections" {...inputFunc("addContent")} />
						<Checkbox label="Approve Collections" {...inputFunc("approveCollections")} />

						<SearchableSelect
							className={classNames(styles.flexColumn, "space")}
							label="Send Collections to:"
							isMulti
							{...inputFuncPrettied("queueGroupIds", groupOptions)}
							options={groupOptions}
							disableDropdownIndicator
						/>
						<SearchableSelect
							className={classNames(styles.flexColumn, "space")}
							label="Invite/remove users to:"
							isMulti
							{...inputFuncPrettied("addUserGroupIds", groupOptions)}
							options={groupOptions}
							disableDropdownIndicator
						/>
						<SearchableSelect
							className={classNames(styles.flexColumn, "space")}
							label="Manage these company social media accounts:"
							isMulti
							{...inputFuncPrettied("manageCompanyIds", companies)}
							options={companies}
							disableDropdownIndicator
						/>
						<SearchableSelect
							className={classNames(styles.flexColumn, "space")}
							label="Send Collections to these Corporate Accounts:"
							isMulti
							{...inputFuncPrettied("queueCompanyIds", companies)}
							options={companies}
							disableDropdownIndicator
						/>
					</div>
				) : null}
			</div>
			<InputRow position="between">
				<Button icon="delete" invert border={false} color="pink" onClick={open} value="De-activate Group" />
				<InputRow position="right">
					<Button value="Discard" invert color="pink" disabled={!dirty} onClick={discard} />
					<Button value="Save" disabled={!dirty} onClick={handleSave} loading={loading} />
				</InputRow>
			</InputRow>
		</Collapsible>
	);
};
