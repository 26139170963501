import {
	DependencyList,
	ReactElement,
	ReactNode,
	createContext,
	memo,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import classnames from "classnames";
import {v4 as uuidv4} from "uuid";

import {Icon} from "../components/images";

import styles from "./modals.module.scss";

export type CloseFunc<T> = (val?: T) => void;

export interface ModalHook<T> {
	open: () => void;
	close: CloseFunc<T>;
}

export type RenderItem<T> = ReactNode | ((close: CloseFunc<T>) => ReactNode);

interface ModalContextProps<T = unknown> {
	remove: (id: string) => void;
	add: (id: string, element: ModalArgs<unknown>) => void;
	open: (id: string) => void;
	close: (id: string, retVal?: T) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ModalContext = createContext<ModalContextProps<any>>({
	remove: () => undefined,
	add: () => undefined,
	open: () => undefined,
	close: () => undefined,
});

export interface BaseModalArgs<T> {
	onClose?: (val: T) => boolean | undefined | null | void;
	onOpen?: () => boolean | undefined | null | void;
}

export interface ModalArgs<T> extends BaseModalArgs<T> {
	header?: RenderItem<T>;
	body: RenderItem<T>;
	footer?: RenderItem<T>;
	size?: "small" | "large" | "medium";
	maxWidth?: string;
}

interface ModalProps {
	data: ModalArgs<unknown>;
	id: string;
	className: string;
	zIndex: number;
}

const Modal = memo(({data, id, className, zIndex}: ModalProps) => {
	const refModal = useRef<HTMLDivElement | null>(null);
	const context = useContext(ModalContext);
	const close = useCallback((val: unknown) => context.close(id, val), [context, id]);
	if (!data) return <></>;

	const renderItem = (key: keyof ModalArgs<unknown>, className?: string): ReactElement => {
		const item = data[key];
		if (!item) return <></>;
		const val = (typeof item === "function" ? item(close) : item) as ReactElement;
		return (
			<div
				className={className}
				onMouseDown={e => {
					if (refModal.current && !refModal.current.contains(e.target as Node)) {
						e.stopPropagation();
					}
				}}
			>
				{val}
			</div>
		);
	};

	return (
		<div
			className={className}
			style={{zIndex}}
			onMouseDown={e => {
				if (refModal.current && !refModal.current.contains(e.target as Node)) {
					close(id);
				}
			}}
			key={id}
		>
			<div
				ref={refModal}
				className={classnames(styles.modal, styles[data.size ?? "small"])}
				style={{"--max-width": data.maxWidth} as React.CSSProperties}
			>
				<Icon icon="close" className={styles.close} onClick={() => close(id)} />
				{renderItem("header", styles.header)}
				<div className={classnames(styles.modalContents, data.footer && styles.hasFooter)}>
					{renderItem("body", styles.body)}
					{renderItem("footer")}
				</div>
			</div>
		</div>
	);
});

Modal.displayName = "Modal";

export const ModalProvider = ({children}: {children: ReactElement}): ReactElement => {
	const [order, setOrder] = useState<string[]>([]);
	const ref = useRef<Record<string, ModalArgs<unknown>>>({});

	useEffect(() => {
		const keyDown = e => {
			if (e.key !== "Escape") return;
			setOrder(c => {
				if (!c.length) return c;
				const id = c[c.length - 1];
				if (ref[id]?.onClose?.()) return c;
				return c.filter(i => i !== id);
			});
		};

		if (order.length) window.addEventListener("keydown", keyDown);
		return () => window.removeEventListener("keydown", keyDown);
	}, [order.length]);

	const value = useMemo(() => {
		const remove = (id: string) => {
			setOrder(c => (c.includes(id) ? c.filter(i => i !== id) : c));
			delete ref.current[id];
		};

		const add = (id: string, element: ModalArgs<unknown>) => {
			ref.current[id] = element;
			setOrder(c => (c.includes(id) ? [...c] : c));
		};

		const open = (id: string) =>
			setOrder(c => {
				if (c.includes(id)) return c;
				if (ref.current[id]?.onOpen?.()) return c;
				return [...c, id];
			});

		const close = (id: string, val?: unknown) =>
			setOrder(c => {
				if (!c.includes(id)) return c;
				if (ref.current[id]?.onClose?.(val)) return c;
				return c.filter(i => i !== id);
			});

		return {open, close, remove, add};
	}, []);

	return (
		<ModalContext.Provider value={value}>
			{children}
			{order.length > 0 && (
				<div className={styles.container}>
					{order.map((id, i) => (
						<Modal
							key={id}
							className={classnames(styles.modalContainer, i === order.length - 1 && styles.backdrop)}
							zIndex={i}
							data={ref.current[id]}
							id={id}
						/>
					))}
				</div>
			)}
		</ModalContext.Provider>
	);
};

export function useModal<T>(render: (ops: ModalHook<T>) => ModalArgs<T>, deps: DependencyList): ModalHook<T> {
	const idRef = useRef("");
	if (!idRef.current) idRef.current = uuidv4();

	const {close, open, remove, add} = useContext<ModalContextProps<T>>(ModalContext);

	const funcs = useMemo(
		() => ({open: () => open(idRef.current), close: (arg?: T) => close(idRef.current, arg)}),
		[open, close]
	);

	useEffect(() => {
		add(idRef.current, render(funcs) as ModalArgs<unknown>);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);

	useEffect(() => () => remove(idRef.current), [remove]);

	return funcs;
}

export {useConfirmModal, useConfirmDeleteModal, useConfirmRevokeModal} from "./confirm";
export type {ConfirmArgs} from "./confirm";
export {useSelectionConfirmModal} from "./selection-confirm";
export {ReferralCampaignModal} from "./collections/referral-campaign";
export type {Selection} from "./selection-confirm";
export {Modal as NewModal, useModal as useNewModal, ConfirmModal} from "./new";
