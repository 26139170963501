import React, {ReactElement} from "react";
import classnames from "classnames";

import {EmptyComponent} from "../../types";
import {Span} from "../text";

import styles from "./tab.module.scss";
interface TabProps extends EmptyComponent {
	active: boolean;
	onClick: () => void;
	title: string;
}
export const Tab = ({className, onClick, active, title}: TabProps): ReactElement => (
	<div className={classnames(styles.tab, active && styles.active, className)} tabIndex={0} onClick={onClick}>
		<Span>{title}</Span>
	</div>
);
