import {gql} from "@apollo/client";
import {Dayjs} from "dayjs";

export type SalesforceLeadField = {
	cvsField:
		| "name"
		| "city"
		| "state"
		| "site_url"
		| "phone_number"
		| "email"
		| "industry"
		| "employees"
		| "created"
		| "clicks"
		| "leadSource";
	salesforceField: string;
	required?: boolean;
	type?: string;
};
export type SalesforceLeadFields = SalesforceLeadField[];

export type SalesforceLog = {
	date: Dayjs;
	errors: JSON[];
	totalRecords: number;
	totalErrors: number;
};

type SalesforceUserInfo = {
	name: string;
	email: string;
	domain: string;
};

export type SalesforceSyncResponse = {
	success: boolean;
	message: string;
};

export interface SalesforceIntegration {
	connected: boolean;
	authUrl: string;
	fields?: SalesforceLeadFields;
	leadFields?: SalesforceLeadFields;
	lastSyncStatus?: string;
	lastSyncedAt?: Dayjs;
	logs?: SalesforceLog[];
	userInfo?: SalesforceUserInfo;
	needsConfiguration: boolean;
}

const SALESFORCE_INTEGRATION_FRAGMENT = gql`
	fragment SalesforceIntegrationFields on SalesforceIntegration {
		connected
		authUrl
		userInfo {
			name
			email
			domain
		}
		fields {
			cvsField
			salesforceField
			required
		}
		leadFields {
			label
			name
			required
			type
		}
		lastSyncedAt
		lastSyncStatus
		logs {
			date
			errors
			totalRecords
			totalErrors
		}
		needsConfiguration
	}
`;

export const GET_SALESFORCE_INTEGRATION = gql`
	query SalesforceIntegration {
		salesforceIntegration {
			...SalesforceIntegrationFields
		}
	}
	${SALESFORCE_INTEGRATION_FRAGMENT}
`;

export const SET_SALESFORCE_INTEGRATION = gql`
	mutation SetSalesforceIntegration($fields: [SalesforceLeadFieldInput!]) {
		setSalesforceIntegration(fields: $fields) {
			...SalesforceIntegrationFields
		}
	}
	${SALESFORCE_INTEGRATION_FRAGMENT}
`;

export const DISCONNECT_SALESFORCE = gql`
	mutation DisconnectSalesforce {
		disconnectSalesforce {
			...SalesforceIntegrationFields
		}
	}
	${SALESFORCE_INTEGRATION_FRAGMENT}
`;

export const SYNC_SALESFORCE_LEADS = gql`
	mutation SyncSalesforceLeads($startDate: DateTime) {
		syncSalesforceLeads(startDate: $startDate) {
			success
			message
		}
	}
`;
