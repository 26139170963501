import React, {
	DependencyList,
	ReactElement,
	UIEvent,
	cloneElement,
	createContext,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import {v4 as uuidv4} from "uuid";
import {useLocation, useNavigate, useMatch, Outlet, Link, NavLink} from "react-router-dom";
import classnames from "classnames";
import {useLocalStorage} from "react-use";

import {InputRow} from "../components/input";
import {Arrow} from "../components/images";
import {Component, Setter} from "../types";
import {SideNav} from "./side-nav";
import {useMyUser} from "../data";
import {NavItem} from ".";
import {QuickAdd} from "./quick-add";

import styles from "./page.module.scss";

interface BasePageProps extends Component {
	title: string;
	tabs?: NavItem[];
	parentPath?: string;
	returnTo?: string;
	sideNav?: boolean;
	scroll?: boolean;
	input?: ReactElement;
	fullWidth?: boolean;
}

interface SideNavProps extends BasePageProps {
	sideNav: true;
	tabs: NavItem[];
	scroll?: never;
	input?: never;
}

interface TabNavProps extends BasePageProps {
	sideNav?: false | undefined;
}

interface InputStorage {
	id: string;
	element: ReactElement;
}

interface InputContext {
	inputs: InputStorage[];
	setInputs: Setter<InputStorage[]>;
	onScroll: ((e: UIEvent<HTMLDivElement>) => void) | undefined;
	setOnScroll: Setter<((e: UIEvent<HTMLDivElement>) => void) | undefined>;
}

const PageInputContext = createContext<InputContext>({
	inputs: [],
	setInputs: () => undefined,
	onScroll: undefined,
	setOnScroll: () => undefined,
});

export const usePageScroll = (onScroll: (e: UIEvent<HTMLDivElement>) => void) => {
	const {setOnScroll} = useContext(PageInputContext);
	return useEffect(() => {
		setOnScroll(() => onScroll);
		return () => setOnScroll(undefined);
	}, [onScroll, setOnScroll]);
};

export const usePageInput = (input: () => ReactElement, deps: DependencyList) => {
	/* eslint-disable react-hooks/exhaustive-deps */
	/*
		DANGER WILL ROBINSON!
		DANGER WILL ROBINSON!

		React's normal hook dependency checking has been bypassed.
		This means that you could very easily write code that causes a
		never-ending state update cycle.  Do not change this code without
		a full understanding of React's event cycle and dependency system.
	 */
	const idRef = useRef("");
	if (!idRef.current) idRef.current = uuidv4();
	const element = useMemo(() => cloneElement(input(), {key: idRef.current}), deps);
	const {setInputs} = useContext(PageInputContext);

	useEffect(
		() =>
			setInputs(c => {
				const ret = [...c];
				const i = c.findIndex(i => i.id === idRef.current);
				if (i === -1) {
					ret.push({id: idRef.current, element});
				} else {
					ret[i].element = element;
				}
				return ret;
			}),
		[element]
	);
	useEffect(() => () => setInputs(c => c.filter(i => i.id !== idRef.current)), []);
	/* eslint-enable react-hooks/exhaustive-deps */
};

export const PageProvider = ({children}: {children: ReactElement}): ReactElement => {
	const [inputs, setInputs] = useState<InputStorage[]>([]);
	const [onScroll, setOnScroll] = useState<(e: UIEvent<HTMLDivElement>) => void>();
	const value = useMemo(() => ({inputs, setInputs, onScroll, setOnScroll}), [inputs, onScroll]);
	return <PageInputContext.Provider value={value}>{children}</PageInputContext.Provider>;
};

export const Page = ({
	tabs,
	parentPath,
	title,
	children,
	className,
	fullWidth,
	returnTo,
	sideNav,
}: SideNavProps | TabNavProps): ReactElement => {
	const {onScroll} = useContext(PageInputContext);

	const [savedPath, setSavedPath] = useLocalStorage(parentPath || "mainPath");
	const navigate = useNavigate();
	const location = useLocation();
	const parentMatch = useMatch(parentPath || "");
	const user = useMyUser();
	const matchReturnTo = useMatch(returnTo || "");

	const allowedTabs = useMemo(() => tabs?.filter(t => !t.adminOnly || user?.role === "admin"), [
		tabs,
		user?.role,
	]);

	useEffect(() => {
		// set selected tab in localState
		if (parentPath && !parentMatch) {
			setSavedPath(location.pathname);
		}
	}, [parentPath, parentMatch, location, setSavedPath]);

	useEffect(() => {
		// redirect to saved tab if you are allowed
		const firstAllowedTab = allowedTabs?.[0].path;
		const redirectPath = savedPath ?? firstAllowedTab;

		if (!parentMatch || !redirectPath) {
			return;
		}

		const isTabAllowed = allowedTabs?.some(t => t.path === redirectPath);
		if (!isTabAllowed) {
			setSavedPath(firstAllowedTab);
			return;
		}

		navigate(redirectPath, {replace: true});
	}, [parentMatch, savedPath, navigate, allowedTabs, setSavedPath]);

	return (
		<div className={styles.container}>
			<div className={styles.topRow}>
				<InputRow>
					{returnTo && !matchReturnTo && (
						<NavLink to={returnTo}>
							<Arrow direction="left" className={styles.return} />
						</NavLink>
					)}
					<div className={styles.title}>{title}</div>
				</InputRow>
				{allowedTabs?.length &&
					allowedTabs?.length > 1 &&
					!sideNav &&
					allowedTabs.map(t => (
						<Link
							to={t.path}
							key={t.path}
							className={classnames(styles.tab, location.pathname.startsWith(t.path) && styles.active)}
						>
							{t.name}
						</Link>
					))}
				<QuickAdd />
			</div>
			<div className={styles.scrollContent} onScroll={onScroll}>
				{sideNav && allowedTabs?.length ? (
					<SideNav
						tabs={allowedTabs}
						className={className}
						parentClassName={[styles.page, styles.sidenav, ...(fullWidth ? [styles.fullWidth] : [])]}
						onScroll={onScroll}
					/>
				) : (
					<div className={classnames(styles.page, fullWidth && styles.fullWidth, className)}>
						<Outlet />
						{children}
					</div>
				)}
			</div>
		</div>
	);
};
