import React, {ReactElement} from "react";

import {SVG} from "../images";
import {Input, RadioGroupProps, useId} from ".";

import styles from "./input.module.scss";

export function RadioGroup<T = string>({
	id: maybeId,
	disabled,
	className,
	onChange,
	options,
	value,
	horizontal = false,
	...props
}: RadioGroupProps<T>): ReactElement {
	const id = useId(maybeId);

	return (
		<Input className={[className, styles.checkboxGroup]} id={id} {...props}>
			<div className={horizontal ? styles.radioButtonsHorizontal : ""}>
				{options.map(o => (
					<Input
						id={`${id}-${o.value}`}
						key={`${id}-${o.value}`}
						className={[styles.checkboxInput, styles.labelLast, value === o.value && styles.selected]}
						label={o.label}
					>
						<div className={styles.position}>
							<input
								id={`${id}-${o.value}`}
								type="radio"
								name={id}
								onChange={() => onChange(o.value)}
								checked={value === o.value}
								className={styles.radio}
								disabled={disabled}
							/>
							<SVG
								className={styles.radioSvg}
								svg={{
									width: 24,
									height: 24,
									pathing: (
										<>
											<path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" />
											{value === o.value && (
												<path d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z" />
											)}
										</>
									),
								}}
							/>
						</div>
					</Input>
				))}
			</div>
		</Input>
	);
}
