import {useQuery} from "@apollo/client";
import {useParams} from "react-router-dom";
import {useMemo} from "react";

import {GET_FULL_COLLECTION} from "../../../data";
import {Collection as CollectionEditor} from "./collection";
import {Loading} from "../../../components/loading";
import {DeliveredCollection} from "./delivered";

export const CollectionSplit = () => {
	const {id} = useParams();
	const {data, loading: collectionLoading} = useQuery(GET_FULL_COLLECTION, {variables: {id: Number(id)}});

	const delivered = useMemo(() => data?.collection?.sent, [data]);
	if (collectionLoading) {
		return <Loading position="center" />;
	}
	if (delivered) {
		return <DeliveredCollection />;
	}
	return <CollectionEditor />;
};
