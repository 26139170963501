import React, {ReactNode, useEffect} from "react";
import classnames from "classnames";

import {MentionsText} from "../../../../components/input/mentions-text";
import {PostServiceType} from "../../../../data/collection";
import {Validator, Text} from "../../../../components/input";
import {Span5} from "../../../../components/text";
import {Icon} from "../../../../components/images";
import {AITextPicker} from "../../../../components/input/ai-text-picker";
import {OpenGraph} from "../../../../data";
import {networkDescriptionMaxLength, networkTitleMaxLength} from "./index";
import {Media} from "./media";
import {useConfirmChangeModal} from "../../../collections/change-modal";

import styles from "./components.module.scss";

const bStyles = classnames.bind(styles);

export interface EditorProps<V> {
	comment?: OpenGraph["comment"];
	image?: OpenGraph["image"];
	video?: OpenGraph["video"];
	description?: OpenGraph["description"];
	title?: OpenGraph["title"];
	url?: string | null;
	onChange: (field: string, value: string | null) => void;
	onClear?: (field: string) => void;
	activeNetwork: string;
	loading?: boolean;
	placeholder?: string;
	errors?: string[];
	validate?: Validator<V>;
	disabled?: boolean;
	maxLength?: number;
	hasFocus?: boolean;
	aiEnabled?: boolean;
	showDescription?: boolean;
	loadingMedia?: boolean;
	Header?: ReactNode;
	TopToolbar?: ReactNode;
	BottomToolbar: ReactNode;
	CommentToolbar?: ReactNode;
	MediaToolbar?: ReactNode;
	className?: string;
	textareaRef?: React.RefObject<HTMLTextAreaElement>;
}

export const Editor = ({
	title,
	comment,
	image,
	video,
	description,
	url,
	onChange,
	onClear,
	loading,
	placeholder,
	activeNetwork,
	validate,
	errors,
	disabled,
	maxLength,
	hasFocus,
	aiEnabled,
	showDescription,
	Header,
	TopToolbar,
	loadingMedia,
	BottomToolbar,
	CommentToolbar,
	MediaToolbar,
	className,
	textareaRef,
}: EditorProps<string | undefined>) => {
	useEffect(() => {
		if (!hasFocus || disabled) return;

		textareaRef?.current?.focus();
	}, [disabled, hasFocus, textareaRef]);

	const {open} = useConfirmChangeModal({
		confirmColor: "pink",
		confirmText: "Remove URL",
		onConfirm: close => {
			onClear?.("url");
			close();
		},
	});

	return (
		<div className={bStyles(styles.editor, className)}>
			<div className={styles.container}>
				{Header && <div className={styles.headerContainer}>{Header}</div>}
				{TopToolbar && <div className={styles.topToolbarContainer}>{TopToolbar}</div>}
				<div className={styles.body}>
					<div className={styles.commentInput}>
						<MentionsText
							className={styles.comment}
							ref={textareaRef}
							type="textarea"
							bare
							characterCount
							service={PostServiceType[activeNetwork]}
							onChange={value => onChange("comment", value)}
							onClear={() => onClear?.("comment")}
							value={comment || ""}
							loading={loading}
							placeholder={placeholder ? placeholder : "Write a comment or paste a link to share"}
							validate={validate}
							disabled={disabled}
							maxLength={maxLength}
							minRows={3}
						/>
						{CommentToolbar && <div className={styles.commentToolbar}>{CommentToolbar}</div>}
					</div>
					{(image || video || url || loadingMedia) && (
						<>
							{MediaToolbar && <div className={styles.mediaToolbar}>{MediaToolbar}</div>}
							<div className={bStyles(styles.metadata, {[styles.onlyMedia]: !url})}>
								{(image || video || loadingMedia) && (
									<Media
										loadingMedia={loadingMedia}
										border={!url}
										image={image}
										video={video}
										onRemove={
											!url
												? async () => {
														await onChange("image", null);
														onChange("video", null);
												  }
												: undefined
										}
									/>
								)}
								{url && (
									<div className={styles.info}>
										<div className={styles.url}>{new URL(url).hostname}</div>
										<div className={styles.title}>
											<Text
												size={4}
												disabled={disabled}
												bold
												bare
												placeholder="Add a title."
												type="textarea"
												maxRows={4}
												minRows={1}
												value={title}
												onChange={value => onChange("title", value)}
												characterCount={activeNetwork === "linkedin"}
												maxLength={networkTitleMaxLength[activeNetwork]}
												hideInfo={true}
											/>
											{aiEnabled && (
												<AITextPicker
													className={styles.aiPicker}
													value={title ?? ""}
													label="Title"
													onChange={value => onChange("title", value)}
													disabled={disabled}
													maxLength={networkTitleMaxLength[activeNetwork]}
												/>
											)}
										</div>
										{showDescription && (
											<div className={styles.description}>
												<Text
													value={description ?? ""}
													placeholder="Add a description."
													type="textarea"
													onChange={value => onChange("description", value)}
													disabled={disabled}
													bare
													minRows={2}
													characterCount
													hideInfo={true}
												/>
												{aiEnabled && (
													<AITextPicker
														value={description || ""}
														label="Description"
														onChange={value => onChange("description", value)}
														disabled={disabled}
														maxLength={networkDescriptionMaxLength[activeNetwork]}
														className={styles.aiPicker}
													/>
												)}
											</div>
										)}
										<div
											className={styles.removeBtn}
											onClick={activeNetwork === "general" ? () => onClear?.("url") : open}
										>
											<Icon icon="close" width={24} />
										</div>
									</div>
								)}
							</div>
						</>
					)}
					{BottomToolbar}
				</div>
			</div>
			{!!errors?.length && (
				<div>
					{errors?.map((error, index) => (
						<Span5 color={"red"} key={index}>
							{index > 0 ? ", " : ""}
							{error}
						</Span5>
					))}
				</div>
			)}
		</div>
	);
};
