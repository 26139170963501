import {ReactElement} from "react";
import classnames from "classnames/bind";

import {Input, InputComponent, toggle, useId} from "..";
import {DayOfWeekNumber} from "../../../types";

import styles from "./day-of-week.module.scss";
const bStyles = classnames.bind(styles);

interface BaseDOW extends Omit<InputComponent<DayOfWeekNumber>, "onChange" | "value"> {
	size?: number;
}
interface Single extends BaseDOW {
	value: DayOfWeekNumber | undefined;
	onChange?: (newValue: DayOfWeekNumber) => void;
	multi?: false | null | undefined;
}
interface Multi extends BaseDOW {
	value: DayOfWeekNumber[];
	onChange?: (newValue: DayOfWeekNumber[]) => void;
	multi: true;
}

const names = ["S", "M", "T", "W", "R", "F", "S"];

export const DayOfWeek = ({
	className,
	disabled,
	id: maybeId,
	multi,
	onChange,
	size = 24,
	value,
	...props
}: Single | Multi): ReactElement => {
	const id = useId(maybeId);
	const handleClick = v => onChange && (multi ? onChange(toggle(v, value)) : onChange(v));
	const v = multi ? value : value === undefined ? [] : [value];
	if (!onChange) disabled = true;

	return (
		<Input id={id} disabled={disabled} {...props} className={[styles.dotwContainer, className]}>
			<div className={styles.container}>
				{names.map((n, i) => (
					<div
						key={i}
						className={bStyles("day", {disabled, onChange, selected: v.includes(i as DayOfWeekNumber)})}
						onClick={() => handleClick(i)}
						style={{width: size, height: size, fontSize: size / 2}}
					>
						{n}
					</div>
				))}
			</div>
		</Input>
	);
};
