import {Key, ReactElement} from "react";
import classnames from "classnames";

import {Span} from "../text";
import {InputComponent, Option, SingleOptionProps} from "../input";
import {Dot, Icon, IconType} from "../images";
import {Badge} from "../badge";

import styles from "./toggle.module.scss";

export function ToggleGroup<T>({
	options,
	value,
	onChange,
	className,
	compact,
	disabled,
}: SingleOptionProps<T> & {compact?: boolean}): ReactElement {
	const handleOnChange = (v: T) => {
		if (disabled) return;

		onChange(v);
	};

	return (
		<div className={classnames(styles.container, className, "space", disabled && styles.disabled)}>
			{options.map(t => (
				<div
					tabIndex={disabled ? -1 : 0}
					onKeyDown={e => (e.key === "Enter" || e.key === " ") && handleOnChange(t.value)}
					key={t.value as Key}
					onClick={() => handleOnChange(t.value)}
					className={classnames(
						styles.toggle,
						compact && styles.compact,
						t.value === value && styles.selected,
						disabled && styles.disabled
					)}
				>
					{!compact && <Dot />}
					<Span>{t.label}</Span>
					{!!t.count && <Badge size="small" color={"pink"} text={t.count} />}
				</div>
			))}
		</div>
	);
}

interface IconOptions<V, C = V> extends InputComponent<V, C> {
	options: readonly (Omit<Option<C>, "label"> & {icon: IconType})[];
}

export function IconToggleGroup<T>({options, value, onChange, className}: IconOptions<T>): ReactElement {
	return (
		<div className={classnames(styles.container, className, "space")}>
			{options.map(t => (
				<div
					key={(t.value || "toggle-option") as Key}
					onClick={() => onChange(t.value)}
					className={classnames(
						styles.toggle,
						styles.compact,
						styles.icon,
						t.value === value && styles.selected
					)}
				>
					<Icon icon={t.icon} />
				</div>
			))}
		</div>
	);
}
