import React, {useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import classnames from "classnames";

import {Card, Collapsible} from "../../components/card";
import {
	Button,
	Checkbox,
	CheckboxGroup,
	DropdownButton,
	InputRow,
	MultiColumn,
	RadioGroup,
	Select,
	SelectSomeMenu,
	Switch,
	TreeDropdown,
	SearchableSelect,
	Option,
} from "../../components/input";
import {DayOfWeek} from "../../components/input/date-time";
import {Icon, IconProps, IconType, iconList} from "../../components/images/icon";
import {Page} from "../../layout";
import {Pill} from "../../components/pill";
import {useConfirmModal} from "../../modals";
import {UserAvatar} from "../../components/user-avatar";
import {AvatarSize, Color, DayOfWeekNumber} from "../../types";
import {P, Span, Span1, Span2, Span3, Span4} from "../../components/text";
import {Tabs} from "../../layout/tabs";
import {HoverTooltip} from "../../components/tooltip";
import {useToast} from "../../toast";
import {Badge} from "../../components/badge";
import {Social} from "../../components/social";
import {ToggleGroup} from "../../components/toggle";
import {NetworkShare, useBulkScheduleModal, useScheduleModal} from "../collections/schedule-modal";
import {useMediaLibraryModal} from "../../modals/media/media-library";
import {FileUpload} from "../../components/input/file-upload";
import {Service} from "../../data";
import {DemoTextFields} from "./demo-text-fields";
import {MediaButton} from "../../components/media/media-button";

import styles from "./demo.module.scss";

const selectOptions = [
	{value: 1, label: "One"},
	{value: 2, label: "Two"},
	{value: 3, label: "Three"},
] as const;

const options = [
	{value: "a", label: "Company A"},
	{value: "b", label: "Company B"},
	{value: "c", label: "Company C"},
	{value: "d", label: "Company D"},
] as const;

const avatarSizes: AvatarSize[] = ["extraSmall", "small", "medium", "large", "extraLarge"];

const colors: Color[] = ["pink", "blue", "black"];

const allColors = [
	"white",
	"grey-100",
	"grey-200",
	"grey-300",
	"grey-400",
	"grey-500",
	"grey-600",
	"black",
	"black-dark",
	"pink-primary",
	"pink-100",
	"pink-dark",
	"blue-primary",
	"blue-100",
	"blue-dark",
	"red",
	"yellow",
	"green",
];

const treeOptions = [
	{
		label: "Corporate account name A",
		hint: "All social accounts",
		icon: "account" as IconType,
		value: "account A",
		options: [
			{
				label: "Corporate account name A",
				hint: "Facebook",
				icon: "facebook" as IconType,
				value: "facebook",
			},
			{
				label: "Corporate account name A",
				hint: "LinkedIn",
				icon: "linkedin" as IconType,
				value: "linkedin",
			},
			{
				label: "Corporate account name A",
				hint: "X",
				icon: "twitter" as IconType,
				value: "twitter",
			},
		],
	},
	{
		label: "Corporate account name B",
		hint: "All social accounts",
		icon: "account",
		value: "account B",
		options: [
			{
				label: "Corporate account name testing testing testing B",
				hint: "Facebook",
				icon: "facebook",
				value: "facebook",
			},
			{
				label: "Corporate account name B",
				hint: "Instagram",
				icon: "instagram",
				value: "instagram",
			},
			{
				label: "Corporate account name B",
				hint: "X",
				icon: "twitter",
				value: "twitter",
			},
		],
	},
] as const;

const dummyNetworkShares: Record<Service, NetworkShare> = {
	facebook: {
		scheduledFor: dayjs("2023-10-20 12:00:00"),
		peakTime: true,
	},
	linkedin: {
		scheduledFor: dayjs("2023-10-21 14:30:00"),
		peakTime: true,
	},
	twitter: {
		scheduledFor: dayjs("2023-10-22 16:15:00"),
		peakTime: false,
	},
	instagram: {
		scheduledFor: dayjs("2023-10-23 11:45:00"),
		peakTime: true,
	},
};

export const Demo = (): React.ReactElement => {
	const toast = useToast();
	const [dotw, setDotw] = useState<DayOfWeekNumber>();
	const [mdotw, setMdotw] = useState<DayOfWeekNumber[]>([]);
	const [selected, setSelected] = useState<typeof options[number]["value"][]>([]);
	const [loading, setLoading] = useState(false);
	const [b1, setB1] = useState(false);
	const [b2, setB2] = useState(false);
	const [checks, setChecks] = useState<string[]>([]);
	const [b3, setB3] = useState<string>();
	const [b4, setB4] = useState<string>("a");
	const [peakDateTime, setPeakDate] = useState<Dayjs | undefined | "peakTime">(undefined);
	const [dateTime, setDate] = useState<Dayjs | undefined>();
	const [sel, setSel] = useState<typeof selectOptions[number]["value"]>();
	const [sel2, setSel2] = useState<typeof selectOptions[number]["value"][]>([]);
	const [sel3, setSel3] = useState<typeof selectOptions[number]["value"][]>([]);
	const [sel4, setSel4] = useState<Option<typeof selectOptions[number]["value"]>[]>([]);
	const [treeSelected, setSelectedTree] = useState<Record<typeof treeOptions[number]["value"], string[]>>({
		"account A": [],
		"account B": [],
	});
	const [three, setThree] = useState<boolean>();
	const [file, setFile] = useState<File>();
	const {open} = useConfirmModal(
		() => ({
			title: "Default Confirm Message",
			body: "Description of message or buttons",
			onConfirm: close => close(),
		}),
		[]
	);
	const bulkScheduleModal = useBulkScheduleModal({
		value: dayjs(),
		onConfirm: () => undefined,
	});
	const separateScheduleModal = useScheduleModal({
		id: 145946502,
		value: dummyNetworkShares,
		onConfirm: () => undefined,
	});
	const mediaLibraryModal = useMediaLibraryModal({
		onConfirm: () => mediaLibraryModal.close(),
	});
	const icons: IconProps[] = [
		{
			icon: "refresh",
			onClick: () => undefined,
		},
	];

	const getIcons = (
		<div className={styles.icons}>
			{iconList.map(icon => (
				<div key={icon} style={{display: "inline-block"}}>
					<Icon icon={icon} width={32} />
					<span>{icon}</span>
				</div>
			))}
		</div>
	);

	let i = 0;

	return (
		<Page title="Demo">
			<Collapsible header={<>Select</>} icons={icons}>
				<Select options={selectOptions} value={sel} onChange={setSel} label="Singular select" />
				<Select options={selectOptions} value={sel2} onChange={setSel2} label="Select multiple" multi />
				<Select
					options={selectOptions}
					value={sel3}
					onChange={setSel3}
					label="Select multiple pills"
					multi
					pills
				/>
				<SearchableSelect
					bare
					options={selectOptions}
					value={sel4}
					isMulti
					onChange={value => {
						setSel4(value as Option<typeof selectOptions[number]["value"]>[]);
					}}
					label="Searchable Select"
					disableDropdownIndicator
				/>
			</Collapsible>

			<Collapsible header={<>Dates</>}>
				<DayOfWeek value={dotw} onChange={setDotw} label="One" />
				<DayOfWeek value={dotw} onChange={setDotw} disabled label="One Disabled" />
				<DayOfWeek value={mdotw} onChange={setMdotw} multi label="Multi" />
				<DayOfWeek value={mdotw} onChange={setMdotw} disabled multi label="Multi Disabled" />
			</Collapsible>

			<Collapsible isDefaultOpen header={<>Modals</>}>
				<Button value="Open Modal" icon="help" invert color="black" onClick={() => open()} />

				<Span>Separate mode set schedule modal</Span>
				<InputRow>
					<Icon height={20} width={20} icon="schedule" />
					<Button
						invert
						border={false}
						onClick={() => separateScheduleModal.open()}
						value={dateTime?.formatAs("longDateAndTime") ?? "Set Schedule"}
						arrow={dateTime ? undefined : "down"}
					/>
					{dateTime && <Icon icon="close" height={24} width={24} onClick={() => setDate(undefined)} />}
				</InputRow>
				<Span>Bulk mode set schedule modal</Span>
				<InputRow>
					<Icon height={20} width={20} icon="schedule" />
					<Button
						invert
						border={false}
						onClick={() => bulkScheduleModal.open()}
						value={
							peakDateTime
								? peakDateTime !== "peakTime"
									? peakDateTime.formatAs()
									: "Schedule Set with Peak Time"
								: "Set Schedule"
						}
						arrow={peakDateTime ? undefined : "down"}
					/>
					{peakDateTime && (
						<Icon icon="close" height={24} width={24} onClick={() => setPeakDate(undefined)} />
					)}
				</InputRow>

				<Span>Media</Span>
				<InputRow>
					<Button
						value="Open Media Library"
						invert
						icon="upload"
						border={false}
						onClick={() => mediaLibraryModal.open()}
					/>
					<FileUpload
						iconSize={20}
						onChange={f => {
							setFile(f);
							toast({text: "File Uploaded", color: "green"});
						}}
						type="image"
						value={file}
						onlyIcon
					/>
					<MediaButton
						onCanvaSelect={id => id}
						mediaLibrary={{
							onConfirm: () => mediaLibraryModal.close(),
						}}
						onFileChange={f => {
							setFile(f);
							toast({text: "File Uploaded", color: "green"});
						}}
					/>
				</InputRow>
			</Collapsible>

			<Collapsible header={<>Checks and Radios</>}>
				<Switch label="Option 1" value={b1} onChange={setB1} />
				<Switch label="Option 2" value={b2} onChange={setB2} />
				<Switch label="Option X" value={b2} onChange={setB2} disabled />
				<Checkbox label="Three-way" value={three} onChange={setThree} />
				<Checkbox label="Three-way but disabled" value={three} onChange={setThree} disabled />
				<Button invert color="pink" onClick={() => setThree(undefined)} value="Reset to indeterminate" />
				<CheckboxGroup<string>
					options={[
						{value: "a", label: "A"},
						{value: "b", label: "B"},
					]}
					value={checks}
					onChange={setChecks}
					label="Check some things."
				/>
				<RadioGroup
					options={[
						{value: "B3", label: "B3"},
						{value: "B4", label: "B4"},
					]}
					onChange={setB3}
					value={b3}
					label="Radio group"
				/>

				<P>Toggle group</P>
				<ToggleGroup
					options={[
						{value: "Corporate", label: "Corporate"},
						{value: "Employee", label: "Employee"},
					]}
					onChange={setB4}
					value={b4}
				/>
			</Collapsible>

			<Collapsible header={<>Buttons</>}>
				<MultiColumn columns={3}>
					{[false, true].map(disabled =>
						[false, true].map(border =>
							[false, true].map(invert =>
								["left", "right", "none", "only"].map(icon =>
									colors.map(color => {
										if (border && !invert) return;
										const text = `${color.uFirst()}${invert ? " Inverted" : ""}${border ? " Border" : ""}${
											disabled ? " Disabled" : ""
										}`;
										if (i >= iconList.length) i = 0;
										return (
											<Button
												key={`${color}-${invert}-${disabled}-${border}-${icon}`}
												invert={invert}
												color={color}
												icon={icon === "left" || icon === "only" ? iconList[i++] : undefined}
												onClick={() => undefined}
												disabled={disabled}
												border={border}
												value={icon !== "only" ? text : undefined}
											/>
										);
									})
								)
							)
						)
					)}
				</MultiColumn>
				<Button
					color="pink"
					invert
					loading={loading}
					onClick={() => {
						setLoading(c => !c);
						setTimeout(() => setLoading(false), 1000);
					}}
					value="Load"
					icon="help"
				/>
			</Collapsible>

			<Collapsible header={<>Pills</>}>
				<MultiColumn>
					{colors.map(color =>
						[false, true].map(outline =>
							[false, true].map(disabled => (
								<div key={`${color}-${outline}-${disabled}`}>
									<Pill
										outline={outline}
										color={color}
										disabled={disabled}
										onDelete={() => undefined}
										text={`${color.uFirst()}${outline ? " Outline" : ""}${disabled ? " Disabled" : ""}`}
									/>
								</div>
							))
						)
					)}
				</MultiColumn>
			</Collapsible>

			<Collapsible header={<>Typography</>}>
				<h1>Header 1</h1>
				<h2>Header 2</h2>
				<h3>Header 3</h3>
				<h4>Header 4</h4>
				<h5>Header 5</h5>
				<h6>Header 6</h6>
				<P>Paragraph</P>
				<Span1>Span 1</Span1>
				<br />
				<Span2>Span 2</Span2>
				<br />
				<Span3>Span 3</Span3>
				<br />
				<Span4>Span 4</Span4>
				<br />
				<a href="#">Link</a>
			</Collapsible>

			<Collapsible header={<>Icons</>}>{getIcons}</Collapsible>

			<Collapsible header={<>Tabs</>}>
				<Tabs
					tabs={[
						{
							path: "/",
							end: true,
							element: <div />,
							name: "Example Tab",
						},
						{
							path: "/",
							end: true,
							disabled: true,
							element: <div />,
							name: "Disabled Tab",
						},
						{
							path: "/settings",
							end: true,
							element: <div />,
							name: "Settings Tab",
						},
					]}
				/>
			</Collapsible>

			<Collapsible header={<>Avatars</>}>
				<MultiColumn>
					{[true, false].map(disabled =>
						[undefined, -1234].map(userId =>
							[true, false].map(name =>
								avatarSizes.map(size =>
									[undefined, () => undefined].map((onClick, i) => (
										<>
											<Span>
												{size}, {userId ? "some unknown user's" : "your"} avatar,{" "}
												{disabled ? "disabled" : "enabled"}, {onClick ? "" : "not "}clickable,{" "}
												{name ? "with" : "without"} name
											</Span>
											<UserAvatar
												userId={userId}
												key={`${i}-${size}-${name}-${userId}`}
												onClick={onClick}
												size={size}
												name={name}
												disabled={disabled}
											/>
										</>
									))
								)
							)
						)
					)}
				</MultiColumn>
			</Collapsible>

			<Collapsible header={<>Tooltips</>}>
				<HoverTooltip text="Default">
					<Span1>Hover me default</Span1>
				</HoverTooltip>
				<HoverTooltip text="Left" positions={["left"]}>
					<Span1>Hover me left</Span1>
				</HoverTooltip>
				<HoverTooltip text="Right" positions={["right"]}>
					<Span1>Hover me right</Span1>
				</HoverTooltip>
				<HoverTooltip text="Top" positions={["top"]}>
					<Span1>Hover me top</Span1>
				</HoverTooltip>
				<HoverTooltip text="Bottom" positions={["bottom"]}>
					<Span1>Hover me bottom</Span1>
				</HoverTooltip>
			</Collapsible>

			<DemoTextFields />

			<Collapsible header={<>Colors</>}>
				<Card title="Text">
					<MultiColumn columns={3}>
						{allColors.map(color => (
							<div key={color} className={styles.colors}>
								Color: <Span1 className={[styles.colors, styles.colorText, styles[color]]}>{color}</Span1>
								<br />
							</div>
						))}
					</MultiColumn>
				</Card>

				<Card title="Background">
					<MultiColumn columns={3}>
						{allColors.map(color => (
							<div key={color} className={styles.colors}>
								{color}: <div className={classnames(styles.colorBackground, styles[color])}></div>
								<br />
							</div>
						))}
					</MultiColumn>
				</Card>
			</Collapsible>

			<Collapsible header={<>Menus</>}>
				<MultiColumn>
					<DropdownButton
						value="Dropdown Menu"
						icon="hamburger-menu"
						options={[
							{icon: "edit", label: "Edit Post", onClick: () => undefined},
							{icon: "collection", label: "Add to Collection", onClick: () => undefined},
							{icon: "delete", label: "Delete Post", onClick: () => undefined},
						]}
					/>
					<SelectSomeMenu value={selected} options={options} onChange={setSelected} />
				</MultiColumn>
			</Collapsible>

			<Collapsible header={<>Badges</>}>
				<MultiColumn>
					{colors.map(color => (
						<div key={color}>
							<Badge color={color} text={color.uFirst()} />
						</div>
					))}
					{colors.map(color => (
						<div key={color}>
							<Badge color={color} solid text="1" size="small" />
						</div>
					))}
				</MultiColumn>
			</Collapsible>

			<Collapsible header={<>Toasts</>}>
				<InputRow>
					<Button
						color="blue"
						value="Success"
						onClick={() => toast({color: "green", text: "Something succeeded!"})}
					/>
					<Button
						color="black"
						value="Info"
						onClick={() => toast({color: "blue", text: "Some information."})}
					/>
					<Button
						color="pink"
						value="Failure"
						onClick={() => toast({color: "red", text: "Something failed!"})}
					/>
				</InputRow>
			</Collapsible>

			<Collapsible header={<>Social Logos</>}>
				<div className={styles.socialLogos}>
					{(["facebook", "linkedin", "twitter"] as const).map(network =>
						(["small", "medium", "large"] as const).map(size =>
							[true, false].map(active =>
								[true, false].map(error => (
									<Social
										name={network}
										active={active}
										hasError={error}
										size={size}
										key={`${network}-${size}-${active}-${error}`}
									/>
								))
							)
						)
					)}
				</div>
			</Collapsible>

			<Collapsible header={<>TreeDropdown</>}>
				<TreeDropdown
					label="Tree Dropdown"
					options={treeOptions}
					value={treeSelected}
					onChange={setSelectedTree}
				/>
			</Collapsible>
		</Page>
	);
};
