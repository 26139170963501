import React, {ReactNode, useCallback, useEffect, useState} from "react";
import {
	DragDropContext,
	Droppable,
	Draggable,
	DroppableProps,
	DraggableProvided,
	DragHandleProps,
	DraggableStateSnapshot,
} from "react-beautiful-dnd";
import classnames from "classnames";

import styles from "./styles.module.scss";

const bStyles = classnames.bind(styles);

export type DragHandleProps = DragHandleProps;

const getDraggedDom = (draggableId: number) => {
	// const queryAttr = "data-rbd-drag-handle-context-id";
	const queryAttr = "data-rbd-draggable-id";
	const domQuery = `[${queryAttr}='${draggableId}']`;
	const draggedDOM = document.querySelector(domQuery);
	return draggedDOM;
};

const StrictModeDroppable = ({children, ...props}: DroppableProps) => {
	const [enabled, setEnabled] = useState(false);

	useEffect(() => {
		const animation = requestAnimationFrame(() => setEnabled(true));

		return () => {
			cancelAnimationFrame(animation);
			setEnabled(false);
		};
	}, []);

	if (!enabled) {
		return null;
	}

	return <Droppable {...props}>{children}</Droppable>;
};

export const DnDContext = ({children, onDragEnd}: DroppableProps) => {
	const [placeholderProps, setPlaceholderProps] = useState<{
		clientX: number;
		clientY: number;
		clientHeight: number;
		clientWidth: number;
	}>();
	const handleDragStart = event => {
		const draggedDOM = getDraggedDom(event.draggableId) as HTMLElement;

		if (!draggedDOM) {
			return;
		}

		const {clientHeight, clientWidth} = draggedDOM;
		const sourceIndex = event.source.index;
		const children = Array.from((draggedDOM.parentNode as Element).children);
		const clientY =
			parseFloat(window.getComputedStyle(draggedDOM.parentNode as Element).paddingTop) +
			children.slice(0, sourceIndex).reduce((total, curr) => {
				const style = window.getComputedStyle(curr);
				const margin = parseFloat(style.marginBottom) + parseFloat(style.marginTop);
				return total + curr.clientHeight + margin;
			}, 0);

		setPlaceholderProps({
			clientHeight,
			clientWidth,
			clientY,
			clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode as Element).paddingLeft),
		});
	};

	const handleDragUpdate = event => {
		if (!event.destination) {
			return;
		}

		const draggedDOM = getDraggedDom(event.draggableId) as HTMLElement;

		if (!draggedDOM) {
			return;
		}

		const {clientHeight, clientWidth} = draggedDOM;
		const destinationIndex = event.destination.index;
		const sourceIndex = event.source.index;

		const childrenArray = Array.from((draggedDOM.parentNode as Element).children);
		const movedItem = childrenArray[sourceIndex];
		childrenArray.splice(sourceIndex, 1);

		const updatedArray = [
			...childrenArray.slice(0, destinationIndex),
			movedItem,
			...childrenArray.slice(destinationIndex + 1),
		];

		const clientY =
			parseFloat(window.getComputedStyle(draggedDOM.parentNode as Element).paddingTop) +
			updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
				const style = window.getComputedStyle(curr);
				const margin = parseFloat(style.marginBottom) + parseFloat(style.marginTop);
				return total + curr.clientHeight + margin;
			}, 0);

		setPlaceholderProps({
			clientHeight,
			clientWidth,
			clientY,
			clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode as Element).paddingLeft),
		});
	};

	const handleDragEnd = useCallback(
		result => {
			setPlaceholderProps(undefined);
			onDragEnd(result);
		},
		[onDragEnd, setPlaceholderProps]
	);

	return (
		<DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart} onDragUpdate={handleDragUpdate}>
			{children(placeholderProps)}
		</DragDropContext>
	);
};

export const DroppableList = ({
	droppableId,
	children,
	className,
	placeholderProps,
	type,
}: DroppableProps & {droppableId?: string; className?: string}) => (
	<StrictModeDroppable droppableId={droppableId ?? "droppableList"} type={type}>
		{(provided, snapshot) => (
			<div
				className={bStyles(className, styles.droppableList, {[styles.draggingOver]: snapshot.isDraggingOver})}
				{...provided.droppableProps}
				ref={provided.innerRef}
			>
				{children}
				{provided.placeholder}
				{!!Object.keys(placeholderProps || {}).length && snapshot.isDraggingOver && (
					<div
						className={styles.dragPlaceholder}
						style={{
							top: placeholderProps?.clientY,
							left: placeholderProps?.clientX,
							height: placeholderProps?.clientHeight,
							width: placeholderProps?.clientWidth,
						}}
					>
						<div />
					</div>
				)}
			</div>
		)}
	</StrictModeDroppable>
);

export const DraggableItem = ({
	children,
	index,
	draggableId,
	className,
	isDragDisabled,
}: {
	children: ({dragHandleProps}: {dragHandleProps: DragHandleProps}) => ReactNode;
	index: number;
	draggableId: number | null;
	className?: string;
	isDragDisabled?: boolean;
}) => (
	<Draggable
		isDragDisabled={isDragDisabled}
		draggableId={draggableId?.toString() ?? "draggable"}
		index={index}
	>
		{(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
			<div
				ref={provided.innerRef}
				className={bStyles(className, styles.draggableItem, {[styles.dragging]: snapshot.isDragging})}
				{...provided.draggableProps}
				style={provided.draggableProps.style}
			>
				{children({dragHandleProps: provided.dragHandleProps})}
			</div>
		)}
	</Draggable>
);
