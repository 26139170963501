import {useMemo} from "react";

import {EmptyList} from "../../components/empty-list";
import {BaseModalArgs, ModalHook, useModal} from "../../modals";
import {Span} from "../../components/text";
import {Post} from "../../data";
import {UserAvatar} from "../../components/user-avatar";
import {Loading} from "../../components/loading";

import styles from "./post-shares-modal.module.scss";

interface PostSharesModalArgs<T> extends BaseModalArgs<T> {
	posts: Post[];
	loading?: boolean;
}

interface UserShare {
	successCount: number;
	mediaValueSum: number;
}

interface UsersShares {
	[userId: number]: UserShare;
}

export function usePostSharesModal<T>({posts, loading}: PostSharesModalArgs<T>): ModalHook<T> {
	const usersShares: UsersShares = useMemo(
		() =>
			posts
				.flatMap(post => post?.shares ?? [])
				.filter(share => share.successCount > 0)
				.reduce((result, item) => {
					result[item.userId] = result[item.userId] || {
						userId: item.userId,
						successCount: 0,
						mediaValueSum: 0,
					};
					result[item.userId].successCount += item.successCount;
					result[item.userId].mediaValueSum += item.mediaValueSum;
					return result;
				}, {}),
		[posts]
	);

	const isEmpty = Object.keys(usersShares).length === 0;

	return useModal(
		() => ({
			size: "large",
			header: <h3>Post Shares</h3>,
			body: (
				<div className={styles.items}>
					<div className={styles.row}>
						<Span bold className={styles.rowItem}>
							User
						</Span>
						<Span bold className={styles.rowItem}>
							Shares
						</Span>
						<Span bold className={styles.rowItem}>
							EMV
						</Span>
					</div>
					{loading ? (
						<Loading position="absolute" />
					) : (
						<>
							{Object.values(usersShares).map(share => (
								<div key={share.userId} className={styles.row}>
									<Span className={styles.rowItem}>
										<UserAvatar userId={share.userId} name />
									</Span>
									<Span className={styles.rowItem}>{share.successCount}</Span>
									<Span className={styles.rowItem}>
										{new Intl.NumberFormat("en-US", {
											style: "currency",
											currency: "USD",
										}).format(share.mediaValueSum)}
									</Span>
								</div>
							))}
							{isEmpty && <EmptyList text="No items" />}
						</>
					)}
				</div>
			),
		}),
		[usersShares, isEmpty, loading]
	);
}
