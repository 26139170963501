/* eslint-disable @typescript-eslint/no-unused-vars */
// Just until the ToggleGroup is restored.
import {FC, createContext, useState, useMemo} from "react";
import {Navigate, Outlet, useMatch, useNavigate} from "react-router";
import dayjs from "dayjs";

import {IconToggleGroup} from "../../../components/toggle";
import {InputHeader, Select} from "../../../components/input";
import {accountNames, services, useMyUser} from "../../../data";
import {
	DateIntervalPicker,
	DateIntervalOptions,
	DateIntervalType,
} from "../../../components/input/date-time/date-interval-picker";
import {useCompanyPagesList} from "../../../data/company";

import styles from "./company.module.scss";

export interface CompanyAnalyticsFilter {
	accounts: number[];
	dateRange: DateIntervalType;
	comparisonDateRange: DateIntervalType;
	network: string | undefined;
}

export const CompanyAnalyticsContext = createContext<CompanyAnalyticsFilter>({
	accounts: [],
	dateRange: DateIntervalOptions[3].value,
	comparisonDateRange: undefined,
	network: undefined,
});

export const minDate = dayjs().add(-10, "year").startOf("year");

export const Company: FC = () => {
	const {companies} = useCompanyPagesList();
	const me = useMyUser();
	const navigate = useNavigate();
	const {
		params: {network},
	} = useMatch({path: "/analytics/company/:network", caseSensitive: false, end: false}) ?? {params: {}};
	const [filter, setFilter] = useState<CompanyAnalyticsFilter>({
		accounts: [],
		dateRange: DateIntervalOptions[3].value,
		comparisonDateRange: undefined,
		network,
	});

	const toggleOptions = useMemo(
		() =>
			services
				.filter(
					s =>
						(s === "facebook" && me?.org?.features.facebookAnalytics) ||
						(s === "instagram" && me?.org?.features.instagramAnalytics) ||
						(s !== "facebook" && s !== "instagram")
				)
				.map(s => ({value: s, icon: s})),
		[me?.org?.features]
	);

	if (!network) return <Navigate to="linkedin" replace />;

	return (
		<CompanyAnalyticsContext.Provider value={filter}>
			<InputHeader className={styles.header}>
				<>
					<IconToggleGroup
						value={network}
						options={toggleOptions}
						onChange={n => navigate(`/analytics/company/${n}`)}
					/>
					<h3 className="space">{network ? accountNames[network] : "All Social Media"}</h3>
				</>
				<>
					<Select
						placeholder="All Accounts"
						multi
						options={companies}
						value={filter.accounts}
						onChange={v => setFilter(c => ({...c, accounts: v}))}
					/>
					<DateIntervalPicker
						min={minDate}
						max={dayjs().add(2, "day").startOf("day")}
						showPresets={true}
						showComparison={network === "linkedin"}
						value={filter.dateRange}
						comparisonValue={network === "linkedin" ? filter.comparisonDateRange : undefined}
						onChange={v => setFilter(c => ({...c, dateRange: v}))}
						onComparisonChange={v => setFilter(c => ({...c, comparisonDateRange: v}))}
					/>
				</>
			</InputHeader>
			<Outlet />
		</CompanyAnalyticsContext.Provider>
	);
};

export {Facebook} from "./facebook";
export {LinkedIn} from "./linkedin";
export {Instagram} from "./instagram";
export {Twitter} from "./twitter";
