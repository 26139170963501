import {FC} from "react";
import classnames from "classnames";

import {EmptyComponent} from "../../types";
import {OpenGraph} from "../../data";

import styles from "./opengraph.module.scss";

export interface OpengraphMediaProps extends EmptyComponent {
	openGraph: OpenGraph;
	width?: number | string;
	height?: number | string;
	showControls?: boolean;
}

export const OpengraphMedia: FC<OpengraphMediaProps> = ({
	className,
	height,
	openGraph,
	width,
	showControls,
}) => (
	<div className={classnames(className)}>
		{openGraph.video ? (
			<video
				className={styles.previewMedia}
				src={openGraph.video}
				{...(showControls && {controls: true})}
				muted
				preload="metadata"
				style={{width, height}}
			>
				<source src={openGraph.video} />
				<track kind="captions" srcLang="en" label="english_captions" />
			</video>
		) : openGraph.image ? (
			<img
				className={styles.previewMedia}
				src={openGraph.image}
				alt="OpenGraph Image"
				style={{width, height}}
			/>
		) : null}
	</div>
);
