import React, {useCallback, useEffect, useState} from "react";

import {useNewModal} from "../../modals";
import {Modal} from "../../modals/new";
import {Button} from "../../components/input";

type dayValuesObject = {
	Monday: number;
	Tuesday: number;
	Wednesday: number;
	Thursday: number;
	Friday: number;
	Saturday: number;
	Sunday: number;
};

interface DayValuesProps {
	dayValuesObject: dayValuesObject;
	setDayValuesObject: React.Dispatch<React.SetStateAction<dayValuesObject>>;
}

export default function DayValues({dayValuesObject, setDayValuesObject}: DayValuesProps) {
	const {modal, open, close} = useNewModal({});
	const [copy, setCopy] = useState<dayValuesObject>(dayValuesObject);

	useEffect(() => {
		if (modal.open) {
			setCopy(dayValuesObject);
		}
	}, [modal.open, dayValuesObject]);

	const onSave = useCallback(() => {
		setDayValuesObject(copy);
		close();
	}, [copy, close, setDayValuesObject]);
	return (
		<>
			<Button invert value="Edit Day Presets" onClick={open} />
			<Modal modal={modal} title="Edit Day Presets">
				<div style={{marginBottom: "16px"}}>
					<span>Select a value from 1 to 100</span>
				</div>
				{Object.keys(copy ?? {}).map((key, ind) => (
					<div key={ind} style={{display: "flex", marginBottom: "8px"}}>
						<span style={{width: "100px"}}>{key}</span>
						<input
							type="number"
							value={copy[key]}
							onChange={e => {
								setCopy(old => ({...old, [key]: e.target.value}));
							}}
						/>
					</div>
				))}
				<Button value="Save" onClick={onSave} />
			</Modal>
		</>
	);
}
