import {Key, ReactElement, ReactNode, useRef, useState} from "react";
import classnames from "classnames/bind";

import {EmptyComponent} from "../../types";
import {Arrow, Dot, Icon} from "../../components/images";
import {Span} from "../../components/text";
import {Separator, toggle} from "../../components/input";

import styles from "./tree-view.module.scss";

const bStyles = classnames.bind(styles);

type TreeItem<T> = {
	id: T;
	label: string;
	bookmarkId?: number;
	disabled?: boolean;
};
type TreeCollapse<T> = {[key: string]: TreeDropdown<T>};
type TreeDropdown<T> = TreeCollapse<T> | TreeItem<T>[];

interface AllTreeProps<T> extends EmptyComponent {
	onSelect: (newVal: T[]) => void;
	selected: T[];
	scheduled?: number[];
	onBookmark?: (id: T, bookmarkId?: number) => void;
	onCalendarClick?: (id: T, el: HTMLDivElement | null) => void;
	// eslint-disable-next-line react/no-unused-prop-types
	search?: string;
	xIcon?: boolean;
}

export interface TreeViewProps<T> extends AllTreeProps<T> {
	items: TreeCollapse<T>;
}

interface TreeCollapseProps<T> extends AllTreeProps<T> {
	label: string;
	item: TreeDropdown<T>;
	inner: boolean;
}

interface TreeItemProps<T> extends AllTreeProps<T> {
	item: TreeItem<T>;
}

const TreeViewItem = <T extends unknown>({
	item: {label, id, disabled, bookmarkId},
	onSelect,
	selected,
	onBookmark,
	onCalendarClick,
	scheduled,
	xIcon,
}: TreeItemProps<T>): ReactElement => {
	const ref = useRef<HTMLDivElement>(null);

	return (
		<div ref={ref} className={bStyles("item", {selected: selected.includes(id), disabled})}>
			<div className={styles.itemContent} onClick={() => !disabled && onSelect(toggle(id, selected))}>
				<Icon icon="check" className={styles.checkIcon} color="blue" width={16} height={16} />
				<Span size={3} color={disabled ? "grey" : undefined}>
					{label}
				</Span>
			</div>
			{onBookmark && label !== "Suggested to Admins" && (
				<>
					{bookmarkId && (
						<div
							className={bStyles(styles.calendarIcon, scheduled?.includes(bookmarkId) && styles.scheduled)}
							onClick={e => {
								onCalendarClick?.(id, ref?.current), e.stopPropagation();
							}}
						>
							<Icon icon="calendar" width={16} height={16} />
						</div>
					)}
					<Icon
						icon={xIcon ? "close" : bookmarkId ? "filled-bookmark" : "collection"}
						height={16}
						onClick={() => onBookmark(id, bookmarkId)}
						className={[styles.bookmark, bookmarkId && !xIcon && styles.bookmarked]}
					/>
				</>
			)}
		</div>
	);
};

const renderItem = <T extends unknown>(
	item: TreeDropdown<T>,
	props: AllTreeProps<T>,
	inner = false
): ReactNode => {
	const searchLC = props.search?.toLowerCase();
	return Array.isArray(item)
		? item
				.sort((a, b) => a.label.localeCompare(b.label))
				.filter(i => !searchLC || i.label.toLowerCase().includes(searchLC))
				.map(i => <TreeViewItem key={i.id as Key} item={i} {...props} />)
		: Object.keys(item)
				.sort()
				.map(i => <TreeCollapse key={i} item={item[i]} label={i} {...props} inner={inner} />);
};

const childrenSelected = <T extends unknown>(item: TreeDropdown<T>, selected: T[]): boolean =>
	Array.isArray(item)
		? item.some(i => selected.includes(i.id))
		: Object.values(item).some(i => childrenSelected(i, selected));

const TreeCollapse = <T extends unknown>({
	className,
	label,
	item,
	inner,
	selected,
	...props
}: TreeCollapseProps<T>): ReactElement => {
	const [shown, setShown] = useState(false);
	const isSelected = inner && childrenSelected(item, selected);
	const isCvsBookmark = label === "Bookmarks" && !inner;
	return (
		<>
			<div className={bStyles("collapse", {inner, outer: !inner}, className)}>
				<div className={bStyles("header", {selected: isSelected})} onClick={() => setShown(c => !c)}>
					{isSelected && <Dot className={styles.dot} color="grey" />}
					<Span size={inner ? 2 : 1} color={isCvsBookmark ? "black" : "grey"} bold>
						{label}
					</Span>
					<Arrow direction={shown ? "up" : "down"} />
				</div>
				{shown && renderItem(item, {...props, selected, xIcon: isCvsBookmark}, true)}
			</div>
			{isCvsBookmark && <Separator horizontal className={styles.bookmarkSeparator} />}
		</>
	);
};

export const TreeView = <T extends unknown>({items, className, ...props}: TreeViewProps<T>): ReactElement => (
	<div className={bStyles("container", className)}>{renderItem(items, props)}</div>
);
