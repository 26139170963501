import {ReactElement} from "react";
import {Link} from "react-router-dom";

import {EmptyComponent} from "../../types";
import {Icon} from "../../components/images";

import styles from "./feed-empty-list.module.scss";

export interface FeedEmptyListProps extends EmptyComponent {
	text: string;
}

export const FeedEmptyList = ({text}: FeedEmptyListProps): ReactElement => (
	<div className={styles.container}>
		<Icon icon="feed" height={114} className={styles.emptyIcon} />
		<h3>{text}</h3>
	</div>
);

export const CompanyFeedEmptyList = (): ReactElement => (
	<div className={styles.container}>
		<Icon icon="feed" height={114} className={styles.emptyIcon} />
		<h3>
			Content shared by your admins will appear here. Visit the <Link to="/feed/explore">Explore tab</Link> to
			find content to suggest to them.
		</h3>
	</div>
);
