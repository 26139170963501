import {gql} from "@apollo/client";

export const BADGE_FRAGMENT = gql`
	fragment BadgeFields on Badge {
		id
		description
		imageUrl
		title
		experience
	}
`;

export const USER_BADGE_FRAGMENT = gql`
	fragment UserBadgeFields on UserBadge {
		id
		badgeId
		relationship
		seen
		expiration
	}
`;

export const GET_BADGES = gql`
	query Badges($limit: PositiveInt) {
		badges(limit: $limit) {
			items {
				...BadgeFields
			}
			cursor
		}
	}
	${BADGE_FRAGMENT}
`;

export const REGISTER_PAGE_EVENT = gql`
	mutation RegisterPageEvent($type: String!, $userId: PositiveInt!) {
		registerPageEvent(type: $type, userId: $userId) {
			...UserBadgeFields
		}
	}
	${USER_BADGE_FRAGMENT}
`;
