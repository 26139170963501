import {gql} from "@apollo/client";
import dayjs, {Dayjs} from "dayjs";

export interface Visitor {
	id: number;
	name?: string;
	clicks?: number;
	city?: string;
	dateVisited?: Dayjs;
	email?: string | null;
	employees?: string;
	industry?: string;
	phoneNumber?: string;
	url?: string;
	state?: string;
}

export interface FeedPostVisitor extends Visitor {
	as_name?: string;
	date_visited?: string;
	phone_number?: string;
	site_url?: string;
	visitor_id?: number;
}

export const VISITOR_FRAGMENT = gql`
	fragment VisitorFields on Visitor {
		id
		name
		url
		clicks
		dateVisited
		city
		state
		phoneNumber
		email
		industry
		employees
	}
`;

export const GET_VISITORS = gql`
	query Visitors($start: DateTime!, $end: DateTime!, $groups: [Int!], $network: String) {
		visitors(start: $start, end: $end, groups: $groups, network: $network) {
			items {
				...VisitorFields
			}
		}
	}
	${VISITOR_FRAGMENT}
`;

export const getFieldValue = (visitor: FeedPostVisitor, field: string) => {
	let value = visitor?.[field];

	if (field === "location") {
		const locationField: string[] = [];
		if (visitor.city) {
			locationField.push(visitor.city);
		}
		if (visitor.state) {
			locationField.push(visitor.state);
		}
		value = locationField.length && locationField.join(", ");
	}

	if (field === "name") {
		value = visitor.as_name || visitor.name;
	}
	if (field === "employees") {
		value = visitor.employees;
	}
	if (field === "site_url") {
		value = visitor.site_url || visitor.url;
	}
	if (field === "contact") {
		value = visitor.phone_number || visitor.phoneNumber || visitor.email;
	}
	if (field === "date_visited") {
		const dateValue = visitor.date_visited || visitor.dateVisited;

		// Check if the date is valid before formatting
		if (dayjs(dateValue).isValid()) {
			value = dayjs(dateValue).formatAs("shortDate");
		}
	}

	return value || "-";
};

export const inflateVisitor = (visitor: FeedPostVisitor) => ({
	...visitor,
	location: getFieldValue(visitor, "location"),
	name: getFieldValue(visitor, "name"),
	site_url: getFieldValue(visitor, "site_url"),
	contact: getFieldValue(visitor, "contact"),
	date_visited: getFieldValue(visitor, "date_visited"),
});
