import classnames from "classnames";

import styles from "./components.module.scss";

const bStyles = classnames.bind(styles);

interface PostSkeletonProps {
	className?: string;
}

export const PostSkeleton = ({className}: PostSkeletonProps) => (
	<div className={bStyles(styles.editor, styles.postSkeleton, className)}>
		<div className={styles.container}>
			<div className={styles.headerContainer}>
				<div className={styles.title} />
				<div className={styles.buttons} />
			</div>
			<div className={styles.body}>
				<div className={styles.commentInput} />
				<div className={styles.commentInput} />
				<div className={styles.metadata} />
			</div>
			<div className={styles.skeletonToolbar}>
				<div className={styles.buttons} />
				<div className={styles.buttons} />
			</div>
		</div>
	</div>
);
