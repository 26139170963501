import {FC, Fragment, useCallback, useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import classnames from "classnames";

import {count, isURL} from "../../../utils/text";
import {P, Span2} from "../../../components/text";
import {Validate, Validator, required} from "../../../components/input/validation";
import {Button, InputRow, MultiColumn, Text} from "../../../components/input";
import {useMutationToast} from "../../../toast";
import {GET_TEAMS_CHANNELS, SET_TEAMS_CHANNELS, TeamsChannel} from "../../../data/teams";
import {deleteTypename} from "../../../data";
import {ConfirmModal, ModalData} from "../../../modals/new";
import {Card} from "../../../components/card";
import {Icon} from "../../../components/images";
import {Loading} from "../../../components/loading";
import {useNewModal as useModal} from "../../../modals";

import styles from "./integrations.module.scss";

export const isTeamWebhookUrl = (input: string): boolean =>
	/^(https?:\/\/(.+?\.)?webhook\.office\.com(\/[A-Za-z0-9\-/@=]*)?)/.test(input.trim());

export const isTeamWorkFlowUrl = (input: string): boolean =>
	/^https:\/\/[a-zA-Z0-9\-.]+(:[0-9]+)?\/workflows\/[a-zA-Z0-9-]+\/triggers\/[a-zA-Z0-9-]+\/paths\/invoke(\?[a-zA-Z0-9\-._~%!$&'()*+,;=:@/?]*)?$/.test(
		input.trim()
	);

export const validTeamWebhook: Validator<string | undefined> = {
	required: true,
	check: value =>
		value && isURL(value) && (isTeamWebhookUrl(value) || isTeamWorkFlowUrl(value))
			? false
			: "You must enter a valid teams webhook URL.",
};

export const TeamsCard: FC = () => {
	const {open: teamsOpen, modal: teamsModal} = useModal({maxWidth: "800px"});
	const {data, loading} = useQuery<{teamsChannels: TeamsChannel[]}>(GET_TEAMS_CHANNELS);
	return (
		<Card space={false} className={classnames(styles.integrationsCard)}>
			<div className={classnames(styles.header, "space")}>
				<Icon icon="ms-teams-multicolor" width={32} />
				<h5>Microsoft Teams</h5>
			</div>
			<P color="grey">
				Integrate with Microsoft Teams to boost organizational communication and collaboration. This link
				enables direct sharing of updates, content, and alerts, streamlining your workflow.
			</P>
			<InputRow position="between" className={styles.bottom}>
				<Button invert onClick={teamsOpen} icon="settings" value="Configure" />
				{loading ? (
					<Loading position="input" />
				) : (
					<Span2 color="grey" bold>
						{count(data?.teamsChannels, "channel")} configured
					</Span2>
				)}
			</InputRow>
			<TeamsModal modal={teamsModal} />
		</Card>
	);
};

const TeamsModal: FC<{modal: ModalData}> = ({modal}) => {
	const {data, loading} = useQuery<{teamsChannels: TeamsChannel[]}>(GET_TEAMS_CHANNELS);
	const [hooks, setHooks] = useState<Partial<TeamsChannel>[]>();
	const [valid, setValid] = useState(false);
	const [setTeams, {loading: updating}] = useMutationToast(SET_TEAMS_CHANNELS);

	const handleSave = (close: ModalData["close"]) =>
		hooks &&
		setTeams({
			variables: {channels: hooks.map(h => deleteTypename(h))},
			onCompleted: close,
		});

	useEffect(() => {
		setHooks(
			data?.teamsChannels && data?.teamsChannels.length > 0 ? data.teamsChannels : [{channelId: "", url: ""}]
		);
	}, [data?.teamsChannels, modal.open]);

	const handleAdd = useCallback(() => setHooks(c => [...(c ?? data?.teamsChannels ?? []), {}]), [
		data?.teamsChannels,
	]);
	const handleDelete = useCallback(
		(idx: number) =>
			setHooks(c => {
				const ret = [...(c ?? data?.teamsChannels ?? [])];
				ret.splice(idx, 1);
				return ret;
			}),
		[data?.teamsChannels]
	);
	const update = useCallback(
		<T extends keyof TeamsChannel>(idx: number, key: T, value: TeamsChannel[T]) =>
			setHooks(c => {
				const ret = [...(c ?? data?.teamsChannels ?? [])];
				if (idx >= ret.length) return c;
				ret[idx] = {...ret[idx], [key]: value};
				return ret;
			}),
		[data?.teamsChannels]
	);

	return (
		<ConfirmModal
			modal={modal}
			title="Microsoft Teams"
			size="large"
			confirmText="Save"
			disabled={!valid}
			loading={loading || updating}
			onConfirm={handleSave}
		>
			<Validate setStatus={setValid}>
				<MultiColumn className={styles.teams}>
					<P color="grey">Channel</P>
					<P color="grey">Microsoft Teams Workflow URL</P>
					<div />
					{(hooks ?? data?.teamsChannels ?? []).map((c, i) => (
						<Fragment key={i}>
							<Text validate={[required]} value={c.channelId} onChange={v => update(i, "channelId", v)} />
							<Text
								validate={[validTeamWebhook]}
								value={c.url}
								onChange={v => update(i, "url", v)}
								type="textarea"
								minRows={1}
							/>
							<Button icon="delete" onClick={() => handleDelete(i)} invert color="black" border={false} />
						</Fragment>
					))}
				</MultiColumn>
			</Validate>
			<Button icon="add" value="Add Workflow" onClick={handleAdd} invert border={false} />
		</ConfirmModal>
	);
};
