import {gql} from "@apollo/client";

import {USER_FRAGMENT} from "./user";

export const LOGIN = gql`
	mutation Login($email: String!, $password: String!) {
		login(email: $email, password: $password) {
			...UserFields
		}
	}
	${USER_FRAGMENT}
`;

export const SAML_LOGIN = gql`
	mutation SAMLLogin($email: String!) {
		samlLogin(email: $email)
	}
`;

export const RESET_PASSWORD = gql`
	mutation ResetPassword($email: String!) {
		resetPassword(email: $email)
	}
`;
