import {FC, ReactElement, useCallback, useEffect, useMemo, useState} from "react";
import {createPortal} from "react-dom";
import classnames from "classnames";
import {v4 as uuidv4} from "uuid";
import FocusTrap from "react-focus-lock";
import {ErrorBoundary} from "react-error-boundary";

import {Icon} from "../components/images";
import {Color, Component} from "../types";
import {Button, InputRow} from "../components/input";
import {useConfirmCloseModal} from "./confirm";
import {useConfirmModal} from ".";
import {P2} from "../components/text";
import {FallbackComponent} from "../components/error";

import styles from "./modals.module.scss";

export type CloseFunc<T> = (val?: T) => boolean | undefined | null | void;

export interface ModalData {
	backdrop?: boolean;
	close: CloseFunc<unknown>;
	id: string;
	open: boolean;
	maxWidth?: string;
	padding?: string;
	zIndex?: number;
	size?: "small" | "large" | "medium" | "fit-content";
	isDirty?: boolean;
}

interface ModalProps extends Component {
	modal: ModalData;
	description?: ReactElement;
	header?: ReactElement;
	footer?: ReactElement;
	size?: "small" | "large" | "medium" | "fit-content";
	title?: string;
	className?: string;
	onClose?: () => void;
}

export const Modal: FC<ModalProps> = ({
	className = "",
	children,
	title,
	description,
	header,
	footer,
	size: modalSize,
	modal: {backdrop = true, close, id, open, zIndex, size: hookSize, maxWidth, isDirty, padding},
	onClose,
}) => {
	const {open: confirmLeavingOpen, close: closeConfirmLeaving} = useConfirmModal(
		() => ({
			onConfirm: () => {
				closeConfirmLeaving();
				close();
			},
			confirmColor: "pink",
			confirmText: "Leave",
			icon: "warning",
			body: (
				<div className={styles.confirm}>
					<P2 bold>
						It looks like you have unfinished work. Would you like to leave and discard your changes, or stay
						and continue editing?
					</P2>
				</div>
			),
			title: "Unsaved Changes",
			confirming: false,
		}),
		[close]
	);
	const size = modalSize ?? hookSize ?? "small";
	useEffect(() => {
		const keyDown = e => {
			if (e.key === "Escape") {
				close();
				onClose?.();
			}
		};
		if (open) {
			window.addEventListener("keydown", keyDown);
		}
		return () => window.removeEventListener("keydown", keyDown);
	}, [open, close, onClose]);
	if (!open) return <></>;

	return createPortal(
		<FocusTrap>
			<div className={classnames(styles.modalContainer, !open && styles.hidden)} style={{zIndex}} key={id}>
				<div
					className={classnames(backdrop && styles.backdrop)}
					onClick={
						isDirty
							? confirmLeavingOpen
							: () => {
									close();
									onClose?.();
							  }
					}
				/>
				<div
					className={classnames(styles.modal, styles[size], className)}
					style={{"--max-width": maxWidth, padding} as React.CSSProperties}
				>
					{header || (
						<div className={styles.header}>
							<h3>{title}</h3>
							<Icon
								icon="close"
								className={styles.close}
								onClick={() => {
									close();
									onClose?.();
								}}
							/>
						</div>
					)}
					<ErrorBoundary fallback={<FallbackComponent close={close} />}>
						{description && <div className={"space"}>{description}</div>}
						<div className={classnames(styles.modalContents, footer && styles.hasFooter)}>
							<div className={styles.body}>{children}</div>
							{footer && <div>{footer}</div>}
						</div>
					</ErrorBoundary>
				</div>
			</div>
		</FocusTrap>,
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		document.getElementById("root")!
	);
};

export interface ModalOptions<T = unknown> {
	onClose?: CloseFunc<T>;
	onOpen?: () => boolean | undefined | null | void;
	backdrop?: boolean;
	padding?: string;
	zIndex?: number;
	size?: "small" | "large" | "medium" | "fit-content";
	defaultOpen?: boolean;
	maxWidth?: string;
	onConfirmCloseModal?: CloseFunc<T>;
	isDirty?: boolean;
}

export interface ModalHook<T> {
	open: () => void;
	close: (val?: T) => void;
	modal: ModalData;
	setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useModal<T>(
	{
		onClose,
		onOpen,
		backdrop,
		zIndex,
		size,
		defaultOpen,
		onConfirmCloseModal,
		maxWidth,
		padding,
	}: ModalOptions<T> = {
		backdrop: true,
		zIndex: 100,
		size: "small",
	}
): ModalHook<T> {
	const localZIndex = useMemo(() => zIndex ?? 100, [zIndex]);
	const id = useMemo(() => uuidv4(), []);
	const [open, setOpen] = useState(defaultOpen ?? false);

	const confirmCloseModal = useConfirmCloseModal({
		onConfirm: close => {
			close();
			onConfirmCloseModal?.();
			setOpen(false);
		},
	});

	const openFunc = useCallback(() => {
		if (onOpen?.()) return;
		setOpen(true);
	}, [onOpen]);
	const close = useCallback(
		(val?: T) => {
			if (onConfirmCloseModal) {
				confirmCloseModal.open();
				return;
			}

			if (onClose?.(val)) return;
			setOpen(false);
		},
		[onClose, onConfirmCloseModal, confirmCloseModal]
	);
	const [isDirty, setIsDirty] = useState(false);
	return useMemo(
		() => ({
			open: openFunc,
			close,
			modal: {
				close: close as CloseFunc<unknown>,
				id,
				open,
				backdrop,
				zIndex: localZIndex,
				size,
				maxWidth,
				padding,
				isDirty,
			},
			setIsDirty,
		}),
		[backdrop, close, id, open, openFunc, size, localZIndex, maxWidth, isDirty, padding]
	);
}

export interface ConfirmModalProps<T = unknown> extends Omit<ModalProps, "footer"> {
	onConfirm: (close: CloseFunc<T>) => void;
	confirmText?: string;
	cancelText?: string;
	confirmColor?: Color;
	disabled?: boolean;
	loading?: boolean;
	footerPosition?: "left" | "center" | "right" | "between" | "around";
}

export const ConfirmModal = <T,>({
	cancelText = "Cancel",
	children,
	confirmColor = "blue",
	confirmText = "Confirm",
	disabled = false,
	loading = false,
	modal,
	onConfirm,
	footerPosition = "between",
	...props
}: ConfirmModalProps<T>) => {
	const footer = useMemo(
		() => (
			<div className={styles.footer}>
				<InputRow position={footerPosition}>
					<Button
						onClick={modal.close}
						value={cancelText}
						invert
						color="black"
						border={false}
						disabled={loading}
					/>
					<Button
						onClick={() => onConfirm(modal.close)}
						value={confirmText}
						loading={loading}
						color={confirmColor}
						disabled={disabled}
					/>
				</InputRow>
			</div>
		),
		[cancelText, confirmColor, confirmText, disabled, loading, modal.close, onConfirm, footerPosition]
	);
	return (
		<Modal footer={footer} modal={modal} {...props}>
			{children}
		</Modal>
	);
};
