import {ReactElement, UIEvent} from "react";
import {NavLink, Outlet} from "react-router-dom";
import classnames, {Argument} from "classnames";

import {EmptyComponent} from "../types";
import {Span1} from "../components/text";
import {InputRow, TabOption} from "../components/input";
import {NavItem as TabProps} from "./router";
import {useMyUser} from "../data/user";
import {itemAllowed} from "../layout/perms";

import styles from "./tabs.module.scss";

export interface TabsProps extends EmptyComponent {
	tabs: TabProps[];
	parentClassName?: Argument;
	input?: ReactElement;
	header?: ReactElement;
	scroll?: boolean;
	onScroll?: (e: UIEvent<HTMLDivElement>) => void;
}

export interface BlankTabsProps extends EmptyComponent {
	tabs: TabOption[];
	activeIndex?: number;
	onSelect: (idx: number) => void;
}

export const Tabs = ({
	className,
	header,
	input,
	onScroll,
	parentClassName,
	scroll = true,
	tabs,
}: TabsProps): ReactElement => {
	const me = useMyUser();
	return (
		<div className={classnames(parentClassName)} onScroll={onScroll}>
			{header}
			<div className={styles.tabs}>
				{tabs
					.filter(t => itemAllowed(t, me))
					.map(t => (
						<NavLink
							to={t.path}
							key={t.path}
							className={({isActive}) =>
								classnames(styles.tab, isActive && styles.active, t.disabled && styles.disabled)
							}
							end={t.end}
							tabIndex={t.disabled ? -1 : undefined}
						>
							<Span1 title={t.name}>{t.name}</Span1>
						</NavLink>
					))}
				{input && <InputRow className={styles.input}>{input}</InputRow>}
			</div>
			<div className={classnames(className, styles.page, !scroll && styles.noScroll)}>
				<Outlet />
			</div>
		</div>
	);
};

export const BlankTabs = ({className, tabs, activeIndex, onSelect}: BlankTabsProps): ReactElement => (
	<div className={classnames(className, styles.tabs, styles.blankTabs)}>
		{tabs.map((t, i) => (
			<div
				onClick={() => onSelect(i)}
				key={i}
				className={classnames(styles.tab, styles.blankTab, activeIndex === i && styles.active)}
			>
				<Span1>{t.label}</Span1>
			</div>
		))}
	</div>
);
