import dayjs from "dayjs";
import {useFormikContext} from "formik";

import {CalendarButton, RichText, Text, UserSelect} from "../../../../components/input";
import {Badge} from "../../../../components/badge";
import {Recipients} from "./recipients";
import {getCollectionStatusBadge} from "../../../collections";
import {Collection} from "../../../../data";
import {CollectionFormValues, OnChange} from "./collection-form";
import {AutoLikeButton} from "./auto-like-button";

import styles from "./styles.module.scss";

interface CollectionSettingsProps {
	onChange: OnChange;
	disabled?: boolean;
}

export const CollectionSettings = ({onChange, disabled}: CollectionSettingsProps) => {
	const {values} = useFormikContext<CollectionFormValues>();
	const {status, title, owner, sent, scheduledFor, message} = values;
	const statusBadge = getCollectionStatusBadge({sent, status} as Collection);

	return (
		<div className={styles.collectionSettings}>
			<div className={styles.ownerStatusContainer}>
				<UserSelect
					className={styles.ownerSelect}
					value={owner}
					name
					onChange={value => onChange("owner", value)}
					roles={["admin"]}
					size="extraSmall"
					menuMaxHeight={300}
				/>
				<Badge {...statusBadge} />
			</div>
			<Text
				className={styles.title}
				placeholder={"Untitled Collection"}
				value={title}
				onChange={value => onChange("title", value)}
				bare
				bold
				size={2}
			/>
			<Recipients onChange={onChange} />
			<RichText
				className={styles.messageInput}
				value={message}
				onChange={value => onChange("message", value)}
				placeholder="Write a message to your recipients. This text will appear in the email body or the Slack message."
				disabled={disabled}
			/>
			<div className={styles.autoLikeScheduledContainer}>
				<CalendarButton
					className={styles.scheduleButton}
					value={scheduledFor}
					formattedValue={scheduledFor ? `Scheduled ${scheduledFor?.format("M/DD/YYYY h:mm A")}` : undefined}
					color="black"
					size="large"
					icon="schedule"
					emptyValue="Not scheduled"
					disabled={disabled}
					min={dayjs().startOf("day")}
					border={false}
					invert
					showClear={true}
					onChange={value => onChange("scheduledFor", value)}
				/>
				<div className={styles.autoLikeBtn}>
					<AutoLikeButton onChange={onChange} />
				</div>
			</div>
		</div>
	);
};
