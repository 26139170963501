import {ReactElement, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {useSearchParam} from "react-use";

import {Button, DropdownButton, InputRow, TabProps, Text} from "../../components/input";
import {
	Collection,
	CREATE_COLLECTION,
	ADD_POST_DRAFT_COLLECTION,
	loadCollection,
	CollectionStatus,
	useSimpleUsers,
} from "../../data";
import {CollectionItem} from "./collection-item";
import {useDebounce} from "../../debounce";
import {defaultLoadingProps as loadingProps, usePaginatedQuery} from "../../paginated-query";
import {useMutationToast, useToast} from "../../toast";
import {usePageScroll} from "../../layout";
import {TabsSelectSomeMenu} from "../../components/input/dropdown-button";
import {GET_SIMPLE_COLLECTION} from "../../data/collection";
import {useSimpleGroup} from "../../data/group";

import styles from "./collection.module.scss";

interface CollectionFilter {
	status: CollectionStatus[];
	groups: number[];
	owner: string[];
	sort: "created" | "scheduled";
}

const defaultFilter = (): CollectionFilter => ({
	status: [],
	owner: [],
	groups: [],
	sort: "created",
});

const empty = (
	<div className={styles.empty}>
		<img src="/svgs/collection_blank.svg" alt="No Collections" />
	</div>
);
const renderItem = collection => <CollectionItem key={collection.id} collection={collection} />;

export const Collections = (): ReactElement => {
	const [search, setSearch] = useState("");
	const url = useSearchParam("url");
	const toast = useToast();
	const [filter, setFilter] = useState(defaultFilter);
	const debouncedSearch = useDebounce(search, 300);
	const {groups, loading: loadingGroups} = useSimpleGroup();
	const {users, loading: loadingUsers} = useSimpleUsers({
		limit: null,
		filter: {
			roles: ["admin"],
		},
	});

	const userItems = users.map(u => ({value: u.id, label: [u.firstName, u.lastName].join(" ")}));

	const total = useMemo(
		() =>
			Object.keys(filter).reduce(
				(acc, current) =>
					acc + (Array.isArray(filter[current]) && current !== "accounts" ? filter[current].length : 0),
				0
			),
		[filter]
	);
	const filterLabel = total > 0 ? `${total} filter(s) applied` : "Filter By";

	const navigate = useNavigate();
	const [createCollection, {loading: creating}] = useMutationToast(CREATE_COLLECTION, {
		onCompleted: ({createCollection: {id}}) => navigate(`/collections/${id}`),
	});
	const [addPostDraftCollection] = useMutationToast(ADD_POST_DRAFT_COLLECTION, {
		onCompleted: ({addPostDraftCollection: {id}}) => navigate(`/collections/${id}`),
	});

	const variables = useMemo(
		() => ({
			limit: 20,
			sort: filter.sort?.toUpperCase(),
			status: filter.status?.map(s => s.toUpperCase()),
			groups: filter.groups.length ? filter.groups : undefined,
			owner: filter.owner,
			search: debouncedSearch,
		}),
		[filter, debouncedSearch]
	);

	const {handleScroll, render, data} = usePaginatedQuery<Collection>(GET_SIMPLE_COLLECTION, {
		loadingProps,
		inflateItem: loadCollection,
		variables,
		renderItem,
		itemsClassName: styles.collectionList,
		empty,
	});
	usePageScroll(handleScroll);
	const toBeSentCollections = useMemo(
		() =>
			data
				.filter(
					collection =>
						collection.status === "approved" &&
						!collection.sent &&
						collection.scheduledFor &&
						collection.scheduledFor?.isBefore()
				)
				.map(collection => collection.id),
		[data]
	);

	const {startPolling, stopPolling} = useQuery(GET_SIMPLE_COLLECTION, {
		variables: {
			ids: toBeSentCollections,
		},
		skip: toBeSentCollections.length === 0,
	});

	useEffect(() => {
		if (toBeSentCollections.length === 0) return stopPolling();
		startPolling(5000);
	}, [startPolling, stopPolling, toBeSentCollections]);

	useEffect(() => {
		if (url) {
			toast({
				color: "blue",
				text: "Adding Post To Collection...",
				timeout: 2,
			});
			addPostDraftCollection({variables: {url}});
		}
	}, [addPostDraftCollection, toast, url]);

	const filterByButton: TabProps<keyof Omit<CollectionFilter, "sort">, string | number>[] = useMemo(
		() => [
			{
				label: "Status",
				value: "status",
				options: [
					{value: "draft", label: "Draft"},
					{value: "approved", label: "Scheduled"},
					{value: "sent", label: "Delivered"},
				],
			},
			{label: "Creator", value: "owner", loading: loadingUsers, options: userItems},
			{label: "Recipients", value: "groups", options: groups, loading: loadingGroups},
		],

		[groups, userItems, loadingUsers, loadingGroups]
	);

	return (
		<>
			<InputRow position="between">
				<Button loading={creating} onClick={createCollection} value="Create Collection" icon="edit" />
				<InputRow>
					<Text value={search} onChange={setSearch} placeholder="Search" icon="search" />
					<DropdownButton
						value="Sort By"
						arrow
						invert
						options={[
							{label: "Last Created", onClick: () => setFilter(c => ({...c, sort: "created"}))},
							{label: "Scheduled", onClick: () => setFilter(c => ({...c, sort: "scheduled"}))},
						]}
					/>
					<TabsSelectSomeMenu
						label={filterLabel}
						value={filter}
						tabs={filterByButton}
						onChange={v => setFilter(c => ({...c, ...v} as CollectionFilter))}
					/>
				</InputRow>
			</InputRow>
			{render}
		</>
	);
};
