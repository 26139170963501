import {useCallback, useEffect, useMemo, useState} from "react";
import {Link, useParams} from "react-router-dom";
import classNames from "classnames";
import {useQuery} from "@apollo/client";

import {
	MARK_CONTEST_COMPLETE,
	RewardError,
	SEND_TREMENDOUS_REWARD,
	TREMENDOUS_INTEGRATION,
	TREMENDOUS_REWARD_OPTIONS,
} from "../../data/contest";
import {Span, Span1, Span2} from "../../components/text";
import {Button, InputRow, Select, Separator} from "../../components/input";
import {Modal, ModalData} from "../../modals/new";
import {useMutationToast, useToast} from "../../toast";
import {Icon} from "../../components/images";
import {Loading} from "../../components/loading";

import styles from "./contest.module.scss";

const StepTremendous = ({modal}: {modal: ModalData}) => {
	const toast = useToast();
	const {id: stringId} = useParams();
	const {data, loading} = useQuery(TREMENDOUS_REWARD_OPTIONS);
	const [sendRewards, {loading: loadingSendRewards}] = useMutationToast(SEND_TREMENDOUS_REWARD);
	const {data: dataTremendous} = useQuery(TREMENDOUS_INTEGRATION);
	const [errors, setErrors] = useState<RewardError[]>([]);
	const tremendousUrl = dataTremendous?.tremendousIntegration?.url;
	const [fundingSource, setFundingSource] = useState("");
	const [campaign, setCampaign] = useState("");
	const close = useMemo(() => modal.close, [modal.close]);
	const fundingSourceOptions = useMemo(
		() =>
			data
				? data?.tremendousRewardOptions.fundingSources.map(({name, id}) => ({label: name, value: id}))
				: [],
		[data]
	);
	const campaignOptions = useMemo(
		() =>
			data ? data?.tremendousRewardOptions.campaigns.map(({name, id}) => ({label: name, value: id})) : [],
		[data]
	);

	const onSendRewards = useCallback(() => {
		sendRewards({variables: {id: stringId, fundingSource, campaign}}).then(({data}) => {
			const res = data?.sendTremendousReward;
			if (res.type === "error") {
				setErrors(res.errors);
			} else {
				toast({color: "green", text: "Rewards sent successfully!"});
				close();
			}
		});
	}, [sendRewards, stringId, fundingSource, campaign, close, toast]);
	const hasErrors = errors.length > 0;

	if (hasErrors)
		return (
			<Modal modal={modal} title="Tremendous issue encountered">
				<Span2 className="space">
					We encountered an issue while trying to communicate with your Tremendous account. Tremendous
					responded with this error message:
				</Span2>
				<div className="space">
					<ul>
						{errors.map(({title, description}, i) => (
							<li key={i}>
								<div key={i}>
									<Span2>{title}</Span2>
									<br />
									<Span>{description}</Span>
								</div>
							</li>
						))}
					</ul>
				</div>
				<Separator horizontal />
				<InputRow position="between">
					<div>
						<Link to={"/settings/integrations"} className={styles.integrationsLink}>
							Tremendous app
							<Icon icon="goto-link" width={21} height={21} color="blue" />
						</Link>
					</div>
					<InputRow position="right">
						<Button value="Cancel" border={false} color="black" invert onClick={close} />
						<Button value="Try again" loading={loadingSendRewards} onClick={onSendRewards} />
					</InputRow>
				</InputRow>
			</Modal>
		);

	return (
		<Modal modal={modal} title={"Distribute rewards through Tremendous"}>
			<Select
				loading={loading}
				label="Tremendous Funding Source"
				options={fundingSourceOptions}
				value={fundingSource}
				onChange={setFundingSource}
				placeholder="Select funding source"
			/>
			<Select
				loading={loading}
				label="Tremendous Campaign"
				options={campaignOptions}
				value={campaign}
				onChange={setCampaign}
				placeholder="Select funding source"
			/>
			<Separator horizontal />

			<InputRow position="between">
				<Link to={tremendousUrl} className={styles.integrationsLink} target="_blank">
					<Icon icon="goto-link" color="blue" />
					Tremendous App
				</Link>
				<InputRow position="right">
					<Button value="Cancel" border={false} color="black" invert onClick={close} />
					<Button value="Send Rewards" loading={loadingSendRewards} onClick={onSendRewards} />
				</InputRow>
			</InputRow>
		</Modal>
	);
};

export const RewardsModal = ({close, modal}: {close: () => void; modal: ModalData}) => {
	const [option, setOption] = useState(0);
	const [markComplete, {loading: loadingMarkComplete}] = useMutationToast(MARK_CONTEST_COMPLETE);
	const toast = useToast();
	const {data, loading} = useQuery(TREMENDOUS_INTEGRATION, {
		onError: ({message}) => {
			toast({color: "red", text: message});
		},
	});
	const {id: stringId} = useParams();
	const onMarkComplete = useCallback(() => {
		markComplete({variables: {id: stringId}});
		close();
	}, [markComplete, stringId, close]);
	const [step, setStep] = useState(0);
	const hasTremendous = useMemo(() => data?.tremendousIntegration?.connected ?? false, [
		data?.tremendousIntegration?.connected,
	]);
	useEffect(() => {
		if (modal.open) {
			setStep(0);
		}
	}, [modal.open]);
	return (
		<>
			{step === 0 && (
				<Modal modal={modal} title={`Distribute Rewards`} className={styles.modal} size="fit-content">
					<div>
						<Span2> Please select a method for sending the rewards:</Span2>
						<div className={styles.rewardTypes}>
							<label htmlFor="manually" className={styles.rewardOption}>
								<input
									type="radio"
									id="manually"
									name="rewardType"
									value={0}
									checked={option === 0}
									onChange={() => setOption(0)}
								/>
								<Span1>Distribute rewards manually</Span1>
							</label>

							<label
								htmlFor="temendous"
								className={classNames(styles.rewardOption, !hasTremendous && styles.inputDisabled)}
							>
								<input
									type="radio"
									id="temendous"
									name="rewardType"
									value={1}
									disabled={!hasTremendous}
									checked={option === 1}
									onChange={() => setOption(1)}
								/>
								<div className={styles.tremendousOption}>
									<Span1>Distribute rewards via Tremendous</Span1>
									<div className={styles.tremendousLogoContainer}>
										<div className={styles.tremendousLogo}>
											<img src={"/svgs/tremendous-logo.svg"} />
										</div>
										{!hasTremendous && (
											<Link to={"/settings/integrations"} className={styles.integrationsLink}>
												Setup integration
												<Icon icon="goto-link" width={21} height={21} color="blue" />
											</Link>
										)}
									</div>
								</div>
								{loading && <Loading size="small" position="absolute" />}
							</label>
						</div>
					</div>
					<Separator horizontal />
					<InputRow position="right">
						<Button value="Cancel" border={false} color="black" invert onClick={close} />
						<Button value="Next" onClick={() => setStep(1)} />
					</InputRow>
				</Modal>
			)}
			{step === 1 && option === 0 && (
				<Modal modal={modal} title={"Distribute rewards manually"}>
					<Span>
						In case you have already distributed the rewards manually, you can mark this contest as completed.
					</Span>
					<Separator horizontal />
					<InputRow position="right">
						<Button value="Cancel" border={false} color="black" invert onClick={close} />
						<Button value="Mark Complete" onClick={onMarkComplete} loading={loadingMarkComplete} />
					</InputRow>
				</Modal>
			)}
			{step === 1 && option === 1 && <StepTremendous modal={modal} />}
		</>
	);
};
