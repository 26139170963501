import {gql} from "@apollo/client";

export interface SlackChannel {
	channelId: string;
	channelName: string;
	url: string;
}

const SLACK_CHANNEL_FRAGMENT = gql`
	fragment SlackChannelFields on SlackChannel {
		channelId
		channelName
		url
	}
`;

export const GET_SLACK_CHANNELS = gql`
	query SlackChannels {
		slackChannels {
			...SlackChannelFields
		}
	}
	${SLACK_CHANNEL_FRAGMENT}
`;

export const SET_SLACK_CHANNELS = gql`
	mutation SetSlackChannels($channels: [SlackChannelInput!]!) {
		setSlackChannels(channels: $channels) {
			...SlackChannelFields
		}
	}
	${SLACK_CHANNEL_FRAGMENT}
`;
