import {Fragment, Key, ReactElement, useCallback, useState} from "react";
import classnames from "classnames/bind";

import {Span2, Span3} from "../text";
import {Arrow, Icon, IconType} from "../images";
import {useDropdown} from "./dropdown";
import {Checkbox, Input, InputComponent, Option, toggle, triState, useId} from ".";
import {Pill} from "../pill";

import styles from "./input.module.scss";
const bStyles = classnames.bind(styles);

export interface TreeOption<K extends string, T> {
	label: string;
	hint?: string;
	value: K;
	icon?: IconType;
	options: readonly Option<T>[];
}

export interface TreeDropdownProps<K extends string, T> extends InputComponent<Record<K, T[]>> {
	options: readonly TreeOption<K, T>[];
	placeholder?: string;
}

interface TreeGroupedDropdownItemProps {
	arrow?: () => void;
	label?: string;
	handleSelect: () => void;
	hint?: string;
	icon?: IconType;
	opened?: boolean;
	subItem?: boolean;
	value: boolean | undefined;
}

const TreeGroupedDropdownItem = ({
	arrow,
	handleSelect,
	hint,
	icon,
	label,
	opened,
	subItem,
	value,
}: TreeGroupedDropdownItemProps): ReactElement => (
	<div className={styles.treeItem}>
		<div className={bStyles("treeInfo", {subItem})}>
			<Checkbox onChange={handleSelect} value={value} />
			{icon && <Icon icon={icon} className={styles.icon} />}
			<div className={styles.treeLabel}>
				<Span2>{label}</Span2>
				<Span3 color="grey">{hint}</Span3>
			</div>
		</div>
		{arrow && <Arrow direction={opened ? "up" : "down"} onClick={arrow} />}
	</div>
);

export function TreeDropdown<K extends string, T>({
	className,
	id: maybeId,
	options,
	onChange,
	value,
	disabled,
	...props
}: TreeDropdownProps<K, T>): ReactElement {
	const id = useId(maybeId);
	const [opened, setOpened] = useState<K[]>([]);

	const popup = useCallback(() => {
		const handleSelectAll = (key: K) => {
			const group = options.find(o => o.value === key);
			if (!group) return;
			const selected = value[group.value]?.length === group.options.length;
			const selectedOptions = group.options.map(option => option.value);
			onChange({...value, [group.value]: selected ? [] : selectedOptions});
		};

		return (
			<div className={styles.dropdownDiv}>
				{options.map(main => (
					<Fragment key={main.value}>
						<TreeGroupedDropdownItem
							opened={opened.includes(main.value)}
							arrow={() => setOpened(c => toggle(main.value, c))}
							icon={main.icon}
							label={main.label}
							hint={main.hint}
							value={triState(main.options, value[main.value])}
							handleSelect={() => handleSelectAll(main.value)}
						/>
						{opened.includes(main.value) &&
							main.options.map(option => (
								<TreeGroupedDropdownItem
									label={option.label}
									hint={option.hint}
									icon={option.icon}
									key={option.value as Key}
									subItem
									value={value[main.value]?.includes(option.value) ?? false}
									handleSelect={() =>
										onChange({...value, [main.value]: toggle(option.value, value[main.value])})
									}
								/>
							))}
					</Fragment>
				))}
			</div>
		);
	}, [onChange, opened, options, value]);

	const {isOpen, portal, reference, toggle: toggleDropdown} = useDropdown({popup});

	const values = Object.keys(value).filter(k => value[k].length);
	return (
		<Input id={id} className={bStyles(className, "selectInput")} disabled={disabled} {...props}>
			<div
				onClick={disabled ? undefined : toggleDropdown}
				ref={reference}
				className={bStyles("select", "inputBorder", {open: isOpen}, {disabled})}
				tabIndex={disabled ? -1 : 0}
			>
				<div className={styles.pillsContainer}>
					{values.length > 0 && (
						<div className={styles.labels}>
							{values.map(k => (
								<Pill
									disabled={disabled}
									key={k}
									color="blue"
									text={`${options.find(o => o.value === k)?.label} (${value[k].length})`}
									onDelete={() => onChange({...value, [k]: []})}
								/>
							))}
						</div>
					)}
				</div>
				<Arrow direction={isOpen ? "up" : "down"} />
			</div>
			{portal}
		</Input>
	);
}
