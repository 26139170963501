import React, {useCallback, useEffect, useState} from "react";

import {BaseModalArgs, ModalHook, useConfirmModal} from "..";
import {Span} from "../../components/text";
import {MediaSelector} from "../../components/media/media-selector";
import {Tab} from "../../components/tabs/tab";
import {Media} from "../../data/media";

import styles from "./media-library.module.scss";

export interface MediaLibraryModalArgs<T> extends BaseModalArgs<T> {
	onConfirm: (media: Media) => void;
	tabs?: ("image" | "video")[];
}

export function useMediaLibraryModal<T>({
	onConfirm,
	tabs = ["image", "video"],
}: MediaLibraryModalArgs<T>): ModalHook<T> {
	const [selectedService, setSelectedService] = useState<"image" | "video">("image");
	const [selectedMedia, setSelectedMedia] = useState<Media[]>([]);
	const handleSelectMedia = useCallback(
		media => setSelectedMedia(media.length ? [media[media.length - 1]] : []),
		[setSelectedMedia]
	);
	useEffect(() => {
		if (!tabs.includes(selectedService)) {
			setSelectedService(tabs[0]);
		}
	}, [tabs, setSelectedMedia, selectedService]);

	return useConfirmModal(
		({close}) => {
			const handleTabClick = (value: typeof selectedService) => {
				setSelectedMedia([]);
				setSelectedService(value);
			};
			return {
				size: "large",
				title: "Media Library",
				maxWidth: "936px",
				body: (
					<div className={styles.container}>
						<Span>
							Choose or upload content for your post, edit if needed, and manage your library by deleting
							files.
						</Span>
						<div className={styles.tabs}>
							{tabs.includes("image") && (
								<Tab
									onClick={() => handleTabClick("image")}
									active={selectedService === "image"}
									title="Images"
								/>
							)}
							{tabs.includes("video") && (
								<Tab
									onClick={() => handleTabClick("video")}
									active={selectedService === "video"}
									title="Videos"
								/>
							)}
						</div>
						<MediaSelector
							className={styles.mediaContainer}
							selectedMedia={selectedMedia.filter(({type}) => type === selectedService)}
							onChangeSelectedMedia={handleSelectMedia}
							type={selectedService}
							onConfirm={() => {
								close();
								onConfirm(selectedMedia[0]);
							}}
						/>
					</div>
				),
				confirmDisabled: selectedMedia.length === 0,
				onConfirm: close => {
					close();
					onConfirm(selectedMedia[0]);
				},
			};
		},
		[onConfirm, selectedMedia, selectedService, handleSelectMedia, tabs]
	);
}
