import {ReactElement, useCallback, useMemo, useState} from "react";
import {useQuery} from "@apollo/client";
import {useLocation, useParams} from "react-router";

import {Loading} from "../../components/loading";
import {HidablePanel} from "../../components/hidable-panel";
import {Page} from "../../layout";
import {CollectionEditor} from "./collection-editor";
import {
	ADD_POST_FROM_RSS_FEED,
	Collection,
	GET_COLLECTION,
	MOVE_POST,
	MovePostVars,
	Post,
	SAVE_COLLECTION,
	loadCollection,
	ADD_POST_FROM_SUGGESTED_FEED,
} from "../../data";
import {useMutationToast} from "../../toast";
import {useDirtyCopy} from "../../dirty-copy";
import {ContentLibrary} from "./content-library";

export type ModalType = "schedule" | "send";

const Panel = (): ReactElement => {
	const {id: collectionId} = useParams();
	const {data} = useQuery(GET_COLLECTION, {variables: {id: collectionId}});
	const [addPostFromSuggestedFeed] = useMutationToast(ADD_POST_FROM_SUGGESTED_FEED);
	const [addPostFromRssFeed] = useMutationToast(ADD_POST_FROM_RSS_FEED);

	const onAddToCollection = useCallback(
		async ({shareId, feedId}: {shareId?: number; feedId?: number | undefined}) => {
			if (!feedId) {
				return addPostFromSuggestedFeed({
					variables: {id: collectionId, postId: shareId, postType: "recommended"},
				});
			}
			return addPostFromRssFeed({
				variables: {id: collectionId, postId: shareId},
			});
		},
		[collectionId, addPostFromSuggestedFeed, addPostFromRssFeed]
	);

	const excludedUrls = useMemo(() => {
		const collection = data?.collection;
		if (!collection) return [];
		return collection.posts.filter(p => p.url).map(p => p.url);
	}, [data?.collection]);

	return <ContentLibrary excludedUrls={excludedUrls} type="Collection" onAdd={onAddToCollection} />;
};

export const EditCollection = (): ReactElement => {
	const [saved, setSaved] = useState(false);
	const {id} = useParams();
	const {data, loading: init} = useQuery(GET_COLLECTION, {variables: {id}});
	const [saveCollection, {loading}] = useMutationToast(SAVE_COLLECTION);
	const [movePost] = useMutationToast(MOVE_POST);
	const collection = useMemo(
		() =>
			data?.collection
				? loadCollection(data.collection)
				: ({
						posts: [] as Post[],
						autoLike: {},
						to: {
							groupIds: [] as number[],
							virtualAssistantIds: [] as number[],
							companyIds: [] as number[],
							slackChannels: [] as string[],
						},
				  } as Collection),
		[data?.collection]
	);

	const onUpdate = useCallback(
		({val, discard, changes: baseChanges}) => {
			let changes = {...baseChanges};
			if (baseChanges?.to) changes = {...baseChanges, ...baseChanges.to};
			delete changes.__typename;
			delete changes.to;
			return saveCollection({
				variables: {id: val.id, changes},
				onCompleted: () => {
					setSaved(true);
					discard();
				},
			});
		},
		[saveCollection]
	);

	const {val, update, dirty, flush} = useDirtyCopy(collection, {debounce: 2000, onUpdate});

	const handleMovePost = useCallback((variables: MovePostVars) => flush().then(() => movePost({variables})), [
		flush,
		movePost,
	]);

	const disabled = useMemo(() => !!(val.status === "approved" && val.scheduledFor?.isBefore()), [
		val.status,
		val.scheduledFor,
	]);

	const {state} = useLocation();
	const returnTo = state?.from || "/collections";

	return (
		<Page title="Collection" returnTo={returnTo}>
			{init ? (
				<Loading position="center" />
			) : val.sent ? (
				<CollectionEditor
					collection={val}
					onChange={update}
					flush={flush}
					saved={loading ? "saving" : saved && !dirty ? "saved" : undefined}
					movePost={handleMovePost}
					disabled={disabled}
				/>
			) : (
				<HidablePanel Panel={Panel} what="Library">
					<CollectionEditor
						collection={val}
						onChange={update}
						flush={flush}
						saved={loading ? "saving" : saved && !dirty ? "saved" : undefined}
						movePost={handleMovePost}
						disabled={disabled}
					/>
				</HidablePanel>
			)}
		</Page>
	);
};
