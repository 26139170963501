import {SVG, SVGData, SVGProps} from ".";

const data: SVGData = {
	width: 40,
	height: 40,
	viewBox: "0 -1 40 39",
	pathing: (
		<>
			<path
				d="M27.8035 12.7832C26.6331 12.7838 25.469 12.9535 24.3473 13.2871C24.1323 15.7226 23.1898 18.0371 21.6422 19.93C20.0947 21.8229 18.0136 23.2066 15.6694 23.9013C15.6402 24.2552 15.6152 24.6091 15.6152 24.9714C15.6144 27.3832 16.3289 29.741 17.6683 31.7467C19.0077 33.7524 20.9118 35.3157 23.1399 36.2391C25.3679 37.1624 27.8197 37.4042 30.1852 36.9339C32.5507 36.4636 34.7235 35.3023 36.4289 33.5969C38.1343 31.8915 39.2956 29.7187 39.7659 27.3532C40.2362 24.9877 39.9944 22.5359 39.0711 20.3078C38.1478 18.0798 36.5844 16.1757 34.5787 14.8363C32.5731 13.4969 30.2152 12.7824 27.8035 12.7832"
				fill="#2AB9FB"
			/>
			<path
				d="M24.3473 13.2875C24.3764 12.9335 24.4014 12.5796 24.4014 12.2131C24.404 9.65011 23.5994 7.15135 22.1017 5.07142C20.6041 2.99149 18.4894 1.43603 16.0579 0.625753C13.6263 -0.184528 11.0013 -0.208474 8.55535 0.557313C6.10941 1.3231 3.96674 2.83972 2.43138 4.89198C0.896022 6.94424 0.0459599 9.4279 0.00181107 11.9905C-0.0423377 14.5532 0.721669 17.0647 2.18542 19.1686C3.64918 21.2725 5.73834 22.862 8.15646 23.7116C10.5746 24.5612 13.1988 24.6277 15.6569 23.9017C15.8728 21.4646 16.8171 19.1489 18.367 17.2559C19.917 15.3628 22.0007 13.98 24.3473 13.2875"
				fill="#E72B81"
			/>
			<path
				d="M24.3469 13.2871C22.0026 13.9818 19.9216 15.3655 18.374 17.2584C16.8264 19.1512 15.8839 21.4658 15.6689 23.9013C18.0132 23.2066 20.0942 21.8229 21.6418 19.9301C23.1894 18.0372 24.1319 15.7226 24.3469 13.2871Z"
				fill="#262461"
			/>
			<path
				d="M27.8038 26.7043L25.4511 21.8115H22.2031L26.5962 30.6477H29.0197L33.4086 21.8115H30.1648L27.8038 26.7043Z"
				fill="#FEFEFE"
			/>
			<path
				d="M12.3254 15.145C11.8492 15.1381 11.3819 15.0146 10.9645 14.7853C10.547 14.556 10.1921 14.2279 9.93084 13.8297C9.66954 13.4315 9.50979 12.9753 9.46557 12.5011C9.42136 12.0269 9.49402 11.549 9.6772 11.1094C9.86039 10.6697 10.1485 10.2817 10.5164 9.97917C10.8842 9.67664 11.3206 9.46887 11.7874 9.37402C12.2541 9.27917 12.737 9.30014 13.1937 9.4351C13.6505 9.57005 14.0672 9.81487 14.4075 10.1481L16.4895 8.12857C15.6869 7.30518 14.6587 6.73755 13.5342 6.49708C12.4098 6.2566 11.2393 6.35403 10.1701 6.7771C9.10091 7.20017 8.1807 7.92999 7.52523 8.87475C6.86977 9.81951 6.50834 10.937 6.48641 12.0867C6.46448 13.2363 6.78303 14.3668 7.40198 15.3359C8.02093 16.3049 8.91264 17.0693 9.96494 17.5329C11.0172 17.9964 12.1831 18.1384 13.3159 17.941C14.4487 17.7436 15.4978 17.2156 16.3313 16.4234L14.3409 14.3164C13.8021 14.8443 13.0797 15.1428 12.3254 15.1492"
				fill="#FEFEFE"
			/>
		</>
	),
};

export const Logo = (props: Omit<SVGProps, "svg">) => <SVG {...props} svg={data} />;
