import {ReactElement, useEffect, useMemo, useState} from "react";
import dayjs from "dayjs";
import {useParams, NavLink} from "react-router-dom";
import {useQuery} from "@apollo/client";
import classnames from "classnames";

import {Button, InputRow} from "../../components/input";
import {DateRange} from "../../components/input/date-time";
import {P, P2, P4, Span, Span2, Span3, Span4} from "../../components/text";
import {Card} from "../../components/card";
import {UserAvatar} from "../../components/user-avatar";
import {useMyUser, useUser} from "../../data";
import {Loading} from "../../components/loading";
import {AnalyticsCards} from "./card";
import {AnalyticsBadge} from "../../components/analytics-badge";
import {DateRangeOptions, DateRangeType, useCustomRange} from "../../components/input/date-time/date-range";
import {Arrow} from "../../components/images";
import {useModal} from "../../modals";
import {ChartCard, ChartLine} from "./chart-card";
import {GET_TOP_POSTS} from "../../data/collection";
import {
	GET_LEADER_BOARD,
	GET_USER_BADGES,
	GET_DAILY_LEADERBOARD,
	getUserRank,
	LEVEL_XP,
	GET_DAILY_LEADERBOARD_RANK,
} from "../../data/user";
import {GET_BADGES, REGISTER_PAGE_EVENT} from "../../data/badges";
import {OpengraphMedia} from "../../components/opengraph";
import {usePopularPostsModal} from "./popular-posts-modal";
import {useMutationToast} from "../../toast";

import styles from "./personal.module.scss";

const labelMap = {
	rank: "CVS Ranking",
	clicks: "Clicks",
	emv: "EMV",
	shares: "Shares",
	smartScore: "Social Score",
} as const;

export const Personal = (): ReactElement => {
	const me = useMyUser();
	const {id: stringId} = useParams();
	const id = stringId ? parseInt(stringId) : me.id;
	const user = useUser(id);
	const [registerPageEvent] = useMutationToast(REGISTER_PAGE_EVENT);

	const [customDate, setCustomDate] = useState<DateRangeType>(DateRangeOptions[3].value);
	const {start, end} = useCustomRange(customDate, user ? user.created : dayjs());

	const {data, loading} = useQuery(GET_LEADER_BOARD, {variables: {userId: id}});
	const {data: allBadges} = useQuery(GET_BADGES, {variables: {limit: null}});
	const {data: dailyLeaderBoardRank, loading: loadingDailyRank} = useQuery(GET_DAILY_LEADERBOARD_RANK, {
		variables: {
			...(customDate !== "Max" ? {start, end} : {}),
			userId: id,
		},
	});
	const {data: dailyLeaderBoard, loading: loadingDaily} = useQuery(GET_DAILY_LEADERBOARD, {
		variables: {start, end, userId: id},
	});
	const {data: userBadges} = useQuery(GET_USER_BADGES, {variables: {limit: null, userId: id}});
	const {data: topPosts, loading: loadingTopPosts} = useQuery(GET_TOP_POSTS, {
		variables: {start, end},
	});

	useEffect(() => {
		registerPageEvent({
			variables: {
				type: "results",
				userId: id,
			},
		});
	}, [registerPageEvent, id]);

	const userLevel = useMemo(() => getUserRank(data?.leaderBoard?.experience), [
		data?.leaderBoard?.experience,
	]);
	const userLevelProgress = useMemo(() => {
		if (!data?.leaderBoard?.experience) return 0;
		if (userLevel === LEVEL_XP.length - 1) return 100;
		const progress =
			((data.leaderBoard.experience - LEVEL_XP[userLevel - 1]) /
				(LEVEL_XP[userLevel] - LEVEL_XP[userLevel - 1])) *
			100;
		if (progress <= 5) return 5;
		if (progress > 99) return 100;

		return progress;
	}, [userLevel, data?.leaderBoard?.experience]);
	const [labels, chartData] = useMemo<[string[], ChartLine<string>[]]>(() => {
		const labels: string[] = [];
		const clickData: number[] = [];
		const shareData: number[] = [];
		const emvData: number[] = [];
		dailyLeaderBoard?.dailyLeaderBoard?.forEach(l => {
			labels.push(dayjs(l.created).format("MM/DD"));
			clickData.push(l.clicks);
			shareData.push(l.shares);
			emvData.push(l.emv);
		});
		return [
			labels,
			[
				{label: "Clicks", value: "clicks", color: "pink-primary", data: clickData},
				{label: "Shares", value: "shares", color: "blue-primary", data: shareData},
				{label: "EMV", value: "emv", color: "pink-100", data: emvData},
			],
		];
	}, [dailyLeaderBoard?.dailyLeaderBoard]);

	const achivementsBadgesModal = useModal(
		({close}) => {
			const earnedBadges = userBadges?.earnedBadges ?? [];
			const restBadges =
				allBadges?.badges?.items?.filter(({id}) => !earnedBadges.map(({id}) => id).includes(id)) ?? [];

			return {
				size: "small",
				header: <h3>Achievement Badges</h3>,
				body: (
					<>
						<P2>
							Achievement badges are earned for completing tasks within the software, such as connecting your
							social media accounts, visiting specific pages, or for achieving big milestones. Badges are a
							fun way to interact with the software while also learning ways of being successful on social
							media.
							<br />
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://cvssupport.wpenginepowered.com/article/available-badges"
							>
								Learn more about badges
							</a>
							<br />
							Below are the badges that you have or can earn:
						</P2>
						<div className={classnames("space", styles.badgeRow)}>
							{[...earnedBadges, ...restBadges].map((badge, i) => (
								<AnalyticsBadge
									{...(i < earnedBadges.length ? {} : {className: styles.faded})}
									size="mini"
									badge={badge}
									key={badge.id}
								/>
							))}
						</div>
					</>
				),
				footer: (
					<InputRow position="right">
						<Button value="Cancel" onClick={close} />
					</InputRow>
				),
			};
		},
		[userBadges?.earnedBadges, allBadges]
	);
	const popularPosts = useMemo(() => topPosts?.topPosts?.items || [], [topPosts]);
	const popularPostsModal = usePopularPostsModal({popularPosts});

	const lastLabel = end.isSame(undefined, "day") ? start.fromNow() : "Custom Range";

	const analyticsCardInput = type => ({
		label: labelMap[type],
		value: dailyLeaderBoard?.dailyLeaderBoard?.reduce((acc, cur) => acc + cur[type], 0),
		lastLabel,
		lastValue:
			((data?.leaderBoard?.[type] ?? 0) / dayjs().diff(user.created, "day")) *
			dayjs().diff(user.created.isAfter(start) ? user.created : start, "day"),
	});

	if (loading || !user) return <Loading position="center" />;

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<div className={styles.title}>
					{stringId ? (
						<>
							<NavLink to="/analytics/leaderboard">
								<Arrow direction="left" color="blue" />
							</NavLink>
							<h3>Leaderboard</h3>
						</>
					) : (
						<h3>Personal</h3>
					)}
				</div>
				<DateRange
					min={user.created}
					max={dayjs().add(2, "day").startOf("day")}
					value={customDate}
					onChange={setCustomDate}
				/>
			</div>

			<Card className={styles.userCard}>
				<div className={styles.userInfo}>
					<div className={styles.avatar}>
						<UserAvatar size="extraLarge" userId={id} />
						<div className={styles.hint}>
							<Span4 color="blue" className={styles.text}>
								Level {userLevel}
							</Span4>
						</div>
					</div>
					<div className={styles.name}>
						<h2>{user.fullName}</h2>
						<div>
							<div className={styles.progress}>
								<div className={styles.progressFill} style={{width: `${userLevelProgress}%`}} />
							</div>
							<div>
								<Span>See how to </Span>
								<Span
									color="blue"
									href="https://cvssupport.wpenginepowered.com/article/badges-and-achievements-in-clearview-social"
								>
									Earn Extra Points
								</Span>
							</div>
						</div>
					</div>
				</div>

				<div className={styles.badgeList}>
					<div className={styles.badges}>
						{userBadges?.earnedBadges?.slice(0, 5).map(badge => (
							<AnalyticsBadge size="mini" badge={badge} key={badge.id} />
						))}
					</div>
					<Span color="blue" bold onClick={achivementsBadgesModal.open}>
						View all achievement badges
					</Span>
				</div>
			</Card>

			{loadingDaily || loadingDailyRank ? (
				<Loading position="center" />
			) : (
				<>
					<AnalyticsCards
						cards={[
							{
								label: labelMap.rank,
								lastLabel,
								lastValue: data?.leaderBoard?.rank,
								value: dailyLeaderBoardRank?.dailyLeaderBoardRank?.rank ?? 0,
							},
							...["clicks", "emv", "shares", "smartScore"].map(t => analyticsCardInput(t)),
						]}
					/>
					<div className={styles.engagementPostCards}>
						<div>
							<ChartCard title="Post Engagement" allLabel="Engagement" data={chartData} labels={labels} />
						</div>
						<div>
							<Card column className={styles.topPostsCard}>
								<div className={classnames(styles.header, styles.grouped, "space")}>
									<h3>Popular Posts</h3>
									<Span color="blue" bold onClick={popularPostsModal.open}>
										Full view
									</Span>
								</div>
								{loadingTopPosts ? (
									<Loading position="center" />
								) : popularPosts.length ? (
									popularPosts?.map(post => (
										<div key={post.id} className={classnames(styles.topPost, "space")}>
											<OpengraphMedia openGraph={post.openGraph} />
											<div className={styles.text}>
												<P title={post.openGraph.title} bold href={post.url} trim={1}>
													{post.openGraph.title}
												</P>
												{post.openGraph.comment && (
													<Span4 title={post.openGraph.comment} trim={2}>
														{post.openGraph.comment}
													</Span4>
												)}
											</div>
											<div className={styles.topPostStats}>
												<Span2 bold>{post.clicks}</Span2>
												<Span3 color="grey">Clicks</Span3>
											</div>
											<div className={styles.topPostStats}>
												<Span2 bold>{post.shares}</Span2>
												<Span3 color="grey">Shares</Span3>
											</div>
											<div className={styles.topPostStats}>
												<Span2 bold color="blue">
													${post.emv}
												</Span2>
												<Span3 color="grey">EMV</Span3>
											</div>
										</div>
									))
								) : (
									<div className="space">
										<P4 color="grey">No posts have been shared during this time period.</P4>
									</div>
								)}
							</Card>
						</div>
					</div>
				</>
			)}
		</div>
	);
};
