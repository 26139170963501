import {useMutation, useQueryClient} from "@tanstack/react-query";

import {useAITextInputSession} from "./use-ai-text-input-session";

export const useAIAcceptTerms = () => {
	const {apiUrl, sessionId} = useAITextInputSession();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async () => {
			const resp = await fetch(`${apiUrl}/chatbot/chat/accept-terms-and-conditions`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${sessionId}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({accepted: true}),
			});

			queryClient.setQueryData(["ai-terms-and-conditions"], true);
			return resp.json();
		},
	});
};
