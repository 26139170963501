import {ReactElement} from "react";
import {Outlet} from "react-router-dom";
import classnames from "classnames";

import {EmptyComponent} from "../types";
import {useNewModal as useModal} from "../modals";
import {Sidebar} from "./sidebar";
import {SwitchRedirectModal} from "./switch-redirect";

import styles from "./layout.module.scss";

export const Layout = ({className}: EmptyComponent): ReactElement => {
	const {open, modal} = useModal({size: "medium"});

	return (
		<div className={classnames(className, styles.container)}>
			<Sidebar open={open} />
			<div className={styles.page}>
				<Outlet />
			</div>
			<SwitchRedirectModal modal={modal} />
		</div>
	);
};

export default Layout;
