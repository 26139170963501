import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";
import {Dayjs} from "dayjs";

import {Option} from "../components/input";
import {loadDate} from ".";
import {CONNECTIONS_FRAGMENT, Connections} from "./connection";

export interface Company {
	id: number;
	orgId: number;
	status: "inactive" | "active" | "pending";
	name: string;
	lastLogin?: Dayjs;
	lastShared?: Dayjs;
	email: string;
	virtualAssistant: boolean;
	peakTime: boolean;
	peakTimeSlots: Dayjs[];
	connections: Connections;
	queueDefaultTime: Dayjs;
	linkedinShareAmplifiedGroupIds: number[];
	twitterShareAmplifiedGroupIds: number[];
	twitterLikeAmplifiedGroupIds: number[];
	linkedinLikeAmplifiedGroupIds: number[];
	facebookLikeAmplifiedGroupIds: number[];
	timezone: string;
}

export const COMPANY_FRAGMENT = gql`
	fragment CompanyFields on Company {
		id
		orgId
		status
		name
		lastLogin
		lastShared
		email
		virtualAssistant
		peakTime
		peakTimeSlots
		queueDefaultTime
		timezone
		amplifiedGroups {
			twitterShareAmplifiedGroupIds
			linkedinShareAmplifiedGroupIds
			twitterLikeAmplifiedGroupIds
			linkedinLikeAmplifiedGroupIds
			facebookLikeAmplifiedGroupIds
		}
		connections {
			...ConnectionsFields
		}
	}
	${CONNECTIONS_FRAGMENT}
`;

export const GET_COMPANIES = gql`
	query {
		companies {
			...CompanyFields
		}
	}
	${COMPANY_FRAGMENT}
`;

export const GET_COMPANY_PAGES = gql`
	query {
		companyPages {
			...CompanyFields
		}
	}
	${COMPANY_FRAGMENT}
`;

export const UPDATE_COMPANY = gql`
	mutation UpdateCompany(
		$id: PositiveInt!
		$virtualAssistant: Boolean
		$peakTime: Boolean
		$peakTimeSlots: [DateTime!]
		$timezone: String
		$twitterShareAmplifiedGroupIds: [PositiveInt!]
		$linkedinShareAmplifiedGroupIds: [PositiveInt!]
		$twitterLikeAmplifiedGroupIds: [PositiveInt!]
		$linkedinLikeAmplifiedGroupIds: [PositiveInt!]
		$facebookLikeAmplifiedGroupIds: [PositiveInt!]
		$queueDefaultTime: DateTime
		$email: String
		$name: String
	) {
		updateCompany(
			id: $id
			email: $email
			name: $name
			virtualAssistant: $virtualAssistant
			peakTime: $peakTime
			peakTimeSlots: $peakTimeSlots
			timezone: $timezone
			twitterShareAmplifiedGroupIds: $twitterShareAmplifiedGroupIds
			linkedinShareAmplifiedGroupIds: $linkedinShareAmplifiedGroupIds
			queueDefaultTime: $queueDefaultTime
			twitterLikeAmplifiedGroupIds: $twitterLikeAmplifiedGroupIds
			linkedinLikeAmplifiedGroupIds: $linkedinLikeAmplifiedGroupIds
			facebookLikeAmplifiedGroupIds: $facebookLikeAmplifiedGroupIds
		) {
			...CompanyFields
		}
	}
	${COMPANY_FRAGMENT}
`;

export const DISCONNECT_COMPANY = gql`
	mutation DisconnectCompany($id: PositiveInt!, $type: ConnectionType!) {
		disconnectCompany(id: $id, type: $type) {
			...CompanyFields
		}
	}
	${COMPANY_FRAGMENT}
`;

export const DELETE_COMPANY = gql`
	mutation DeleteCompany($id: PositiveInt!) {
		deleteCompany(id: $id) {
			...CompanyFields
		}
	}
	${COMPANY_FRAGMENT}
`;

export const CONNECT_COMPANY = gql`
	mutation ConnectCompany($id: PositiveInt!, $type: ConnectionType!, $pageId: String!) {
		connectCompany(id: $id, type: $type, pageId: $pageId) {
			...CompanyFields
		}
	}
	${COMPANY_FRAGMENT}
`;

export const ADD_COMPANY = gql`
	mutation AddCompany(
		$name: String!
		$email: String!
		$virtualAssistant: Boolean!
		$peakTime: Boolean!
		$peakTimeSlots: [DateTime!]!
	) {
		addCompany(
			name: $name
			email: $email
			virtualAssistant: $virtualAssistant
			peakTime: $peakTime
			peakTimeSlots: $peakTimeSlots
		) {
			...CompanyFields
		}
	}
	${COMPANY_FRAGMENT}
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const loadCompany = (company: Record<string, any>): Company =>
	({
		...company,
		lastLogin: loadDate(company.lastLogin),
		lastShared: loadDate(company.lastShared),
		peakTimeSlots: company.peakTimeSlots.map(d => loadDate(d) as Dayjs),
		queueDefaultTime: loadDate(company.queueDefaultTime),
		linkedinShareAmplifiedGroupIds: company.amplifiedGroups.linkedinShareAmplifiedGroupIds,
		twitterShareAmplifiedGroupIds: company.amplifiedGroups.twitterShareAmplifiedGroupIds,
		twitterLikeAmplifiedGroupIds: company.amplifiedGroups.twitterLikeAmplifiedGroupIds,
		linkedinLikeAmplifiedGroupIds: company.amplifiedGroups.linkedinLikeAmplifiedGroupIds,
		facebookLikeAmplifiedGroupIds: company.amplifiedGroups.facebookLikeAmplifiedGroupIds,
	} as Company);

export const useCompanies = (): Company[] => {
	const {data, loading, error} = useQuery(GET_COMPANIES);

	return useMemo(() => {
		if (loading || error || !data?.companies) {
			return [];
		}

		return data.companies.map(loadCompany);
	}, [data, loading, error]);
};

export const sortCompany = (a: Company, b: Company): number => a.name.localeCompare(b.name);

export const useCompanyList = (): Option<number>[] => {
	const companies = useCompanies();
	return useMemo(() => companies.sort(sortCompany).map(u => ({value: Number(u.id), label: u.name})), [
		companies,
	]);
};

export const useCompanyPagesList = (): {companies: Option<number>[]; loading: boolean} => {
	const {data, loading, error} = useQuery(GET_COMPANY_PAGES);

	const companies = useMemo(() => {
		if (loading || error || !data?.companyPages) {
			return [];
		}

		return data.companyPages.map((u: Company) => ({value: Number(u.id), label: u.name}));
	}, [data, loading, error]);

	return {companies, loading};
};
export const useCompanyPages = ({skip}: {skip?: boolean} = {}): Company[] => {
	const {data, loading, error} = useQuery(GET_COMPANY_PAGES, {skip: skip ?? false});

	return useMemo(() => {
		if (loading || error || !data?.companyPages) {
			return [];
		}

		return data.companyPages.map(loadCompany);
	}, [data, loading, error]);
};
