import {ReactElement, useCallback, useEffect, useMemo, useState, useRef} from "react";
import {useQuery} from "@apollo/client";
import {useLocation, useNavigate, useParams} from "react-router";
import dayjs, {Dayjs} from "dayjs";

import {useConfirmChangeModal} from "./change-modal";
import {
	Button,
	InputRow,
	MultiColumn,
	Separator,
	SmallButton,
	TreeDropdown,
	TreeOption,
	Validate,
} from "../../components/input";
import {Loading} from "../../components/loading";
import {HidablePanel, HidingProps} from "../../components/hidable-panel";
import {Social} from "../../components/social";
import {Page} from "../../layout";
import {
	CREATE_COMPANY_SHARE,
	CREATE_SHARE,
	Connections,
	GET_SHARE,
	OPENGRAPH_FROM_URL,
	OpenGraphs,
	Service,
	Share,
	SHARE_IMMEDIATELY,
	UPDATE_SHARE,
	UPDATE_SHARE_EVENT,
	UPDATE_SHARE_NETWORK,
	accountNames,
	loadShare,
	services,
	useMyUser,
} from "../../data";
import {useMutationToast, useToast} from "../../toast";
import {useConfirmModal, NewModal as Modal, useNewModal as useModal} from "../../modals";
import {useDirtyCopy} from "../../dirty-copy";
import {ContentLibrary} from "./content-library";
import {Card} from "../../components/card";
import {P, P2, Span, Span3} from "../../components/text";
import {Icon} from "../../components/images";
import {getURL, isURL, stripURL} from "../../utils/text";
import {SocialScore} from "./social-score";
import {ScheduleArg, useBulkScheduleModal, useScheduleModal} from "./schedule-modal";
import {OGEditor} from "./opengraph-editor";
import {UserShareEventsResult} from "../../data/share";
import {useCompanyPages} from "../../data/company";
import {Pill} from "../../components/pill";
import {GET_RSS_FEED_POST} from "../../data/rssFeed";
import {REGISTER_PAGE_EVENT} from "../../data/badges";
import {useStateRef} from "../../state-ref";
import {useDebounceCallback} from "../../hooks/use-debounce-callback";

import styles from "./post.module.scss";

const humanJoin = <T extends unknown>(items: T[], mapFunc: (item: T) => string): string =>
	items.reduce((acc, item, i, arr): string => {
		let ret = acc;
		if (i > 0) {
			if (arr.length === 2) ret += " and ";
			else if (i < arr.length - 1) ret += ", ";
			else ret += ", and ";
		}
		ret += mapFunc(item);
		return ret;
	}, "");

export const EditShare = (): ReactElement => {
	const me = useMyUser();
	const toast = useToast();
	const [split, setSplit] = useState(false);
	const [valid, setValid, validRef] = useStateRef(true);
	const [newShareTime, setNewShareTime] = useState<Dayjs>();
	const [shareResult, setShareResult] = useState<UserShareEventsResult>();

	const [newId, setNewId] = useState<number>();
	const navigate = useNavigate();
	const {state} = useLocation();
	const idContent = state?.id;
	const rss = state?.rss;
	const urlParam = state?.url;
	const isShareAction = state?.action === "share";
	const startExpanded = state?.expanded;
	const shareCopiedFromParent = useRef(false);
	const shareGotFromUrl = useRef(false);
	const [recipients, setRecipients, recipientsRef] = useStateRef<Record<number, Service[]> | undefined>(
		undefined
	);
	const {id} = useParams<{id: `${number}` | "new"}>();
	const isNew = id === "new";
	const startOpen = isNew || isShareAction || startExpanded;
	const {data, loading: init} = useQuery(GET_SHARE, {
		variables: {id},
		skip: isNew,
		onError: error => toast({color: "red", text: error.message}),
	});
	const {data: shareParentPost, loading: loadingParent} = useQuery(rss ? GET_RSS_FEED_POST : GET_SHARE, {
		variables: {id: idContent},
		skip: !idContent,
		onError: error => toast({color: "red", text: error.message}),
	});
	const shareUrl = useMemo(
		() =>
			shareParentPost ? (rss ? shareParentPost.rssFeedPost?.url : shareParentPost.share.url) : undefined,
		[shareParentPost, rss]
	);
	const shareOpengraph = useMemo(
		() =>
			shareParentPost
				? rss
					? {general: shareParentPost.rssFeedPost?.item}
					: shareParentPost.share?.opengraphs
				: undefined,
		[shareParentPost, rss]
	);
	const isExpired = useMemo(() => data?.share?.expiresAt && dayjs(data.share.expiresAt).isBefore(dayjs()), [
		data?.share?.expiresAt,
	]);
	const isPageLoading = useMemo(() => init || (idContent ? loadingParent : false), [
		init,
		loadingParent,
		idContent,
	]);
	const [updateShare, {loading: saving}] = useMutationToast(UPDATE_SHARE);
	const [updateShareEvent, {loading: updating}] = useMutationToast(UPDATE_SHARE_EVENT);
	const [createCompanyShare, {loading: creatingCompany}] = useMutationToast(CREATE_COMPANY_SHARE);
	const [createShare, {loading: creating}] = useMutationToast(CREATE_SHARE);
	const [updateShareNetwork, {loading: updatingNetwork}] = useMutationToast(UPDATE_SHARE_NETWORK);
	const [shareImmediately, {loading: sharing}] = useMutationToast(SHARE_IMMEDIATELY);
	const [opengraphFromURL, {loading: loadingURL}] = useMutationToast(OPENGRAPH_FROM_URL);
	const [registerPageEvent] = useMutationToast(REGISTER_PAGE_EVENT);

	const loadedShare = useMemo(
		() =>
			(data?.share
				? loadShare(data.share)
				: {
						opengraphs: {
							general: {
								comment: "",
							},
						},
						shareEvents: [],
				  }) as Share,
		[data?.share]
	);

	const personal = isNew ? state?.type === "personal" : !!loadedShare.userId;
	const companies = useCompanyPages({skip: personal});
	const sharedEvents = useMemo(() => loadedShare.shareEvents.filter(event => event.sharedAt), [
		loadedShare.shareEvents,
	]);
	const getAvailableServices = useCallback(
		(connections: Connections) =>
			services.filter(s => connections[s === "facebook" && personal ? "facebookPage" : s]?.connected),
		[personal]
	);

	const [newSchedule, setNewSchedule] = useState(() => {
		if (!isNew || state?.type === "company") return undefined;
		const ret: ScheduleArg = {};
		getAvailableServices(me.connections).forEach(
			service => (ret[service] = {scheduledFor: undefined, peakTime: me.peakTime ?? false})
		);
		return ret;
	});
	const onUpdate = useCallback(
		({val, discard, changes}) => {
			if (!val.id) return Promise.resolve(undefined);
			const opengraphs = {...changes?.opengraphs};
			const deletions: Partial<Record<Service, boolean>> = {};
			Object.keys(opengraphs).forEach(n => {
				if (opengraphs[n] == null) {
					deletions[n] = true;
					delete opengraphs[n];
				} else {
					opengraphs[n] = {...opengraphs[n]};
					delete opengraphs[n].__typename;
				}
			});

			if (!validRef?.current) return Promise.resolve("Invalid form");

			return updateShare({
				variables: {
					id: val.id,
					changes: {opengraphs},
					deletions: {opengraphs: deletions},
					...(changes?.url !== undefined ? {url: changes.url} : {}),
				},
			}).then(discard);
		},
		[updateShare, validRef]
	);

	const {flush, update, val: share} = useDirtyCopy(loadedShare, {debounce: 2000, onUpdate});

	const postType = useMemo(() => {
		if (share.url) return "url";

		const opengraphTypes = Object.keys(share.opengraphs).filter(
			s => s !== "__typename" && !!share.opengraphs[s]
		);
		const hasOpengraphMedia = opengraphTypes.some(
			type => !!share.opengraphs[type].image || !!share.opengraphs[type].video
		);
		if (hasOpengraphMedia) return "media";

		return "text";
	}, [share]);

	const {allShares, futureShares, pastSuccessfulShares, pastFailedShares} = useMemo(() => {
		if (isNew)
			return {
				allShares: newSchedule ?? {},
				futureShares: newSchedule ?? {},
				pastSuccessfulShares: {},
				pastFailedShares: {},
			};
		const allShares: ScheduleArg = {},
			futureShares: ScheduleArg = {},
			pastSuccessfulShares: ScheduleArg = {},
			pastFailedShares: ScheduleArg = {};
		share.shareEvents.forEach(se => {
			allShares[se.network] = {scheduledFor: se.scheduledFor, peakTime: false, id: se.id};
			if (se.sharedAt && se.result) {
				pastSuccessfulShares[se.network] = {scheduledFor: se.scheduledFor, peakTime: false, id: se.id};
			}
			if (se.sharedAt && !se.result) {
				pastFailedShares[se.network] = {scheduledFor: se.scheduledFor, peakTime: false, id: se.id};
			}
			if (!se.scheduledFor || se.scheduledFor.isAfter() || !se.sharedAt || (se.sharedAt && !se.result)) {
				futureShares[se.network] = {scheduledFor: se.scheduledFor, peakTime: false, id: se.id};
			}
		});

		if (personal && isShareAction && !Object.keys(futureShares).length && !recipients) {
			getAvailableServices(me.connections).forEach(
				service =>
					!futureShares[service] &&
					(futureShares[service] = {scheduledFor: undefined, peakTime: me.peakTime ?? false})
			);
		}

		return {allShares, futureShares, pastSuccessfulShares, pastFailedShares};
	}, [
		isNew,
		personal,
		isShareAction,
		newSchedule,
		getAvailableServices,
		share.shareEvents,
		me.peakTime,
		me.connections,
		recipients,
	]);

	const createOGFromUrl = useCallback(
		creationUrl =>
			opengraphFromURL({variables: {url: creationUrl}}).then(({data}) => {
				if (!data.opengraphFromURL) return;
				data.opengraphFromURL.comment = share.opengraphs.general?.comment;
				update({opengraphs: {general: data.opengraphFromURL}, url: creationUrl});
			}),
		[opengraphFromURL, share.opengraphs.general?.comment, update]
	);

	useEffect(() => {
		if (!isNew || shareCopiedFromParent.current) {
			return;
		}
		if (shareOpengraph) {
			const cpy = {...shareOpengraph};
			delete cpy.__typename;

			update({
				opengraphs: cpy,
				url: shareUrl,
			});
			shareCopiedFromParent.current = true;
		}
		if (urlParam && !shareGotFromUrl.current) {
			createOGFromUrl(urlParam).then(() => (shareGotFromUrl.current = true));
		}
	}, [shareOpengraph, shareUrl, isNew, update, shareCopiedFromParent, urlParam, createOGFromUrl]);

	const Panel = useMemo(() => {
		const ContentLibraryPanel = ({maybeClose}: HidingProps): ReactElement => {
			const onAddPostToShare = ({url: suggestedUrl}: {url?: string | null | undefined}) => {
				if (!suggestedUrl) return Promise.resolve();
				return createOGFromUrl(suggestedUrl).finally(() => {
					maybeClose();
				});
			};
			return (
				<ContentLibrary excludedUrls={share?.url ? [share.url] : []} type="Post" onAdd={onAddPostToShare} />
			);
		};
		ContentLibraryPanel.displayName = "ContentLibraryPanel";

		return ContentLibraryPanel;
	}, [createOGFromUrl, share.url]);

	const treeProps = useMemo<{
		options: TreeOption<string, Service>[];
		value: Record<string, Service[]>;
	}>(() => {
		const key: number | undefined = personal ? me.id : share.companyId || newId;
		const services = key && recipients ? recipients[key] : undefined;
		const value = key ? {[key]: (services ?? Object.keys(futureShares)) as Service[]} : {};
		const loadOptions = (connections: Connections, label: string) =>
			getAvailableServices(connections).map(k => ({value: k, label, icon: k, hint: accountNames[k]}));
		let options: TreeOption<string, Service>[];
		if (personal)
			options = [
				{
					value: me.id.toString(),
					hint: "All Social Accounts",
					label: me.fullName,
					options: loadOptions(me.connections, me.fullName),
				},
			];
		else
			options = companies
				.filter(c => c.id === (share.companyId || newId || c.id))
				.map(company => ({
					value: company.id.toString(),
					hint: "All Social Accounts",
					label: company.name,
					options: loadOptions(company.connections, company.name),
				}));

		return {value, options, label: "Share to:", placeholder: "Select Accounts"};
	}, [
		companies,
		futureShares,
		getAvailableServices,
		me.connections,
		me.fullName,
		me.id,
		newId,
		personal,
		share.companyId,
		recipients,
	]);

	const peakTime = useMemo(() => {
		if (personal) return me.peakTime;
		if (newId) return companies.find(c => c.id === newId)?.peakTime;
	}, [companies, me.peakTime, newId, personal]);

	const deleteOGs = useCallback(() => {
		const deletionOGs = {} as OpenGraphs;
		Object.keys(share.opengraphs).forEach(n => {
			// skip for these
			if (["general", "__typename"].includes(n)) {
				return;
			}
			if (share.opengraphs[n] !== null) {
				deletionOGs[n] = null;
			}
		});
		return deletionOGs;
	}, [share.opengraphs]);

	const handleConfirm = useCallback(
		close => {
			update((cur, changes) => {
				const deletionOGs = deleteOGs();
				return {
					...changes,
					...(changes?.opengraphs?.general
						? {
								opengraphs: {
									general: changes?.opengraphs?.general,
								},
						  }
						: {
								opengraphs: deletionOGs,
						  }),
				};
			});
			/* Need to let the update happen before flushing. */
			setTimeout(
				() =>
					flush().then(() => {
						setSplit(false);
						close();
					}),
				0
			);
		},
		[flush, deleteOGs, update]
	);
	const {open} = useConfirmChangeModal({confirming: saving, onConfirm: handleConfirm});
	const {modal, open: openShareResults, close: closeResults} = useModal({});

	const {results, allPosted} = useMemo(() => {
		const results = Object.entries(shareResult ?? []).filter(([key, post]) => key !== "__typename" && post);
		const allPosted = results.every(([, {success}]) => success);

		return {results, allPosted};
	}, [shareResult]);

	const handleBulk = useCallback(() => {
		if (services.every(s => !share.opengraphs[s])) return setSplit(false);
		open();
	}, [open, share.opengraphs]);

	const handleConfirmSchedule = useCallback(
		async (value: ScheduleArg | undefined, close) => {
			for (const [n, se] of Object.entries(value ?? {})) {
				if (!se.id) {
					setNewSchedule(c => ({...c, [n]: se}));
				} else {
					await updateShareEvent({variables: {id: se.id, scheduledFor: se.scheduledFor}});
				}
			}
			close();
		},
		[updateShareEvent]
	);

	const updateShareAutoSelectedNetworks = useCallback(
		async id => {
			if (isShareAction && personal && Object.keys(futureShares).length) {
				return updateShareNetwork({
					variables: {id, services: Object.keys(futureShares)},
					update: cache => {
						cache.evict({fieldName: "userShareEvents", broadcast: false});
						cache.gc();
					},
				});
			}
		},
		[personal, isShareAction, updateShareNetwork, futureShares]
	);

	const handleShareImmediately = useCallback(
		async (id, userId, url) => {
			toast({
				color: "blue",
				text: "Your share is being posted to your social networks right now!",
				timeout: 3,
			});
			await updateShareAutoSelectedNetworks(id);
			shareImmediately({variables: {id}}).then(({data: {shareImmediately: result}}) => {
				if (result) {
					if (url) {
						registerPageEvent({
							variables: {
								type: "pastelink",
								userId,
							},
						});
					}
					setShareResult(result);
					openShareResults();
				}
			});
		},
		[updateShareAutoSelectedNetworks, openShareResults, shareImmediately, toast, registerPageEvent]
	);

	const handleFinalSchedule = useCallback(
		(value: ScheduleArg, close, execute = false) =>
			handleConfirmSchedule(value, close).then(() => {
				if (!isNew) {
					if (execute) handleShareImmediately(share.id, me?.id, null);
					else navigate("/collections/posts");
					return;
				}
				const opengraphs = {} as OpenGraphs;
				Object.keys(share.opengraphs).forEach(n => {
					if (share.opengraphs[n]) {
						opengraphs[n] = {...share.opengraphs[n]};
						delete opengraphs[n].__typename;
					}
				});
				if (personal)
					createShare({
						variables: {
							networks: Object.entries(value ?? newSchedule ?? {}).map(([n, se]) => ({
								network: n,
								scheduledFor: se.scheduledFor,
								peakTime: se.peakTime,
							})),
							url: share.url,
							opengraphs,
							timezone: dayjs.tz.guess(),
						},
					}).then(res => {
						if (!res.data) return;
						if (execute) handleShareImmediately(res.data.createShare.id, me?.id, share.url);
						else navigate("/collections/posts");
					});
				else {
					createCompanyShare({
						variables: {
							companyId: newId,
							networks: Object.entries(value ?? newSchedule ?? {}).map(([n, se]) => ({
								network: n,
								scheduledFor: se.scheduledFor,
								peakTime: se.peakTime,
							})),
							url: share.url,
							opengraphs,
							timezone: dayjs.tz.guess(),
						},
					}).then(res => {
						if (!res.data) return;
						if (execute) handleShareImmediately(res.data.createCompanyShare.id, newId, share.url);
						else navigate("/collections/posts");
					});
				}
			}),
		[
			createCompanyShare,
			createShare,
			handleConfirmSchedule,
			handleShareImmediately,
			isNew,
			navigate,
			newId,
			newSchedule,
			personal,
			share.id,
			share.opengraphs,
			share.url,
			me?.id,
		]
	);

	const {open: openFinal} = useScheduleModal({
		confirming: updating || creating || creatingCompany,
		id: share.id,
		value: futureShares,
		peakTime,
		maxDate: data?.share?.expiresAt ? dayjs(data?.share?.expiresAt) : undefined,
		onConfirm: handleFinalSchedule,
		loading: init,
	});

	const confirmBulkSchedule = useCallback((date: Dayjs | undefined, close: () => void) => {
		setNewShareTime(date);
		close();
	}, []);
	const {open: bulkSchedule} = useBulkScheduleModal({value: newShareTime, onConfirm: confirmBulkSchedule});

	const disabled =
		(isNew && (!newSchedule || !Object.keys(newSchedule).length)) ||
		(Object.keys(allShares).length > 0 && !Object.keys(futureShares).length) ||
		isExpired ||
		!valid;

	const onScheduleClick = useCallback(async () => {
		await updateShareAutoSelectedNetworks(share.id);
		await flush();
		openFinal();
	}, [updateShareAutoSelectedNetworks, share.id, flush, openFinal]);

	const {open: openShareNow} = useConfirmModal(
		() => ({
			title: "Share all posts now",
			body: "All posts will immediately be shared to social media. This action cannot be undone.",
			confirming: updating,
			onConfirm: close => {
				const sharesNow: ScheduleArg = {};
				//for new shares, schedule 5 minutes from now, to avoid immediate sharing by parallel processes
				const scheduledFor = dayjs().add(5, "minutes");

				for (const network in futureShares) {
					sharesNow[network] = {
						...futureShares[network],
						peakTime: false,
						scheduledFor: isNew ? scheduledFor : futureShares[network]?.scheduledFor,
					};
				}

				handleFinalSchedule(sharesNow, close, true).then(() => flush());
			},
		}),
		[flush, handleFinalSchedule, updating, futureShares, isNew]
	);

	const perAccount = split || services.some(s => share.opengraphs[s]);
	const socialScoreSuggestion = useMemo(() => share.smartScoreSuggestions?.general[0]?.message, [
		share.smartScoreSuggestions,
	]);
	const socialScore = useMemo(
		() => share.shareEvents.filter(se => !se.sharedAt).reduce((acc, item) => acc + item.socialScore, 0),
		[share.shareEvents]
	);
	const potentialSocialScore = useMemo(() => {
		const potential =
			socialScore + share.smartScoreSuggestions?.general.reduce((acc, item) => acc + item.value, 0);
		return (socialScore / potential || 0) * 100;
	}, [socialScore, share.smartScoreSuggestions?.general]);

	const pastSharesSocialScore = useMemo(
		() =>
			share.shareEvents
				.reduce((acc, se) => {
					if (se.sharedAt && !acc.find(ev => se.network === ev.network)) acc.push(se);
					return acc;
				}, [] as typeof share.shareEvents)
				.reduce((acc, item) => acc + item.socialScore, 0),
		[share]
	);

	const updateRecipients = useCallback(() => {
		const recipients = recipientsRef.current;

		if (!recipients) return;

		if (isNew) {
			const ids = Object.keys(recipients);
			const services = Object.values(recipients)[0];
			if (ids.length !== 1 || !services?.length) {
				setNewId(undefined);
				setNewSchedule(undefined);
				return;
			}
			setNewId(parseInt(ids[0], 10));
			setNewSchedule(c => {
				const ret: ScheduleArg = {};
				services.forEach(s => {
					if (c && s in c) {
						ret[s] = c[s];
					} else {
						ret[s] = {
							scheduledFor: newShareTime,
							peakTime: newShareTime ? false : me.peakTime ?? false,
						};
					}
				});
				return ret;
			});
		} else {
			const services = recipients[personal ? me.id : share.companyId];
			updateShareNetwork({
				variables: {id: share.id, services},
				update: cache => {
					cache.evict({fieldName: personal ? "userShareEvents" : "companyShareEvents", broadcast: false});
					cache.gc();
				},
			});
		}
	}, [isNew, me, newShareTime, personal, share.companyId, share.id, updateShareNetwork, recipientsRef]);
	const debouncedUpdateRecipients = useDebounceCallback(updateRecipients, 1500);
	const handleRecipients = useCallback(
		(v: Record<number, Service[]>) => {
			if (updatingNetwork) return;
			setRecipients(v);

			if (isNew) updateRecipients();
			else debouncedUpdateRecipients();
		},
		[isNew, debouncedUpdateRecipients, updateRecipients, setRecipients, updatingNetwork]
	);

	const onUpdateOpengraph = useCallback(
		(v, network = "general") =>
			update((cur, changes) => ({
				...changes,
				opengraphs: {
					...changes?.opengraphs,
					[network]: {
						...((changes?.opengraphs ?? cur.opengraphs)?.[network] ??
							(changes?.opengraphs ?? cur.opengraphs)?.general),
						...v,
					},
				},
			})),
		[update]
	);

	const hasMedia = useMemo(() => !!(share.opengraphs.general?.image || share.opengraphs.general?.video), [
		share,
	]);

	const onUpdateComment = useCallback(
		async (comment, modifiedNetwork = "general") => {
			let changedURL: null | string = null;
			let ogMetadata = {};
			if (postType === "text" && isURL(comment)) {
				changedURL = getURL(comment);
				const ogResult = await opengraphFromURL({variables: {url: changedURL}});

				ogMetadata = ogResult.data?.opengraphFromURL;
			}

			if ("comment" in ogMetadata) {
				delete ogMetadata.comment;
			}
			if (!changedURL && !Object.keys(ogMetadata).length) {
				onUpdateOpengraph({comment}, modifiedNetwork);
				return;
			}

			const networks = Array.from(
				new Set([...services.filter(s => Object.hasOwn(share.opengraphs, s)), "general", modifiedNetwork])
			);

			return update((cur, changes) => {
				const opengraphs = {};
				networks.forEach(network => {
					const ogChangesPerNetwork = changes?.opengraphs?.[network];

					if (ogChangesPerNetwork && changedURL && Object.hasOwn(changes?.opengraphs?.[network], "comment")) {
						ogChangesPerNetwork.comment = stripURL(ogChangesPerNetwork.comment);
					}

					if (network === modifiedNetwork && changedURL) {
						comment = stripURL(comment);
					}

					opengraphs[network] = {
						...changes?.opengraphs?.[network],
						...ogMetadata,
						...(network === modifiedNetwork
							? {
									comment,
							  }
							: {}),
					};
				});

				return {
					...changes,
					opengraphs: opengraphs,
					...(changedURL ? {url: changedURL} : {}),
				};
			});
		},
		[update, onUpdateOpengraph, share, opengraphFromURL, postType]
	);

	const resetAndRemove = useCallback(
		close => {
			close();
			update((cur, changes) => {
				const deletionOGs = deleteOGs();

				return {
					...changes,
					...(changes?.opengraphs?.general
						? {
								opengraphs: {
									general: {
										...changes?.opengraphs?.general,
										image: null,
									},
								},
								url: null,
						  }
						: {
								opengraphs: deletionOGs,
								url: null,
						  }),
				};
			});
			setTimeout(
				() =>
					flush().then(() => {
						setSplit(false);
						close();
					}),
				0
			);
		},
		[flush, deleteOGs, update]
	);

	const confirmResetAndRemove = useConfirmChangeModal({
		confirmColor: "pink",
		confirmText: "Remove URL",
		confirming: saving,
		onConfirm: resetAndRemove,
	});

	const removeURL = useCallback(() => {
		update(() => ({
			opengraphs: {
				general: {
					comment: "",
					title: "",
					image: null,
					description: "",
				},
			},
			url: null,
		}));
	}, [update]);

	const onRemoveAll = useCallback(() => {
		if (perAccount) {
			confirmResetAndRemove.open();
			return;
		}
		removeURL();
	}, [removeURL, perAccount, confirmResetAndRemove]);
	const removeMedia = useCallback(
		(network: string) =>
			update((cur, changes) => {
				const ogNetwork = {...changes?.opengraphs?.[network]};

				if (ogNetwork) {
					ogNetwork.image = null;
					ogNetwork.video = null;
				}
				return {
					...changes,
					opengraphs: {
						...changes?.opengraphs,
						[network]: ogNetwork,
					},
				};
			}),
		[update]
	);

	const setScheduledFor = useCallback(
		async (scheduledFor, shareEvent, network) => {
			if (shareEvent.id) {
				return updateShareEvent({variables: {id: shareEvent.id, scheduledFor}});
			}

			if (Object.keys(futureShares).length && share.id) {
				const result = await updateShareAutoSelectedNetworks(share.id);
				const shareEvent = result?.data?.updateShareNetwork?.shareEvents.find(
					ev => ev.network === network && !ev.result
				);

				return shareEvent?.id && (await updateShareEvent({variables: {id: shareEvent.id, scheduledFor}}));
			}

			setNewSchedule(({...c} = {}) => {
				c[network] = {scheduledFor, peakTime: false};
				return c;
			});
		},
		[updateShareEvent, updateShareAutoSelectedNetworks, share.id, futureShares]
	);
	const displayRemove = useMemo(() => {
		if (loadingURL) return false;
		if (share.url) return !perAccount;
		if (!perAccount) return hasMedia;
		return true;
	}, [share.url, hasMedia, loadingURL, perAccount]);

	const ContentEditor = () => (
		<Card>
			<MultiColumn labelRow>
				<TreeDropdown {...treeProps} onChange={handleRecipients} disabled={updatingNetwork} />
				{perAccount && share.url ? (
					<div className={styles.urlBadge}>
						<Pill
							outline
							disabled={disabled}
							textClassName={styles.postUrl}
							color="blue"
							text={share.url}
							onDelete={confirmResetAndRemove.open}
						/>
					</div>
				) : (
					<div />
				)}
				{perAccount ? (
					<SmallButton value="Go back to bulk" onClick={handleBulk} border={false} invert />
				) : (
					<div />
				)}
				{!isNew && (
					<SocialScore
						socialScore={socialScore}
						potentialSocialScore={potentialSocialScore}
						suggestion={socialScoreSuggestion}
						className={styles.socialScore}
					/>
				)}
			</MultiColumn>
			{Object.keys(pastFailedShares).length > 0 && (
				<MultiColumn>
					<div>
						<h5 className={styles.error}>Some posts couldn’t be shared</h5>
						<P>
							Confirm the social media account is connected and that you have access then try rescheduling the
							post or learn more about common errors when sharing to Social Media{" "}
							<Span color="blue" onClick={() => navigate(`/settings/${personal ? "personal" : "accounts"}`)}>
								here
							</Span>
						</P>
					</div>
				</MultiColumn>
			)}
			{!Object.keys(futureShares).length && !isNew ? null : perAccount ? (
				Object.entries(futureShares)
					.sort()
					.map(([network, se], index) => (
						<OGEditor
							hasErrors={sharedEvents.some(
								event => event.network === network && event.sharedAt && !event.result
							)}
							placeholder="Write a comment or paste a link to share"
							key={network}
							opengraph={share.opengraphs[network] ?? share.opengraphs.general ?? {}}
							network={network as Service}
							isURL={!!share.url}
							url={share.url ?? undefined}
							displayRemoveIcon={displayRemove}
							onRemove={share.url ? onRemoveAll : () => removeMedia(network)}
							isLoading={loadingURL}
							updateOpengraph={v => {
								if (v.comment) {
									onUpdateComment(v.comment, network);
								}
								return onUpdateOpengraph(v, network);
							}}
							scheduledFor={se.scheduledFor}
							setScheduledFor={(scheduledFor: Dayjs | undefined) =>
								setScheduledFor(scheduledFor, se, network)
							}
							scheduling={updating}
							showEditButton={!index}
						/>
					))
			) : (
				<OGEditor
					hasErrors={Object.keys(pastFailedShares).length > 0}
					placeholder="Write a comment or paste a link to share"
					opengraph={share.opengraphs.general ?? {}}
					schedule={isNew && !Object.keys(newSchedule ?? {}).length ? bulkSchedule : onScheduleClick}
					isLoading={loadingURL}
					isURL={!!share.url}
					displayRemoveIcon={displayRemove}
					onRemove={onRemoveAll}
					url={share.url}
					startOpen={startOpen}
					showEditButton={!startOpen}
					updateOpengraph={v => {
						if (v.comment) {
							onUpdateComment(v.comment, "general");
						}
						return onUpdateOpengraph(v, "general");
					}}
					peakTime={peakTime}
					disabled={isExpired}
				/>
			)}

			<hr />

			{!perAccount && !disabled && (
				<InputRow position="between">
					<Span3 color="grey">
						Social Media Accounts ({humanJoin(Object.keys(futureShares), item => accountNames[item])})
					</Span3>

					<SmallButton
						value="Customize post per social media"
						onClick={() => setSplit(true)}
						border={false}
						invert
					/>
				</InputRow>
			)}

			{Object.keys(pastSuccessfulShares).length > 0 && (
				<>
					<Separator />
					<div className={styles.sharedEvents}>
						<div className={styles.sharedEventsHeader}>
							<div>
								<h3>Shared</h3>
								<h5>These social media posts were already shared.</h5>
							</div>
							<SocialScore
								socialScore={pastSharesSocialScore}
								potentialSocialScore={potentialSocialScore}
								className={styles.socialScore}
							/>
						</div>
						{Object.entries(pastSuccessfulShares).map(([network, se]) => (
							<OGEditor
								disabled
								placeholder=""
								key={network}
								opengraph={share.opengraphs[network] ?? share.opengraphs.general ?? {}}
								network={network as Service}
								isURL={!!share.url}
								url={share.url}
								updateOpengraph={() => undefined}
								scheduledFor={se.scheduledFor}
								setScheduledFor={() => undefined}
								scheduling={false}
							/>
						))}
					</div>
				</>
			)}
			{!split && <Separator horizontal />}
			<InputRow className={styles.postActions}>
				<Button
					onClick={onScheduleClick}
					disabled={disabled}
					value="Schedule"
					loading={saving || updatingNetwork}
					invert
				/>
				<Button onClick={openShareNow} value="Share Now" loading={saving || sharing} disabled={disabled} />
			</InputRow>

			<Modal
				modal={modal}
				title={
					allPosted
						? `You’ve done it! The share was posted successfully${
								results.length > 1 ? " to all the networks" : ""
						  }.`
						: `Sorry, we’re unable to post to${results.length > 1 ? " all of" : ""} your networks.`
				}
			>
				<div className={styles.socialNetworkIcons}>
					{results.map(([key, post], i) => (
						<Social
							name={key as Service}
							active={post.success}
							size="large"
							key={i}
							onClick={post.success && post.url ? () => window.open(post.url, "_blank") : undefined}
						/>
					))}
				</div>
				{!allPosted &&
					results
						.filter(([, {success}]) => !success)
						.map(([, post], i) => (
							<InputRow position="center" key={i}>
								<Icon icon="information" color="pink" />
								<P2 color="pink">{post.message}</P2>
							</InputRow>
						))}
				<Button
					large
					value="Back to Posts"
					onClick={() => {
						closeResults();
						setShareResult(undefined);
						navigate("/collections/posts");
					}}
				/>
			</Modal>
		</Card>
	);

	const returnTo = state?.from || "/collections/posts";

	return (
		<Page
			title={`${me.role === "admin" ? (personal ? "Personal" : "Company") : ""} Post`}
			returnTo={returnTo}
		>
			<Validate setStatus={setValid}>
				{isPageLoading ? (
					<Loading position="center" />
				) : Object.keys(pastSuccessfulShares).length > 0 &&
				  sharedEvents.length === Object.keys(pastSuccessfulShares).length ? (
					ContentEditor()
				) : (
					<HidablePanel Panel={Panel} what="Library">
						{ContentEditor()}
					</HidablePanel>
				)}
			</Validate>
		</Page>
	);
};
