import {useMyUser} from "../../../data";
import {EditCollection} from "../../collections";
import {Page} from "../../../layout";
import {CollectionSplit} from "./collectionSplit";

import styles from "./collection.module.scss";

export const Collection = () => {
	const me = useMyUser();

	if (me.org.features.upcomingFeatures) {
		return (
			<Page
				className={styles.collectionPage}
				title="Builder"
				parentPath="/collections"
				tabs={[
					{name: "Collections", path: "/collections"},
					{name: "Posts", path: "/collections/posts"},
				]}
				fullWidth={true}
			>
				<CollectionSplit />
			</Page>
		);
	}

	return <EditCollection />;
};
