import {useQuery} from "@apollo/client";
import React, {Dispatch, SetStateAction, useCallback, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";

import {ModalHook, useConfirmModal} from "../../modals";
import {Select} from "../../components/input";
import {ADD_POST_FROM_RSS_FEED, ADD_POST_FROM_SUGGESTED_FEED, CREATE_COLLECTION} from "../../data";
import {useMutationToast} from "../../toast";
import {FeedPost as FeedPostType} from "../../data/feed";
import {GET_SIMPLE_COLLECTION} from "../../data/collection";

interface AddToCollectionProps {
	post: Omit<FeedPostType, "userId">;
	rss?: boolean;
}

interface BodyProps {
	selectedCollection: SetStateAction<number | undefined>;
	setSelectedCollection: Dispatch<SetStateAction<number | undefined>>;
}

const AddToCollectionBody: React.FC<BodyProps> = ({selectedCollection, setSelectedCollection}) => {
	const {data} = useQuery(GET_SIMPLE_COLLECTION, {variables: {limit: null}});

	const options = useMemo(() => {
		const collectionItems = data?.collections?.items ?? [];
		const itemsInPopup = collectionItems.map(c => ({
			label: c.title,
			value: c.id,
		}));

		itemsInPopup.unshift({
			label: "New Collection",
			value: undefined,
		});

		return itemsInPopup;
	}, [data]);

	return <Select options={options} value={selectedCollection} onChange={setSelectedCollection} />;
};

export function useAddToCollectionModal<T>({post, rss}: AddToCollectionProps): ModalHook<T> {
	const [selectedCollection, setSelectedCollection] = useState<number>();

	const navigate = useNavigate();
	const [addPostFromRssFeed] = useMutationToast(ADD_POST_FROM_RSS_FEED);
	const [addPostFromSuggestedFeed] = useMutationToast(ADD_POST_FROM_SUGGESTED_FEED);

	const onAddPostToCollection = useCallback(
		async collectionId => {
			if (rss) {
				await addPostFromRssFeed({variables: {id: collectionId, postId: post.id}});
			} else {
				await addPostFromSuggestedFeed({
					variables: {id: collectionId, postId: post.id, postType: "shared"},
				});
			}

			navigate(`/collections/${collectionId}`);
		},
		[addPostFromRssFeed, addPostFromSuggestedFeed, navigate, post, rss]
	);

	const [createCollection] = useMutationToast(CREATE_COLLECTION, {
		onCompleted: ({createCollection: {id}}) => onAddPostToCollection(id),
	});

	const handleContinue = useCallback(
		close => {
			if (selectedCollection === undefined) {
				return createCollection().then(() => close());
			}

			return onAddPostToCollection(selectedCollection).then(() => close());
		},
		[createCollection, onAddPostToCollection, selectedCollection]
	);

	return useConfirmModal(
		() => ({
			title: "Add To Collection",
			body: (
				<AddToCollectionBody
					selectedCollection={selectedCollection}
					setSelectedCollection={setSelectedCollection}
				/>
			),
			onConfirm: handleContinue,
		}),
		[handleContinue, selectedCollection]
	);
}
