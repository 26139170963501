import {useMutation, useQueryClient} from "@tanstack/react-query";

import {useAITextInputSession} from "./use-ai-text-input-session";
import {AiToneType} from "./ai-assistant-form";

type ActionType = "shorten" | "expand" | "rephrase" | "refine";

type ExecutPrompt = {
	prompt?: string;
	action?: ActionType;
	tone?: AiToneType["value"];
	hashtag?: boolean;
	emoji?: boolean;
	maxLength?: number;
};

export type AIAssistantFormResponse = {
	message: string;
};

const getFullPrompt = ({
	prompt = "",
	action = "refine",
	tone = "polite",
	hashtag = false,
	emoji = false,
	maxLength,
}: ExecutPrompt) => {
	const instructionsMap = {
		shorten: `Please shorten the following message for brevity.`,
		expand: `Please expand the following message for more detail.`,
		rephrase: `Please rephrase the following message for better clarity.`,
		refine: `Please refine the following message for clarity and check for errors.`,
	};
	const instruction = instructionsMap[action] || instructionsMap["refine"];
	const toneInstruction = `Text tone: ${tone}`;
	const emojiInstruction = emoji
		? "Embellish the text with emojis appropriate to the sentiment."
		: "Do not include emojis.";
	const hashtagInstruction = hashtag
		? "Append relevant hashtags to highlight key concepts."
		: "Do not include hashtags.";
	const lengthInstruction = maxLength
		? `The response should be no more than ${maxLength} characters long.`
		: "";
	const outputInstructions =
		"Do not include any additional explanations or acknowledgments, and do not wrap responses in quotes.";

	return `${instruction} ${toneInstruction} ${emojiInstruction} ${hashtagInstruction} ${lengthInstruction} ${outputInstructions} Message: "${prompt}"`;
};

export const useExecutePrompt = ({formId}: {formId: string}) => {
	const {apiUrl, sessionId} = useAITextInputSession();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (params: ExecutPrompt): Promise<AIAssistantFormResponse> => {
			const data = {
				prompt: getFullPrompt(params),
			};

			const resp = await fetch(`${apiUrl}/chatbot/chat`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${sessionId}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			});

			const responseData = await resp.json();

			queryClient.setQueryData(["ai-message", formId], responseData);
			return responseData;
		},
	});
};
